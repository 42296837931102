import { Button } from "@primitives/button";
import { H1, P } from "@primitives/typography";
import { ChevronLeft } from "lucide-react";
import { useNavigate, useRouteError } from "react-router-dom";

function Error() {
  const error = useRouteError();
  console.log("error", error);
  const nav = useNavigate();

  return (
    <div className="flex min-h-screen items-center justify-center dark:bg-gray-950 dark:text-gray-300">
      <div>
        <H1>Oups! That didn't work as expected.</H1>
        <P className="text-gray-400">
          This has been logged and we will look into it.
        </P>
        <P>
          <Button variant={"ghost"} onClick={() => nav("/")}>
            <ChevronLeft size={20} className="mr-2" />
            Return to dashboard
          </Button>
        </P>
      </div>
    </div>
  );
}

export default Error;
