import { FC, ReactElement, useEffect, useState } from "react";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { Card } from "@primitives/card.tsx";
import { Input } from "@primitives/input.tsx";
import { Space } from "@components/space.tsx";
import { Button } from "@primitives/button.tsx";
import { PlaceholderInput } from "@pages/settings/categories/components/placeholder-input.tsx";
import { MultiSelect } from "@primitives/multi-select.tsx";
import { X } from "lucide-react";
import { useGetBedAccessory } from "@api/bed-types.ts";
import {
  BedTypeAccessory,
  GetBedTypeResponse,
} from "../../../../../../api-contracts/bed-capacity";
import { useTranslation } from "react-i18next";
import { useGetLocations } from "@api/locations.ts";
import { Switch } from "@primitives/switch.tsx";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";

export type CreateEdit = "edit" | "create";
interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  bedData: GetBedTypeResponse | undefined;
  type: CreateEdit;
  btnText: string;
}
export const ExtraBedSheet: FC<Props> = ({
  open,
  onOpenChange,
  bedData,
  type,
  btnText,
}): ReactElement => {
  const emptyBed: GetBedTypeResponse = {
    bedAccessories: [],
    capacityFullSize: 0,
    capacityHalfSize: 0,
    cot: false,
    extraBed: false,
    id: "",
    internalPrice: 0,
    length: 0,
    locationInventories: [],
    name: "",
    totalExtraBeds: 0,
    width: 0,
  };
  const { t } = useTranslation();
  const { updateBed, createBed } = useBedData();
  const { data: bedAccessory } = useGetBedAccessory();
  const { data: locations } = useGetLocations();
  const [bed, setBed] = useState<GetBedTypeResponse>(emptyBed);
  const [selectedAccessorys, setSelectedAccessorys] = useState<
    { id: string; name: string; quantity: number }[]
  >([]);
  const [selectedLocations, setSelectedLocations] = useState<
    BedTypeAccessory[]
  >([]);

  const [availableAccessories, setAvailableAccessories] = useState<
    BedTypeAccessory[]
  >([]);
  const [availableLocations, setAvailableLocations] = useState<
    { id: string; name: string; quantity: number }[]
  >([]);

  useEffect(() => {
    setBed(bedData ?? emptyBed);
    setSelectedAccessorys(bedData?.bedAccessories ?? []);
    setSelectedLocations(bedData?.locationInventories ?? []);
  }, [bedData]);

  useEffect(() => {}, [selectedLocations]);

  useEffect(() => {
    if (!bedAccessory) return;
    const list = bedAccessory.map((elem) => ({
      id: elem.id,
      name: elem.name,
      quantity: 1,
    }));
    setAvailableAccessories(list);
    if (!locations) return;
    const list2 = locations.map((elem) => ({
      id: elem.id,
      name: elem.name,
      quantity: 1,
    }));
    setAvailableLocations(list2);
  }, [bedAccessory]);

  const updateAccessoryQuantity = (id: string, quantity: number) => {
    const newList = selectedAccessorys.map((item) => {
      if (item.id == id) {
        return { ...item, quantity: quantity };
      } else {
        return item;
      }
    });
    setSelectedAccessorys(newList);
  };

  const updateLocationQuantity = (id: string, quantity: number) => {
    const newList = selectedLocations.map((item) => {
      if (item.id == id) {
        return { ...item, quantity: quantity };
      } else {
        return item;
      }
    });
    setSelectedLocations(newList);
  };
  useEffect(() => {
    if (bedData?.locationInventories)
      setSelectedLocations(bedData?.locationInventories);
  }, [bedData]);

  useEffect(() => {
    setBed({
      ...bed,
      bedAccessories: selectedAccessorys,
    });
  }, [selectedAccessorys]);

  return (
    <DefaultSideSheet
      title={type === "edit" ? t("edit") : t("create-extra-bed")}
      open={open}
      onOpenChange={onOpenChange}
    >
      <div className={"flex h-full flex-col justify-between overflow-auto"}>
        <div className={"flex flex-col justify-between"}>
          <div className={"flex flex-grow flex-col"}>
            <Card className={" mt-0 bg-secondary-card-backplate"}>
              <div>
                <p className={"font-extrabold"}>{t("bed-information")}</p>
              </div>
              <div className={"mt-3"}>
                <div className={"flex"}>
                  <p>{t("name")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                <Input
                  className={
                    "font-neue text-[15px] font-normal text-secondary-text"
                  }
                  type={"text"}
                  value={bed.name ?? t("name")}
                  onChange={(event) =>
                    setBed({ ...bed, name: event.target.value })
                  }
                  placeholder={t("name")}
                />
                <Space h={8} />
                <div className={"flex"}>
                  <p>{t("width")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                <PlaceholderInput
                  defaultValue={t("width")}
                  placeholder={"cm"}
                  value={bed.width === 0 ? t("width") : bed.width?.toString()}
                  onChange={(event) =>
                    setBed({ ...bed, width: Number(event.target.value) })
                  }
                />
                <Space h={8} />
                <div className={"flex"}>
                  <p>{t("length")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                <PlaceholderInput
                  defaultValue={t("length")}
                  placeholder={"cm"}
                  value={bed.length === 0 ? t("width") : bed.length?.toString()}
                  onChange={(event) =>
                    setBed({ ...bed, length: Number(event.target.value) })
                  }
                />
                <Space h={8} />
                <div className={"flex"}>
                  <p>{t("internal-cost")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                <PlaceholderInput
                  defaultValue={t("internal-cost")}
                  placeholder={"SEK"}
                  value={
                    bed.internalPrice === 0
                      ? t("internal-cost")
                      : bed.internalPrice?.toString()
                  }
                  onChange={(event) =>
                    setBed({
                      ...bed,
                      internalPrice: Number(event.target.value),
                    })
                  }
                />
              </div>
            </Card>
            <Space h={18} />
            <Card className={" bg-secondary-card-backplate"}>
              <div>
                <p className={"font-extrabold"}>{t("number-per-area")}</p>
              </div>
              <div className={"mt-3"}>
                <div className={"mt-3"}>
                  {locations && (
                    <MultiSelect
                      placeholder={t("add-area")}
                      options={availableLocations}
                      value={availableLocations?.filter((group) =>
                        selectedLocations?.some(
                          (selected) => selected.name === group.name,
                        ),
                      )}
                      onChange={(value: BedTypeAccessory[]) =>
                        setSelectedLocations(value)
                      }
                      getName={(opt) => opt.name}
                    />
                  )}
                </div>
                <Space h={12} />
                {selectedLocations?.map((accessory) => {
                  return (
                    <div key={accessory.id}>
                      <Card
                        className={
                          "rounded-xl, flex h-[50px] items-center bg-primary-card-backplate"
                        }
                      >
                        <div className={"flex w-full justify-between"}>
                          <div className={"flex items-center"}>
                            <X
                              onClick={() => {
                                setSelectedLocations(
                                  selectedLocations?.filter(
                                    (item) => item.name !== accessory.name,
                                  ),
                                );
                              }}
                              size={14}
                              className={
                                "cursor-pointer text-primary-icon-color"
                              }
                            />
                            <Space w={12.5} />
                            <p
                              key={accessory.name}
                              className={
                                "h-[12px] font-neue text-xs font-extrabold"
                              }
                            >
                              {accessory.name}
                            </p>
                          </div>
                          <div>
                            <Input
                              min={1}
                              type={"number"}
                              onChange={(event) =>
                                updateLocationQuantity(
                                  accessory.id,
                                  Number(event.target.value),
                                )
                              }
                              value={accessory.quantity}
                              className={
                                "h-[35px] w-[35px] rounded-lg bg-primary-button-backplate p-0 text-center"
                              }
                            />
                          </div>
                        </div>
                      </Card>
                      <Space h={8} />
                    </div>
                  );
                })}
              </div>
            </Card>
            <Space h={18} />
            <Card className={" bg-secondary-card-backplate"}>
              <div className={"flex"}>
                <p className={"font-extrabold"}>{t("bed-capacity")} </p>
                <Space w={3} />
                <p>({t("number-of-beds")})</p>
              </div>
              <Card className={"flex p-4"}>
                <Switch
                  defaultChecked={false}
                  onCheckedChange={() =>
                    setBed({
                      ...bed,
                      cot: !bed.cot,
                    })
                  }
                />
                <p className={"f text- ml-2 font-extrabold"}>{t("cot")}</p>
              </Card>
              {!bed.cot && (
                <div className={"mt-3"}>
                  <div className={"flex"}>
                    <p>{t("max-number-of-full-beds")}</p>
                    <p className={"pl-1.5 text-red-700"}>*</p>
                  </div>
                  <PlaceholderInput
                    defaultValue={t("max-number-of-full-beds")}
                    value={
                      bed.capacityFullSize === 0
                        ? t("max-number-of-full-beds")
                        : bed.capacityFullSize?.toString()
                    }
                    onChange={(event) =>
                      setBed({
                        ...bed,
                        capacityFullSize: Number(event.target.value),
                      })
                    }
                  />
                  <Space h={8} />
                  <div className={"flex"}>
                    <p>{t("max-number-of-shared-beds")}</p>
                    <p className={"pl-1.5 text-red-700"}>*</p>
                  </div>
                  <PlaceholderInput
                    defaultValue={t("max-number-of-shared-beds")}
                    value={
                      bed.capacityHalfSize === 0
                        ? t("max-number-of-shared-beds")
                        : bed.capacityHalfSize?.toString()
                    }
                    onChange={(event) =>
                      setBed({
                        ...bed,
                        capacityHalfSize: Number(event.target.value),
                      })
                    }
                  />
                </div>
              )}
            </Card>
            <Space h={18} />
            <Card className={" bg-secondary-card-backplate"}>
              <div>
                <p className={"font-extrabold"}>
                  {t("accessory-dropdown-title")}
                </p>
              </div>
              <div className={"mt-3"}>
                {bedAccessory && (
                  <MultiSelect
                    placeholder={t("accessory-dropdown-title")}
                    options={availableAccessories}
                    value={availableAccessories.filter((group) =>
                      selectedAccessorys?.some(
                        (selected) => selected.name === group.name,
                      ),
                    )}
                    onChange={(value: BedTypeAccessory[]) => {
                      setSelectedAccessorys(value);
                    }}
                    getName={(opt) => opt.name}
                  />
                )}
              </div>
              <div>
                <Space h={12} />
                {selectedAccessorys?.map((accessory) => {
                  return (
                    <div key={accessory.id}>
                      <Card
                        className={
                          "rounded-xl, flex h-[50px] items-center bg-primary-card-backplate"
                        }
                      >
                        <div className={"flex w-full justify-between"}>
                          <div className={"flex items-center"}>
                            <X
                              onClick={() => {
                                setSelectedAccessorys(
                                  selectedAccessorys?.filter(
                                    (item) => item.name !== accessory.name,
                                  ),
                                );
                              }}
                              size={14}
                              className={
                                "cursor-pointer text-primary-icon-color"
                              }
                            />
                            <Space w={12.5} />
                            <p
                              key={accessory.name}
                              className={
                                "h-[12px] font-neue text-xs font-extrabold"
                              }
                            >
                              {accessory.name}
                            </p>
                          </div>
                          <div>
                            <Input
                              min={1}
                              type={"number"}
                              onChange={(event) =>
                                updateAccessoryQuantity(
                                  accessory.id,
                                  Number(event.target.value),
                                )
                              }
                              value={accessory.quantity}
                              className={
                                "h-[35px] w-[35px] rounded-lg bg-primary-button-backplate p-0 text-center"
                              }
                            />
                          </div>
                        </div>
                      </Card>
                      <Space h={8} />
                    </div>
                  );
                })}
              </div>
            </Card>
          </div>
        </div>
        <Space h={20} />
        <div className="flex justify-end">
          <Button
            className={"rounded-xl"}
            onClick={() => {
              const bedAccessories = selectedAccessorys.map((acc) => ({
                id: acc.id,
                quantity: acc.quantity,
              }));
              const bedLocations = selectedLocations.map((acc) => ({
                id: acc.id,
                quantity: acc.quantity,
              }));
              const bedToAdd = {
                name: bed.name,
                width: bed.width,
                length: bed.length,
                capacityFullSize: bed.capacityFullSize,
                capacityHalfSize: bed.capacityHalfSize,
                extraBed: true,
                internalPrice: bed.internalPrice ? bed.internalPrice : 0,
                cot: bed.cot,
                bedAccessoryIds: bedAccessories,
                locationInventoryIds: bedLocations,
              };

              if (type === "create") {
                createBed(bedToAdd).then(() => setBed(emptyBed));
              } else {
                updateBed(bedToAdd, bed.id).then(() => setBed(emptyBed));
              }
              onOpenChange(!open);
            }}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </DefaultSideSheet>
  );
};
