import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { DefaultInput } from "@primitives/default-input.tsx";
import {
  ChannelsActionType,
  useChannelsPageContext,
} from "../channels-context.tsx";
import { useCreateChannel } from "@api/channels.ts";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const AddChannel = () => {
  const {
    sharedState: { actionType },
    sharedFunctions: { setActionType, handleRefetch },
  } = useChannelsPageContext();
  const {
    sharedFunctions: { toast, setLoading },
  } = useSharedContext();
  const { t } = useTranslation();

  const { mutate: addChannel } = useCreateChannel();

  const createChannel = (req: { name: string; url: string }) => {
    addChannel(req, {
      onError: (e) => {
        toast({
          variant: "destructive",
          title: t("create-channel-error", { error: e.message }),
        });
      },
      onSuccess: async () => {
        await handleRefetch();
      },
      onSettled: () => {
        setLoading(false);
        setActionType(ChannelsActionType.DEFAULT);
      },
    });
  };

  return (
    <DefaultSideSheet
      open={actionType === ChannelsActionType.ADD}
      onOpenChange={() => setActionType(ChannelsActionType.DEFAULT)}
      side="left"
      title={t("create-channel")}
    >
      <DefaultInput
        onSubmit={(value: string) => {
          createChannel({ name: value, url: value });
        }}
        buttonText={t("create")}
        inputName="channel-add-name"
        placeholder={t("name")}
        required={true}
      />
    </DefaultSideSheet>
  );
};
