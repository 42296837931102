import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (
    selected: "asset" | "price" | "departure",
  ) => void | Promise<void>;
}

export const BookingChangeOptionsCheckedInDialog = ({
  open,
  onOpenChange,
  onProceed,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit max-w-fit">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("what-do-you-want-to-change?")}
            </DialogTitle>

            <div className=" mt-4 flex space-x-2">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  onOpenChange(false);
                  onProceed("asset");
                }}
              >
                {t("unit")}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  onOpenChange(false);
                  onProceed("price");
                }}
              >
                {t("price-code")}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  onOpenChange(false);
                  onProceed("departure");
                }}
              >
                {t("departure")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BookingChangeOptionsCheckedInDialog;
