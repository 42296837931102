import { AdminState } from "../context";
import { OrgUserSection } from "./org-users";
import { OrganizationSection } from "./organizations";
import { SiteUserSection } from "./site-users";
import { SiteSection } from "./sites";
import { OrgRolesSection } from "./org-roles";

export type AdminNode = {
  from: AdminNode | null;
  to: AdminNode[];
  name: string;
  component: React.FC<unknown>;
  enabled: (state: AdminState) => boolean;
};

export const OrganizationNode: AdminNode = {
  from: null,
  name: "Organizations",
  component: OrganizationSection,
  to: [],
  enabled: () => true,
};

const SiteNode: AdminNode = {
  from: OrganizationNode,
  to: [],
  name: "Sites",
  component: SiteSection,
  enabled: (state) => !!state.organization,
};

const SiteUserNode: AdminNode = {
  from: SiteNode,
  to: [],
  name: "Users",
  component: SiteUserSection,
  enabled: (state) => !!state.site,
};

const OrgUserNode: AdminNode = {
  from: OrganizationNode,
  to: [],
  name: "Users",
  component: OrgUserSection,
  enabled: (state) => !!state.organization,
};

const OrgRolesNode: AdminNode = {
  from: OrganizationNode,
  to: [],
  name: "Roles",
  component: OrgRolesSection,
  enabled: (state) => !!state.organization,
};

OrganizationNode.to.push(SiteNode, OrgUserNode, OrgRolesNode);
SiteNode.to.push(SiteUserNode);

export const extractParentNodes = (
  node: AdminNode,
  nodes: AdminNode[] = [],
): AdminNode[] => {
  if (!node.from) {
    return nodes;
  }
  const newNodes = [node.from, ...nodes];
  return extractParentNodes(node.from, newNodes);
};
