import {
  FormEvent,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import {
  CreateUserRequest,
  OrganizationPermission,
  User,
} from "../../../../../../api-contracts/admin";
import { ToggleGroup, ToggleGroupItem } from "@primitives/toggle-group";
import { Button } from "@primitives/button";
import { z } from "zod";
import { Skeleton } from "@primitives/skeleton";
import { PatchOrgUserRequest } from "../../../../../../api-contracts/organizations";
import { EventFor } from "@shared/types/props";
import { omit } from "@utils/helpers";

const capitalize = (str: string) =>
  str.length > 0 ? str[0].toUpperCase() + str.slice(1) : "";

const createUserSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  password: z.string().min(6),
  grants: z.string().array().optional(),
});

const editUserSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  grants: z.string().array().optional(),
});

const Input = (props: {
  type: HTMLInputTypeAttribute;
  label: string;
  placeholder: string;
  value?: InputHTMLAttributes<HTMLInputElement>["value"];
  onChange?: (e: EventFor<"input", "onChange">) => void;
}) => (
  <div className="mb-4 flex">
    <label
      className="h-100 flex  w-28 items-center rounded-l border border-r-0 bg-gray-50 p-1 pl-4 pr-4 text-sm font-bold text-gray-700"
      htmlFor={props.label}
    >
      {props.label}
    </label>
    <input
      className="w-full appearance-none rounded-r border px-3 py-2 focus:outline-none"
      id={props.label}
      type={props.type}
      value={props.value}
      onChange={props.onChange ?? console.log}
      placeholder={props.placeholder}
    />
  </div>
);
const emptyUser: CreateUserRequest = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  orgId: "",
  grants: [],
};

interface BaseUserFormProps {
  onSubmit: (user: CreateUserRequest) => void;
  loading: boolean;
  loadingGrants: boolean;
  grants: string[];
  setPassword: boolean;
  schema: z.AnyZodObject;
  user?: CreateUserRequest;
}

export const BaseUserForm = (props: BaseUserFormProps) => {
  const [user, setUser] = useState<Partial<CreateUserRequest>>({
    ...(props.user ?? emptyUser),
  });
  const [inputOk, setInputOk] = useState<boolean>(false);

  useEffect(() => {
    const { success } = props.schema.safeParse(user);
    setInputOk(success);
  }, [props.schema, user]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit(user as CreateUserRequest);
    setUser({ ...emptyUser });
  };

  return (
    <>
      {!props.loadingGrants ? (
        <form className="rounded border p-6 shadow" onSubmit={onSubmit}>
          <Input
            label="Name"
            placeholder="Insert name"
            type="text"
            value={user.firstName}
            onChange={(e) =>
              setUser((user) => ({
                ...user,
                firstName: e.target.value,
              }))
            }
          />
          <Input
            label="Surname"
            placeholder="Insert surname"
            type="text"
            value={user.lastName}
            onChange={(e) =>
              setUser((user) => ({
                ...user,
                lastName: e.target.value,
              }))
            }
          />
          <Input
            label="Email"
            placeholder="Insert email"
            type="email"
            value={user.email}
            onChange={(e) =>
              setUser((user) => ({
                ...user,
                email: e.target.value,
              }))
            }
          />
          {props.setPassword && (
            <Input
              label="password"
              placeholder="*****"
              type="password"
              value={user.password}
              onChange={(e) =>
                setUser((user) => ({
                  ...user,
                  password: e.target.value,
                }))
              }
            />
          )}
          <div className="mb-4 flex">
            <label
              className="h-100 flex  w-24 items-center rounded border  bg-gray-50 p-1 pl-4 pr-4 text-sm font-bold text-gray-700"
              htmlFor="role-select"
            >
              Grants
            </label>
            <ToggleGroup
              type="multiple"
              className="pl-4"
              value={user.grants}
              onValueChange={(grants) =>
                setUser((user) => ({
                  ...user,
                  grants: grants as OrganizationPermission[],
                }))
              }
            >
              {props.grants.map((grant) => (
                <ToggleGroupItem
                  key={grant}
                  value={grant}
                  aria-label="Toggle bold"
                >
                  <span>{capitalize(grant)}</span>
                </ToggleGroupItem>
              ))}
            </ToggleGroup>
          </div>
          <div className="flex justify-end">
            <Button
              loading={props.loading}
              variant={"purple"}
              type="submit"
              disabled={!inputOk}
            >
              Submit
            </Button>
          </div>
        </form>
      ) : (
        <div className="h-full w-full space-y-4">
          <Skeleton className="h-10" />
          <Skeleton className="h-10" />
          <Skeleton className="h-10" />
          <Skeleton className="h-10" />
          <Skeleton className="h-10 w-2/4" />
          <div className="flex w-full justify-end">
            <Skeleton className="h-10 w-36" />
          </div>
        </div>
      )}
    </>
  );
};

export const CreateUserForm = (props: {
  onSubmit: (user: CreateUserRequest) => void;
  loading: boolean;
  loadingGrants: boolean;
  grants: string[];
}) => {
  return (
    <BaseUserForm {...props} schema={createUserSchema} setPassword={true} />
  );
};

export const EditUserForm = (props: {
  onSubmit: (body: { user: PatchOrgUserRequest; id: string }) => void;
  loading: boolean;
  loadingGrants: boolean;
  grants: string[];
  user: User;
}) => {
  const { id, ...data } = props.user;
  const onSubmit = (user: CreateUserRequest) => {
    props.onSubmit({ id, user: omit(user, ["password"]) });
  };
  return (
    <BaseUserForm
      key={id}
      {...props}
      schema={editUserSchema}
      setPassword={false}
      onSubmit={onSubmit}
      user={{ ...data, password: "" }}
    />
  );
};
