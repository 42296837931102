import { ActionFunctionArgs } from "react-router-dom";
import { z } from "zod";
import { LoginRequest, LoginResponse } from "../../../../api-contracts/auth";
import { api } from "@api/api";
import { setAuth } from "@utils/auth-manager";
import { t } from "i18next";
import { AuthUser } from "@context/auth-context";

const LoginSchema = z.object({
  email: z
    .string({ required_error: t("login.email-required") })
    .email({ message: t("login.email-invalid") }),
  password: z
    .string({ required_error: t("login.password-required") })
    .min(6, { message: t("login.password-invalid") }),
  remember: z.coerce.boolean().default(false),
} satisfies Record<keyof LoginRequest | "remember", z.ZodTypeAny>);

type LoginCredentials = z.infer<typeof LoginSchema>;

export async function loader(): Promise<LoginCredentials> {
  const email = localStorage.getItem("email");
  return {
    email,
    password: "",
    remember: email ? true : false,
  } as LoginCredentials;
}

type LoginActionError = {
  success: false;
  errors: Partial<Record<keyof LoginCredentials, string> & { root: string }>;
} & LoginCredentials;

type LoginActionSuccess = {
  success: true;
} & AuthUser;

export async function action({
  request,
}: ActionFunctionArgs): Promise<LoginActionError | LoginActionSuccess> {
  const credentials = await request.formData().then(Object.fromEntries);
  const result = LoginSchema.safeParse(credentials);
  if (!result.success) {
    return {
      success: false,
      errors: Object.fromEntries(
        result.error.issues.map((issue) => [issue.path[0], issue.message]),
      ),
      ...credentials,
    } satisfies LoginActionError;
  }

  const { email, password, remember } = result.data;
  maybeSaveEmail(remember, email);

  return api
    .post<LoginResponse>("/api/auth/login", { email, password })
    .then((user) => {
      setAuth(user);
      return {
        success: true,
        ...user,
      } satisfies LoginActionSuccess;
    })
    .catch((err) => {
      return {
        success: false,
        errors: { root: err.message },
        ...result.data,
      } satisfies LoginActionError;
    });
}

function maybeSaveEmail(remember: boolean, email: string) {
  if (remember) return localStorage.setItem("email", email);

  localStorage.removeItem("email");
}
