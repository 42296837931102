import { SetStateAction, useEffect, useState } from "react";
import {
  FixtureGroup,
  FixtureGroupWithFixtures,
  FixtureWithGroups,
} from "../../../../../../api-contracts/fixtures-and-items";
import { Input } from "@primitives/input";
import { Button } from "@primitives/button";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { Added, Available } from "./sheet-components";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

const initialFixtureWithGroups: FixtureWithGroups = {
  id: "",
  name: "",
  groups: [],
};
export const FixtureSheet = ({
  availableFixtureGroups,
  openSheet,
  setOpenSheet,
  fixtureToEdit,
  onSave,
}: {
  availableFixtureGroups: FixtureGroupWithFixtures[] | undefined;
  openSheet: boolean;
  setOpenSheet: React.Dispatch<SetStateAction<boolean>>;
  fixtureToEdit: FixtureWithGroups;
  onSave: (fixtureWithGrp: FixtureWithGroups) => void;
}) => {
  const { t } = useTranslation();
  const [tempFixtureWithGrp, setTempFixtureWithGrp] =
    useState<FixtureWithGroups>(initialFixtureWithGroups);
  const [addedGroups, setAddedGroups] = useState<FixtureGroup[]>([]);

  const handleAddFixtureGroup = (group: FixtureGroup) => {
    setAddedGroups((prev) => {
      if (!prev.some((g) => g.id === group.id)) {
        return [...prev, group];
      }
      return prev;
    });
  };

  const handleRemoveFixtureGroup = (group: FixtureGroup) => {
    setAddedGroups((prev) => {
      return prev.filter((g) => g.id !== group.id);
    });
  };

  const groupsHasChanges = !isEqual(fixtureToEdit.groups, addedGroups);
  const nameHasChanges = !isEqual(fixtureToEdit.name, tempFixtureWithGrp.name);
  const nameIsEmpty = tempFixtureWithGrp.name.length > 0;
  const saveIsEnabled: boolean =
    (groupsHasChanges || nameHasChanges) && nameIsEmpty;

  useEffect(() => {
    if (openSheet) {
      setTempFixtureWithGrp(fixtureToEdit);
      setAddedGroups(fixtureToEdit.groups || []);
    } else {
      setTempFixtureWithGrp(initialFixtureWithGroups);
      setAddedGroups([]);
    }
  }, [openSheet, fixtureToEdit]);

  const handleSave = () => {
    const data = {
      id: tempFixtureWithGrp.id,
      name: tempFixtureWithGrp.name,
      groups: addedGroups,
    };
    onSave(data);
  };

  return (
    <>
      <DefaultSideSheet
        className="sm:w-[600px]"
        open={openSheet}
        onOpenChange={setOpenSheet}
        title={tempFixtureWithGrp.id ? t("edit-fixture") : t("create-fixture")}
      >
        <div>
          <Input
            required
            className="bg-primary-card-backplate"
            type="text"
            minLength={1}
            maxLength={20}
            placeholder={t("name")}
            value={tempFixtureWithGrp.name}
            onChange={(e) =>
              setTempFixtureWithGrp((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
        </div>
        <p className="text-base font-bold text-primary-text">
          {t("add-to-groups")}
        </p>
        <div className="flex flex-grow gap-4 overflow-auto">
          {availableFixtureGroups && (
            <Available
              addedItems={addedGroups}
              availableItems={availableFixtureGroups}
              onAdd={handleAddFixtureGroup}
            />
          )}
          <Added addedItems={addedGroups} onRemove={handleRemoveFixtureGroup} />
        </div>

        <div className="flex justify-end">
          <Button disabled={!saveIsEnabled} onClick={() => handleSave()}>
            {t("save")}
          </Button>
        </div>
      </DefaultSideSheet>
    </>
  );
};
