import { PriceRulesPageProvider } from "@pages/booking/price-rules/price-rules-context.tsx";
import { PriceRulesBaseView } from "@pages/booking/price-rules/components/price-rules-base-view.tsx";
import { AddPriceRules } from "@pages/booking/price-rules/components/add-price-rules.tsx";
import { EditPriceRules } from "@pages/booking/price-rules/components/edit-price-rules.tsx";

function Pricing() {
  return (
    <PriceRulesPageProvider>
      <AddPriceRules />
      <EditPriceRules />
      <PriceRulesBaseView />
    </PriceRulesPageProvider>
  );
}

export default Pricing;
