import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "@primitives/dialog";
import { Button } from "@primitives/button";
import { useGetAllocableBookables } from "@api/bookables";
import { useGetCategories } from "@api/categories";
import { queryClient } from "../../../../query-client.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import {
  useAllocateReservation,
  useGetUnallocatedReservations,
} from "@api/reservations";
import { useToast } from "@hooks/use-toast";
import { format } from "date-fns";

export const AllocateBookableDialog: FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
  reservationId: string;
  holderName: string;
  reservationCategoryId: string;
  from: Date | string;
  to: Date | string;
}> = ({
  open,
  onOpenChange,
  reservationId,
  holderName,
  reservationCategoryId,
  from,
  to,
}) => {
  const { t } = useTranslation();
  const [categoryId, setCategoryId] = useState<string>(
    reservationCategoryId.toString(),
  );
  const [bookableId, setBookableId] = useState<string>();
  const { toast } = useToast();

  const allocateReservation = useAllocateReservation();

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    isRefetching: categoriesRefetching,
  } = useGetCategories({
    variables: { categoryTypes: ["room", "area", "bed", "dormitory"] },
  });

  const {
    data: bookablesData,
    isLoading: bookablesLoading,
    isRefetching: bookablesRefetching,
  } = useGetAllocableBookables({
    variables: {
      categoryId,
      startDate: format(from, "yyyy-MM-dd"),
      endDate: format(to, "yyyy-MM-dd"),
    },
  });

  const allocate = async () => {
    if (bookableId) {
      try {
        await allocateReservation.mutateAsync({
          id: reservationId,
          body: { bookableId: bookableId },
        });

        queryClient.invalidateQueries({
          queryKey: useGetUnallocatedReservations.getKey(),
        });
        onOpenChange(false);
      } catch (err) {
        toast({
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
          variant: "destructive",
        });
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" block h-screen !max-h-full sm:!h-[310px] sm:!w-[500px] sm:!max-w-full">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("assign-unit")}
            </DialogTitle>
            <DialogDescription className=" text-sm font-normal text-secondary-text">
              {t("select-unit-for")} {holderName}
            </DialogDescription>

            <div className=" flex-grow">
              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("category")}
              </p>
              <Select value={categoryId} onValueChange={setCategoryId}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {categoriesData?.map((c) => (
                    <SelectItem key={c.id} value={c.id} className=" text-xs">
                      {c.short}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("unit")}
              </p>
              <Select value={bookableId} onValueChange={setBookableId}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {bookablesData?.bookables?.map((b) => (
                    <SelectItem key={b.id} value={b.id} className=" text-xs">
                      {b.name}
                    </SelectItem>
                  ))}
                  {bookablesData?.bookables.length === 0 && (
                    <p className="text-sm font-normal text-secondary-text">
                      {t("no-units-available")}
                    </p>
                  )}
                </SelectContent>
              </Select>
            </div>

            <div className=" mt-6 flex justify-end space-x-2">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                variant="primary"
                disabled={!bookableId}
                onClick={allocate}
              >
                {t("assign")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
