import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context";
import {
  CheckBridgeCodeResponse,
  ConnectNewBridgeResponse,
  CreateBridgeDeviceRequest,
  CreateBridgeDeviceResponse,
  CreateBridgeRequest,
  CreateBridgeResponse,
  GetBridgesResponse,
  UpdateBridgeDeviceRequest,
  UpdateBridgeDeviceResponse,
  UpdateBridgeRequest,
  UpdateBridgeResponse,
} from "../../../../api-contracts/bridges";

export const BRIDGES_QUERY_KEY = "bridges";

export const useGetBridges = createQuery<GetBridgesResponse>({
  primaryKey: BRIDGES_QUERY_KEY,
  staleTime: 10 * 1000,
  queryFn: async () => api.get(`/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}`),
});

export const useSendPrintCommand = createMutation<
  {},
  { bridgeId: string; deviceId: string }
>({
  mutationFn: async (data) =>
    api.post(
      `/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}/${data.bridgeId}/devices/${data.deviceId}/test-print`,
      {},
    ),
});

export const useCheckCodeCommand = createMutation<
  CheckBridgeCodeResponse,
  { code: string }
>({
  mutationFn: async (data) =>
    api.post(
      `/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}/check-code/${data.code}`,
      {},
    ),
});

export const useConnectBridgeCommand = createMutation<
  CreateBridgeResponse,
  { payload: CreateBridgeRequest }
>({
  mutationFn: async (data) =>
    api.post(`/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}`, data.payload),
});

export const useReconnectBridgeCommand = createMutation<
  ConnectNewBridgeResponse,
  { bridgeId: string; code: string }
>({
  mutationFn: async (data) =>
    api.post(
      `/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}/${data.bridgeId}/new-connection/${data.code}`,
      {},
    ),
});

export const useUpdateBridgeCommand = createMutation<
  UpdateBridgeResponse,
  { bridgeId: string; payload: UpdateBridgeRequest }
>({
  mutationFn: async (data) =>
    api.put(
      `/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}/${data.bridgeId}`,
      data.payload,
    ),
});

export const useDeleteBridgeCommand = createMutation<
  void,
  { bridgeId: string }
>({
  mutationFn: async (data) =>
    api.delete(`/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}/${data.bridgeId}`),
});

export const useCreateDeviceCommand = createMutation<
  CreateBridgeDeviceResponse,
  { bridgeId: string; payload: CreateBridgeDeviceRequest }
>({
  mutationFn: async (data) =>
    api.post(
      `/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}/${data.bridgeId}/devices`,
      data.payload,
    ),
});

export const useUpdateDeviceCommand = createMutation<
  UpdateBridgeDeviceResponse,
  { bridgeId: string; deviceId: string; payload: UpdateBridgeDeviceRequest }
>({
  mutationFn: async (data) =>
    api.put(
      `/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}/${data.bridgeId}/devices/${data.deviceId}`,
      data.payload,
    ),
});

export const useDeleteDeviceCommand = createMutation<
  {},
  { bridgeId: string; deviceId: string }
>({
  mutationFn: async (data) =>
    api.delete(
      `/api/${currentSite?.id}/${BRIDGES_QUERY_KEY}/${data.bridgeId}/devices/${data.deviceId}`,
    ),
});
