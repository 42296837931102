import { TitlesActionType, useTitlesPageContext } from "../titles-context.tsx";
import { useState } from "react";
import { Title } from "../../../../../../api-contracts/titles";
import { DefaultListItem } from "@components/default-list.tsx";
import { useSharedContext } from "@context/shared-context.tsx";
import { useDeleteTitle } from "@api/titles.ts";
import { useTranslation } from "react-i18next";
import { Switch } from "@primitives/switch.tsx";
import { DefaultAlert } from "@pages/settings/locations/components/locations-list-item.tsx";

export const TitlesCardItem = ({ title }: { title: Title }) => {
  const {
    sharedFunctions: { updateTitle, handleRefetch, setAction, setCurrentTitle },
  } = useTitlesPageContext();
  const {
    sharedFunctions: { toast, setLoading },
  } = useSharedContext();
  const { mutate: delTitle } = useDeleteTitle();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(title.active);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const handleChange = () => {
    updateTitle(title.id, { name: title.name, active: !checked });
    setChecked(() => !checked);
  };

  const removeTitle = (id: string, name: string) => {
    setLoading(true);
    delTitle(
      { id: id },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("delete-title-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          toast({
            variant: "success",
            title: t("title-deleted", { title: name }),
          });
          await handleRefetch();
        },
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  const onEdit = () => {
    setAction(TitlesActionType.EDIT);
    setCurrentTitle(title);
  };
  return (
    <DefaultListItem
      title={title.name}
      chevron={false}
      onEdit={onEdit}
      onDelete={() => setAlertOpen(true)}
    >
      <Switch
        className="ml-2"
        checked={checked}
        onCheckedChange={handleChange}
      />
      <DefaultAlert
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        onPrimaryButton={() => removeTitle(title.id, title.name)}
        title={t("are-you-sure-you-want-to-delete-title", {
          title: title?.name,
        })}
        description={t("title-will-be-removed-from-guests")}
        primaryButtonText={t("yes-delete-title", { title: title?.name })}
      />
    </DefaultListItem>
  );
};
