import { Button } from "@primitives/button";
import { ChevronLeft } from "lucide-react";
import { useLocation } from "react-router-dom";

export const NavigationArrows = ({
  rootPath = "/categories",
}: {
  rootPath: string;
}) => {
  const location = useLocation();

  const goBack = () => {
    window.history.back();
  };

  const isAtRoot = location.pathname === rootPath;

  return (
    <div className="flex flex-row ">
      <Button
        disabled={isAtRoot}
        onClick={goBack}
        variant={"secondary"}
        className="mr-2 h-9 w-9 p-0"
      >
        <ChevronLeft size={20} className="text-primary-icon-color" />
      </Button>
    </div>
  );
};
