import {
  useGetOrganizationUsers,
  useGetSiteUsers,
  useUpdateUserSiteRole,
} from "@api/users";
import { useAdmin } from "../context";
import { SiteUserCard } from "../components/user-cards";
import { useOrganizationRolesAdmin } from "@api/roles";
import { AddSiteUserCard } from "../components/add-site-user-card";
import { useMemo } from "react";

export const SiteUserSection: React.FC<unknown> = () => {
  const { state } = useAdmin();
  const { data: orgUsersRaw, isLoading: loadingOrgUsers } =
    useGetOrganizationUsers({
      variables: {
        orgId: state.organization?.id ?? "",
      },
    });

  const { data: siteUsers, isLoading: loadingSiteUsers } = useGetSiteUsers({
    variables: {
      siteId: state.site?.id ?? "",
    },
  });

  const { data: roles, isLoading: loadingRoles } = useOrganizationRolesAdmin({
    variables: { orgId: state.organization?.id ?? "" },
  });

  const orgUsers = useMemo(
    () =>
      orgUsersRaw?.filter(
        (user) => !siteUsers?.some(({ id }) => user.id === id),
      ),
    [orgUsersRaw, siteUsers],
  );
  const { mutate } = useUpdateUserSiteRole();

  const updateRole = (userId: string, roleIds: { id: string }[]) => {
    mutate({
      body: { roles: roleIds.map(({ id }) => id) },
      siteId: state.site?.id ?? "",
      userId,
    });
  };

  const loading = loadingOrgUsers || loadingRoles || loadingSiteUsers;

  return (
    <>
      <div className="flex space-x-8 divide-x">
        <div className="w-1/2 space-y-4">
          <AddSiteUserCard
            users={orgUsers ?? []}
            roles={roles?.roles ?? []}
            onSubmit={updateRole}
          />
        </div>
        <div className="max-h-[50vh] w-1/2 space-y-4 overflow-scroll p-4">
          <>
            {siteUsers?.map((user) => (
              <SiteUserCard
                key={user.id}
                allRoles={roles?.roles ?? []}
                user={user}
                loading={loading}
                onSave={updateRole}
              />
            ))}
          </>
        </div>
      </div>
    </>
  );
};
