import { AlertDialog } from "@shared/primitives/alert-dialog.tsx";
import { useEffect, useState } from "react";
import { Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";

export type DeleteProps = {
  onClick: () => void;
  alertTitle: string;
  alertDescription: string;
  submitText: string;
  buttonText?: string;
};

export const DeleteItem = ({
  onClick,
  alertTitle,
  alertDescription,
  submitText,
  buttonText,
}: DeleteProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updOpen, setUpdOpen] = useState<boolean>();
  const { t } = useTranslation();

  const onClose = () => {
    setOpenDialog(false);
  };

  //wouldnt update state properly so added an extra steps
  useEffect(() => {
    updOpen !== undefined && setOpenDialog(true);
  }, [updOpen, setOpenDialog]);

  return (
    <AlertDialog.Root
      open={openDialog}
      onOpenChange={() => setOpenDialog(false)}
    >
      <AlertDialog.Trigger asChild>
        <div onClick={() => setUpdOpen((prev) => !prev)}>
          <Trash2 />
          {buttonText && buttonText}
        </div>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>{alertTitle}</AlertDialog.Title>
        </AlertDialog.Header>
        <AlertDialog.Description>{alertDescription}</AlertDialog.Description>
        <AlertDialog.Footer>
          <AlertDialog.Cancel onClick={onClose}>
            {t("cancel")}
          </AlertDialog.Cancel>
          <AlertDialog.Action
            onClick={() => {
              onClick();
              onClose();
            }}
          >
            {submitText}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
