import {
  Column,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ReceptionArrival } from "../../../../../api-contracts/reception";
import { useTranslation } from "react-i18next";
import { Loading } from "@primitives/loading";
import {
  ChevronsUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Lock,
} from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import { format } from "date-fns";
import { Button } from "@primitives/button";
import { formatGuests, formatPrice } from "../reception-utils";
import { useReceptionContext } from "../reception-context";
import { HighlightText } from "@pages/settings/categories/components/highlight-text";

export const ArrivalsTable = () => {
  const { t, i18n } = useTranslation();

  const { arrivals, isLoading, searchTerm } = useReceptionContext();
  if (!arrivals && isLoading) return <Loading />;

  const columnHelper = createColumnHelper<ReceptionArrival>();

  const columnDefinitions = [
    columnHelper.accessor("bookingId", {
      header: t("reservation"),
      id: "bookingId",
      cell: ({ getValue }) => (
        <HighlightText text={getValue()} textToHighlight={searchTerm} />
      ),
    }),
    columnHelper.accessor("guests.name", {
      header: t("first-name"),
      id: "firstName",
      cell: () => <HighlightText text={"TODO"} textToHighlight={searchTerm} />,
    }),
    columnHelper.accessor("guests.surname", {
      header: t("surname"),
      id: "surname",
      cell: () => <HighlightText text={"TODO"} textToHighlight={searchTerm} />,
    }),
    columnHelper.accessor(
      (row) => {
        const { adults, teenagers, children, infants } = row;

        return formatGuests(
          { adults, teenagers, children, infants },
          i18n.language,
        );
      },
      {
        header: t("guests"),
        id: "guests",
      },
    ),
    columnHelper.accessor("departureDate", {
      header: t("departure-date"),
      id: "departureDate",
      cell: ({ getValue }) => format(getValue(), "yyyy-MM-dd"),
    }),
    columnHelper.accessor("numberOfNights", {
      header: t("number-of-nights"),
      id: "numberOfNights",
    }),
    columnHelper.accessor("roomCategory", {
      header: t("category"),
      id: "roomCategory",
    }),
    columnHelper.accessor("room", {
      header: t("room"),
      id: "room",
      cell: (info) => {
        const { lockedBookable, room } = info.row.original;
        return (
          <div className="flex ">
            {lockedBookable ? (
              <Button size={"sm"} variant={"outline"}>
                {`${room ? room : t("assign")}`}
              </Button>
            ) : (
              <div className="flex w-full items-center justify-between">
                <p className="mr-2">{room}</p>
                <Lock strokeWidth={1} size={16} className="ml-auto" />
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("state", {
      header: t("cleaning-status"),
      id: "cleaningStatus",
      cell: () => `TODO`,
    }),
    columnHelper.accessor("paymentRemaining", {
      header: t("to-be-paid"),
      id: "paymentRemaining",
      cell: ({ getValue }) => formatPrice(getValue()),
    }),
    columnHelper.accessor("state", {
      header: t("status"),
      id: "state",
    }),
  ];

  const table = useReactTable({
    data: arrivals,
    columns: columnDefinitions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const getSortIcon = (
    column: Column<ReceptionArrival>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };

  if (!arrivals) return;
  return (
    <>
      <Table className="rounded-md bg-primary-card-backplate">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      className="flex cursor-pointer items-center"
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getCanSort() && (
                        <div className="ml-1">
                          {getSortIcon(header.column, table.getState().sorting)}
                        </div>
                      )}
                    </div>
                  )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}px`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={table.getAllLeafColumns().length}
                className="h-24 text-center"
              >
                {t("no-results")}.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};
