import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@primitives/dialog.tsx";
import { FormField } from "@primitives/form-field.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select.tsx";
import DatePicker from "@primitives/date-picker.tsx";
import { Button } from "@primitives/button.tsx";
import {
  PriceRuleActionType,
  usePriceRulesPageContext,
} from "@pages/booking/price-rules/price-rules-context.tsx";
import {
  PRICE_RULES_KEY,
  useDisablePriceRule,
  useEnablePriceRule,
} from "@api/price-rules.ts";
import { useQueryClient } from "@tanstack/react-query";

const schema = z.object({
  replacementRateId: z.string().optional(),
  date: z.date(),
});

export type RateValues = z.infer<typeof schema>;

export const RateModal = ({ isOpen }: { isOpen: boolean }) => {
  const { handleSubmit, control } = useForm<RateValues>({
    defaultValues: {},
    resolver: zodResolver(schema),
  });
  const {
    sharedState: { actionType, activeRate, priceRules },
    sharedFunctions: { closeModal },
  } = usePriceRulesPageContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: disablePriceRule } = useDisablePriceRule();
  const { mutateAsync: enablePriceRule } = useEnablePriceRule();
  const onSubmitDeactivateRate = async (values: RateValues) => {
    await disablePriceRule(
      {
        ruleId: activeRate!.id,
        replacementPriceRuleId: values.replacementRateId,
        inactivationDate: values.date,
      },
      {
        onSuccess(data, variables, context) {
          console.log("disablePriceRule onSuccess", data, variables, context);
          queryClient.invalidateQueries({ queryKey: [PRICE_RULES_KEY] });
        },
        onError(error, variables, context) {
          console.error("disablePriceRule onError", error, variables, context);
        },
      },
    );

    closeModal();
  };

  const onSubmitActivateRate = async (values: RateValues) => {
    await enablePriceRule(
      {
        ruleId: activeRate!.id,
        reactivationDate: values.date,
      },
      {
        onSuccess(data, variables, context) {
          console.log("enablePriceRule onSuccess", data, variables, context);
          queryClient.invalidateQueries({ queryKey: [PRICE_RULES_KEY] });
        },
        onError(error, variables, context) {
          console.error("enablePriceRule onError", error, variables, context);
        },
      },
    );

    closeModal();
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <DialogContent>
        <form
          onSubmit={handleSubmit(
            actionType === PriceRuleActionType.ACTIVATE
              ? onSubmitActivateRate
              : onSubmitDeactivateRate,
          )}
          className="flex flex-col"
        >
          <DialogHeader className="mb-8 space-y-2">
            <DialogTitle>
              {actionType === PriceRuleActionType.ACTIVATE
                ? t("price-rules-activate-title", { rate: activeRate!.name })
                : t("price-rules-deactivate-title", { rate: activeRate!.name })}
            </DialogTitle>
            <DialogDescription>
              {actionType === PriceRuleActionType.ACTIVATE
                ? t("price-rules-activate-description", {
                    rate: activeRate!.name,
                  })
                : t("price-rules-deactivate-description")}
            </DialogDescription>
          </DialogHeader>

          <div className="flex-1 space-y-4">
            {actionType === PriceRuleActionType.DEACTIVATE && (
              <FormField.Root>
                <FormField.Label>
                  {t("price-rules-replace-rate")}
                </FormField.Label>
                <FormField.Control className="w-2/3">
                  <Controller
                    name="replacementRateId"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          autoComplete="off"
                        >
                          <SelectTrigger>
                            <SelectValue
                              placeholder={t("price-rules-select-rate")}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value={null as unknown as string}>
                              {t("price-rules-no-replacement-rate")}
                            </SelectItem>
                            {priceRules.rules.map((rule) => (
                              <SelectItem
                                key={rule.id}
                                value={rule.id}
                                disabled={
                                  rule.id === activeRate!.id || !rule.active
                                }
                              >
                                {rule.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      );
                    }}
                  />
                </FormField.Control>
              </FormField.Root>
            )}

            <FormField.Root>
              <FormField.Label>
                {actionType === PriceRuleActionType.ACTIVATE
                  ? t("price-rules-activation-date")
                  : t("price-rules-deactivation-date")}
                <span className="ml-1 text-red-400">*</span>
              </FormField.Label>
              <FormField.Control className="w-2/3">
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker onValueChange={field.onChange} />
                  )}
                />
              </FormField.Control>
            </FormField.Root>
          </div>
          <DialogFooter>
            <Button variant={"ghost"} onClick={closeModal} type="button">
              {t("cancel")}
            </Button>
            <Button variant={"default"} type="submit">
              {actionType === PriceRuleActionType.ACTIVATE
                ? t("activate")
                : t("deactivate")}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
