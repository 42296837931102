import { TitlesPageProvider } from "./titles-context.tsx";
import { TitlesCard } from "../../settings/titles/components/titles-card.tsx";

const TitlesPage = () => {
  return (
    <TitlesPageProvider>
      <TitlesCard />
    </TitlesPageProvider>
  );
};

export default TitlesPage;
