import {
  DropdownMenuRoot,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { cn } from "@shared/utils/css";
import { ChevronRight, Ellipsis, Pencil, Trash, XCircle } from "lucide-react";
import { Fragment, ReactElement, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

type TitleType = "header" | "title";

export const DefaultListItem = ({
  title,
  titleType,
  subTitle,
  children,
  className,
  chevron,
  badge,
  leftIconComponent,
  rightIconComponent,
  customDropdownItems,
  onClickListItem,
  onMouseEnter,
  onMouseLeave,
  onDelete,
  onEdit,
  onMove,
}: {
  title: string;
  titleType?: TitleType;
  subTitle?: string;
  children?: ReactNode;
  className?: string;
  chevron?: boolean;
  badge?: string;
  leftIconComponent?: ReactNode;
  rightIconComponent?: ReactNode;
  customDropdownItems?: ReactElement<typeof DropdownMenuItem>[];
  onClickListItem?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  onDelete?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  onEdit?: () => void;
  onMove?: () => void;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const hasDropDownMenu = Boolean(
    onDelete || onEdit || onMove || customDropdownItems,
  );
  const showChevron =
    chevron === undefined ? Boolean(onClickListItem) : chevron;

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsHovered(true);
    onMouseEnter?.(e);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsHovered(false);
    onMouseLeave?.(e);
  };

  const titleClassName =
    titleType === "header"
      ? "text-md font-bold font-itc"
      : "text-sm font-medium font-neue";
  return (
    <>
      <div
        className={cn(
          `flex items-center justify-between border-b-[0.5px] border-secondary-card-backplate p-4 text-primary-text hover:bg-secondary-card-backplate`,
          onClickListItem && "cursor-pointer",
          className,
        )}
        onClick={onClickListItem}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex">
          {leftIconComponent && <div className="mr-2">{leftIconComponent}</div>}
          <div className="flex flex-col">
            <p className={titleClassName}>{title}</p>
            {subTitle && (
              <p className="text-xs text-secondary-text">{subTitle}</p>
            )}
          </div>
        </div>
        <div className="flex items-center">
          {hasDropDownMenu && (
            <DropDown
              onDelete={onDelete}
              onEdit={onEdit}
              onMove={onMove}
              isVisible={isHovered}
              customDropdownItems={customDropdownItems}
            />
          )}
          {children}
          {rightIconComponent && <div>{rightIconComponent}</div>}
          {badge && (
            <div className="flex items-center">
              <p className="mx-2 rounded-sm bg-status-disabled-100 p-1 pt-2 text-xs font-extrabold text-status-disabled">
                {badge}
              </p>
            </div>
          )}
          {showChevron && <ChevronRight size={15} />}
        </div>
      </div>
    </>
  );
};

export const DropDown = ({
  onEdit,
  onDelete,
  onMove,
  onActivation,
  isActive,
  isVisible,
  customDropdownItems,
}: {
  onDelete?: () => void;
  onEdit?: () => void;
  onMove?: () => void;
  onActivation?: () => void;
  isActive?: boolean;
  isVisible?: boolean;
  customDropdownItems?: ReactElement<typeof DropdownMenuItem>[];
}) => {
  const { t } = useTranslation();
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger
        className={`${isVisible ? "opacity-100" : "opacity-0"}`}
      >
        <Ellipsis size={18} />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {onEdit && (
          <DropdownMenuItem onClick={onEdit}>
            <Pencil size={16} className="mr-2" />
            {t("edit")}
          </DropdownMenuItem>
        )}
        {onActivation && (
          <DropdownMenuItem onClick={onActivation}>
            <XCircle size={16} className="mr-2" />
            {isActive ? t("inactivate") : t("activate")}
          </DropdownMenuItem>
        )}
        {onMove && (
          <DropdownMenuItem onClick={onMove}>
            <Pencil size={16} className="mr-2" />
            {t("move")}
          </DropdownMenuItem>
        )}
        {customDropdownItems &&
          customDropdownItems.map((menu, index) => (
            <Fragment key={index}>{menu}</Fragment>
          ))}
        {onDelete && (
          <DropdownMenuItem className="text-status-error" onClick={onDelete}>
            <Trash size={16} className="mr-2 text-status-error" />
            {t("remove")}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};
