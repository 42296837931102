import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loading } from "@primitives/loading.tsx";
import { useGetChannels } from "@api/channels.ts";
import {
  GetChannelResponse,
  GetChannelsResponse,
} from "../../../../../api-contracts/channels";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const enum ChannelsActionType {
  DEFAULT = "DEFAULT",
  ADD = "ADD",
  EDIT = "EDIT",
}

interface SharedStateProps {
  channels: GetChannelsResponse;
  actionType: ChannelsActionType;
  current: GetChannelResponse | null;
}

interface SharedFunctionsProps {
  setChannels: Dispatch<SetStateAction<GetChannelsResponse>>;
  setActionType: Dispatch<SetStateAction<ChannelsActionType>>;
  setCurrent: Dispatch<SetStateAction<GetChannelResponse | null>>;
  handleRefetch: () => Promise<void | undefined | null>;
}

interface ChannelsPageContextProps {
  sharedState: SharedStateProps;
  sharedFunctions: SharedFunctionsProps;
}

interface ChannelsPageProviderProps {
  children: ReactNode;
}

const ChannelsContext = createContext<ChannelsPageContextProps | undefined>(
  undefined,
);

export const useChannelsPageContext = () => {
  const context = useContext(ChannelsContext);

  if (!context) {
    throw new Error("context must be used in correct provider");
  }

  return context;
};

export const ChannelsPageProvider: React.FC<ChannelsPageProviderProps> = ({
  children,
}) => {
  const {
    sharedState: { isLoading },
    sharedFunctions: { toast },
  } = useSharedContext();

  const [actionType, setActionType] = useState<ChannelsActionType>(
    ChannelsActionType.DEFAULT,
  );
  const [channels, setChannels] = useState<GetChannelsResponse>([]);
  const [current, setCurrent] = useState<GetChannelResponse | null>(null);
  const { data: getChannels, refetch } = useGetChannels();

  const { t } = useTranslation();

  useEffect(() => {
    getChannels && setChannels(() => getChannels);
  }, [getChannels, setChannels]);

  const handleRefetch = async () => {
    const res = await refetch();
    if (res.error) {
      toast({
        variant: "destructive",
        title: t("refetch-channels-error", { error: res.error }),
      });
      return null;
    }
    return res.data && setChannels(() => res.data);
  };

  const sharedState = {
    channels,
    actionType,
    current,
  };

  const sharedFunctions = {
    setChannels,
    setActionType,
    setCurrent,
    handleRefetch,
  };

  return (
    <ChannelsContext.Provider value={{ sharedState, sharedFunctions }}>
      {isLoading && <Loading />}
      {actionType != ChannelsActionType.DEFAULT && (
        <div className="backdrop fixed inset-0 z-[100] flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black/75 outline-none focus:outline-none" />
      )}
      <div className="relative z-0 h-full space-y-4 overflow-y-auto p-4">
        {children}
      </div>
    </ChannelsContext.Provider>
  );
};
