import {
  useCreateOrganizationRole,
  useOrganizationRolesAdmin,
  useUpdateOrganizationRole,
} from "@api/roles";
import { useAdmin } from "../context";
import {
  CreateRoleRequest,
  GetRolesResponse,
} from "../../../../../api-contracts/organizations";
import { useMemo, useState } from "react";
import { RoleCard } from "../components/role-card";
import { CreateRoleForm, UpdateRoleForm } from "../components/forms/roles";
import { Skeleton } from "@primitives/skeleton";

export type ExtendedGrant = { grant: string; enabled: boolean };
export type ExtendedRole = {
  name: string;
  id: string;
  grants: ExtendedGrant[];
};

const enhanceRoles = (data?: GetRolesResponse): ExtendedRole[] | undefined =>
  data?.roles?.reduce((acc, curr) => {
    acc.push({
      name: curr.name,
      id: curr.id,
      grants: data?.allGrants
        .map((grant) => ({
          grant,
          enabled: curr.actions.includes(grant),
        }))
        .sort((a) => (a.enabled ? -1 : 1)),
    });
    return acc;
  }, [] as ExtendedRole[]);

export const OrgRolesSection = () => {
  const { state } = useAdmin();
  const orgId = state.organization?.id ?? "";
  const [editRole, setEditRole] = useState<ExtendedRole | null>(null);

  const { data: roles, isLoading: loadingRoles } = useOrganizationRolesAdmin({
    variables: { orgId },
  });

  const { mutate: createRole, isPending: loadingPost } =
    useCreateOrganizationRole();
  const { mutate: updateRole, isPending: loadingPut } =
    useUpdateOrganizationRole();

  const loading = loadingRoles || loadingPost || loadingPut;

  const extendedRoles = useMemo(() => enhanceRoles(roles), [roles]);

  const onCreateRoleSubmit = (body: CreateRoleRequest) => {
    createRole({
      body,
      orgId,
    });
  };

  const toggleEditRole = (role: ExtendedRole) => {
    if (role.id === editRole?.id) {
      return setEditRole(null);
    }
    setEditRole(role);
  };

  const onEditSubmit = (body: CreateRoleRequest) => {
    console.log("edit request");
    updateRole({
      body: { ...body, id: editRole?.id ?? "" },
      orgId,
    });
    setEditRole(null);
  };

  const renderRoleForm = () => {
    if (!editRole) {
      return (
        <CreateRoleForm
          allGrants={roles?.allGrants ?? []}
          loading={loading}
          onSubmit={onCreateRoleSubmit}
        />
      );
    }
    return (
      <UpdateRoleForm
        allGrants={roles?.allGrants ?? []}
        role={editRole as ExtendedRole}
        loading={false}
        onSubmit={onEditSubmit}
        onCancel={() => setEditRole(() => null)}
      />
    );
  };

  return (
    <div className="flex w-full divide-x">
      <div className="w-[50%] pr-4">
        <h1 className="mb-6 w-fit text-2xl font-semibold">Roles</h1>
        <div className="max-h-[50vh] min-h-80 space-y-4 overflow-scroll rounded border bg-gray-50 p-4">
          {!loadingRoles ? (
            extendedRoles?.map((role) => (
              <RoleCard
                key={role.id}
                role={role}
                onEditClick={toggleEditRole}
              />
            ))
          ) : (
            <Skeleton className="h-80 w-full" />
          )}
        </div>
      </div>
      <div className="w-[50%] pl-4">
        <h1 className="mb-6 w-fit text-2xl font-semibold">
          {!editRole ? "Create Role" : "Edit Role"}
        </h1>
        {!loading ? (
          renderRoleForm()
        ) : (
          <div className="flex flex-col space-y-8">
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-40 w-full" />
            <Skeleton className="h-12 w-[100px]" />
          </div>
        )}
      </div>
    </div>
  );
};
