import { Card, CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";
import {
  Amenity,
  CreateAmenityRequest,
} from "../../../../../../api-contracts/amenities";
import { useState } from "react";
import { IconSelectorSideSheet } from "./icon-selector-sheet";
import { Tooltip } from "@primitives/tooltip";
import { Info } from "lucide-react";
import { Button } from "@primitives/button";
import {
  useDeleteAmenity,
  useGetAmenities,
  usePatchAmenityById,
  usePostAmenity,
} from "@api/amenities";
import { DefaultListItem } from "@components/default-list";
import { LucideIcon } from "@components/icon-selector";
import { Loading } from "@primitives/loading";
import { queryClient } from "query-client";
import { toast } from "@hooks/use-toast";
import dynamicIconImports from "lucide-react/dynamicIconImports";

const initialAmenity: Amenity = {
  id: "",
  name: "",
  iconName: "",
};

export const AmenityCard = () => {
  const { t } = useTranslation();
  const [openSheet, setOpenSheet] = useState<boolean>(false);
  const [amenityToEdit, setAmenityToEdit] = useState<Amenity>(initialAmenity);

  const { mutateAsync: postAmenity } = usePostAmenity();
  const { mutateAsync: patchAmenity } = usePatchAmenityById();
  const { mutateAsync: deleteAmenity } = useDeleteAmenity();

  const { data: amenities, isLoading } = useGetAmenities();

  const createAmenity = async (amenity: CreateAmenityRequest) => {
    try {
      await postAmenity(amenity);
      queryClient.invalidateQueries({
        queryKey: usePostAmenity.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: amenity.name }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const updateAmenity = async (amenity: Amenity) => {
    try {
      await patchAmenity({
        id: amenity.id,
        patch: { name: amenity.name, iconName: amenity.iconName },
      });
      queryClient.invalidateQueries({
        queryKey: usePatchAmenityById.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: amenity.name }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(
            decodeURIComponent(
              (error instanceof Error && error.message) || t("no-message"),
            ),
          ),
        variant: "destructive",
      });
    }
  };

  const delAmenity = async (amenity: Amenity) => {
    try {
      await deleteAmenity({ id: amenity.id });
      queryClient.invalidateQueries({
        queryKey: useDeleteAmenity.getKey(),
      });
      toast({
        title: amenity.name + " " + t("deleted"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const handleSave = async (amenity: Amenity) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...amenityWithoutId } = amenity;

    if (amenity.id.length < 1) {
      await createAmenity(amenityWithoutId);
    } else {
      await updateAmenity(amenity);
    }
    setOpenSheet(false);
  };

  const onEditAmenity = (amenity: Amenity) => {
    setAmenityToEdit(amenity);
    setOpenSheet(true);
  };

  const onCreateNewAmenity = () => {
    setAmenityToEdit(initialAmenity);
    setOpenSheet(true);
  };

  return (
    <>
      <Card className="h-full">
        <CardTitle className="flex">
          {t("amenities")}
          <Tooltip content={"Press the add button to create a new amenity"}>
            <Info size="20" className="ml-2" strokeWidth={1.5} />
          </Tooltip>
        </CardTitle>
        <div className="py-4">
          <Button onClick={() => onCreateNewAmenity()}>+ {t("amenity")}</Button>
        </div>
        <div className="mx-[-16px]">
          {amenities &&
            amenities.map((amenity) => {
              return (
                <DefaultListItem
                  key={amenity.id}
                  title={amenity.name}
                  leftIconComponent={
                    <LucideIcon
                      name={amenity.iconName as keyof typeof dynamicIconImports}
                    />
                  }
                  onEdit={() => onEditAmenity(amenity)}
                  onDelete={() => delAmenity(amenity)}
                />
              );
            })}
        </div>
      </Card>
      <IconSelectorSideSheet
        sheetOpen={openSheet}
        setSheetOpen={setOpenSheet}
        onSave={handleSave}
        amenity={amenityToEdit}
      />
      {isLoading && <Loading />}
    </>
  );
};
