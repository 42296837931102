import { useDebounce } from "@hooks/use-debounce";
import { Button } from "@primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@primitives/command";
import { Input } from "@primitives/input";
import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "@primitives/popover";
import { ChevronDown, ChevronUp, Plus, Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "@hooks/use-toast";
import { queryClient } from "query-client";
import { TravelAgency } from "../../../../../../api-contracts/travel-agencies";
import {
  useCreateTravelAgency,
  useGetTravelAgencies,
  useGetTravelAgency,
  useUpdateTravelAgency,
} from "@api/travel-agencies";
import { cn } from "@shared/utils/css";
import { Switch } from "@primitives/switch";
import { Label } from "@primitives/label";

interface Props {
  value?: { id: string; name: string };
  onValueChange?: (value?: { id: string; name: string }) => void;
  id?: string;
  disabled?: boolean;
}

const initialTravelAgency: TravelAgency = {
  id: "",
  IATA: "",
  name: "",
  organizationNumber: "",
  address: null,
  address2: null,
  city: null,
  zip: null,
  country: null,
  phone: null,
  email: null,
  state: null,
  phone2: null,
  invoiceReference: null,
  invoiceName: null,
  invoiceAddress: null,
  invoiceAddress2: null,
  invoiceZip: null,
  invoiceCity: null,
  invoiceState: null,
  invoiceCountry: null,
};

const TravelAgencySelect = ({ value, onValueChange, id, disabled }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = React.useState<"search" | "new">();
  const [t] = useTranslation();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [travelAgency, setTravelAgency] =
    useState<TravelAgency>(initialTravelAgency);
  const [billingSame, setBillingSame] = useState<boolean>(true);

  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const {
    data: travelAgenciesData,
    isLoading: travelAgenciesIsLoading,
    isRefetching: travelAgenciesIsRefetching,
  } = useGetTravelAgencies({
    variables: {
      limit: 50,
      page: 1,
      pattern: debouncedSearchTerm || "",
    },
  });

  const {
    data: fetchedTravelAgency,
    isLoading: fetchedTravelAgencyIsLoading,
    isRefetching: fetchedTravelAgencyIsRefetching,
  } = useGetTravelAgency({
    variables: {
      id: value?.id || "",
    },
    enabled: !!value?.id,
  });

  const createTravelAgency = useCreateTravelAgency();
  const updateTravelAgency = useUpdateTravelAgency();

  useEffect(() => {
    setTravelAgency(
      fetchedTravelAgency ? { ...fetchedTravelAgency } : initialTravelAgency,
    );
    setBillingSame(
      fetchedTravelAgency?.address === fetchedTravelAgency?.invoiceAddress &&
        fetchedTravelAgency?.address2 ===
          fetchedTravelAgency?.invoiceAddress2 &&
        fetchedTravelAgency?.city === fetchedTravelAgency?.invoiceCity &&
        fetchedTravelAgency?.invoiceCountry === fetchedTravelAgency?.country &&
        fetchedTravelAgency?.invoiceName === fetchedTravelAgency?.name &&
        fetchedTravelAgency?.invoiceState === fetchedTravelAgency?.state &&
        fetchedTravelAgency?.invoiceZip === fetchedTravelAgency?.zip,
    );
  }, [fetchedTravelAgency]);

  const handleCreate = async () => {
    try {
      setCreateLoading(true);
      const org = await createTravelAgency.mutateAsync({
        name: travelAgency.name.trim(),
        organizationNumber: travelAgency.organizationNumber.trim(),
        IATA: travelAgency.IATA.trim(),
        address: travelAgency.address?.trim() || null,
        address2: travelAgency.address2?.trim() || null,
        city: travelAgency.city?.trim() || null,
        zip: travelAgency.zip?.trim() || null,
        country: travelAgency.country?.trim() || null,
        phone: travelAgency.phone?.trim() || null,
        email: travelAgency.email?.trim() || null,
        state: travelAgency.state?.trim() || null,
        phone2: travelAgency.phone2?.trim() || null,
        invoiceReference: travelAgency.invoiceReference?.trim() || null,
        invoiceName: travelAgency.invoiceName?.trim() || null,
        invoiceAddress: travelAgency.invoiceAddress?.trim() || null,
        invoiceAddress2: travelAgency.invoiceAddress2?.trim() || null,
        invoiceZip: travelAgency.invoiceZip?.trim() || null,
        invoiceCity: travelAgency.invoiceCity?.trim() || null,
        invoiceState: travelAgency.invoiceState?.trim() || null,
        invoiceCountry: travelAgency.country?.trim() || null,
      });

      setTravelAgency(org);
      onValueChange?.({ id: org.id, name: org.name });
      setOpen(undefined);
      queryClient.invalidateQueries({
        queryKey: useGetTravelAgencies.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: travelAgency.name }),
        className: "text-status-success",
        variant: "success",
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setCreateLoading(false);
  };

  const handleUpdate = async () => {
    try {
      setUpdateLoading(true);
      const org = await updateTravelAgency.mutateAsync({
        id: travelAgency.id,
        name: travelAgency.name.trim(),
        organizationNumber: travelAgency.organizationNumber.trim(),
        IATA: travelAgency.IATA.trim(),
        address: travelAgency.address?.trim() || null,
        address2: travelAgency.address2?.trim() || null,
        city: travelAgency.city?.trim() || null,
        zip: travelAgency.zip?.trim() || null,
        country: travelAgency.country?.trim() || null,
        phone: travelAgency.phone?.trim() || null,
        email: travelAgency.email?.trim() || null,
        state: travelAgency.state?.trim() || null,
        phone2: travelAgency.phone2?.trim() || null,
        invoiceReference: travelAgency.invoiceReference?.trim() || null,
        invoiceName: travelAgency.invoiceName?.trim() || null,
        invoiceAddress: travelAgency.invoiceAddress?.trim() || null,
        invoiceAddress2: travelAgency.invoiceAddress2?.trim() || null,
        invoiceZip: travelAgency.invoiceZip?.trim() || null,
        invoiceCity: travelAgency.invoiceCity?.trim() || null,
        invoiceState: travelAgency.invoiceState?.trim() || null,
        invoiceCountry: travelAgency.country?.trim() || null,
      });

      setTravelAgency(org);
      onValueChange?.({ id: org.id, name: org.name });
      setOpen(undefined);
      queryClient.invalidateQueries({
        queryKey: useGetTravelAgencies.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: travelAgency.name }),
        className: "text-status-success",
        variant: "success",
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setUpdateLoading(false);
  };

  return (
    <PopoverRoot
      open={!!open}
      onOpenChange={(o) =>
        disabled
          ? setOpen(undefined)
          : !o
            ? setOpen(undefined)
            : fetchedTravelAgency
              ? setOpen("new")
              : setOpen("search")
      }
    >
      <PopoverTrigger asChild disabled={disabled} className=" flex-1">
        {fetchedTravelAgency ? (
          <div
            className={cn(
              "flex h-full items-center justify-between rounded-lg bg-amber-500 px-4 py-2",
              {
                " opacity-70": disabled,
              },
            )}
          >
            {" "}
            <div>
              <p className=" text-sm font-extrabold">
                {fetchedTravelAgency.name}
              </p>
              <p className=" text-xs font-normal text-secondary-text">
                {fetchedTravelAgency.organizationNumber}
              </p>
              <p className=" text-xs font-normal text-secondary-text">
                {fetchedTravelAgency.email}
              </p>
            </div>
            <div>
              {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
            </div>
          </div>
        ) : (
          <div className="relative h-full">
            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
              <Search className="text-primary-icon-color" size={17} />
            </div>
            <Input
              autoComplete="off"
              disabled={disabled}
              id={id}
              className=" h-full pe-20 ps-10"
              placeholder={t("search-travel-agency")}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setOpen("search");
              }}
            />
            <div className="absolute inset-y-0 end-0 flex items-center pe-3">
              <Button
                disabled={disabled}
                size="sm"
                variant="outline"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("new");
                }}
              >
                <Plus />
              </Button>
            </div>
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent
        avoidCollisions={false}
        className="PopoverContent p-0"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        {open === "search" && (
          <Command>
            <CommandList>
              <CommandEmpty>{t("no-travel-agencies-found")}</CommandEmpty>
              <CommandGroup>
                {travelAgenciesData?.map((o) => (
                  <CommandItem
                    key={o.id}
                    value={o.id}
                    onSelect={(value) => {
                      onValueChange &&
                        onValueChange(
                          travelAgenciesData?.find((o) => o.id === value),
                        );
                      setOpen(undefined);
                    }}
                  >
                    <div>
                      <p className=" text-sm font-extrabold">{o.name}</p>
                      <p className=" text-xs font-normal text-secondary-text">
                        {o.organizationNumber}
                      </p>
                      <p className=" text-xs font-normal text-secondary-text">
                        {o.email}
                      </p>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        )}
        {open === "new" && (
          <div className="p-4">
            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyIATA"
            >
              IATA*
            </label>
            <Input
              id="travelAgencyIATA"
              value={travelAgency?.IATA}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  IATA: e.target.value,
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyOrganizationNumber"
            >
              {t("organization-number")}*
            </label>
            <Input
              id="travelAgencyOrganizationNumber"
              value={travelAgency?.organizationNumber}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  organizationNumber: e.target.value,
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyName"
            >
              {t("name")}*
            </label>
            <Input
              id="travelAgencyName"
              value={travelAgency?.name}
              onChange={(e) =>
                setTravelAgency({ ...travelAgency, name: e.target.value })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyAddress1"
            >
              {t("address-1")}
            </label>
            <Input
              id="travelAgencyAddress1"
              value={travelAgency?.address || ""}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  address: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyAddress2"
            >
              {t("address-2")}
            </label>
            <Input
              id="travelAgencyAddress2"
              value={travelAgency?.address2 || ""}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  address2: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyZipCode"
            >
              {t("zip-code")}
            </label>
            <Input
              id="travelAgencyZipCode"
              value={travelAgency?.zip || ""}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  zip: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyCity"
            >
              {t("city")}
            </label>
            <Input
              id="travelAgencyCity"
              value={travelAgency?.city || ""}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  city: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyCountry"
            >
              {t("country")}
            </label>
            <Input
              id="travelAgencyCountry"
              value={travelAgency?.country || ""}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  country: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyPhone"
            >
              {t("phone")}
            </label>
            <Input
              id="travelAgencyPhone"
              value={travelAgency?.phone || ""}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  phone: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyEmail"
            >
              {t("email")}
            </label>
            <Input
              id="travelAgencyEmail"
              value={travelAgency?.email || ""}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  email: e.target.value || "",
                })
              }
            />

            <h4 className=" text-md mt-1 font-light">{t("billing-address")}</h4>
            <div className="flex items-center space-x-2">
              <Switch
                id="travelAgencyBillingSame"
                checked={billingSame}
                onCheckedChange={setBillingSame}
              />
              <Label htmlFor="travelAgencyBillingSame">
                {t("same-as-above")}
              </Label>
            </div>

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgencyInvoiceReference"
            >
              {t("reference")}
            </label>
            <Input
              id="travelAgencyInvoiceReference"
              value={travelAgency?.invoiceReference || ""}
              onChange={(e) =>
                setTravelAgency({
                  ...travelAgency,
                  invoiceReference: e.target.value || "",
                })
              }
            />

            {!billingSame && (
              <>
                <label
                  className="mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="travelAgencyInvoiceName"
                >
                  {t("travelAgency-name")}
                </label>
                <Input
                  id="travelAgencyInvoiceName"
                  value={travelAgency?.invoiceName || ""}
                  onChange={(e) =>
                    setTravelAgency({
                      ...travelAgency,
                      invoiceName: e.target.value,
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="travelAgencyInvoiceAddress1"
                >
                  {t("address-1")}
                </label>
                <Input
                  id="travelAgencyInvoiceAddress1"
                  value={travelAgency?.invoiceAddress || ""}
                  onChange={(e) =>
                    setTravelAgency({
                      ...travelAgency,
                      invoiceAddress: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="travelAgencyInvoiceAddress2"
                >
                  {t("address-2")}
                </label>
                <Input
                  id="travelAgencyInvoiceAddress2"
                  value={travelAgency?.invoiceAddress2 || ""}
                  onChange={(e) =>
                    setTravelAgency({
                      ...travelAgency,
                      invoiceAddress2: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="travelAgencyInvoiceZip"
                >
                  {t("zip-code")}
                </label>
                <Input
                  id="travelAgencyInvoiceZip"
                  value={travelAgency?.invoiceZip || ""}
                  onChange={(e) =>
                    setTravelAgency({
                      ...travelAgency,
                      invoiceZip: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="travelAgencyInvoiceCity"
                >
                  {t("city")}
                </label>
                <Input
                  id="travelAgencyInvoiceCity"
                  value={travelAgency?.invoiceCity || ""}
                  onChange={(e) =>
                    setTravelAgency({
                      ...travelAgency,
                      invoiceCity: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="travelAgencyInvoiceState"
                >
                  {t("state/province/region")}
                </label>
                <Input
                  id="travelAgencyInvoiceState"
                  value={travelAgency?.invoiceState || ""}
                  onChange={(e) =>
                    setTravelAgency({
                      ...travelAgency,
                      invoiceState: e.target.value || "",
                    })
                  }
                />

                <label
                  className=" mt-1 block text-xs font-medium text-secondary-text"
                  htmlFor="travelAgencyInvoiceCountry"
                >
                  {t("country")}
                </label>
                <Input
                  id="travelAgencyInvoiceCountry"
                  value={travelAgency?.invoiceCountry || ""}
                  onChange={(e) =>
                    setTravelAgency({
                      ...travelAgency,
                      invoiceCountry: e.target.value || "",
                    })
                  }
                />
              </>
            )}

            <div className="flex space-x-2">
              {!fetchedTravelAgency && (
                <Button
                  className="mt-2"
                  size="sm"
                  variant="primary"
                  disabled={
                    !travelAgency.name.trim() ||
                    !travelAgency.organizationNumber.trim() ||
                    !travelAgency.IATA.trim()
                  }
                  onClick={handleCreate}
                  loading={createLoading}
                >
                  {t("add")}
                </Button>
              )}
              {fetchedTravelAgency && (
                <Button
                  className="mt-2"
                  size="sm"
                  variant="primary"
                  disabled={
                    !travelAgency.name.trim() ||
                    !travelAgency.organizationNumber.trim()
                  }
                  onClick={handleUpdate}
                  loading={updateLoading}
                >
                  {t("save")}
                </Button>
              )}
              {fetchedTravelAgency && (
                <Button
                  className="mt-2"
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    onValueChange?.(undefined);
                    setOpen("search");
                  }}
                >
                  {t("remove")}
                </Button>
              )}
            </div>
          </div>
        )}
      </PopoverContent>
    </PopoverRoot>
  );
};

export default TravelAgencySelect;
