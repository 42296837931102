import { AmenityCard } from "./components/amenity-card";
import { FixtureCard } from "./components/fixture-card";

const FixtureAndAmenityPage = () => {
  return (
    <div className="flex h-full py-4">
      <div className="w-full px-4">
        <FixtureCard />
      </div>
      <div className="w-full px-4">
        <AmenityCard />
      </div>
    </div>
  );
};

export default FixtureAndAmenityPage;
