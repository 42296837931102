import { Card, CardTitle } from "@primitives/card.tsx";

import { Button } from "@primitives/button.tsx";
import { Plus } from "lucide-react";
import { TitlesCardItem } from "../../../settings/titles/components/titles-card-item.tsx";
import { TitlesActionType, useTitlesPageContext } from "../titles-context.tsx";
import { AddTitle } from "../../../settings/titles/components/add-title.tsx";
import { EditTitle } from "../../../settings/titles/components/edit-title.tsx";
import { useTranslation } from "react-i18next";

export const TitlesCard = () => {
  const {
    sharedState: { action, titles },
    sharedFunctions: { setAction },
  } = useTitlesPageContext();
  const { t } = useTranslation();

  return (
    <>
      {action === TitlesActionType.EDIT && <EditTitle />}
      {action === TitlesActionType.ADD && <AddTitle />}
      <Card className="max-w-[500px]">
        <CardTitle>{t("titles")}</CardTitle>
        <div className="py-4">
          <Button
            variant="primary"
            size="icon"
            onClick={() => setAction(TitlesActionType.ADD)}
          >
            <Plus size={17} />
          </Button>
        </div>
        <div className="mx-[-1rem] flex h-[calc(100vh-250px)] flex-grow flex-col gap-0.5 overflow-y-auto">
          {titles
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((title, i) => (
              <TitlesCardItem
                key={`${i}-${title.id}`}
                title={title}
              ></TitlesCardItem>
            ))}
        </div>
      </Card>
    </>
  );
};
