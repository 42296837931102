import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector, {
  type DetectorOptions,
} from "i18next-browser-languagedetector";

const detectorOptions: DetectorOptions = {
  lookupLocalStorage: "lang",
  convertDetectedLanguage(lng) {
    if (lng.startsWith("en-")) {
      return "en";
    }

    return lng;
  },
};

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    lowerCaseLng: true,
    fallbackLng: "en",
    load: "currentOnly",
    detection: detectorOptions,
    backend: {},
  });
