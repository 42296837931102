import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { useMemo, useState } from "react";
import {
  GetBooking,
  GetReservation,
} from "../../../../../../api-contracts/reservations";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  booking: GetBooking;
  reservation: GetReservation;
  onProceed: () => void;
  proceedLabel?: string;
}

export const DebitBookingDialog = ({
  open,
  onOpenChange,
  booking,
  reservation,
  onProceed,
  proceedLabel,
}: Props) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns: ColumnDef<any>[] = [
    {
      id: "type",
      header: t("type"),
      accessorKey: "type",
    },
    {
      id: "date",
      header: t("date"),
      accessorKey: "date",
    },
    {
      id: "price",
      header: t("price"),
      accessorKey: "price",
    },
    {
      id: "guest",
      header: t("guest"),
      accessorKey: "guest",
    },
    {
      id: "paid",
      header: t("debit"),
      accessorKey: "paid",
    },
  ];

  const data = useMemo(() => {
    // TODO use folio here
    return booking.slots
      .map((s) => ({
        type: t("accommodation"),
        price: s.price,
        guest: `${reservation.holder?.title?.name ? reservation.holder?.title?.name + " " : ""} ${reservation.holder?.name} ${reservation.holder?.surname}`,
        date: format(s.start, "yyyy-MM-dd"),
        paid: "TODO",
      }))
      .flat();
  }, [booking]);

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const handleNextClick = () => {
    // TODO
    onOpenChange(false);
    onProceed();
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit min-w-[500px] max-w-fit">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("debiting")}
            </DialogTitle>

            <div className="mt-4">
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>

                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        className=" cursor-pointer text-primary-text"
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center text-primary-text"
                      >
                        {t("no-entries-found")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <div className=" mt-4 flex justify-end space-x-2">
                <Button variant="outline" onClick={() => onOpenChange(false)}>
                  {t("cancel")}
                </Button>
                <Button variant="primary" onClick={handleNextClick}>
                  {proceedLabel || t("save")}
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DebitBookingDialog;
