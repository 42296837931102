import { Cloud, Pencil, Plus } from "lucide-react";
import { CalendarDay } from "../../../../../../api-contracts/price-calendar";
import { useTranslation } from "react-i18next";
import {
  formatMonthName,
  formatOccupancyAsPercentage,
  getWeatherIcon,
} from "../price-calendar.utils";

export const TooltipCard = ({
  day,
  openNoteDialog,
}: {
  day: CalendarDay;
  openNoteDialog: () => void;
}) => {
  const { priceRule, occupancy, date, notes, weather } = day;
  const { i18n } = useTranslation();
  const formattedDate = formatMonthName(date, i18n.language);
  const { t } = useTranslation();

  return (
    <div className="min-w-44 bg-solid-backplate px-2">
      <div className="flex justify-between gap-2">
        <span className="text-sm font-medium">{formattedDate}</span>

        {weather ? (
          <div className="flex items-center justify-center text-secondary-text">
            <span className="mr-1 text-[10px]">{`${weather.tempMax} / ${weather.tempMin} °`}</span>
            {getWeatherIcon(weather.weatherIcon)}
          </div>
        ) : (
          <div className="flex items-center justify-center text-secondary-text"></div>
        )}
      </div>
      <div className="flex flex-col py-2">
        <span className="text-sm font-medium">{priceRule.name}</span>
        <span className="text-xs text-secondary-text">
          {formatOccupancyAsPercentage(occupancy)}%
        </span>
      </div>

      <div className="w-full border-b border-highlighted-backplate" />

      <div className="flex flex-col pt-4">
        <div className="flex items-center justify-between">
          <span className="text-xs font-bold">{t("notes")}</span>

          <div className="flex gap-2">
            <button
              onClick={openNoteDialog}
              className="flex rounded-[4px] border border-highlighted-backplate bg-primary-button-backplate p-[2px] hover:bg-hover"
            >
              {notes.length > 0 ? (
                <Pencil strokeWidth={1} size={10} />
              ) : (
                <Plus strokeWidth={1} size={10} />
              )}
            </button>
          </div>
        </div>
        {notes &&
          notes.map((note) => {
            return (
              <div key={note.id} className="pt-2 text-xs font-normal">
                <span>{note.message}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};
