import { LocationsPageProvider } from "./locations-context.tsx";
import { LocationsBreadcrumbs } from "./components/locations-breadcrumbs.tsx";
import { LocationsTree } from "./components/locations-tree.tsx";
import { LocationsCard } from "./components/locations-card.tsx";

const LocationsPage = () => {
  return (
    <LocationsPageProvider>
      <div className="flex  h-full flex-col space-y-3 p-4">
        <LocationsBreadcrumbs />
        <div className="flex flex-grow space-x-3">
          <LocationsTree />
          <LocationsCard />
        </div>
      </div>
    </LocationsPageProvider>
  );
};

export default LocationsPage;
