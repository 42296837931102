import { Switch } from "@primitives/switch";
import { Textarea } from "@primitives/textarea";
import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import {
  DescriptionText,
  Available,
} from "../categories-detail/categories-detail";
import { useCategoriesContext } from "../categories-context";

export const ConnectChannels = ({
  available,
  setAvailable,
  descriptionText,
  setDescriptionText,
}: {
  available: Available;
  setAvailable: React.Dispatch<SetStateAction<Available>>;
  descriptionText: DescriptionText;
  setDescriptionText: React.Dispatch<SetStateAction<DescriptionText>>;
}) => {
  const { t } = useTranslation();
  const { selectedCategoryType } = useCategoriesContext();
  const isAreaCategoryType = selectedCategoryType === "area";
  return (
    <>
      <div>
        <ChannelSwitchWithTextarea
          switchDisabled={true}
          label={t(`available-internal`)}
          description={t(`bookable-in-pms`)}
          switchValue={available.internal}
          setSwitchValue={(value) =>
            setAvailable({ ...available, internal: value })
          }
          textValue={descriptionText.internal || ""}
          setTextValue={(text) =>
            setDescriptionText({ ...descriptionText, internal: text })
          }
        />
      </div>

      <div className="py-4">
        <ChannelSwitchWithTextarea
          label={t(`available-on-web-booking`)}
          description={t(`only-roomr-wbe`)}
          switchValue={available.public}
          setSwitchValue={(value) =>
            setAvailable({ ...available, public: value })
          }
          textValue={descriptionText.public || ""}
          setTextValue={(text) =>
            setDescriptionText({ ...descriptionText, public: text })
          }
        />
      </div>
      {!isAreaCategoryType && (
        <div>
          <ChannelSwitchWithTextarea
            label={t(`available-external`)}
            description={t(`available-external-channels`)}
            switchValue={available.external}
            setSwitchValue={(value) =>
              setAvailable({ ...available, external: value })
            }
            textValue={descriptionText.external || ""}
            setTextValue={(text) =>
              setDescriptionText({ ...descriptionText, external: text })
            }
          />
        </div>
      )}
    </>
  );
};

export const ChannelSwitchWithTextarea = ({
  label,
  description,
  switchValue,
  setSwitchValue,
  switchDisabled,
  textValue,
  setTextValue,
}: {
  label: string;
  description: string;
  switchValue: boolean;
  setSwitchValue: (value: boolean) => void;
  switchDisabled?: boolean;
  textValue: string;
  setTextValue: (value: string) => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-sm border border-highlighted-backplate p-4">
      <div className="flex flex-row items-center justify-between ">
        <div>
          <p className="text-sm font-bold text-primary-text">{label}</p>
          <span className="text-sm text-secondary-text">{description}</span>
        </div>
        <Switch
          disabled={switchDisabled}
          checked={switchValue}
          onCheckedChange={(newValue) => setSwitchValue(newValue)}
        />
      </div>
      {switchValue && (
        <Textarea
          placeholder={t("description")}
          className="mt-2 rounded-sm border-highlighted-backplate bg-primary-card-backplate p-4"
          rows={3}
          value={textValue || ""}
          onChange={(e) => setTextValue(e.target.value)}
        />
      )}
    </div>
  );
};
