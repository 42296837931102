import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@primitives/card";
import {
  OrganizationPermission,
  User,
} from "../../../../../api-contracts/admin";
import { Check, Pencil, Save, X } from "lucide-react";
import { PropsWithChildren, useState } from "react";
import { SiteUser } from "../../../../../api-contracts/sites";
import { Role } from "../../../../../api-contracts/organizations";
import { ToggleGroup, ToggleGroupItem } from "@primitives/toggle-group";
import { Skeleton } from "@primitives/skeleton";

type BaseUser = Omit<User, "orgId" | "grants">;

type BaseProps = PropsWithChildren<{
  user: BaseUser;
  onEditClick: () => void;
  isEditMode: boolean;
  onSaveClick?: () => void;
}>;

const BaseCard = ({
  user,
  onEditClick,
  isEditMode,
  onSaveClick,
  children,
}: BaseProps) => {
  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle>{user.firstName + " " + user.lastName}</CardTitle>
          <div className="flex">
            {isEditMode && (
              <Save
                className="hover:cursor-pointer"
                onClick={onSaveClick ? onSaveClick : () => null}
              />
            )}
            <Pencil className="hover:cursor-pointer" onClick={onEditClick} />
          </div>
        </div>
        <CardDescription>{user.email}</CardDescription>
      </CardHeader>
      <CardContent className="flex flex-wrap">{children}</CardContent>
    </Card>
  );
};

export const SiteUserCard = ({
  user,
  allRoles,
  onSave,
  loading,
}: {
  user: SiteUser;
  allRoles: Role[];
  onSave: (userId: string, roleIds: { id: string }[]) => void;
  loading: boolean;
}) => {
  const [iseEditMode, setIsEditMode] = useState(false);
  const [roles, setRoles] = useState(user.roles);
  const onToggle = (value: string[]) =>
    setRoles(() => allRoles.filter(({ id }) => value.includes(id)));
  const onSaveClick = () => {
    onSave(
      user.id,
      roles.map(({ id }) => ({ id })),
    );
    setIsEditMode(false);
  };

  if (loading) {
    return <Skeleton className="h-36 w-full" />;
  }
  return (
    <BaseCard
      isEditMode={iseEditMode}
      onEditClick={() => setIsEditMode(!iseEditMode)}
      user={user}
      onSaveClick={onSaveClick}
    >
      <>
        {!iseEditMode ? (
          allRoles.map((role) => (
            <span
              key={user.id + role.id}
              className="w-100 text-l m-1 flex justify-between rounded border p-2"
            >
              <span key={user.id + role.id + "inner"} className="text-m mr-2">
                {role.name}
              </span>
              {user.roles.map(({ id }) => id).includes(role.id) ? (
                <Check
                  key={user.id + role.id + "check"}
                  className="text-purple-500"
                />
              ) : (
                <X key={user.id + "x"} className="text-blush-500" />
              )}
            </span>
          ))
        ) : (
          <ToggleGroup
            type="multiple"
            variant="outline"
            className="flex flex-wrap"
            onValueChange={onToggle}
            value={roles.map(({ id }) => id)}
          >
            {allRoles.map((role) => (
              <ToggleGroupItem
                key={role.id}
                value={role.id}
                aria-label={`Toggle role ${role.name}`}
              >
                <span>{role.name}</span>
              </ToggleGroupItem>
            ))}
          </ToggleGroup>
        )}
      </>
    </BaseCard>
  );
};

export const OrgUserCard = ({
  user,
  allGrants,
  onEditClick,
  loading,
}: {
  user: User;
  allGrants: OrganizationPermission[];
  onEditClick: (user: User) => void;
  loading: boolean;
}) => {
  if (loading) {
    return <Skeleton className="h-36 w-full" />;
  }
  return (
    <BaseCard
      onEditClick={() => onEditClick(user)}
      isEditMode={false}
      user={user}
    >
      {allGrants.map((grant) => (
        <span
          key={user.id + grant}
          className="w-100 text-l m-1 flex justify-between rounded border p-2"
        >
          <span key={user.id + grant + "inner"} className="text-m mr-2">
            {grant}
          </span>
          {user.grants.includes(grant) ? (
            <Check
              key={user.id + grant + "check"}
              className="text-purple-500"
            />
          ) : (
            <X key={user.id + "x"} className="text-blush-500" />
          )}
        </span>
      ))}
    </BaseCard>
  );
};
