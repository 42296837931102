import { Card } from "@primitives/card";
import { CategoryTabs } from "../components/category-tabs";
import { CardHeader } from "../components/card-header";
import { ArrivalsTable } from "../components/arrivals-table";
import { TableHeader } from "../components/table-header";
import { ReceptionContextProvider } from "../reception-context";

const ArrivalsPage = ({
  tab,
}: {
  tab: "all" | "hotel" | "area" | "hostel";
}) => {
  return (
    <ReceptionContextProvider>
      <div className="flex h-full w-full flex-col p-4">
        <div>
          <CategoryTabs tab={tab} />
        </div>
        <Card className="mt-4 flex-grow p-0">
          <CardHeader />
          <div>
            <div className="p-4">
              <TableHeader />
            </div>
            <ArrivalsTable />
          </div>
        </Card>
      </div>
    </ReceptionContextProvider>
  );
};

export default ArrivalsPage;
