import { Card, CardContent, CardHeader } from "@primitives/card.tsx";
import { SearchBar } from "@primitives/search-bar.tsx";
import { useLocationsPageContext } from "../locations-context.tsx";
import React, { useEffect, useState } from "react";
import { DotIcon } from "lucide-react";
import {
  LocationProps,
  LocationsHierarchyTree,
} from "../components/locations-hierarchy-tree.tsx";
import { searchTree, sortByTitle } from "../locations-utils.tsx";
import { LocationsExpand } from "../../../settings/locations/components/locations-expand.tsx";
import { useTranslation } from "react-i18next";

export const LocationsTree = () => {
  const {
    sharedState: { locations, currentLocation, value },
    sharedFunctions: { onSearchChange, onClickTree, onNodeExpand },
  } = useLocationsPageContext();
  const { t } = useTranslation();

  const [filteredNodes, setFilteredNodes] = useState<LocationProps[]>([]);

  useEffect(() => {
    value === ""
      ? setFilteredNodes(
          locations.sort((a, b) => sortByTitle(a.location, b.location, null)),
        )
      : setFilteredNodes(() => {
          const foundNodes = searchTree(value, locations);
          return foundNodes.sort((a, b) =>
            sortByTitle(a.location, b.location, null),
          );
        });
  }, [locations, value, setFilteredNodes]);

  return (
    <Card className="flex min-w-[320px] flex-col">
      <CardHeader className="p-4">
        <SearchBar
          value={value}
          placeholder={t("search-locations")}
          onChange={(e) => onSearchChange(e)}
        />
      </CardHeader>
      <CardContent className="h-full flex-grow bg-primary-card-backplate pt-4">
        <LocationsHierarchyTree
          onSelect={onClickTree}
          nodes={
            filteredNodes.length === 0 && value === ""
              ? locations
              : filteredNodes
          }
          current={currentLocation?.location.id ?? null}
        >
          {(node) => (
            <>
              <DotIcon className="size-4" />
              <li
                className={`cursor-pointer list-inside overflow-x-hidden text-ellipsis ${node.location.id === currentLocation?.location.id && "font-bold"}`}
                onClick={() => onClickTree(node)}
              >
                {node.location.name}
              </li>
              <LocationsExpand
                location={node}
                locations={locations}
                onExpand={onNodeExpand}
              />
            </>
          )}
        </LocationsHierarchyTree>
      </CardContent>
    </Card>
  );
};
