import { LucideIcon } from "@components/icon-selector";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import { X } from "lucide-react";

interface Item {
  id: string;
  name: string;
  iconName?: string;
}

export const HoveringDeleteList = ({
  items,
  setItems,
}: {
  items: Item[];
  setItems: React.Dispatch<React.SetStateAction<Item[]>>;
}) => {
  const handleDelete = (id: string) => {
    setItems(items.filter((item) => item.id !== id));
  };

  return (
    <div className="flex w-full flex-col space-y-2 overflow-y-auto p-2 ">
      {items.map((item) => (
        <div
          key={item.id}
          className="group flex h-[34px] min-h-[34px] flex-nowrap items-center justify-between rounded-sm bg-primary-card-backplate hover:bg-secondary-card-backplate"
        >
          <div className="flex items-center">
            {item?.iconName && (
              <div className="ml-2">
                <LucideIcon
                  size={20}
                  strokeWidth={0.7}
                  name={item.iconName as keyof typeof dynamicIconImports}
                />
              </div>
            )}
            <p className=" ml-2 text-xs font-extrabold text-primary-text">
              {item.name}
            </p>
          </div>
          <X
            className=" mr-1 hidden cursor-pointer group-hover:block"
            size={18}
            onClick={() => handleDelete(item.id)}
          />
        </div>
      ))}
    </div>
  );
};
