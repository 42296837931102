import { CalendarHeader } from "./components/calendar-header";
import { YearLayoutCalendar } from "./components/year-layout-calendar";
import { PriceCalendarContextProvider } from "./price-calendar-context";

const PriceCalendarPage = () => {
  return (
    <PriceCalendarContextProvider>
      <div className="flex h-full w-full flex-col ">
        <CalendarHeader />
        <YearLayoutCalendar />
      </div>
    </PriceCalendarContextProvider>
  );
};

export default PriceCalendarPage;
