import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { GetReservation } from "../../../../../../api-contracts/reservations";
import { differenceInCalendarDays, format } from "date-fns";
import { enGB, sv } from "date-fns/locale";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (debit?: boolean) => void;
  reservation: GetReservation;
}

export const CancelReservationDialog = ({
  open,
  onOpenChange,
  onProceed,
  reservation,
}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit min-w-[500px] max-w-fit">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("cancel-reservation")}
            </DialogTitle>

            <div className="mt-4">
              <p className=" font-normal">
                {t(
                  "are-you-sure-you-wish-to-cancel-the-entire-reservation-{{id}}?",
                  { id: reservation.id },
                )}
              </p>

              <div className=" mx-4 my-4">
                <ul className=" list-disc">
                  {reservation.bookings.map((b) => (
                    <li key={b.id} className=" text-sm ">
                      <strong>{b.category.name}</strong>{" "}
                      {format(b.checkin, "d MMMM", {
                        locale: i18n.language === "sv-se" ? sv : enGB,
                      })}{" "}
                      -{" "}
                      {format(b.checkout, "d MMMM", {
                        locale: i18n.language === "sv-se" ? sv : enGB,
                      })}{" "}
                      ({differenceInCalendarDays(b.checkout, b.checkin)}{" "}
                      {t("nights-lower")})
                    </li>
                  ))}
                </ul>
              </div>

              <div className=" mt-4 flex justify-end space-x-2">
                <Button variant="outline" onClick={() => onOpenChange(false)}>
                  {t("cancel")}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    onOpenChange(false);
                    onProceed(false);
                  }}
                >
                  {t("cancel-reservation")}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    onOpenChange(false);
                    onProceed(true);
                  }}
                >
                  {t("cancel-reservation-and-debit")}
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CancelReservationDialog;
