import { useGetConfirmationSpheres } from "@api/confirmation-spheres";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useTranslation } from "react-i18next";
import { ConfirmationSphereType } from "../../../../../../api-contracts/confirmation-spheres";

interface Props {
  value?: { id: string; name: ConfirmationSphereType };
  onValueChange?: (value?: {
    id: string;
    name: ConfirmationSphereType;
  }) => void;
  id?: string;
  disabled?: boolean;
}

const ConfirmationSphereSelect = ({
  value,
  onValueChange,
  id,
  disabled,
}: Props) => {
  const [t] = useTranslation();

  const {
    data: confirmationSpheresData,
    isLoading: confirmationSpheresIsLoading,
    isRefetching: confirmationSpheresIsRefetching,
  } = useGetConfirmationSpheres({});

  return (
    <Select
      disabled={disabled}
      value={value?.id}
      onValueChange={(value) =>
        onValueChange &&
        onValueChange(confirmationSpheresData?.find((c) => c.id === value))
      }
    >
      <SelectTrigger id={id} className="text-xs">
        <SelectValue>{value?.name}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {confirmationSpheresData?.map((s, i) => (
          <SelectItem key={i} value={s.id} className=" text-xs">
            {t(s.name)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default ConfirmationSphereSelect;
