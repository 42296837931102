import { createMutation, createQuery } from "react-query-kit";
import {
  DeleteMessageOfTheDayRequest,
  GetReceptionArrivalsResponse,
  SaveMessageOfTheDayRequest,
  SaveMessageOfTheDayResponse,
} from "../../../../api-contracts/reception";
import { api } from "./api";
import { currentSite } from "@context/site-context";

export const ARRIVALS_QUERY_KEY = "reception/arrivals";
export const MOTD_QUERY_KEY = "reception/motd";

// -- ARRIVALS --
// GET /api/:siteId/reception/arrivals/:date
export const useGetArrivalsByDate = createQuery<
  GetReceptionArrivalsResponse,
  { date: string }
>({
  queryFn: async ({ queryKey: [, { date }] }) =>
    api.get(`/api/${currentSite?.id}/${ARRIVALS_QUERY_KEY}/${date}`),
  primaryKey: ARRIVALS_QUERY_KEY,
});

// POST /api/:siteId/reception/arrivals/check-in/:bookingId

// -- DEPARTURES --
// GET /api/:siteId/reception/departures/:date

// POST /api/:siteId/reception/departures/check-out/:bookingId

// -- MESSAGE OF THE DAY --
// PUT /api/:siteId/reception/motd
export const usePutMotd = createMutation<
  SaveMessageOfTheDayRequest,
  SaveMessageOfTheDayResponse
>({
  mutationFn: async ({ message, date }) =>
    api.put<SaveMessageOfTheDayResponse>(
      `/api/${currentSite?.id}/${MOTD_QUERY_KEY}`,
      {
        message,
        date,
      },
    ),
});

// DELETE /api/:siteId/reception/motd  //TODO Delete by ID or Date

// -- OCCUPANTS --
// GET /api/:siteId/reception/occupants/:date
