import { useEffect, useState } from "react";
import {
  LocationsAction,
  useLocationsPageContext,
} from "../../locations-context.tsx";
import { LocationsSettingsMenu } from "../../components/locations-settings-menu.tsx";
import { LocationProps } from "../../components/locations-hierarchy-tree.tsx";
import {
  createToastMessageOnMove,
  findLocationById,
  getFilter,
  getPathForDisplay,
  sortByTitle,
} from "../../locations-utils.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { ListItemWithButton } from "@primitives/list-item-with-button.tsx";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const MoveAssets = () => {
  const {
    sharedState: { locations, currentAsset, currentLocation, actionType },
    sharedFunctions: {
      setCurrentLocation,
      setActionType,
      updateBookable,
      setCurrentAsset,
    },
  } = useLocationsPageContext();
  const {
    sharedFunctions: { toast },
  } = useSharedContext();
  const { t } = useTranslation();

  const defaultLocations = locations
    .filter(({ location }) => location.id !== currentLocation?.location.id)
    .sort((a, b) => sortByTitle(a.location, b.location, null));

  const [filtered, setFiltered] = useState<LocationProps[]>(defaultLocations);
  const [filteredBySearch, setFilteredBySearch] =
    useState<LocationProps[]>(defaultLocations);

  useEffect(() => {
    const location = locations.find(({ location }) =>
      currentAsset?.locations?.find((l) => location.id === l.id),
    );
    setCurrentLocation((prev) => location ?? prev);
  }, [currentAsset, setCurrentLocation, locations]);

  const onUpdateBookableSuccess = (locationId: string) => {
    const data = findLocationById(locationId, locations);
    if (currentAsset && data) {
      toast({
        variant: "success",
        title: createToastMessageOnMove(currentAsset.name, data, locations, t),
      });
      setCurrentAsset({
        ...currentAsset,
        location: { id: data.location.id, name: data.location.name },
      });
    }
  };

  const onClick = (data: LocationProps) => {
    if (currentAsset) {
      updateBookable(
        data.location.id,
        currentAsset.id,
        onUpdateBookableSuccess,
      );
    }
  };

  const onClickFilter = (filterIds: string[]) => {
    filterIds.length === 0 && setFiltered(defaultLocations);
    filterIds.length > 0 &&
      setFiltered(
        defaultLocations.filter(
          ({ location }) => location.id && filterIds.includes(location.id),
        ),
      );
  };

  const onInputSearch = (value: string) => {
    value === "" && setFilteredBySearch(defaultLocations);
    value != "" &&
      setFilteredBySearch(
        defaultLocations.filter(({ location }) =>
          location.name.toLowerCase().includes(value.toLowerCase().trim()),
        ),
      );
  };

  return (
    <DefaultSideSheet
      title={`${t("move")} ${currentAsset?.name}`}
      description={`${t("from")} ${currentLocation?.location.name}`}
      open={actionType === LocationsAction.ASSET_MOVE}
      onOpenChange={() => setActionType(LocationsAction.DEFAULT)}
      side={"left"}
      className={"overflow-y-auto"}
    >
      <>
        <LocationsSettingsMenu
          filters={defaultLocations}
          onSearchInput={onInputSearch}
          onAddFilter={onClickFilter}
          filterMenuLabel={t("filter-bookables")}
        />
        <p className="pb-0 text-xs font-bold">{t("locations")} </p>
        <div className="border-gray-150 flex-grow overflow-auto rounded-md border-[1.5px]">
          {getFilter(filtered, filteredBySearch).map((item, i) => (
            <ListItemWithButton
              key={i}
              title={item.location.name}
              subtitle={getPathForDisplay(item)}
              buttonText={t("move")}
              onClick={() => onClick(item)}
            />
          ))}
        </div>
      </>
    </DefaultSideSheet>
  );
};
