import { DefaultListItem } from "@components/default-list";
import { Plus, X } from "lucide-react";
import { useTranslation } from "react-i18next";

interface AddedProps<T> {
  addedItems: T[];
  onRemove: (item: T) => void;
}

export const Added: React.FC<AddedProps<any>> = ({ addedItems, onRemove }) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col">
      <p className="mb-2 font-itc text-sm text-secondary-text">
        {t("added-plural")}
      </p>
      <div className="flex-grow overflow-y-auto rounded-md bg-secondary-card-backplate text-primary-text">
        {addedItems.map((item) => {
          return (
            <div key={item.id} className="p-0">
              <DefaultListItem
                title={item.name}
                rightIconComponent={
                  <ListButton type="added" onClick={() => onRemove(item)} />
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface AvailableProps<T> {
  availableItems: T[];
  onAdd: (item: T) => void;
  addedItems: T[];
}

export const Available: React.FC<AvailableProps<any>> = ({
  availableItems,
  onAdd,
  addedItems,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col">
      <p className="mb-2 font-itc text-sm text-secondary-text">
        {t("available")}
      </p>
      <div className="flex-grow overflow-y-auto rounded-md bg-secondary-card-backplate text-primary-text">
        {availableItems.map((item) => {
          const isAdded = addedItems.some((g) => g.id === item.id);
          if (isAdded) return;
          return (
            <div key={item.id} className="p-0">
              <DefaultListItem
                title={item.name}
                rightIconComponent={
                  <ListButton type="available" onClick={() => onAdd(item)} />
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ListButton = ({
  type,
  onClick,
}: {
  type: "added" | "available";
  onClick: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className=" flex h-[35px] w-[35px] items-center justify-center rounded-md bg-primary-button-backplate hover:opacity-60"
    >
      {type === "available" ? (
        <Plus strokeWidth={0.5} />
      ) : (
        <X strokeWidth={0.5} />
      )}
    </button>
  );
};
