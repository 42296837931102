import {
  TargetGroupsActionType,
  useTargetGroupsPageContext,
} from "../target-groups-context.tsx";
import { Card, CardContent, CardTitle } from "@primitives/card.tsx";
import { Edit, X } from "lucide-react";
import { Button } from "@primitives/button.tsx";
import { DefaultBadge } from "@primitives/default-badge.tsx";
import { DeleteItem } from "@primitives/delete-item.tsx";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@primitives/tooltip.tsx";
import { cn } from "@utils/css.ts";

export const TargetGroupsDetailCard = () => {
  const {
    sharedState: { selectedTargetGroupDetails },
    sharedFunctions: {
      setSelectedTargetGroupDetails,
      setAction,
      deleteTargetGroup,
    },
  } = useTargetGroupsPageContext();
  const { t } = useTranslation();

  const onRemove = () => {
    selectedTargetGroupDetails &&
      deleteTargetGroup(
        selectedTargetGroupDetails.id,
        selectedTargetGroupDetails.name,
      );
  };

  return (
    <Card className="relative h-full max-w-[500px] flex-grow">
      <X
        className="absolute right-3 top-3 w-[16px] cursor-pointer hover:text-secondary-text"
        onClick={() => setSelectedTargetGroupDetails(null)}
      />
      <div className="h-[100px]">
        <CardTitle>{t(selectedTargetGroupDetails?.name ?? "")}</CardTitle>
        <div className="flex space-x-2 py-4">
          <div className=" m-0 flex flex-shrink p-0">
            <Button
              variant="secondary"
              className="h-[40px] w-[40px] p-2"
              onClick={() => setAction(TargetGroupsActionType.EDIT_SELECTED)}
            >
              <Edit />
            </Button>
          </div>
          <Tooltip
            content={t("target-group-can-not-be-deleted")}
            className={cn(
              "bg-black",
              selectedTargetGroupDetails?.mutable && "hidden",
            )}
          >
            <div className="m-0 flex flex-shrink p-0">
              <Button
                variant="secondary"
                className="h-[40px] w-[40px] p-2"
                disabled={!selectedTargetGroupDetails?.mutable}
              >
                <DeleteItem
                  alertTitle={t(
                    "are-you-sure-you-want-to-delete-target-group",
                    {
                      targetGroup: selectedTargetGroupDetails?.name,
                    },
                  )}
                  alertDescription={t("target-group-will-be-deleted")}
                  submitText={t("yes-delete-target-group", {
                    targetGroup: selectedTargetGroupDetails?.name,
                  })}
                  onClick={onRemove}
                />
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
      <CardContent className="my-4 flex flex-col space-y-10">
        <div className="flex flex-grow flex-col">
          <p className="font-bold">{t("channels")}</p>
          <div className="mt-2 flex flex-wrap items-center gap-2">
            {selectedTargetGroupDetails?.channels?.map((channel) => (
              <DefaultBadge
                key={channel.id}
                id={channel.id}
                text={channel.name}
              />
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
