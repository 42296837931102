import {
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogRoot,
  AlertDialogTitle,
} from "@primitives/alert-dialog";
import { Button } from "@primitives/button";
import { useTranslation } from "react-i18next";
import {
  CalendarDay,
  Note,
} from "../../../../../../api-contracts/price-calendar";
import { Input } from "@primitives/input";
import {
  useCreatePriceCalendarNote,
  useUpdatePriceCalendarNote,
  useGetPriceCalendar,
  useDeletePriceCalendarNote,
} from "@api/price-calendar";
import { format } from "date-fns";
import { queryClient } from "query-client";
import { useEffect, useState } from "react";
import { toast } from "@hooks/use-toast";
import { Loading } from "@primitives/loading";
import { Label } from "@primitives/label";

export const NoteAlertDialog = ({
  day,
  open,
  close,
}: {
  day: CalendarDay | null;
  open: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");
  const [tempNotes, setTempNotes] = useState<Note[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { mutateAsync: postNote } = useCreatePriceCalendarNote();
  const { mutateAsync: patchNote } = useUpdatePriceCalendarNote();
  const { mutateAsync: delNote } = useDeletePriceCalendarNote();

  useEffect(() => {
    if (day) {
      setTempNotes(day.notes);
    }
  }, [day]);

  const createNote = async (
    startDate: string,
    endDate: string,
    message: string,
  ) => {
    const newNote = {
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
      message,
    };
    setIsLoading(true);
    try {
      await postNote(newNote);

      queryClient.invalidateQueries({
        queryKey: useGetPriceCalendar.getKey(),
      });

      toast({
        title: t("saved-succesfully", { name: newNote.message }),
        variant: "success",
      });
      setMessage("");
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateNote = async (note: Note, message: string) => {
    setIsLoading(true);
    try {
      await patchNote({
        priceCalendarNoteIds: [note.id],
        message,
      });

      queryClient.invalidateQueries({
        queryKey: useGetPriceCalendar.getKey(),
      });

      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteNote = async (note: Note) => {
    setIsLoading(true);
    try {
      await delNote({
        priceCalendarNoteId: note.id,
      });

      queryClient.invalidateQueries({
        queryKey: useGetPriceCalendar.getKey(),
      });
      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setMessage("");
    close();
  };

  if (!day) return null;

  const isNewNote = day.notes.length === 0;

  return (
    <AlertDialogRoot open={open}>
      <AlertDialogContent className="text-primary-text ">
        <AlertDialogHeader>
          <AlertDialogTitle>
            {isNewNote ? t("create-note") : t("edit-note")}
          </AlertDialogTitle>
          <AlertDialogDescription aria-describedby="note-dialog" />
        </AlertDialogHeader>
        {tempNotes.length > 0 && (
          <div className="flex flex-col">
            {tempNotes.map((note, index) => (
              <NoteItem
                key={`${note.id}-${index}`}
                note={note}
                updateNote={updateNote}
                deleteNote={deleteNote}
                date={day.date}
              />
            ))}
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                <Loading />
              </div>
            )}
          </div>
        )}
        <div>
          <Label className="text-xs">
            {isNewNote ? t("new-note") : t("add-note")}
          </Label>
          <Input value={message} onChange={(e) => setMessage(e.target.value)} />
          <Button
            loading={isLoading}
            className="my-4"
            disabled={message.length === 0}
            size={"sm"}
            onClick={() => createNote(day.date, day.date, message)}
          >
            {t("add")}
          </Button>
        </div>
        <AlertDialogFooter className="justify-end">
          <Button onClick={handleCloseDialog}>{t("close")}</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogRoot>
  );
};

export const NoteItem = ({
  note,
  updateNote,
  deleteNote,
  date,
}: {
  note: Note;
  updateNote: (note: Note, message: string) => void;
  deleteNote: (note: Note) => void;
  date: string;
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(note.message);

  useEffect(() => {
    setMessage(note.message);
  }, [isEditing]);

  const handleUpdateNote = () => {
    updateNote(note, message);
    setIsEditing(false);
  };

  return (
    <div className="flex flex-col border-b border-highlighted-backplate pb-4">
      <div className="py-2">
        {isEditing ? (
          <Input value={message} onChange={(e) => setMessage(e.target.value)} />
        ) : (
          <>
            <p>{note.message}</p>
          </>
        )}
      </div>
      <div className="mt-2 flex justify-between ">
        <div>
          <Button
            size={"sm"}
            variant={`${isEditing ? "primary" : "outline"}`}
            onClick={isEditing ? handleUpdateNote : () => setIsEditing(true)}
          >
            {isEditing ? t("save") : t("change")}
          </Button>
          <Button
            size={"sm"}
            variant={"outline"}
            className={`${!isEditing && "ml-2 bg-red-100 text-status-error"}`}
            onClick={
              !isEditing ? () => deleteNote(note) : () => setIsEditing(false)
            }
          >
            {isEditing ? t("cancel") : t("delete")}
          </Button>
        </div>
        <div className="flex items-center">
          {/* {date && <p className=" text-xs italic">{date}</p>} */}
        </div>
      </div>
    </div>
  );
};
