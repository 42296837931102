/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import {
  CategoryAsset,
  CategoryCapacity,
  CategoryDetails,
  CreateCategoryRequest,
  PatchCategoryRequest,
} from "../../../../../../api-contracts/categories";
import { useGetCategoryById } from "@api/categories";
import { useGetCategoryGroups } from "@api/groups";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loading } from "@primitives/loading";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { Button } from "@primitives/button";
import { Card } from "@primitives/card";
import { Input } from "@primitives/input";
import { MultiSelect } from "@primitives/multi-select";
import { useCategoriesContext } from "../categories-context";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { CategoryDetailCard } from "../components/category-detail-card";
import { useTranslation } from "react-i18next";
import { Group } from "../../../../../../api-contracts/groups";
import { NavigationArrows } from "@components/navigation-arrows";
import { isEqual } from "lodash";
import { ConfirmNavigationDialog } from "@pages/settings/categories/components/confirm-navigation-dialog";
import {
  AreaCapacity,
  EditCapacity,
  GuestCapacity,
} from "../components/capacity";
import { Image } from "../../../../../../api-contracts/images";
import { Media } from "../components/media";
import { ConnectChannels } from "../components/connected-channel-switch";
import { HoveringDeleteList } from "../components/hovering-delete-list";
import { PlaceholderInput } from "../components/placeholder-input";
import { ConfirmDialog } from "../components/confirm-dialog";
import { useProfileContext } from "@context/profile-context";
import { useGetAmenities } from "@api/amenities";
import { Amenity } from "../../../../../../api-contracts/amenities";
import { BorderedListItem } from "../components/bordered-list-item";

const initialCategory: CategoryDetails = {
  id: "",
  active: true,
  basePrice: 0,
  name: "",
  short: "",
  siteId: "",
  description: {
    internal: "",
    external: "",
    public: "",
  },
  available: {
    internal: true,
    external: false,
    public: true,
  },
  overrides: [],
  capacity: {
    adults: { min: 1, max: 1 },
    children: { max: 0 },
    teenagers: { max: 0 },
    infants: { max: 0 },
    total: { max: 1 },
  },
  groups: [],
  allGroups: [],
  assets: [],
  products: [],
  media: { images: [] },
  amenities: [],
  cleaning: [],
  type: "room",
};

enum SheetContentType {
  Capacity = "guest-capacity",
  Cleaning = "cleaning",
  Price = "rack-rate",
}

interface Item {
  id: string;
  name: string;
}

export interface Available {
  internal: boolean;
  external: boolean;
  public: boolean;
}

export interface DescriptionText {
  internal: string | null;
  external: string | null;
  public: string | null;
}

const CategoryDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const { patchCategory, createCategory, selectedCategoryType, categoryTypes } =
    useCategoriesContext();
  const [category, setCategory] = useState<CategoryDetails>(initialCategory);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenSheet, setIsOpenSheet] = useState<boolean>(false);
  const [sheetContent, setSheetContent] = useState<SheetContentType>();
  const [descriptionText, setDescriptionText] = useState<DescriptionText>({
    ...initialCategory.description,
  });
  const [categoryName, setCategoryName] = useState({
    name: initialCategory.name,
    short: initialCategory.short,
  });
  const [assets, setAssets] = useState<CategoryAsset[]>([
    ...initialCategory.assets,
  ]);
  const [groups, setGroups] = useState<Group[]>([...initialCategory.groups]);
  const [allGroups, setAllGroups] = useState<Group[]>([
    ...initialCategory.allGroups,
  ]);
  const [available, setAvailable] = useState<Available>({
    ...initialCategory.available,
  });
  const [active, setActive] = useState<boolean>(initialCategory.active);
  const [basePrice, setBasePrice] = useState<number>(initialCategory.basePrice);
  const [capacity, setCapacity] = useState<CategoryCapacity>({
    ...initialCategory.capacity,
  });
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState<boolean>(false);
  const [media, setMedia] = useState<{ images: Image[] }>(
    initialCategory.media,
  );
  const [amenities, setAmenities] = useState<Amenity[]>([
    ...initialCategory.amenities,
  ]);
  const [isOpenDeactivateDialog, setIsOpenDeactivateDialog] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);

  const isNewCategory = id === "new";
  const isDuplicate = location.pathname.includes("duplicate");
  const isEdit = searchParams.has("edit");
  const isAreaCategoryType = selectedCategoryType === "area";

  const { data: categoryData, isLoading: categoryIsLoading } =
    useGetCategoryById({
      variables: { id: !isNewCategory ? (id as string) : "" },
    });

  const { data: groupsData, isLoading: groupsIsLoading } =
    useGetCategoryGroups();

  const { data: amenitiesData, isLoading: amenitiesIsLoading } =
    useGetAmenities();

  useEffect(() => {
    setIsLoading(categoryIsLoading || groupsIsLoading || amenitiesIsLoading);
    if (groupsData) setAllGroups(groupsData);
    if (!isNewCategory) {
      if (!categoryIsLoading && categoryData !== undefined) {
        setCategory({
          ...categoryData,
        });
        setDescriptionText({ ...categoryData.description });
        setCategoryName(
          !isDuplicate
            ? {
                name: categoryData.name,
                short: categoryData.short,
              }
            : { name: "", short: "" },
        );
        setAvailable({
          ...categoryData.available,
        });
        setBasePrice(categoryData.basePrice);
        setGroups(categoryData.groups);
        setAssets(categoryData.assets);
        setActive(categoryData.active);
        setCapacity({ ...categoryData.capacity });
        setMedia(categoryData.media);
        setAmenities(categoryData.amenities);
      }
    }
    if (shouldResetForm) {
      setShouldResetForm(false);
    }
  }, [
    isNewCategory,
    categoryData,
    categoryIsLoading,
    isDuplicate,
    allGroups,
    groupsData,
    groupsIsLoading,
    shouldResetForm,
  ]);

  useEffect(() => {
    setIsEditing(isNewCategory || isDuplicate || isEdit);
  }, [isDuplicate, isNewCategory, isEdit]);

  const handleOpenSheet = (contentType: SheetContentType) => {
    setSheetContent(contentType);
    setIsOpenSheet(true);
  };

  const getInitialCategoryData = () => {
    return isNewCategory
      ? { ...initialCategory, type: selectedCategoryType }
      : {
          ...categoryData,
          name: isDuplicate ? "" : categoryData?.name,
          short: isDuplicate ? "" : categoryData?.short,
          description: categoryData?.description,
          available: categoryData?.available,
          groups: categoryData?.groups.map((group) => group.id),
          assets: categoryData?.assets.map((asset) => asset.id),
          active: categoryData?.active,
          basePrice: categoryData?.basePrice,
          capacity: categoryData?.capacity,
          media: categoryData?.media,
          amenities: categoryData?.amenities,
        };
  };

  const checkIfValuesChanged = () => {
    const currentCategory = {
      ...categoryData,
      name: categoryName.name,
      short: categoryName.short,
      description: descriptionText,
      available: available,
      groups: groups.map((group) => group.id),
      assets: assets.map((asset) => asset.id),
      active: active,
      basePrice: basePrice,
      capacity: capacity,
      media: media,
      amenities: amenities,
    };

    const initialCategoryData = getInitialCategoryData();

    const inputHasBeenChanged = !isEqual(currentCategory, initialCategoryData);
    const nameIsEmpty =
      categoryName.name.length < 1 || categoryName.short.length < 1;
    const basePriceIsEmpty = basePrice < 1;
    setSaveIsEnabled(inputHasBeenChanged && !nameIsEmpty && !basePriceIsEmpty);
  };

  useEffect(() => {
    checkIfValuesChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoryName.name,
    categoryName.short,
    descriptionText,
    available,
    groups,
    assets,
    categoryData,
    isNewCategory,
    isDuplicate,
    active,
    basePrice,
    capacity,
    media,
    amenities,
  ]);
  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (!isNewCategory && !isDuplicate) {
        const patchData: PatchCategoryRequest = {
          active: active,
          name: categoryName.name,
          short: categoryName.short,
          description: {
            internal: descriptionText.internal,
            external: descriptionText.external,
            public: descriptionText.public,
          },
          available,
          groupIds: groups.map((grp) => grp.id),
          basePrice: basePrice,
          capacity,
          imageIds: media.images.map((img) => img.id),
          amenityIds: amenities.map((amenity) => amenity.id),
        };

        await patchCategory(category.id, patchData);
        setIsEditing(false);
      }

      if (isNewCategory || isDuplicate) {
        const {
          id,
          siteId,
          groups,
          allGroups,
          assets,
          cleaning,
          amenities,
          products,
          media,
          type,
          config,
          ...rest
        } = category;
        const newCategoryData: CreateCategoryRequest = {
          ...rest,
          name: categoryName.name,
          short: categoryName.short,
          description: {
            internal: descriptionText.internal,
            external: descriptionText.external,
            public: descriptionText.public,
          },
          available,
          groupIds: groups.map((grp) => grp.id),
          basePrice: basePrice,
          capacity,
          imageIds: media.images.map((img) => img.id),
          amenityIds: amenities.map((amenity) => amenity.id),
          type: selectedCategoryType,
        };
        const response = await createCategory(newCategoryData);
        if (response.id)
          navigate(`/${module}/categories/${response.id}`, { replace: true });
      }
    } catch (error) {
      console.error("Error while patching/posting category:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleActive = async () => {
    if (assets.length < 1) {
      setActive((prev) => !prev);
      return;
    }
    if (active) {
      setIsOpenDeactivateDialog(true);
    } else {
      setActive((prev) => !prev);
      setIsOpenDeactivateDialog(false);
    }
  };

  const handleEditMode = () => {
    if (saveIsEnabled && isEditing) {
      setIsOpenEditDialog(true);
    } else {
      setIsEditing((prev) => !prev);
    }
  };

  const handleDiscardChanges = () => {
    setShouldResetForm(true);
    setIsEditing(false);
  };

  return (
    <>
      {!isLoading ? (
        <div className="p-4">
          <Card>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <NavigationArrows rootPath={`/${module}/categories`} />
              </div>
              <div>
                <Button
                  disabled={isDuplicate || isNewCategory || isEditing}
                  variant={"outline"}
                  onClick={() =>
                    navigate(`/${module}/categories/${id}/duplicate`)
                  }
                >
                  {t("duplicate")}
                </Button>
                {isEditing && (
                  <Button
                    className="ml-4 bg-primary-button-backplate"
                    onClick={handleEditMode}
                    variant={"ghost"}
                  >
                    {t("cancel")}
                  </Button>
                )}
                <Button
                  className="ml-4"
                  onClick={isEditing ? handleSave : handleEditMode}
                  variant={"primary"}
                  disabled={isEditing && !saveIsEnabled}
                >
                  {isEditing ? t("save") : t("edit")}
                </Button>
              </div>
            </div>
            <div
              className={`flex flex-col ${!isEditing && "pointer-events-none opacity-60"}`}
            >
              <div className="flex flex-row space-x-4 pt-4">
                <CategoryDetailCard title={t("category-information")}>
                  <>
                    <div className="mb-4 flex justify-between">
                      <p className="text-sm font-medium">Status</p>
                      {isEditing ? (
                        <SwitchWithLabel
                          checked={active}
                          onCheckedChange={handleToggleActive}
                          label={t("category-active")}
                        />
                      ) : (
                        <div
                          className={`${
                            active
                              ? "bg-green-100 text-status-success"
                              : "bg-red-100 text-status-error"
                          } flex h-[24px] items-center justify-center rounded-md px-2 font-bold`}
                        >
                          <p>{active ? t("active") : t("disabled")}</p>
                        </div>
                      )}
                    </div>
                    <p className=" text-sm font-medium text-primary-text">
                      {`${t("name")} ${categoryName.name?.length < 1 ? " *" : ""}`}
                    </p>
                    <Input
                      required
                      value={categoryName.name}
                      onChange={(e) => {
                        setCategoryName({
                          ...categoryName,
                          name: e.target.value,
                        });
                      }}
                    />
                    <p className=" mt-3 text-sm font-medium text-primary-text">
                      {`${t("short")} ${categoryName.short?.length < 1 ? " *" : ""}`}
                    </p>
                    <Input
                      required
                      value={categoryName.short}
                      onChange={(e) => {
                        setCategoryName({
                          ...categoryName,
                          short: e.target.value,
                        });
                      }}
                    />
                  </>
                </CategoryDetailCard>
                <CategoryDetailCard
                  title={t("media")}
                  className="flex flex-col"
                >
                  <Media
                    mediaLibraryOpen={mediaLibraryOpen}
                    setMediaLibraryOpen={setMediaLibraryOpen}
                    media={media}
                    setMedia={setMedia}
                  />
                </CategoryDetailCard>
                <CategoryDetailCard
                  title={t("guest-capacity")}
                  className="flex flex-grow flex-col text-sm text-primary-text"
                >
                  <>
                    {!isAreaCategoryType ? (
                      <>
                        <GuestCapacity capacity={capacity} />
                        <div className="flex flex-grow"></div>
                        <div className="mt-2 flex items-end justify-start">
                          <Button
                            onClick={() =>
                              handleOpenSheet(SheetContentType.Capacity)
                            }
                            variant={"outline"}
                          >
                            {t("edit")}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <AreaCapacity
                        capacity={capacity}
                        setCapacity={setCapacity}
                      />
                    )}
                  </>
                </CategoryDetailCard>
              </div>
              <div className="flex flex-row space-x-4 pt-4">
                <CategoryDetailCard
                  title={t("assets")}
                  className="flex flex-col"
                >
                  <>
                    <p className="text-sm">{t("connecting-assets")}</p>
                    <div
                      className={`flex h-[220px] flex-grow rounded-sm border border-highlighted-backplate`}
                    >
                      {assets.length > 0 ? (
                        <div className="flex  flex-grow flex-col overflow-y-auto">
                          {assets.map((item) => {
                            return (
                              <BorderedListItem
                                key={item.id}
                                leftSideText={item.name}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <div className=" flex  flex-grow items-center justify-center bg-secondary-card-backplate p-4">
                          <p className="p-5 text-center text-sm text-secondary-text">
                            {t(
                              "there-are-no-bookables-associated-with-this-category",
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                </CategoryDetailCard>
                <CategoryDetailCard
                  title={t("cluster")}
                  className="flex flex-col"
                >
                  <>
                    <div className="pb-4">
                      <MultiSelect
                        placeholder={t("choose-cluster")}
                        options={allGroups}
                        value={allGroups.filter((group) =>
                          groups.some((selected) => selected.id === group.id),
                        )}
                        onChange={(value) => setGroups(value)}
                        getName={(option) => option.name}
                      />
                    </div>
                    <p className=" text-sm font-medium text-primary-text">
                      {t("chosen-clusters")}
                    </p>
                    <div className="flex max-h-[270px] min-h-[220px] flex-grow flex-col border border-highlighted-backplate">
                      {groups.length > 0 ? (
                        <HoveringDeleteList
                          items={groups}
                          setItems={setGroups}
                        />
                      ) : (
                        <div className="flex h-[220px] items-center justify-center p-4">
                          <p className="p-5 text-center text-sm text-secondary-text">
                            {t("this-category-is-not-part-of-a-cluster-yet")}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                </CategoryDetailCard>
                <CategoryDetailCard
                  title={t("accounting")}
                  className={"flex flex-grow flex-col"}
                >
                  <div className="flex flex-grow flex-col">
                    <p className="text-sm font-medium text-primary-text">
                      {`${t("minimum-rate")} ${basePrice < 1 ? " *" : ""}`}
                    </p>
                    <PlaceholderInput
                      placeholder="SEK"
                      value={basePrice.toString()}
                      onChange={(e) => setBasePrice(+e.target.value)}
                      min={1}
                    />
                    <span className="mt-1 text-xs text-secondary-text">
                      {t("vat-included")}
                    </span>
                    <div>
                      <p className="pt-4 text-sm font-medium text-primary-text">
                        {t("accounting-number")}
                      </p>
                      <div>
                        <MultiSelect
                          placeholder={t("select-accounting-account")}
                          options={[{ name: "Not implemented", id: "1" }] || []}
                          value={[]}
                          onChange={() => {}}
                          getName={(option) => option.name}
                        />
                      </div>
                    </div>
                  </div>
                </CategoryDetailCard>
                <CategoryDetailCard
                  title={t("included-products")}
                  className="flex flex-col"
                >
                  <>
                    <div>
                      <MultiSelect
                        placeholder={t("choose-product")}
                        options={[{ name: "Not implemented", id: "1" }]}
                        value={[]}
                        onChange={() => {}}
                        getName={(option) => option.name}
                      />
                    </div>
                    <div className=" mt-4 flex flex-grow items-center rounded-sm border border-highlighted-backplate bg-secondary-card-backplate p-4">
                      <p className="p-5 text-center text-sm text-secondary-text">
                        {t(
                          "there-are-no-products-associated-with-this-category",
                        )}
                      </p>
                    </div>
                  </>
                </CategoryDetailCard>
              </div>
              <div className="flex flex-row space-x-4 pt-4">
                <CategoryDetailCard
                  title={t("amenities")}
                  className="flex flex-col"
                >
                  <>
                    <p className=" text-sm font-medium text-primary-text">
                      {t("connect-amenities")}
                    </p>
                    <div>
                      <MultiSelect
                        placeholder={t("choose-an-amenity")}
                        options={amenitiesData || []}
                        value={
                          amenitiesData?.filter((amenity) =>
                            amenities.some(
                              (selected) => selected.id === amenity.id,
                            ),
                          ) || []
                        }
                        onChange={(value) => setAmenities(value)}
                        getName={(option) => option.name}
                      />
                    </div>

                    <p className="mt-4 text-sm font-medium text-primary-text">
                      {t("chosen-amenities")}
                    </p>
                    <div
                      className={`flex h-[220px] flex-grow rounded-sm border border-highlighted-backplate `}
                    >
                      {amenities.length < 1 ? (
                        <div className="flex flex-col justify-center bg-secondary-card-backplate p-4">
                          <p className="p-5 text-center text-sm text-secondary-text ">
                            {t(
                              "there-are-no-amenities-associated-with-this-category",
                            )}
                          </p>
                        </div>
                      ) : (
                        <HoveringDeleteList
                          items={amenities}
                          setItems={
                            setAmenities as React.Dispatch<
                              React.SetStateAction<Item[]>
                            >
                          }
                        />
                      )}
                    </div>
                    <div className="mt-2 h-10" />
                  </>
                </CategoryDetailCard>
                <CategoryDetailCard
                  title={t("housekeeping-cleaning")}
                  className="flex flex-col"
                >
                  <>
                    <div className="flex flex-grow flex-col">
                      <p className="text-sm">
                        {t("connected-housekeeping-tasks")}
                      </p>
                      <div className=" flex flex-grow items-center justify-center rounded-sm border border-highlighted-backplate bg-secondary-card-backplate  p-4">
                        <p className="p-5 text-center text-sm text-secondary-text">
                          {t(
                            "there-are-no-Housekeeping-tasks-associated-with-this-category",
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row items-end justify-start">
                      <Button
                        onClick={() =>
                          handleOpenSheet(SheetContentType.Cleaning)
                        }
                        disabled
                        variant={"outline"}
                      >
                        {t("edit")}
                      </Button>
                    </div>
                  </>
                </CategoryDetailCard>
                <CategoryDetailCard
                  title={t("connected-channels")}
                  className="flex-grow"
                >
                  <>
                    {descriptionText && (
                      <ConnectChannels
                        available={available}
                        setAvailable={setAvailable}
                        descriptionText={descriptionText}
                        setDescriptionText={setDescriptionText}
                      />
                    )}
                    <div className="mt-2 h-10" />
                  </>
                </CategoryDetailCard>
              </div>
            </div>
          </Card>
          <DefaultSideSheet
            title={`${t("edit")} ${t(sheetContent as string).toLowerCase()}`}
            open={isOpenSheet}
            onOpenChange={() => setIsOpenSheet((isOpen) => !isOpen)}
          >
            {sheetContent === SheetContentType.Capacity && (
              <EditCapacity
                capacity={capacity}
                setCapacity={setCapacity}
                closeSheet={() => setIsOpenSheet(false)}
              />
            )}
            {sheetContent === SheetContentType.Cleaning && (
              <p>CLEANING COMPONENT HERE</p>
            )}
          </DefaultSideSheet>
          <ConfirmNavigationDialog
            title={t("changes-not-saved")}
            description={t("you-have-unsaved-changes-that-will-be-lost")}
            shouldWarn={saveIsEnabled}
            onProceed={handleSave}
          />
          <ConfirmDialog
            proceedBtnText={t("yes")}
            title={t("do-you-really-want-to-deactivate?")}
            description={t(
              "If you deactivate the category, the rooms linked to the category will no longer be bookable. Are you sure you want to deactivate?",
            )}
            onOpenChange={setIsOpenDeactivateDialog}
            isOpen={isOpenDeactivateDialog}
            onProceed={() => setActive((prev) => !prev)}
          />
          <ConfirmDialog
            proceedBtnText={t("save")}
            title={t("changes-not-saved")}
            description={t("do-you-wish-to-save-or-discard-the-changes?")}
            onOpenChange={setIsOpenEditDialog}
            isOpen={isOpenEditDialog}
            onProceed={handleSave}
            onDiscard={handleDiscardChanges}
          />
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CategoryDetail;
