import { Card, CardContent, CardHeader, CardTitle } from "@primitives/card";
import { Check, Pencil, X } from "lucide-react";
import { ExtendedRole } from "../sections/org-roles";

export const RoleCard = ({
  role,
  onEditClick,
}: {
  role: ExtendedRole;
  onEditClick: (role: ExtendedRole) => void;
}) => {
  return (
    <Card key={role.id} className="">
      <CardHeader className="flex w-full flex-row justify-between text-xl">
        <CardTitle className="text-xl">{role.name}</CardTitle>
        <Pencil
          className="hover:cursor-pointer"
          onClick={() => onEditClick(role)}
        />
      </CardHeader>
      <CardContent>
        <div className="flex flex-wrap">
          {role.grants.map(({ grant, enabled }) => (
            <span
              key={role.id + grant}
              className="w-100 text-l m-1 flex justify-between rounded border p-2"
            >
              <span className="text-m mr-2">{grant}</span>
              {enabled ? (
                <Check className="text-purple-500" />
              ) : (
                <X className="text-blush-500" />
              )}
            </span>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
