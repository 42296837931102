import { useTranslation } from "react-i18next";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@primitives/accordion";
import { format } from "date-fns";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const ReservationCommunicationDrawer = ({
  open,
  onOpenChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {t("communication")}
            </SheetTitle>
          </SheetHeader>

          <div className=" h-full overflow-y-auto">
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1" className=" border-none">
                <AccordionTrigger className=" h-14 py-0 ">
                  <div className=" relative flex h-full flex-nowrap items-center">
                    <div className=" absolute bottom-0 left-[130px] h-1/2 w-[4px] bg-primary-color" />
                    <div className=" w-[124px] font-bold">
                      {format(new Date(), "yyyy-MM-dd")}
                    </div>
                    <div className=" h-4 w-4 rounded-full bg-primary-color" />
                    <div className=" mx-2 font-bold">
                      {format(new Date(), "HH:mm")}
                    </div>
                    <div className=" mx-2 font-bold">Type</div>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className=" rounded-md border-2 border-primary-color p-4 ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2" className=" border-none">
                <AccordionTrigger className=" h-14 py-0 ">
                  <div className=" relative flex h-full flex-nowrap items-center">
                    <div className=" absolute left-[130px] top-0 h-full w-[4px] bg-primary-color" />
                    <div className=" w-[124px] font-bold">
                      {format(new Date(), "yyyy-MM-dd")}
                    </div>
                    <div className=" h-4 w-4 rounded-full bg-primary-color" />
                    <div className=" mx-2 font-bold">
                      {format(new Date(), "HH:mm")}
                    </div>
                    <div className=" mx-2 font-bold">Type</div>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className=" rounded-md border-2 border-primary-color p-4 ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3" className=" border-none">
                <AccordionTrigger className=" h-14 py-0 ">
                  <div className=" relative flex h-full flex-nowrap items-center">
                    <div className=" absolute left-[130px] top-0 h-1/2 w-[4px] bg-primary-color" />
                    <div className=" w-[124px] font-bold">
                      {format(new Date(), "yyyy-MM-dd")}
                    </div>
                    <div className=" h-4 w-4 rounded-full bg-primary-color" />
                    <div className=" mx-2 font-bold">
                      {format(new Date(), "HH:mm")}
                    </div>
                    <div className=" mx-2 font-bold">Type</div>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className=" rounded-md border-2 border-primary-color p-4 ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default ReservationCommunicationDrawer;
