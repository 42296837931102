import { createQuery } from "react-query-kit";
import { api } from "./api";
import { currentSite } from "@shared/context/site-context";
import {
  GetCategoryGridQueryParams,
  GetCategoryGridResponse,
} from "../../../../api-contracts/category-grid";

export const useGetCategoryGrid = createQuery<
  GetCategoryGridResponse,
  GetCategoryGridQueryParams
>({
  primaryKey: "/api/<siteId>/category-grid",
  queryFn: async ({ queryKey: [, { startDate, endDate, types }] }) => {
    const qs = [];
    qs.push(`startDate=${startDate}`);
    qs.push(`endDate=${endDate}`);
    if (types) {
      if (typeof types === "string") {
        qs.push(`types=${types}`);
      } else {
        types.forEach((t) => qs.push(`types=${t}`));
      }
    }
    return api.get<GetCategoryGridResponse>(
      `/api/${currentSite?.id}/category-grid?${qs.join("&")}`,
    );
  },
});
