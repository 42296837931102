import { LocationProps } from "./components/locations-hierarchy-tree.tsx";
import { LocationDTO } from "../../../../../api-contracts/locations";
import { TFunction } from "i18next";

export const convertDtosToProps = (
  locations: LocationDTO[],
): LocationProps[] => {
  return locations.map((item) => {
    return {
      location: item,
      isRoot: false,
      path: item.partOf ? getPath(item.partOf, locations) : null,
      isOpen: false,
    };
  });
};

export const updateLocationsOnFetch = (
  arr: LocationProps[],
  locations: LocationProps[],
) => {
  const updLocations = locations.filter((it) =>
    arr.map((a) => a.location.id).includes(it.location.id),
  );
  updLocations.map((it) => {
    // Update properties of existing node if found in arr
    const matchingArrItem = arr.find(
      (item) => item.location.id === it.location.id,
    );
    if (matchingArrItem && it !== matchingArrItem) {
      (it.location.name = matchingArrItem.location.name),
        (it.location.partOf = matchingArrItem.location.partOf),
        (it.path = matchingArrItem.path ?? matchingArrItem.location.name);
      return it;
    }

    return it;
  });

  const newLocations = arr.filter(
    (it) =>
      !updLocations.map(({ location }) => location.id).includes(it.location.id),
  );

  // Concatenate existing locations and new locations
  return updLocations.concat(newLocations);
};

export const findLocationById = (
  id: string,
  locations: LocationProps[],
): LocationProps | null => {
  const result = locations.find(({ location }) => location.id === id);
  return result ?? null;
};

export const updateLocations = (
  upd: LocationProps[],
  locations: LocationProps[],
) => {
  return locations.map((item) => {
    const found = upd.find(({ location }) => location.id === item.location.id);
    if (found && item !== found) {
      item.location.partOf = found.location.partOf;
      item.location.id = found.location.id;
      item.location.name = found.location.name;
      item.isOpen = found.isOpen;
      item.path = found.path;
      return item;
    }
    return item;
  });
};

export const updateLocation = (
  upd: LocationProps,
  locations: LocationProps[],
) => {
  return locations.map((item) => {
    if (upd.location.id === item.location.id) {
      item.location.partOf = upd.location.partOf;
      item.location.id = upd.location.id;
      item.location.name = upd.location.name;
      item.isOpen = upd.isOpen;
      item.path = upd.path;
      return item;
    }
    return item;
  });
};

const getPath = (id: string | null, locations: LocationDTO[]) => {
  return id
    ? findParents(
        id,
        locations.map((it) => {
          return { location: it, isRoot: false, isOpen: false, path: null };
        }),
      )
        .map((it) => it.location.name)
        .reverse()
        .join("/")
    : "";
};

export const findParents = (
  value: string,
  locations: LocationProps[],
): LocationProps[] => {
  const found = locations.find(({ location }) => location.id === value);
  if (!found) return []; // Return an empty array if node is not found

  const parentLocations: LocationProps[] = [found];

  // Recursively find partOfs and flatten the result
  const partOf = found.location.partOf;
  const parentsFlat = partOf
    ? findParents(partOf, locations).flatMap((partOf) => partOf)
    : [];
  parentLocations.push(...parentsFlat);

  return parentLocations;
};

export const findAndSortChildren = (
  parentId: string | null,
  locations: LocationProps[],
) => {
  const root = locations.find((it) => it.isRoot)?.location.name;
  return locations
    .filter((it) =>
      parentId !== null
        ? it.location.partOf === parentId
        : it.location.partOf === null,
    )
    .sort((a, b) => sortByTitle(a.location, b.location, root));
};

export const findAllChildrenRecursive = (
  parentId: string | null,
  locations: LocationProps[],
): LocationProps[] => {
  const childrenNodes: LocationProps[] = [];

  // Find all children locations of the given partOfId
  locations.forEach((it) => {
    if (it.location.partOf === parentId) {
      childrenNodes.push(it);
      const grandchildren = findAllChildrenRecursive(it.location.id, locations);
      childrenNodes.push(...grandchildren);
    }
  });

  return childrenNodes;
};

export const openParents = (value: string, locations: LocationProps[]) => {
  const parents = findParents(value, locations);
  return updateLocations(
    [
      ...parents.map((it) => {
        return { ...it, isOpen: true };
      }),
    ],
    locations,
  );
};

export const searchTree = (search: string, locations: LocationProps[]) => {
  const foundNodes = locations.filter((it) =>
    it.location.name.toLowerCase().includes(search.toLowerCase().trim()),
  );
  const parents = foundNodes
    .map((it) => findParents(it.location.id, locations))
    .flatMap((it) => it);
  const parentsFiltered: LocationProps[] = [];
  parents.map((it) => {
    it &&
      !foundNodes.includes(it) &&
      !parentsFiltered.includes(it) &&
      parentsFiltered.push(it);
    updateLocation({ ...it, isOpen: true }, locations);
  });

  return [...foundNodes, ...parentsFiltered];
};

export const createToastMessageOnMove = (
  current: string,
  location: LocationProps,
  locations: LocationProps[],
  t: TFunction<"translation", undefined>,
) => {
  return `${t("moved-to", {
    from: current,
    to:
      location.location.id === "root"
        ? location.location.siteName
        : findParents(location.location.id, locations)
            .reverse()
            .map((it) => `${it.location.name}`)
            .join("/"),
    ampersand: "/",
    interpolation: { escapeValue: false },
  })}`;
};

export const sortByTitle = <
  T extends {
    name: string;
  },
>(
  a: T,
  b: T,
  root: string | undefined | null,
): number => {
  const first = a.name ?? a.name ?? "";
  const second = b.name ?? b.name ?? "";
  if (root && first === root) {
    return -1;
  }
  if (root && second === root) {
    return 1;
  }
  return first.toLowerCase().localeCompare(second.toLowerCase());
};

export const getPathForDisplay = (location: LocationProps) => {
  if (location.isRoot) {
    return location.location.id;
  }

  if (location.location.name === location.path || !location.path) {
    return "";
  }

  return location.path;
};

export const getFilter = <
  T extends {
    id?: string;
    location?: any;
  },
>(
  filtered: T[],
  filteredBySearch: T[],
): T[] => {
  return filtered.filter((it) =>
    filteredBySearch
      .map((item) => item.id ?? item.location?.id)
      .includes(it.id ?? it.location?.id),
  );
};
