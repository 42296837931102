import { useBridgesPageContext } from "../bridges-context.tsx";
import { t } from "i18next";
import { FormField } from "@primitives/form-field.tsx";
import { Input } from "@primitives/input.tsx";
import { useEffect, useState } from "react";
import { CheckBridgeCodeResponse } from "../../../../../../api-contracts/bridges";
import { AlertCircle, CheckCircle2 } from "lucide-react";
import { CODE_LENGTH } from "./add-bridge-sheet.tsx";

export const CodeCheck: React.FC<{
  onCheck: (code: string, codeResponse: CheckBridgeCodeResponse) => void;
}> = ({ onCheck }) => {
  const { sendCheckCodeCommand } = useBridgesPageContext();

  const [checkCode, setCheckCode] = useState<string>("");
  const [checkCodeResponse, setCheckCodeResponse] =
    useState<CheckBridgeCodeResponse>({
      bridgeId: null,
      foundBridge: false,
    });

  const checkCodeIcon = () => {
    if (checkCodeResponse.foundBridge) {
      return <CheckCircle2 className={"p-[2px] text-status-success"} />;
    }
    return <AlertCircle className={"p-[2px] text-status-disabled"} />;
  };

  useEffect(() => {
    // Resetting on new code check attempt
    setCheckCodeResponse({
      bridgeId: null,
      foundBridge: false,
    });
    if (checkCode && checkCode.length === CODE_LENGTH) {
      sendCheckCodeCommand(checkCode.toUpperCase(), (res) => {
        setCheckCodeResponse(res);
        onCheck(checkCode.toUpperCase(), res);
      });
    } else {
      onCheck(checkCode.toUpperCase(), { bridgeId: null, foundBridge: false });
    }
  }, [checkCode]);

  return (
    <div className={"flex flex-col"}>
      <FormField.Label htmlFor="BridgeCode" className="pb-2 pt-4">
        <span>{"PIN"}</span>
        <span className={"pl-1 text-red-600"}>*</span>
      </FormField.Label>
      <div className={"relative h-[50px]"}>
        <div className={"absolute z-[200] m-[8px]  h-full"}>
          {checkCodeIcon()}
        </div>
        <Input
          className=" absolute w-full border pl-10 ps-8 uppercase"
          required
          name="BridgeCode"
          type="text"
          value={checkCode}
          placeholder={t("ABC123")}
          onChange={(e) => setCheckCode(e.target.value)}
        />
      </div>
      {checkCodeResponse.bridgeId && (
        <div className={"text-xs text-status-disabled"}>
          <span className={"pr-1 font-bold"}>ID</span>
          {checkCodeResponse.bridgeId}
        </div>
      )}
    </div>
  );
};
