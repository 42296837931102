import { Button } from "@primitives/button";
import { CardTitle } from "@primitives/card";
import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs";
import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export const CardHeader = ({
  onCreateNew,
  activeTab,
  setActiveTab,
}: {
  onCreateNew: () => void;
  activeTab: string;
  setActiveTab: React.Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <CardTitle>{t("tags")}</CardTitle>
        <Button onClick={onCreateNew} variant={"primary"}>
          {t("create-tag")}
        </Button>
      </div>
      <Tabs
        defaultValue="active"
        value={activeTab}
        onValueChange={setActiveTab}
      >
        <TabsList>
          <TabsTrigger value="active">{t("active")}</TabsTrigger>
          <TabsTrigger value="inactive">{t("inactive")}</TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  );
};
