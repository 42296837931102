import { Switch } from "./switch";

export const SwitchWithLabel = ({
  checked,
  onCheckedChange,
  label = "Label",
  disabled = false,
}: {
  checked: boolean;
  onCheckedChange: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <div className="flex flex-row items-center justify-center">
      <Switch
        disabled={disabled}
        checked={checked}
        onCheckedChange={onCheckedChange}
      />
      <label className="ml-2 text-sm font-bold text-primary-text">
        {label}
      </label>
    </div>
  );
};
