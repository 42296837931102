import { cn } from "@shared/utils/css";
import { cva } from "class-variance-authority";

export const buttonVariants = cva(
  cn(
    "[&:not(:first-child)]:ml-3]",
    "inline-flex items-center justify-center whitespace-nowrap",
    "rounded-md text-sm font-extrabold ring-offset-background transition-colors",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  ),
  {
    /** Missing styling for hover state in Figma
     * https://www.figma.com/file/IZx62l3MCsVFVyH7pKaz4j/Design-System-2.0?type=design&node-id=874-18765&mode=design&t=jLvZ27gj65fc696l-0
     */
    variants: {
      variant: {
        default:
          "bg-primary-color text-white hover:bg-blush-800 active:bg-blush-700",
        primary: cn(
          "bg-primary-color hover:bg-blush-600 active:bg-blush-500 text-teritary-text rounded-md",
        ),
        secondary: cn(
          "bg-primary-button-backplate hover:bg-gray-100 active:bg-gray-300 text-primary-text rounded-md",
          "dark:hover:bg-gray-700 dark:active:bg-gray-600",
        ),
        destructive: " bg-status-error-100  text-status-error",
        ghost: cn(
          "bg-transparent hover:bg-gray-100 active:bg-gray-300",
          "dark:bg-gray-800 dark:hover:bg-gray-700 dark:active:bg-gray-600",
        ),
        outline: cn(
          "border border-highlighted-backplate text-primary-text rounded-md bg-primary-button-backplate hover:border-gray-400 hover:text-gray-900 active:border-gray-700",
          "dark:hover:border-gray-400 dark:hover:text-gray-100 dark:active:border-gray-300 dark:active:text-gray-100",
        ),
        link: cn(
          "text-sky-700 underline-offset-4 hover:underline",
          "dark:text-sky-400",
        ),
        purple: cn(
          "text-white bg-purple hover:bg-purple-400 active:bg-purple-300",
        ),
        purpleLight: cn(
          "text-gray-800 bg-purple-100 hover:bg-purple-200 active:bg-purple-300",
        ),
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded px-3",
        lg: "h-11 rounded px-8",
        icon: "h-10 w-10",
      },
      pill: { true: "rounded-full" },
      loading: { true: "" },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      pill: false,
    },
  },
);
