import { AlertDialog } from "@primitives/alert-dialog";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router-dom";

interface ConfirmNavigationDialogProps {
  shouldWarn: boolean;
  title?: string;
  description?: string;
  onProceed: () => void | Promise<void>;
}

export const ConfirmNavigationDialog: FC<ConfirmNavigationDialogProps> = ({
  shouldWarn = false,
  title = "Changes Not Saved",
  description = "You have unsaved changes that will be lost if you leave this page. Are you sure you want to exit?",
  onProceed,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (shouldWarn && currentLocation.pathname !== nextLocation.pathname) {
      setOpen(true);
      return true;
    }
    return false;
  });

  const handleProceed = () => {
    onProceed();
    blocker.state === "blocked" && blocker.proceed();
  };
  const handleDiscardChanges = () => {
    blocker.state === "blocked" && blocker.proceed();
  };
  const handleCancel = () => {
    blocker.state === "blocked" && blocker.reset();
  };

  return (
    <AlertDialog.Root open={open} onOpenChange={setOpen}>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>{title}</AlertDialog.Title>
          <AlertDialog.Description>{description}</AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel onClick={handleDiscardChanges}>
            {t("leave")}
          </AlertDialog.Cancel>
          <AlertDialog.Cancel onClick={handleCancel}>
            {t("cancel")}
          </AlertDialog.Cancel>
          <AlertDialog.Action onClick={handleProceed}>
            {t("save")}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
