import { t } from "i18next";
import { Button } from "@primitives/button.tsx";
import { clsx } from "clsx";
import { Badge } from "@primitives/badge.tsx";
import { ChevronRight, CreditCard, Plus, Printer } from "lucide-react";
import { Card, CardTitle } from "@primitives/card.tsx";
import {
  BridgeDeviceType,
  BridgeSheetState,
  useBridgesPageContext,
} from "../bridges-context.tsx";
import { Bridge } from "../../../../../../api-contracts/bridges";
import { AddBridgeSheet } from "./add-bridge-sheet.tsx";
import { EditBridgeSheet } from "./edit-bridge-sheet.tsx";
import { ManageDeviceSheet } from "./manage-device-sheet.tsx";
import { useEffect } from "react";

const getAmountDevicesOfType = (
  bridge: Bridge,
  type: BridgeDeviceType,
): number => {
  return bridge.devices.filter((device) => device.type === type).length;
};

export const BridgesList = () => {
  const { setSheetState, bridges, selectedBridge, setSelectedBridge } =
    useBridgesPageContext();

  useEffect(() => {
    if (
      bridges !== undefined &&
      selectedBridge !== null &&
      selectedBridge?.id !== undefined
    ) {
      const updatedBridge = bridges.find(
        (bridge) => bridge.id === selectedBridge.id,
      );
      if (updatedBridge !== undefined) {
        setSelectedBridge(updatedBridge);
      }
    }
  }, [bridges]);

  return (
    <>
      <div className="relative flex min-h-full flex-nowrap p-4">
        <Card className="w-[500px]">
          <CardTitle>{t("bridges")}</CardTitle>
          <div className="py-4">
            <Button
              variant="primary"
              size="icon"
              onClick={() => setSheetState(BridgeSheetState.ADD_BRIDGE)}
            >
              <Plus size={17} />
            </Button>
          </div>

          <div className={"mx-[-1rem]"}>
            {bridges?.map((bridge) => (
              <div
                key={bridge.id}
                className={clsx(
                  "flex h-[80px] cursor-pointer items-center justify-between border-b-[0.5px] border-[#E5E7EB] p-[18px] text-lg font-medium hover:bg-secondary-card-backplate",
                  {
                    "bg-secondary-card-backplate":
                      selectedBridge?.id === bridge.id,
                  },
                )}
                onClick={() => setSelectedBridge(bridge)}
              >
                <span>{bridge.name}</span>
                <div className={"flex items-center"}>
                  <div className={"flex items-center gap-1 text-white"}>
                    <Badge variant={"rounded"} size={"sm_icon"}>
                      <CreditCard className={"h-[12px] pr-1 "} />
                      <span className={"h-[0.8rem] text-xs"}>
                        {getAmountDevicesOfType(
                          bridge,
                          BridgeDeviceType.CONTROL_UNIT,
                        )}
                      </span>
                    </Badge>
                    <Badge variant={"rounded"} size={"sm_icon"}>
                      <Printer className={"h-[12px]"} />
                      <span className={"h-[0.8rem] text-xs"}>
                        {getAmountDevicesOfType(
                          bridge,
                          BridgeDeviceType.PRINTER,
                        )}
                      </span>
                    </Badge>
                  </div>
                  {bridge.isLive && (
                    <Badge
                      className="ml-3 mr-2"
                      variant={"rounded_success"}
                      size={"sm_wide"}
                    >
                      <span className={"h-[0.8rem]"}>Live</span>
                    </Badge>
                  )}
                  <ChevronRight className={"h-[12px] text-accent-4-500"} />
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>

      <AddBridgeSheet />
      <EditBridgeSheet />
      <ManageDeviceSheet />
    </>
  );
};
