import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CreateTravelAgencyRequest,
  CreateTravelAgencyResponse,
  GetTravelAgencyResponse,
  PatchTravelAgencyRequest,
  PatchTravelAgencyResponse,
  SearchTravelAgenciesQueryParams,
  SearchTravelAgenciesResponse,
} from "../../../../api-contracts/travel-agencies";

export const TRAVEL_AGENCIES_QUERY_KEY = "travel-agencies";

export const useGetTravelAgencies = createQuery<
  SearchTravelAgenciesResponse,
  SearchTravelAgenciesQueryParams
>({
  primaryKey: TRAVEL_AGENCIES_QUERY_KEY,

  queryFn: async ({ queryKey: [, params] }) => {
    return api.get(
      `/api/${currentSite?.id}/${TRAVEL_AGENCIES_QUERY_KEY}`,
      Object.fromEntries(
        Object.entries(params).map(([key, value]) => [
          key,
          encodeURIComponent(value),
        ]),
      ),
    );
  },
});

export const useGetTravelAgency = createQuery<
  GetTravelAgencyResponse,
  { id: string }
>({
  primaryKey: TRAVEL_AGENCIES_QUERY_KEY,
  queryFn: async ({ queryKey: [, { id }] }) => {
    return api.get(
      `/api/${currentSite?.id}/${TRAVEL_AGENCIES_QUERY_KEY}/${id}`,
    );
  },
});

export const useCreateTravelAgency = createMutation<
  CreateTravelAgencyResponse,
  CreateTravelAgencyRequest
>({
  mutationFn: async (data: CreateTravelAgencyRequest) =>
    api.post(`/api/${currentSite?.id}/${TRAVEL_AGENCIES_QUERY_KEY}`, {
      ...data,
    }),
});

export const useUpdateTravelAgency = createMutation<
  PatchTravelAgencyResponse,
  PatchTravelAgencyRequest & { id: string }
>({
  mutationFn: async ({
    id,
    ...patch
  }: PatchTravelAgencyRequest & { id: string }) =>
    api.patch(`/api/${currentSite?.id}/${TRAVEL_AGENCIES_QUERY_KEY}/${id}`, {
      ...patch,
    }),
});
