import { AuthUser } from "@shared/context/auth-context";
import { computed, effect, signal } from "@preact/signals-react";
import { RefreshResponse } from "../../../../api-contracts/auth";

const STORAGE_KEY = "user";

export const user = signal<AuthUser | undefined>(getUserFromStorage());
export const isAuthenticated = computed(() => !!user.value);

function getUserFromStorage(): AuthUser {
  const json = localStorage.getItem(STORAGE_KEY);
  return json ? JSON.parse(json) : undefined;
}

export function updateToken({ accessToken, refreshToken }: RefreshResponse) {
  if (!user.value) return;

  setAuth({
    ...user.value,
    accessToken,
    refreshToken,
  });
}

export function setAuth(authUser: AuthUser) {
  user.value = authUser;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(authUser));
}

export function clearAuth() {
  user.value = undefined;
  localStorage.removeItem(STORAGE_KEY);
}

export default {
  user,
  isAuthenticated,
  setAuth,
  clearAuth,
};
