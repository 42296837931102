import {
  LocationsAction,
  useLocationsPageContext,
} from "../locations-context.tsx";
import { LocationProps } from "../../../settings/locations/components/locations-hierarchy-tree.tsx";
import { AssetOverview } from "../../../../../../api-contracts/assets";
import { DefaultDropdownMenu } from "@primitives/default-dropdown-menu.tsx";
import { useSharedContext } from "@context/shared-context.tsx";
import { useRemoveLocationFromAsset } from "@api/assets.ts";
import { useTranslation } from "react-i18next";

export const enum LocationsInputType {
  LOCATION = "LOCATION",
  ASSET = "ASSET",
}
interface Props<T> {
  type: LocationsInputType;
  item: T;
}
export const LocationsDropdownMenu = <T,>({ type, item }: Props<T>) => {
  const {
    sharedState: { locations },
    sharedFunctions: {
      setActionType,
      setCurrentAsset,
      setCurrentLocation,
      deleteLocation,
      refetchAssetList,
    },
  } = useLocationsPageContext();
  const {
    sharedFunctions: { setLoading, toast },
  } = useSharedContext();
  const { mutate: removeLocation } = useRemoveLocationFromAsset();
  const { t } = useTranslation();

  const removeLocationFromAsset = (id: string, name: string) => {
    setLoading(true);

    removeLocation(
      {
        id: id,
      },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("remove-bookable-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          toast({
            variant: "success",
            title: t("bookable-removed", { bookable: name }),
          });
          setCurrentAsset(null);
          await refetchAssetList();
        },
        onSettled: () => {
          setLoading(false);
          setActionType(LocationsAction.DEFAULT);
        },
      },
    );
  };

  const onMove = () => {
    if (type === LocationsInputType.LOCATION) {
      setActionType(LocationsAction.LOCATION_MOVE);
      setCurrentLocation(item as LocationProps);
    }
    if (type === LocationsInputType.ASSET) {
      setActionType(LocationsAction.ASSET_MOVE);
      setCurrentAsset(item as AssetOverview);
    }
  };

  const onEditLocation = () => {
    setCurrentLocation(item as LocationProps);
    setActionType(LocationsAction.LOCATION_EDIT);
  };

  const onRemoveBookable = () => {
    const asset = item as AssetOverview;
    !asset.location?.id && setCurrentLocation(null);
    asset.location.id &&
      setCurrentLocation(
        locations.find((it) => asset.location.id === it.location.id) ?? null,
      );
    removeLocationFromAsset(asset.id, asset.name);
  };

  const onDeleteLocation = () => {
    const location = item as LocationProps;
    setCurrentLocation(
      locations.find(
        (it) =>
          it.location.id === location.location.partOf &&
          location.location.partOf != null,
      ) ?? null,
    );
    deleteLocation(location.location.id, location.location.name);
  };

  const alertProps = {
    alertTitle:
      type === LocationsInputType.LOCATION
        ? t("are-you-sure-you-want-to-delete-location", {
            location: (item as LocationProps).location.name,
          })
        : t("are-you-sure-you-want-to-remove-bookable", {
            bookable: (item as AssetOverview).name,
          }),
    alertDescription:
      type === LocationsInputType.LOCATION
        ? t("all-underlying-locations-will-be-deleted")
        : t("bookable-will-be-removed-from-allocation"),
    alertSubmitButtonText:
      type === LocationsInputType.LOCATION
        ? t("yes-delete-location", {
            location: (item as LocationProps).location.name,
          })
        : t("yes-remove-bookable", { bookable: (item as AssetOverview).name }),
  };

  return (
    <DefaultDropdownMenu
      onMove={onMove}
      onEdit={type === LocationsInputType.LOCATION ? onEditLocation : undefined}
      onDelete={
        type === LocationsInputType.LOCATION
          ? () => onDeleteLocation()
          : () => onRemoveBookable()
      }
      alertTitle={alertProps.alertTitle}
      alertDescription={alertProps.alertDescription}
      alertSubmitButtonText={alertProps.alertSubmitButtonText}
      deleteDisplayText={
        type === LocationsInputType.ASSET
          ? t("remove-from-location")
          : t("delete")
      }
    />
  );
};
