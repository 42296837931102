import { cn } from "@shared/utils/css";
import { cva } from "class-variance-authority";

export const badgeVariants = cva(
  cn(
    "inline-flex items-center rounded",
    "font-medium leading-none cursor-default",
  ),
  {
    variants: {
      variant: {
        default: cn(
          "bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-100",
        ),
        destructive: "bg-red-500 text-red-50 dark:bg-red-700 dark:text-white",
        positive: cn(
          "bg-green-500 text-green-50 dark:bg-green-700 dark:text-white",
        ),
        outline: cn(
          "bg-transparent border border-gray-300 text-gray-800 dark:border-gray-600 dark:text-gray-300",
        ),
        rounded: cn("bg-accent-4-500 rounded-3xl h-[22px] text-white"),
        rounded_success: cn(
          "bg-status-success rounded-3xl h-[22px] text-white",
        ),
      },
      size: {
        xs: cn("text-xs px-1 py-0.5"),
        sm: cn("text-xs px-1.5 py-1"),
        sm_wide: cn("text-xs px-2 py-1"),
        sm_icon: cn("text-xs pr-2 pl-1 py-1"),
        md: cn("text-sm px-2 py-1"),
        lg: cn("text-base px-2.5 py-1"),
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
    },
  },
);
