export const HierarchyTreeImage = () => (
  <svg
    className="inline-block"
    width="150"
    height="154"
    viewBox="0 0 150 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.0888672" width="149.823" height="154" rx="12" fill="#FEF2F2" />
    <rect
      x="57.4647"
      y="32.3309"
      width="39.0441"
      height="17.8676"
      rx="2.31618"
      fill="#7B233C"
      stroke="#540621"
      strokeWidth="0.661765"
    />
    <rect
      x="29.0076"
      y="75.3455"
      width="32.4265"
      height="13.8971"
      rx="2.31618"
      fill="#18181B"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <rect
      x="92.5369"
      y="75.3455"
      width="32.4265"
      height="13.8971"
      rx="2.31618"
      fill="#18181B"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <rect
      x="18.4197"
      y="111.743"
      width="15.2206"
      height="9.92647"
      rx="2.31618"
      fill="#18181B"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <rect
      x="37.6112"
      y="111.743"
      width="15.2206"
      height="9.92647"
      rx="2.31618"
      fill="#18181B"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <rect
      x="56.8026"
      y="111.743"
      width="15.2206"
      height="9.92647"
      rx="2.31618"
      fill="#18181B"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <rect
      x="86.5819"
      y="111.743"
      width="20.5147"
      height="9.92647"
      rx="2.31618"
      fill="#18181B"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <rect
      x="111.066"
      y="111.743"
      width="20.5147"
      height="9.92647"
      rx="2.31618"
      fill="#18181B"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="76.6541"
      y1="50.5293"
      x2="76.6541"
      y2="60.4558"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="108.751"
      y1="60.7869"
      x2="76.3245"
      y2="60.7869"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="109.082"
      y1="60.4561"
      x2="109.082"
      y2="75.0149"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="45.5526"
      y1="60.4561"
      x2="45.5526"
      y2="75.0149"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="109.082"
      y1="88.9121"
      x2="109.082"
      y2="100.162"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="121.323"
      y1="100.493"
      x2="108.75"
      y2="100.493"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="109.413"
      y1="100.493"
      x2="96.8396"
      y2="100.493"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="96.508"
      y1="111.412"
      x2="96.508"
      y2="100.162"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="120.992"
      y1="111.412"
      x2="120.992"
      y2="100.162"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="64.0822"
      y1="111.412"
      x2="64.0822"
      y2="100.162"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="25.6994"
      y1="111.412"
      x2="25.6994"
      y2="100.162"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="45.2217"
      y1="100.493"
      x2="26.0305"
      y2="100.493"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="64.4131"
      y1="100.493"
      x2="45.2219"
      y2="100.493"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="45.5526"
      y1="88.9121"
      x2="45.5526"
      y2="111.412"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
    <line
      x1="45.2217"
      y1="60.7863"
      x2="76.9864"
      y2="60.7863"
      stroke="#18181B"
      strokeWidth="0.661765"
    />
  </svg>
);
