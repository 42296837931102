import { FC, useEffect, useState } from "react";
import { ChevronRight } from "lucide-react";
import { LocationProps } from "./locations-hierarchy-tree.tsx";

export const Breadcrumbs: FC<{
  nodes: LocationProps[];
  current: LocationProps;
  onClick: (value: string) => void;
}> = ({ nodes, onClick, current }) => {
  const [crumbs, setCrumbs] = useState<LocationProps[]>([]);

  useEffect(() => {
    setCrumbs([current]);
    current?.location.partOf && findAndSetParents(current.location.partOf);
    setCrumbs((prev) => prev.sort().reverse());
  }, [current, setCrumbs]);

  const findAndSetParents = (id: string) => {
    const parent = nodes.find((node) => node.location.id === id);
    parent && setCrumbs((prev) => [...prev, parent]);
    parent?.location.partOf && findAndSetParents(parent.location.partOf);
  };

  return (
    <>
      {crumbs.map((crumb, i) => (
        <div className="flex h-full items-center p-0" key={i}>
          <a
            role="button"
            className={`flex h-full items-center overflow-x-hidden text-ellipsis rounded-md bg-primary-card-backplate px-2 text-sm font-bold`}
            onClick={() => {
              onClick(crumb.location.id);
            }}
          >
            {crumb.location.name}
          </a>
          {i != crumbs.length - 1 && (
            <ChevronRight className="h-[14px] w-[14px] bg-primary-card-backplate" />
          )}
        </div>
      ))}
    </>
  );
};
