import { PriceRulesSheet } from "@pages/booking/price-rules/components/price-rules-sheet.tsx";
import {
  PriceRuleActionType,
  usePriceRulesPageContext,
} from "@pages/booking/price-rules/price-rules-context.tsx";
import { useTranslation } from "react-i18next";
import { useUpdatePriceRule } from "@api/price-rules.ts";

export const EditPriceRules = () => {
  const {
    sharedState: { actionType, bar, priceRuleId },
    sharedFunctions: { setActionType, setPriceRules },
  } = usePriceRulesPageContext();
  const { t } = useTranslation();

  const { mutate } = useUpdatePriceRule();

  const onSubmit = () => {
    const req = priceRuleId && { ...bar, ruleId: priceRuleId };
    req &&
      mutate(req, {
        onSuccess(rule) {
          setActionType(PriceRuleActionType.DEFAULT);
          setPriceRules((prev) => {
            return { ...prev, rules: [...prev.rules, rule] };
          });
        },
      });
  };

  return (
    <PriceRulesSheet
      open={actionType === PriceRuleActionType.EDIT}
      title={t("price-rules-edit-bar")}
      onSubmit={onSubmit}
      namePlaceholder={bar.name ?? ""}
      display={PriceRuleActionType.EDIT}
    />
  );
};
