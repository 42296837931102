import { useGetTitles } from "@api/titles";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useTranslation } from "react-i18next";

interface Props {
  value?: { id: string; name: string };
  onValueChange?: (value?: { id: string; name: string }) => void;
  id?: string;
  disabled?: boolean;
}

const TitleSelect = ({ value, onValueChange, id, disabled }: Props) => {
  const [t] = useTranslation();

  const {
    data: titlesData,
    isLoading: titlesIsLoading,
    isRefetching: titlesIsRefetching,
  } = useGetTitles({});

  return (
    <Select
      disabled={disabled}
      value={String(value?.id)}
      onValueChange={(value) =>
        onValueChange && onValueChange(titlesData?.find((c) => c.id === value))
      }
    >
      <SelectTrigger id={id} className="text-xs">
        <SelectValue>{value?.name}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {titlesData?.map((c, i) => (
          <SelectItem key={i} value={c.id} className=" text-xs">
            {t(c.name)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default TitleSelect;
