import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";

import { cn } from "@shared/utils/css";
import { buttonVariants } from "./button-variants";
import { sv } from "date-fns/locale";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showWeekNumber = true,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      locale={sv}
      showWeekNumber={showWeekNumber}
      showOutsideDays={showOutsideDays}
      className={cn("text-sm", className)}
      classNames={{
        months: "flex flex-col sm:flex-row",
        month: "",
        caption: "h-9 flex justify-center items-center relative",
        caption_label: "font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(buttonVariants({ variant: "ghost" }), "h-9 w-9 p-0"),
        nav_button_previous: "absolute left-0",
        nav_button_next: "absolute right-0",
        table: "w-full border-collapse",
        head_row: "flex items-center mt-1",
        head_cell: "text-gray-500 w-9 font-normal text-xs",
        row: "flex w-full mt-2",
        cell: "w-9 h-9 flex items-center justify-center rounded hover:bg-gray-100  focus:bg-gray-100",
        weeknumber: "h-9 flex items-center justify-center text-gray-500",
        day: "w-full h-full flex items-center justify-center rounded hover:bg-gray-200",
        day_range_end: "",
        day_selected: cn(
          " bg-primary-color text-white",
          "hover:bg-primary-color",
        ),
        day_today: "font-bold",
        day_outside:
          "day-outside text-gray-400 opacity-50 aria-selected:bg-primary-color/50 aria-selected:text-gray-400 aria-selected:opacity-30",
        day_disabled: "text-gray-400 opacity-50",
        day_range_middle: "aria-selected:bg-highlighted-backplate",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-4 w-4" />,
        IconRight: () => <ChevronRight className="h-4 w-4" />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
