import {
  TargetGroupsActionType,
  useTargetGroupsPageContext,
} from "../target-groups-context.tsx";
import { DefaultInputField } from "@primitives/default-input-field.tsx";
import { FormEvent, useState } from "react";
import { DefaultBadge } from "@primitives/default-badge.tsx";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@primitives/select.tsx";
import { Check, Link } from "lucide-react";
import { Button } from "@primitives/button.tsx";
import { TargetGroup } from "../../../../../../api-contracts/target-groups";
import { useTranslation } from "react-i18next";
import { cn } from "@utils/css.ts";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@primitives/simpleTooltip.tsx";

type FormProps = {
  onSubmit: (
    name: string,
    channelIds: string[],
    e: FormEvent<HTMLFormElement>,
  ) => void;
  buttonText: string;
  disabled: (name?: string, channelIds?: string[]) => boolean;
  nameRequired: boolean;
  item?: TargetGroup | null;
};
export const TargetGroupsForm = ({
  onSubmit,
  buttonText,
  disabled,
  nameRequired,
  item,
}: FormProps) => {
  const {
    sharedState: { getChannels, action },
  } = useTargetGroupsPageContext();
  const { t } = useTranslation();

  const channels = getChannels ?? [];
  const [selectedChannels, setSelectedChannels] = useState<
    { name: string; id: string }[]
  >(
    item
      ? item?.channels.map((it) => {
          return { name: it.name, id: it.id };
        })
      : [],
  );
  const [name, setName] = useState<string>("");

  const updateSelected = (id: string) => {
    const itemChannel = channels.find((channel) => channel.id === id);
    itemChannel &&
      setSelectedChannels((prev) =>
        prev.map(({ id }) => id).includes(itemChannel.id)
          ? prev
          : [...prev, itemChannel],
      );
  };

  return (
    <form
      onSubmit={(e) =>
        onSubmit(
          name,
          selectedChannels.map(({ id }) => id),
          e,
        )
      }
      className="flex h-full flex-col justify-between"
    >
      <div className="flex flex-col space-y-2">
        {(item || action === TargetGroupsActionType.ADD) && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <DefaultInputField
                    disabled={
                      action !== TargetGroupsActionType.ADD && !item?.mutable
                    }
                    onChange={(value) => setName(value)}
                    inputName={"target-group-name"}
                    placeholder={`${t("name")}`}
                    required={nameRequired}
                    label={t("name")}
                    defaultValue={t(item?.name ?? "")}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent
                className={cn(
                  "p-4",
                  (action === TargetGroupsActionType.ADD || item?.mutable) &&
                    "hidden",
                )}
              >
                {t("target-group-can-not-be-edited")}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        <div className="my-0">
          <label className="px-1 text-xs" htmlFor={"select-channel"}>
            {t("channels")}
          </label>
          <Select
            name="select-channel"
            onValueChange={(item) => updateSelected(item)}
            value={""}
          >
            <SelectTrigger>
              <SelectValue placeholder={t("select-channel")} />
            </SelectTrigger>
            <SelectContent className="z-[1000]">
              <SelectGroup className="max-h-[calc(100vh-300px)] overflow-y-auto">
                <SelectLabel>{t("channels")}</SelectLabel>
                {channels &&
                  channels.map((channel) => (
                    <SelectItem value={channel.id} key={channel.id}>
                      <div className="flex items-center justify-start">
                        {selectedChannels
                          .map(({ id }) => id)
                          .includes(channel.id) && (
                          <Check size="12" className="fixed left-2" />
                        )}
                        {channel.name}
                      </div>
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <div className="mt-2 flex flex-wrap items-center gap-1">
            {selectedChannels.map((channel) => (
              <DefaultBadge
                key={channel.id}
                id={channel.id}
                text={channel.name}
                onRemove={(value) =>
                  setSelectedChannels((prev) =>
                    prev.filter(({ id }) => id != value),
                  )
                }
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          variant="primary"
          disabled={disabled(
            name,
            selectedChannels.map(({ id }) => id),
          )}
        >
          {buttonText}
        </Button>
      </div>
    </form>
  );
};
