import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { Button } from "@primitives/button";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@primitives/command";
import { cn } from "@utils/css";

export type SearchItem = { value: string; id: string };

export const SearchSelect: React.FC<{
  payload: SearchItem[];
  placeholder: string;
  trigger: string;
  empty: string;
  value: string;
  onSelect: (item: SearchItem | null) => void;
}> = ({ payload, placeholder, trigger, value, empty, onSelect }) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {value
            ? payload.find(
                (data) => data.value.toLowerCase() === value.toLowerCase(),
              )?.value
            : trigger}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-50 max-h-[20px] w-full p-0">
        <Command>
          <CommandInput placeholder={placeholder} className="h-9" />
          <CommandEmpty>{empty}</CommandEmpty>
          <CommandGroup className="max-h-[400px] overflow-auto border">
            {payload.map((data) => (
              <CommandItem
                key={data.value}
                value={data.value}
                onSelect={(currentValue) => {
                  onSelect(currentValue === value ? null : data);
                  setOpen(false);
                }}
              >
                {data.value}
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4",
                    value === data.value ? "opacity-100" : "opacity-0",
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
