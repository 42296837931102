import { AlertDialog } from "@primitives/alert-dialog";
import { SetStateAction, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";
import { getTextColor } from "../tags-utils";

export const ColorPicker = ({
  color,
  setColor,
}: {
  color: string;
  setColor: React.Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className="flex">
      <HexColorPicker
        color={color}
        onChange={setColor}
        style={{ flexGrow: 1 }}
      />
    </div>
  );
};

export const ColorDialog = ({
  isOpen,
  onOpenChange,
  color,
  setColor,
}: {
  isOpen: boolean;
  onOpenChange: React.Dispatch<SetStateAction<boolean>>;
  color: string;
  setColor: (color: string) => void;
}) => {
  const [tempColor, setTempColor] = useState<string>(color);
  const { t } = useTranslation();

  const handleSave = () => {
    setColor(tempColor);
  };

  return (
    <AlertDialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialog.Content className="z-[2001]">
        <AlertDialog.Header>
          <AlertDialog.Title>{t("color-picker")}</AlertDialog.Title>
          <AlertDialog.Description>
            {t("pick-a-tag-color")}
          </AlertDialog.Description>
        </AlertDialog.Header>

        <ColorPicker color={tempColor} setColor={setTempColor} />
        <AlertDialog.Footer className="relative">
          <div
            style={{
              backgroundColor: tempColor,
              color: getTextColor(tempColor),
            }}
            className=" absolute left-0 flex h-10 items-center rounded-md border
          border-highlighted-backplate bg-primary-card-backplate pe-10 ps-10 text-sm font-bold"
          >
            {tempColor.toUpperCase()}
          </div>
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

          <AlertDialog.Action onClick={handleSave}>
            {t("done")}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
