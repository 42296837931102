import { createMutation, createQuery } from "react-query-kit";
import {
  CreateBedAccessoryRequest,
  CreateBedAccessoryResponse,
  CreateBedlinenRequest,
  CreateBedlinenResponse,
  CreateBedTypeRequest,
  CreateBedTypeResponse,
  DeleteBedAccessoryResponse,
  DeleteBedlinenResponse,
  DeleteBedTypeResponse,
  GetBedAccessoryResponse,
  GetBedAgeSpanSettingsResponse,
  GetBedlinenListResponse,
  GetBedTypeResponse,
  PatchBedAccessoryRequest,
  PatchBedAccessoryResponse,
  PatchBedlinenRequest,
  PatchBedlinenResponse,
  PatchBedTypeRequest,
  PatchBedTypeResponse,
  UpsertBedAgeSpanSettingsRequest,
  UpsertBedAgeSpanSettingsResponse,
} from "../../../../api-contracts/bed-capacity";
import { api } from "@api/api.ts";
import { currentSite } from "@context/site-context.tsx";

export const BED_TYPES_QUERY_KEY = "bed-type";
export const BED_CAPACITY = "bed-capacity";
export const BED_ACCESSORY_QUERY_KEY = "bed-accessory";
export const SETTINGS_QUERY_KEY = "settings";
export const BED_LINEN_QUERY_KEY = "bedlinen";

export const useGetBedTypes = createQuery<GetBedTypeResponse[]>({
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${BED_CAPACITY}/${BED_TYPES_QUERY_KEY}`),
  primaryKey: BED_TYPES_QUERY_KEY,
});

export const useGetBedAccessory = createQuery<GetBedAccessoryResponse[]>({
  queryFn: async () =>
    api.get(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_ACCESSORY_QUERY_KEY}`,
    ),
  primaryKey: BED_ACCESSORY_QUERY_KEY,
});

export const useGetBedAgeSpanSettings =
  createQuery<GetBedAgeSpanSettingsResponse>({
    queryFn: async () =>
      api.get(
        `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_TYPES_QUERY_KEY}/${SETTINGS_QUERY_KEY}`,
      ),
    primaryKey: SETTINGS_QUERY_KEY,
  });

export const useGetBedLinen = createQuery<GetBedlinenListResponse>({
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${BED_CAPACITY}/${BED_LINEN_QUERY_KEY}`),
  primaryKey: BED_LINEN_QUERY_KEY,
});

export const usePostAgeRangeRuleSetting = createMutation<
  UpsertBedAgeSpanSettingsResponse,
  UpsertBedAgeSpanSettingsRequest
>({
  mutationFn: async (setting) =>
    api.post(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_TYPES_QUERY_KEY}/${SETTINGS_QUERY_KEY}`,
      setting,
    ),
});

export const usePostLinen = createMutation<
  CreateBedlinenResponse,
  CreateBedlinenRequest
>({
  mutationFn: async (linen) =>
    api.post(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_LINEN_QUERY_KEY}`,
      linen,
    ),
});

export const usePostAccessory = createMutation<
  CreateBedAccessoryResponse,
  CreateBedAccessoryRequest
>({
  mutationFn: async (acc) =>
    api.post(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_ACCESSORY_QUERY_KEY}`,
      acc,
    ),
});

export const usePostBed = createMutation<
  CreateBedTypeResponse,
  CreateBedTypeRequest
>({
  mutationFn: async (bedType) =>
    api.post(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_TYPES_QUERY_KEY}`,
      bedType,
    ),
});

export const usePatchLinenById = createMutation<
  PatchBedlinenResponse,
  { patch: PatchBedlinenRequest; id: string }
>({
  mutationFn: async (linen) =>
    api.patch(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_LINEN_QUERY_KEY}/${linen.id}`,
      linen.patch,
    ),
});

export const usePatchAccessorydById = createMutation<
  PatchBedAccessoryResponse,
  { patch: PatchBedAccessoryRequest; id: string }
>({
  mutationFn: async (acc) =>
    api.patch(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_ACCESSORY_QUERY_KEY}/${acc.id}`,
      acc.patch,
    ),
});

export const usePatchBedById = createMutation<
  PatchBedTypeResponse,
  { patch: PatchBedTypeRequest; id: string }
>({
  mutationFn: async (bed) =>
    api.patch(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_TYPES_QUERY_KEY}/${bed.id}`,
      bed.patch,
    ),
});

export const useDeleteAccessory = createMutation<
  DeleteBedAccessoryResponse,
  { id: string }
>({
  mutationFn: async ({ id }) =>
    api.delete(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_ACCESSORY_QUERY_KEY}/${id}`,
    ),
});

export const useDeletetBed = createMutation<
  DeleteBedTypeResponse,
  { id: string }
>({
  mutationFn: async ({ id }) =>
    api.delete(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_TYPES_QUERY_KEY}/${id}`,
    ),
});

export const useDeleteLinen = createMutation<
  DeleteBedlinenResponse,
  { id: string }
>({
  mutationFn: async ({ id }) =>
    api.delete(
      `/api/${currentSite?.id}/${BED_CAPACITY}/${BED_LINEN_QUERY_KEY}/${id}`,
    ),
});
