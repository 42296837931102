import { Button } from "@primitives/button";
import { CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";
import { MessageOfDay } from "./message-of-day";
import { useReceptionContext } from "../reception-context";
import { DatePicker } from "./date-picker";

export const CardHeader = () => {
  const { t } = useTranslation();
  const { arrivals, setDate, date } = useReceptionContext();
  const arrivalsCount = arrivals.length;
  return (
    <div className="flex items-center justify-between border-b border-highlighted-backplate p-4">
      <div className="flex flex-col">
        <CardTitle>{t("arrivals")}</CardTitle>
        <p className="text-xs font-normal text-secondary-text">{`${arrivalsCount} bokningsbara objekt`}</p>
      </div>
      <div className="flex flex-grow items-center justify-center text-center">
        <div>
          <DatePicker date={date} setDate={setDate} />
        </div>
        <div className="mx-2">
          <MessageOfDay />
        </div>
        <div>
          <Button variant={"secondary"}>{t("assign-all")}</Button>
        </div>
      </div>
      <div>
        <Button variant={"secondary"}>{t("walk-in")}</Button>
      </div>
    </div>
  );
};
