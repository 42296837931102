import { Button } from "@primitives/button";
import { SearchBar } from "@primitives/search-bar";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { Filter, Printer } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useReceptionContext } from "../reception-context";

const ICON_SIZE = 17;
const ICON_STROKE_WIDTH = 1;

export const TableHeader = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { searchTerm, setSearchTerm } = useReceptionContext();

  return (
    <div className="flex items-center justify-between">
      <div className="flex">
        <SearchBar
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="min-w-[250px]"
        />
        <div
          className={
            "mx-2 flex items-center rounded-md bg-primary-button-backplate text-primary-text "
          }
        >
          <div className="mx-2">
            <SwitchWithLabel
              checked={isChecked}
              onCheckedChange={setIsChecked}
              label={t("locations")}
            />
          </div>
        </div>
        <Button variant={"secondary"} size="icon" disabled={!isChecked}>
          <Filter strokeWidth={ICON_STROKE_WIDTH} size={ICON_SIZE} />
        </Button>
      </div>
      <div className="flex items-center">
        <Button className="mx-2 flex" variant={"secondary"}>
          <Filter
            strokeWidth={ICON_STROKE_WIDTH}
            size={ICON_SIZE}
            className="mr-1"
          />
          {t("show")}
        </Button>
        <Button variant={"secondary"} size="icon">
          <Printer strokeWidth={ICON_STROKE_WIDTH} size={ICON_SIZE} />
        </Button>
      </div>
    </div>
  );
};
