import { currentSite } from "@shared/context/site-context.tsx";
import { api } from "./api.ts";
import { createMutation, createQuery } from "react-query-kit";
import {
  CreateFixtureGroupRequest,
  CreateFixtureGroupResponse,
  CreateFixtureRequest,
  CreateFixtureResponse,
  GetFixturesResponse,
  PatchFixtureGroupRequest,
  PatchFixtureGroupResponse,
  PatchFixtureRequest,
  PatchFixtureResponse,
} from "../../../../api-contracts/fixtures-and-items/index";
import { queryClient } from "query-client.ts";

export const FIXTURES_AND_ITEMS_QUERY_KEY = "fixtures";
export const FIXTURES_AND_ITEMS_GROUPS_QUERY_KEY = "fixtures/groups";

// GET /{siteId}/fixtures
export const useGetFixturesAndItems = createQuery<GetFixturesResponse>({
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${FIXTURES_AND_ITEMS_QUERY_KEY}`),
  primaryKey: FIXTURES_AND_ITEMS_QUERY_KEY,
});

// POST /{siteId}/fixtures
export const usePostFixturesAndItems = createMutation<
  CreateFixtureResponse,
  CreateFixtureRequest
>({
  mutationFn: async (fixture) =>
    api.post(
      `/api/${currentSite?.id}/${FIXTURES_AND_ITEMS_QUERY_KEY}`,
      fixture,
    ),
  onSettled: () =>
    queryClient.invalidateQueries({ queryKey: [FIXTURES_AND_ITEMS_QUERY_KEY] }),
});

// PATCH /{siteId}/fixtures
export const usePatchFixuresAndItems = createMutation<
  PatchFixtureResponse,
  PatchFixtureRequest
>({
  mutationFn: async (fixture) =>
    api.patch(
      `/api/${currentSite?.id}/${FIXTURES_AND_ITEMS_QUERY_KEY}`,
      fixture,
    ),
  onSettled: () =>
    queryClient.invalidateQueries({ queryKey: [FIXTURES_AND_ITEMS_QUERY_KEY] }),
});

// DELETE /{siteId}/fixtures/{id}
export const useDeleteFixturesAndItems = createMutation<null, { id: string }>({
  mutationFn: async (fixture) =>
    api.delete(
      `/api/${currentSite?.id}/${FIXTURES_AND_ITEMS_QUERY_KEY}/${fixture.id}`,
    ),
  onSettled: () =>
    queryClient.invalidateQueries({
      queryKey: [FIXTURES_AND_ITEMS_QUERY_KEY],
    }),
});

// GET /{siteId}/fixtures/groups
export const useGetFixtureGroups = createQuery<GetFixturesResponse>({
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${FIXTURES_AND_ITEMS_GROUPS_QUERY_KEY}`),
  primaryKey: FIXTURES_AND_ITEMS_QUERY_KEY,
});

// POST /{siteId}/fixtures/groups
export const usePostFixtureGroups = createMutation<
  CreateFixtureGroupResponse,
  CreateFixtureGroupRequest
>({
  mutationFn: async (fixture) =>
    api.post(
      `/api/${currentSite?.id}/${FIXTURES_AND_ITEMS_GROUPS_QUERY_KEY}`,
      fixture,
    ),
  onSettled: () =>
    queryClient.invalidateQueries({
      queryKey: [FIXTURES_AND_ITEMS_QUERY_KEY],
    }),
});

// PATCH /{siteId}/fixtures/groups
export const usePatchFixtureGroups = createMutation<
  PatchFixtureGroupResponse,
  PatchFixtureGroupRequest
>({
  mutationFn: async (fixture) =>
    api.patch(
      `/api/${currentSite?.id}/${FIXTURES_AND_ITEMS_GROUPS_QUERY_KEY}`,
      fixture,
    ),
  onSettled: () =>
    queryClient.invalidateQueries({
      queryKey: [FIXTURES_AND_ITEMS_QUERY_KEY],
    }),
});

// DELETE /{siteId}/fixtures/groups/{id}
export const useDeleteFixtureGroup = createMutation<null, { id: string }>({
  mutationFn: async (group) =>
    api.delete(
      `/api/${currentSite?.id}/${FIXTURES_AND_ITEMS_GROUPS_QUERY_KEY}/${group.id}`,
    ),
  onSettled: () =>
    queryClient.invalidateQueries({
      queryKey: [FIXTURES_AND_ITEMS_QUERY_KEY],
    }),
});
