import { useGetArrivalsByDate } from "@api/reception";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MessageOfTheDay,
  ReceptionArrival,
} from "../../../../api-contracts/reception";
import { format } from "date-fns";

interface ReceptionContextProviderProps {
  children: ReactNode;
}

interface DataContextType {
  arrivals: ReceptionArrival[];
  isLoading: boolean;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  messageOfTheDay: MessageOfTheDay | undefined;
  date: Date | undefined;
  setDate: Dispatch<SetStateAction<Date | undefined>>;
}
const initialContextValue: DataContextType = {
  searchTerm: "",
  setSearchTerm: () => null,
  arrivals: [],
  isLoading: true,
  messageOfTheDay: undefined,
  date: undefined,
  setDate: () => null,
};
export const ReceptionContext =
  createContext<DataContextType>(initialContextValue);

export const useReceptionContext = (): DataContextType => {
  const context = useContext(ReceptionContext);

  if (context === undefined) {
    throw new Error(
      "useReceptionContext must be used within a ReceptionContextProvider",
    );
  }
  return context;
};

export const ReceptionContextProvider: FC<ReceptionContextProviderProps> = ({
  children,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [date, setDate] = useState<Date | undefined>(new Date());

  const {
    data: arrivalsData,
    isLoading: arrivalsIsLoading,
    refetch: refetchArrivalsData,
  } = useGetArrivalsByDate({
    variables: { date: format(date ? date : new Date(), "yyyy-MM-dd") },
  });
  useEffect(() => {
    refetchArrivalsData();
  }, [date]);

  // Add holder name & surname when implemented
  const filterBySearchTerm = (
    arrival: ReceptionArrival,
    searchTerm: string,
  ) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return arrival.bookingId.toLowerCase().includes(lowerCaseSearchTerm);
  };

  const displayedData = useMemo(() => {
    return arrivalsData?.arrivals
      ? arrivalsData.arrivals.filter((arrival: ReceptionArrival) =>
          filterBySearchTerm(arrival, searchTerm),
        )
      : [];
  }, [arrivalsData, searchTerm]);

  const messageOfTheDay = arrivalsData?.messageOfTheDay;

  const value: DataContextType = {
    arrivals: displayedData,
    messageOfTheDay,
    isLoading: arrivalsIsLoading,
    searchTerm,
    setSearchTerm,
    setDate,
    date,
  };
  return (
    <ReceptionContext.Provider value={value}>
      {children}
    </ReceptionContext.Provider>
  );
};
