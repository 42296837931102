import React from "react";
import { Search } from "lucide-react";
import { SearchBar } from "./search-bar";
import { Button } from "./button";

interface SearchBarWithIconProps {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchBarWithIcon: React.FC<SearchBarWithIconProps> = ({
  isExpanded,
  setIsExpanded,
  searchTerm,
  setSearchTerm,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      setSearchTerm("");
    }
  };

  return (
    <>
      {isExpanded ? (
        <SearchBar
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-[320px] min-w-[100px] max-w-[320px]"
          onBlur={() => setIsExpanded((prevState) => !prevState)}
          autoFocus
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Button
          size="icon"
          variant="secondary"
          onClick={() => setIsExpanded((prevState) => !prevState)}
        >
          <Search size={17} />
        </Button>
      )}
    </>
  );
};
