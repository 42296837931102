import {
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@shared/primitives/toast";
import { useToast } from "@shared/hooks/use-toast";
import { CircleAlert, Check } from "lucide-react";

export function Toaster() {
  const { toasts } = useToast();

  const Icon = ({ variant }: { variant?: string }) => {
    if (!variant) return <></>;
    return variant === "success" ? (
      <div
        className={
          "flex items-center justify-center rounded-full bg-status-success-100 p-3"
        }
      >
        <Check size={18} color={"#059669FF"} />
      </div>
    ) : (
      <div className={"rounded-full bg-status-error-100 p-3"}>
        <CircleAlert color={"#DC2626"} size={18} />
      </div>
    );
  };

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        retry,
        ...props
      }) {
        return (
          <Toast key={id} {...props}>
            <div className={"group flex items-center"}>
              <Icon variant={props.variant} />
              <div className={"mr-3"} />
              <div>
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
            </div>
            <div className={"flex items-center"}>
              {retry && (
                <>
                  <ToastAction
                    onClick={retry}
                    className="inline-flex h-[28px] w-[58px] shrink-0 items-center justify-center rounded-md border border-component-border-color bg-transparent px-3 text-sm font-medium ring-offset-white transition-colors hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-gray-100/40 group-[.destructive]:hover:border-red-500/30 group-[.destructive]:hover:bg-red-500 group-[.destructive]:hover:text-gray-50 group-[.destructive]:focus:ring-red-500 dark:ring-offset-gray-950 dark:hover:bg-gray-800 dark:focus:ring-gray-300 dark:group-[.destructive]:border-gray-800/40 dark:group-[.destructive]:hover:border-red-900/30 dark:group-[.destructive]:hover:bg-red-900 dark:group-[.destructive]:hover:text-gray-50 dark:group-[.destructive]:focus:ring-red-900"
                    asChild
                    altText="Goto schedule to undo"
                  >
                    <p className={"text-primary-text"}>Action</p>
                  </ToastAction>
                  <div className={"mr-3"} />
                </>
              )}
              <ToastClose />
            </div>
            {action}
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
