import { createContext, useEffect, useState } from "react";
import { GetSitesResponse } from "../../../../api-contracts/sites";
import { Button } from "@shared/primitives/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SiteWithImage } from "../../../../api-contracts/admin";
import { useGetProfileContext } from "@api/profile-site-module-context";
import { Loading } from "@primitives/loading";

interface SiteContextProps {
  sites?: GetSitesResponse;
  activeSite?: SiteWithImage;
  setActiveSite: (site: SiteWithImage) => void;
}

export const SiteContext = createContext<SiteContextProps>(
  undefined as unknown as SiteContextProps,
);

export const ACTIVE_SITE_STORAGE_KEY = "activeSiteId";
export let currentSite: SiteWithImage | undefined;

export const SiteProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeSite, setActiveSite1] = useState<SiteWithImage>();
  const { pathname } = useLocation();
  const { data: sites, isLoading, isFetching } = useGetProfileContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const setActiveSite = (site?: SiteWithImage) => {
    localStorage.setItem(ACTIVE_SITE_STORAGE_KEY, site?.id || "");
    setActiveSite1(site);
  };

  useEffect(() => {
    if (sites) {
      const activeSiteId = localStorage.getItem(ACTIVE_SITE_STORAGE_KEY);
      const site = sites.find((s: any) => s.id === activeSiteId) || sites[0];
      setActiveSite(site);
      currentSite = site;
    }
  }, [sites]);

  const proceed = !!activeSite || pathname.includes("/admin");

  if (isLoading || isFetching) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (!proceed) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-2xl">{t("no-site-configured-for-the-user")}</h1>
          <Button className="mt-4" onClick={() => navigate("/logout")}>
            Log out
          </Button>
        </div>
      </div>
    );
  }

  return (
    <SiteContext.Provider
      value={{
        sites,
        activeSite,
        setActiveSite,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
