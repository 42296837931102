import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CreateGuestOrgRequest,
  CreateGuestOrgResponse,
  GetGuestOrgResponse,
  PatchGuestOrgRequest,
  PatchGuestOrgResponse,
  SearchGuestOrgQueryParams,
  SearchGuestOrgResponse,
} from "../../../../api-contracts/guest-organizations";

export const GUEST_ORGANIZATIONS_QUERY_KEY = "guest-organizations";

export const useGetGuestOrganizations = createQuery<
  SearchGuestOrgResponse,
  SearchGuestOrgQueryParams
>({
  primaryKey: GUEST_ORGANIZATIONS_QUERY_KEY,
  queryFn: async ({ queryKey: [, params] }) => {
    return api.get(
      `/api/${currentSite?.id}/${GUEST_ORGANIZATIONS_QUERY_KEY}`,
      Object.fromEntries(
        Object.entries(params).map(([key, value]) => [
          key,
          encodeURIComponent(value),
        ]),
      ),
    );
  },
});

export const useGetGuestOrganization = createQuery<
  GetGuestOrgResponse,
  { id: string }
>({
  primaryKey: GUEST_ORGANIZATIONS_QUERY_KEY,
  queryFn: async ({ queryKey: [, { id }] }) => {
    return api.get(
      `/api/${currentSite?.id}/${GUEST_ORGANIZATIONS_QUERY_KEY}/${id}`,
    );
  },
});

export const useCreateGuestOrganization = createMutation<
  CreateGuestOrgResponse,
  CreateGuestOrgRequest
>({
  mutationFn: async (data: CreateGuestOrgRequest) =>
    api.post(`/api/${currentSite?.id}/${GUEST_ORGANIZATIONS_QUERY_KEY}`, {
      ...data,
    }),
});

export const useUpdateGuestOrganization = createMutation<
  PatchGuestOrgResponse,
  PatchGuestOrgRequest & { id: string }
>({
  mutationFn: async ({ id, ...patch }: PatchGuestOrgRequest & { id: string }) =>
    api.patch(
      `/api/${currentSite?.id}/${GUEST_ORGANIZATIONS_QUERY_KEY}/${id}`,
      {
        ...patch,
      },
    ),
});
