import {
  GetAmenitiesResponse,
  GetAmenityResponse,
  CreateAmenityRequest,
  CreateAmenityResponse,
  PatchAmenityRequest,
  PatchAmenityResponse,
  DeleteAmenityResponse,
  GetAmenityRequest,
} from "../../../../api-contracts/amenities";
import { api } from "./api";
import { currentSite } from "@context/site-context";
import { createMutation, createQuery } from "react-query-kit";

export const AMENITIES_QUERY_KEY = "amenities";

// GET /{siteId}/amenities
export const useGetAmenities = createQuery<GetAmenitiesResponse>({
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${AMENITIES_QUERY_KEY}`),
  primaryKey: AMENITIES_QUERY_KEY,
});

// GET /{siteId}/amenities/:id
export const useGetAmenityById = createQuery<
  GetAmenityResponse,
  GetAmenityRequest
>({
  queryFn: async ({ queryKey: [, { id }] }) =>
    api.get(`/api/${currentSite?.id}/${AMENITIES_QUERY_KEY}/${id}`),
  primaryKey: AMENITIES_QUERY_KEY,
});

// POST /{siteId}/amenities
export const usePostAmenity = createMutation<
  CreateAmenityResponse,
  CreateAmenityRequest
>({
  mutationFn: async (amenity) =>
    api.post(`/api/${currentSite?.id}/${AMENITIES_QUERY_KEY}`, amenity),
});

// PATCH /{siteId}/amenities/:id
export const usePatchAmenityById = createMutation<
  PatchAmenityResponse,
  { patch: PatchAmenityRequest; id: string }
>({
  mutationFn: async (amenity) =>
    api.patch(
      `/api/${currentSite?.id}/${AMENITIES_QUERY_KEY}/${amenity.id}`,
      amenity.patch,
    ),
});

// DELETE /{siteId}/amenities/:id
export const useDeleteAmenity = createMutation<
  DeleteAmenityResponse,
  { id: string }
>({
  mutationFn: async ({ id }) =>
    api.delete(`/api/${currentSite?.id}/${AMENITIES_QUERY_KEY}/${id}`),
});
