import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CreateTitleRequest,
  CreateTitleResponse,
  GetTitleResponse,
  GetTitlesResponse,
  PatchTitleRequest,
  PatchTitleResponse,
} from "../../../../api-contracts/titles";

export const TITLES_QUERY_KEY = "titles";

export const useGetTitle = createQuery<GetTitleResponse>({
  primaryKey: TITLES_QUERY_KEY,
  queryFn: async ({ queryKey: [titleId] }) =>
    api.get(`/api/${currentSite?.id}/${TITLES_QUERY_KEY}/${titleId}`),
});
export const useGetTitles = createQuery<GetTitlesResponse>({
  primaryKey: TITLES_QUERY_KEY,
  queryFn: async () => api.get(`/api/${currentSite?.id}/${TITLES_QUERY_KEY}`),
});

export const useCreateTitle = createMutation<
  CreateTitleResponse,
  CreateTitleRequest
>({
  mutationFn: async (data: CreateTitleRequest) =>
    api.post(`/api/${currentSite?.id}/${TITLES_QUERY_KEY}`, {
      name: data.name,
      active: data.active,
    }),
});

export const useUpdateTitle = createMutation<
  PatchTitleResponse,
  { id: string; body: PatchTitleRequest }
>({
  mutationFn: async (data) =>
    api.patch(
      `/api/${currentSite?.id}/${TITLES_QUERY_KEY}/${data.id}`,
      data.body,
    ),
});

export const useDeleteTitle = createMutation<null, { id: string }>({
  mutationFn: async (data) =>
    api.delete(`/api/${currentSite?.id}/${TITLES_QUERY_KEY}/${data.id}`),
});
