import { createMutation, createQuery } from "react-query-kit";
import { api } from "./api";
import { FindQueryParams } from "../types/common";
import { queryClient } from "../../query-client";

export const ORGANIZATION_QUERY_KEY = "/api/admin/organizations";

interface OrganizationViewModel {
  id: string;
  name: string;
}
type FindOrganizationsQueryParams = FindQueryParams;
type FindOrganizationsResponse = OrganizationViewModel[];

export const useGetOrganizations = createQuery<
  FindOrganizationsResponse,
  FindOrganizationsQueryParams
>({
  primaryKey: ORGANIZATION_QUERY_KEY,
  queryFn: async ({ queryKey: [path] }) => api.get(path),
  staleTime: 1000 * 60 * 5,
});

export const useGetOrganization = createQuery<OrganizationViewModel>({
  primaryKey: ORGANIZATION_QUERY_KEY,
  queryFn: async ({ queryKey: [path, organizationId] }) =>
    api.get(`${path}/${organizationId}`),
});

export const useCreateOrganization = createMutation<
  OrganizationViewModel,
  Omit<OrganizationViewModel, "id">
>({
  mutationFn: async (organization) =>
    api.post(ORGANIZATION_QUERY_KEY, organization),
  onSuccess: () =>
    queryClient.invalidateQueries({ queryKey: [ORGANIZATION_QUERY_KEY] }),
});

export const useUpdateOrganization = createMutation<
  OrganizationViewModel,
  OrganizationViewModel
>({
  mutationFn: async (organization) =>
    api.put(`${ORGANIZATION_QUERY_KEY}/${organization.id}`, organization),
});

export const useDeleteOrganization = createMutation<
  OrganizationViewModel,
  OrganizationViewModel
>({
  mutationFn: async (organization) =>
    api.delete(`${ORGANIZATION_QUERY_KEY}/${organization.id}`),
});
