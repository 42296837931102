import { H2, H1, P } from "@primitives/typography";
import { resizePropotionally } from "@shared/utils/helpers";
import { useTranslation } from "react-i18next";

export const UnderConstruction: React.FC<{ name: string }> = ({ name }) => {
  const { t } = useTranslation();
  const org = { width: 1920, height: 1036.44 };
  const size = resizePropotionally(org.width, org.height, 250, 250);

  return (
    <section className="h-full w-full p-6">
      <H2 className=" text-primary-text">{name}</H2>
      <div className="flex h-full flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <H1 className="lg:text-6xl">{t("under-development-header")}</H1>
          <P className="max-w-[70%] pb-5 text-center text-secondary-text">
            {t("under-development-text")}
          </P>
          <hr className="h-[1px] bg-highlighted-backplate sm:w-28 md:w-60 lg:w-80" />
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size.width}
          height={size.height}
          viewBox={`0 0 ${org.width} ${org.height}`}
          className="fill-primary-color"
        >
          <g>
            <path d="m584.14,34.56L12.35,914.25c-32.72,50.33,3.41,116.9,63.44,116.9h571.79c41.79,0,75.66-33.87,75.66-75.66V75.8c0-75.32-98.05-104.38-139.1-41.23Z" />
            <path d="m1780.9,34.56l-571.79,879.68c-32.72,50.33,3.41,116.9,63.44,116.9h571.79c41.79,0,75.66-33.87,75.66-75.66V75.8c0-75.32-98.05-104.38-139.1-41.23Z" />
            <path d="m998.02,1001.88L1569.81,122.19c32.72-50.33-3.41-116.9-63.44-116.9h-571.79c-41.79,0-75.66,33.87-75.66,75.66v879.68c0,75.32,98.05,104.38,139.1,41.23Z" />
          </g>
        </svg>
      </div>
    </section>
  );
};
