import { AlertDialog } from "@primitives/alert-dialog.tsx";
import React, { useState } from "react";
import { t } from "i18next";
import { useBridgesPageContext } from "../bridges-context.tsx";
import { CodeCheck } from "./code-check.tsx";
import {
  CheckBridgeCodeResponse,
  ConnectNewBridgeResponse,
} from "../../../../../../api-contracts/bridges";
import { FormField } from "@primitives/form-field.tsx";
import { queryClient } from "../../../../query-client.ts";
import { useGetBridges } from "@api/bridges.ts";

export const ReconnectBridgeModal: React.FC = () => {
  const { setSelectedBridge, selectedBridge, sendReconnectBridgeCommand } =
    useBridgesPageContext();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [checkCode, setCheckCode] = useState<string>("");
  const [checkCodeError, setCheckCodeError] = useState<boolean>(false);
  const onCheck = (code: string, codeRes: CheckBridgeCodeResponse) => {
    setCheckCodeError(!codeRes.foundBridge);
    setCheckCode(code);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const reconnectedNewBridge = (bridge: ConnectNewBridgeResponse) => {
    queryClient.invalidateQueries({
      queryKey: useGetBridges.getKey(),
    });
    setSelectedBridge(bridge);
  };

  return (
    <AlertDialog.Root open={openDialog}>
      <AlertDialog.Trigger asChild>
        <a className="ml-2 text-sm" onClick={handleOpen}>
          {t("reconnect-bridge")}{" "}
        </a>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>
            {`${t("reconnect-bridge")} ${selectedBridge?.settings.name || "bryggan"}?`}
          </AlertDialog.Title>
        </AlertDialog.Header>
        <AlertDialog.Description>{t("put-in-new-pin")}</AlertDialog.Description>
        <CodeCheck onCheck={onCheck} />
        {checkCodeError && (
          <FormField.Error>{t("bridge-code-invalid")}</FormField.Error>
        )}
        <AlertDialog.Footer>
          <AlertDialog.Cancel onClick={handleClose}>
            {t("cancel")}
          </AlertDialog.Cancel>
          <AlertDialog.Action
            disabled={checkCode === "" || checkCodeError}
            onClick={() => {
              sendReconnectBridgeCommand(
                selectedBridge?.id || "",
                checkCode,
                reconnectedNewBridge,
              );
              handleClose();
            }}
          >
            {`${t("reconnect-bridge")} ${selectedBridge?.settings.name || "bryggan"}`}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
