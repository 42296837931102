import { Breadcrumbs } from "./breadcrumbs.tsx";
import { useLocationsPageContext } from "../locations-context.tsx";
import { ChevronRight } from "lucide-react";
import { useTranslation } from "react-i18next";

export const LocationsBreadcrumbs = () => {
  const {
    sharedState: { currentLocation, locations },
    sharedFunctions: { handleBreadcrumb },
  } = useLocationsPageContext();
  const { t } = useTranslation();

  return (
    <div className="flex">
      <div className="flex h-10 items-center justify-center overflow-x-hidden text-ellipsis rounded-md border border-main-border-color bg-primary-card-backplate p-0">
        <div
          onClick={() => handleBreadcrumb(null)}
          className="flex h-full cursor-pointer items-center overflow-x-hidden text-ellipsis rounded-md bg-primary-card-backplate px-2 text-sm font-bold"
        >
          {locations[0]?.location?.siteName ?? t("locations")}
        </div>
        {currentLocation && (
          <>
            <ChevronRight className="h-[14px] w-[14px]" />
            <Breadcrumbs
              nodes={locations}
              current={currentLocation}
              onClick={handleBreadcrumb}
            />
          </>
        )}
      </div>
    </div>
  );
};
