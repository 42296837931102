import {
  CategoryType,
  CreateCategoryRequest,
  CreateCategoryResponse,
  GetCategoriesResponse,
  GetCategoryRequest,
  GetCategoryResponse,
  PatchCategoryRequest,
  PatchCategoryResponse,
} from "../../../../api-contracts/categories";
import { currentSite } from "@shared/context/site-context.tsx";
import { api } from "./api.ts";
import { createMutation, createQuery } from "react-query-kit";

export const CATEGORIES_QUERY_KEY = "categories";

// GET /{siteId}/categories
export const useGetCategories = createQuery<
  GetCategoriesResponse,
  { categoryTypes: CategoryType[] }
>({
  queryFn: async ({ queryKey: [_, { categoryTypes }] }) => {
    const types = categoryTypes.map((type) => `types=${type}`).join("&");
    return api.get(`/api/${currentSite?.id}/${CATEGORIES_QUERY_KEY}?${types}`);
  },
  primaryKey: CATEGORIES_QUERY_KEY,
});

// GET /{siteId}/categories/:id
export const useGetCategoryById = createQuery<
  GetCategoryResponse,
  GetCategoryRequest
>({
  queryFn: async ({ queryKey: [, { id }] }) =>
    api.get(`/api/${currentSite?.id}/${CATEGORIES_QUERY_KEY}/${id}`),
  primaryKey: CATEGORIES_QUERY_KEY,
});

// PATCH /{siteId}/categories/:id
export const usePatchCategoryById = createMutation<
  PatchCategoryResponse,
  { patch: PatchCategoryRequest; id: string }
>({
  mutationFn: async (category) =>
    api.patch(
      `/api/${currentSite?.id}/${CATEGORIES_QUERY_KEY}/${category.id}`,
      category.patch,
    ),
});

// POST /{siteId}/categories
export const usePostCategory = createMutation<
  CreateCategoryResponse,
  CreateCategoryRequest
>({
  mutationFn: async (category) =>
    api.post(`/api/${currentSite?.id}/${CATEGORIES_QUERY_KEY}`, category),
});
