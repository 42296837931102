import React from "react";
import { AdminNode, extractParentNodes } from "../sections/nodes";

const Arrow = () => (
  <svg
    className="mx-1 block h-3 w-3 text-gray-400 rtl:rotate-180 "
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 6 10"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m1 9 4-4-4-4"
    />
  </svg>
);

const ParentItem: React.FC<{
  node: AdminNode;
  onSelect: (node: AdminNode) => void;
}> = ({ node, onSelect }) => {
  return (
    <>
      <li className="inline-flex items-center">
        <a
          onClick={() => onSelect(node)}
          role="button"
          className="hover:text-blue-600 inline-flex items-center text-sm font-medium text-gray-700 dark:text-gray-400 dark:hover:text-white"
        >
          {node.name}
        </a>
      </li>
      <Arrow />
    </>
  );
};

const LeafItem: React.FC<{
  node: AdminNode;
}> = ({ node }) => {
  return (
    <li aria-current="page">
      <div className="flex items-center">
        <span className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400 md:ms-2">
          {node.name}
        </span>
      </div>
    </li>
  );
};

type Props = {
  node: AdminNode;
  onSelect: (item: AdminNode) => void;
};

export const BreadCrumbs: React.FC<Props> = ({ node, onSelect }) => {
  const parents = extractParentNodes(node);
  return (
    <nav
      className="m-auto flex w-min rounded-lg border border-gray-200 bg-gray-50 px-5 py-3 text-gray-700 dark:border-gray-700 dark:bg-gray-800"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center  md:space-x-2 rtl:space-x-reverse">
        {parents.map((it) => (
          <ParentItem
            key={it.name + it.to[0].name}
            node={it}
            onSelect={onSelect}
          />
        ))}
        <LeafItem key={node.name} node={node} />
      </ol>
    </nav>
  );
};
