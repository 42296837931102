import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ForgotPassword from "./auth/forgot-password";
import Login from "./auth/login";
import Logout from "./auth/logout";
import Protected from "./auth/protected";
import Verify from "./auth/verify";
import Root from "./root";
import Error from "./error";
import * as loginLoaderAction from "./auth/login.loader";
import Pricing from "@pages/booking/price-rules";
import ProtectedAdmin from "./auth/admin";
import { AdminView } from "./admin";
import LocationsPage from "./settings/locations";
import { BridgesPage } from "./settings/bridges";
import TitlesPage from "./settings/titles";
import CategoriesPage from "./settings/categories";
import Bookables from "./settings/bookables";
import RoomDetailsPage from "./settings/bookables/room-details";
import ChannelsPage from "./settings/channels";
import TargetGroupsPage from "./settings/target_groups";
import CategoriesDetailPage from "./settings/categories/categories-detail";
import { StatusPage } from "./settings/status";
import CombinationDetailsPage from "./settings/bookables/combination-details";
import { GroupsPage } from "./settings/groups";
import CategoryGridPage from "./booking/category-grid";
import TagsPage from "./settings/tags";
import NotFound from "./not-found";
import UnallocatedPage from "./booking/unallocated";
import FixtureAndAmenityPage from "./settings/fixtures-and-amenities";
import {
  AMENITIES_PERMISSION,
  ASSET_GROUPS_PERMISSION,
  ASSETS_PERMISSION,
  BRIDGES_PERMISSION,
  CATEGORIES_PERMISSION,
  CHANNELS_PERMISSION,
  FIXTURES_PERMISSION,
  LOCATIONS_PERMISSION,
  PRICE_CALENDAR_PERMISSION,
  PRICE_RULES_PERMISSION,
  RECEPTION_PERMISSION,
  RESERVATION_PERMISSION,
  ROUTES,
  TAGS_PERMISSION,
  TARGET_GROUPS_PERMISSION,
  TITLES_PERMISSION,
} from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";
import { Loading } from "@primitives/loading";
import { Suspense, useEffect, useState } from "react";
import { BedTypesPage } from "@pages/settings/bed-types";
import AssetGridPage from "./booking/asset-grid";
import ReservationPage from "./booking/reservations/reservation";
import ReservationsPage from "./booking/reservations";
import AreaDetailsPage from "./settings/bookables/area-details";
import DormitoryDetailsPage from "./settings/bookables/dormitory-details";
import BedDetailsPage from "./settings/bookables/bed-details";
import PriceCalendarPage from "./booking/price-calendar";
import DashboardPage from "./dashboard/dashboard";
import ArrivalsPage from "./reception/arrivals";

type RouteType = {
  path: string;
  component: JSX.Element;
  permissions: string[];
};

export function Router() {
  const { module, routePermissions } = useProfileContext();
  const [filteredRoutes, setFilteredRoutes] = useState<RouteType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!module || !routePermissions.length) {
      setIsLoading(true);
      return;
    }

    /** Defined routes and its permissions */
    const routes: RouteType[] = [
      {
        path: `${ROUTES.CATEGORY_GRID_HOTEL}`,
        component: <CategoryGridPage tab="hotel" />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.CATEGORY_GRID_AREA}`,
        component: <CategoryGridPage tab="area" />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.CATEGORY_GRID_HOSTEL}`,
        component: <CategoryGridPage tab="hostel" />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.ASSET_GRID_HOTEL}`,
        component: <AssetGridPage tab="hotel" />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.ASSET_GRID_AREA}`,
        component: <AssetGridPage tab="area" />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.ASSET_GRID_HOSTEL}`,
        component: <AssetGridPage tab="hostel" />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.RESERVATIONS}/:id`,
        component: <ReservationPage />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.RESERVATIONS}`,
        component: <ReservationsPage />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.UNALLOCATED}/:from/:to`,
        component: <UnallocatedPage />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.PRICE_CALENDAR}`,
        component: <PriceCalendarPage />,
        permissions: [PRICE_CALENDAR_PERMISSION],
      },
      {
        path: `${ROUTES.PRICE_RULES}`,
        component: <Pricing />,
        permissions: [PRICE_RULES_PERMISSION],
      },
      {
        path: `${ROUTES.LOCATIONS}`,
        component: <LocationsPage />,
        permissions: [LOCATIONS_PERMISSION],
      },
      {
        path: `${ROUTES.ASSETS}`,
        component: <Bookables tab="all" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.ROOMS}`,
        component: <Bookables tab="room" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.ROOMS}/:id`,
        component: <RoomDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.ROOMS}/:id/duplicate`,
        component: <RoomDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.AREAS}`,
        component: <Bookables tab="area" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.AREAS}/:id`,
        component: <AreaDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.AREAS}/:id/duplicate`,
        component: <AreaDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BEDS}`,
        component: <Bookables tab="bed" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BEDS}/:id`,
        component: <BedDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.BEDS}/:id/duplicate`,
        component: <BedDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.DORMITORIES}`,
        component: <Bookables tab="dormitory" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.DORMITORIES}/:id`,
        component: <DormitoryDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.DORMITORIES}/:id/duplicate`,
        component: <DormitoryDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.COMBINATIONS}`,
        component: <Bookables tab="combination" />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.COMBINATIONS}/:id`,
        component: <CombinationDetailsPage />,
        permissions: [ASSETS_PERMISSION],
      },
      {
        path: `${ROUTES.GROUPS}`,
        component: <GroupsPage />,
        permissions: [ASSET_GROUPS_PERMISSION],
      },
      {
        path: `${ROUTES.BRIDGES}`,
        component: <BridgesPage />,
        permissions: [BRIDGES_PERMISSION],
      },
      {
        path: `${ROUTES.TITLES}`,
        component: <TitlesPage />,
        permissions: [TITLES_PERMISSION],
      },
      {
        path: `${ROUTES.CHANNELS}`,
        component: <ChannelsPage />,
        permissions: [CHANNELS_PERMISSION],
      },
      {
        path: `${ROUTES.TARGET_GROUPS}`,
        component: <TargetGroupsPage />,
        permissions: [TARGET_GROUPS_PERMISSION],
      },
      {
        path: `${ROUTES.CATEGORIES}`,
        component: <CategoriesPage />,
        permissions: [CATEGORIES_PERMISSION],
      },
      {
        path: `${ROUTES.CATEGORIES}/:id/`,
        component: <CategoriesDetailPage />,
        permissions: [CATEGORIES_PERMISSION],
      },
      {
        path: `${ROUTES.CATEGORIES}/:id/duplicate`,
        component: <CategoriesDetailPage />,
        permissions: [CATEGORIES_PERMISSION],
      },
      {
        path: `${ROUTES.STATUS}`,
        component: <StatusPage />,
        permissions: [RESERVATION_PERMISSION],
      },
      {
        path: `${ROUTES.TAGS}`,
        component: <TagsPage />,
        permissions: [TAGS_PERMISSION],
      },
      {
        path: `${ROUTES.AMENITIES}`,
        component: <FixtureAndAmenityPage />,
        permissions: [AMENITIES_PERMISSION, FIXTURES_PERMISSION],
      },
      {
        path: `${ROUTES.BED_TYPES}`,
        component: <BedTypesPage />,
        permissions: [TAGS_PERMISSION],
      },
      {
        path: `${ROUTES.ARRIVALS}`,
        component: <ArrivalsPage tab="all" />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.ARRIVALS_HOTEL}`,
        component: <ArrivalsPage tab="hotel" />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.ARRIVALS_HOSTEL}`,
        component: <ArrivalsPage tab="hostel" />,
        permissions: [RECEPTION_PERMISSION],
      },
      {
        path: `${ROUTES.ARRIVALS_AREA}`,
        component: <ArrivalsPage tab="area" />,
        permissions: [RECEPTION_PERMISSION],
      },
    ];

    /** Filter routes based on permissions */
    const filteredRoutes = routes.filter((route) =>
      route.permissions.some((permission) =>
        routePermissions.includes(permission),
      ),
    );

    setFilteredRoutes(filteredRoutes);
    setIsLoading(false);
  }, [module, routePermissions]);

  /** Converting route object into a flattened array with permissions */
  const flattenedPermissions = Array.from(
    new Set(filteredRoutes.flatMap((route) => route.permissions)),
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path={`/`} element={<Root />} errorElement={<Error />}>
        {/* Public pages */}
        <Route path="/login" element={<Login />} {...loginLoaderAction} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify" element={<Verify />} />

        {/* Protected routes */}
        <Route
          element={
            <Protected
              routePermissions={flattenedPermissions}
              module={module}
            />
          }
        >
          <Route path={`/${module}`} element={<DashboardPage />} />
          {/** Mapping routes for the selected module */}
          {filteredRoutes.map(({ path, component }) => (
            <Route key={path} path={`/${module}/${path}`} element={component} />
          ))}

          <Route element={<ProtectedAdmin />}>
            {/* Admin Routes */}
            <Route path={`/:module/admin`} element={<AdminView />} />
          </Route>
        </Route>

        {/* Unmatched paths */}
        <Route
          path="*"
          element={
            !isLoading ? (
              <NotFound />
            ) : (
              <div className=" h-screen w-screen">
                <Loading />
              </div>
            )
          }
        />
      </Route>,
    ),
  );

  return (
    <Suspense
      fallback={
        <div className=" h-screen w-screen">
          <Loading />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default Router;
