import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs.tsx";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { isArray } from "lodash";

interface Item {
  value: string | string[];
  label: string;
  disabled?: boolean;
  to?: string;
}

interface Props {
  value: string;
  onValueChange?: (value: string) => void;
  items: Item[];
}
export const TabNavbar: FC<Props> = ({ items, value, onValueChange }) => {
  return (
    <Tabs value={value} onValueChange={onValueChange}>
      <TabsList>
        {items.map((item, index) =>
          item.to ? (
            <Link to={item.to}>
              <TabsTrigger
                key={index}
                value={isArray(item.value) ? item.value[0] : item.value}
                disabled={item.disabled}
              >
                {item.label}
              </TabsTrigger>
            </Link>
          ) : (
            <TabsTrigger
              key={index}
              value={isArray(item.value) ? item.value.join("&") : item.value}
              disabled={item.disabled}
            >
              {item.label}
            </TabsTrigger>
          ),
        )}
      </TabsList>
    </Tabs>
  );
};
