import { useGetChannels } from "@api/channels";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useTranslation } from "react-i18next";

interface Props {
  value?: { id: string; name: string };
  onValueChange?: (value?: { id: string; name: string }) => void;
  id?: string;
  disabled?: boolean;
}

const ChannelSelect = ({ value, onValueChange, id, disabled }: Props) => {
  const [t] = useTranslation();

  const {
    data: channelsData,
    isLoading: channelsIsLoading,
    isRefetching: channelsIsRefetching,
  } = useGetChannels({});

  return (
    <Select
      disabled={disabled}
      value={value?.id}
      onValueChange={(value) =>
        onValueChange &&
        onValueChange(channelsData?.find((c) => c.id === value))
      }
    >
      <SelectTrigger id={id} className="text-xs">
        <SelectValue>{value?.name}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {channelsData?.map((c, i) => (
          <SelectItem key={i} value={c.id} className=" text-xs">
            {t(c.name)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default ChannelSelect;
