import { Input } from "@shared/primitives/input.tsx";
import { useEffect, useState } from "react";
import { Asterisk } from "lucide-react";

type InputProps = {
  inputName: string;
  placeholder: string;
  required: boolean;
  label?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export const DefaultInputField = ({ ...props }: InputProps) => {
  const [value, setValue] = useState<string>(props.defaultValue ?? "");

  useEffect(() => {
    props.onChange(value);
  }, [value, props.onChange, props]);

  return (
    <div>
      {props.label && (
        <label className="inline-flex px-1 text-xs" htmlFor={props.inputName}>
          {props.label}
          {props.required && <Asterisk size="10" className="text-red-500" />}
        </label>
      )}
      <Input
        disabled={props.disabled ?? false}
        required={props.required}
        name={props.inputName}
        type="text"
        value={value}
        placeholder={props.placeholder}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};
