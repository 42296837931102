import { DropdownMenu } from "@primitives/dropdown-menu.tsx";
import { ReactNode } from "react";
import { cn } from "@utils/css.ts";

type PriceRulesProps = {
  children: ReactNode | ReactNode[];
  items: { display: string; onClick: () => void }[];
  className: string;
};
export const PriceRulesDropDown = ({
  children,
  items,
  className,
}: PriceRulesProps) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>

      <DropdownMenu.Content className={cn(className)}>
        {items.map((it, i) => (
          <DropdownMenu.Item onClick={it.onClick} key={`${it.display}-${i}`}>
            {it.display}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
