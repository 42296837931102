import { useEffect, useState } from "react";
import { Calendar } from "./calendar";
import { Popover } from "./popover";
import { Button } from "./button";
import { CalendarIcon, X } from "lucide-react";
import { format } from "date-fns";
import { cn } from "@shared/utils/css";
import { useTranslation } from "react-i18next";
import { sv } from "date-fns/locale";
import {
  DayPickerSingleProps,
  SelectSingleEventHandler,
} from "react-day-picker";
import { EventFor } from "../types/props";

interface SingleDatePickerProps {
  defaultValue?: Date;
  value?: Date;
  onValueChange?: (date?: Date) => void;
  formatDate?: (date: Date) => string;
  popoverProps?: Partial<React.ComponentProps<typeof Popover.Root>>;
  calendarProps?: Partial<DayPickerSingleProps>;
  buttonProps?: Partial<React.ComponentProps<typeof Button>>;
  clearable?: boolean;
}

function DatePicker({
  buttonProps,
  calendarProps,
  popoverProps,
  onValueChange,
  defaultValue,
  value,
  clearable = true,
}: SingleDatePickerProps) {
  const [date, setDate] = useState<Date | undefined>(defaultValue || value);
  const { t } = useTranslation();

  useEffect(() => {
    setDate(value);
  }, [value]);

  const onDateSelected: SelectSingleEventHandler = (date) => {
    setDate(date);
    onValueChange?.(date);
  };

  const onClear = (e: EventFor<"svg", "onClick">) => {
    e.preventDefault();
    setDate(undefined);
    onValueChange?.(undefined);
  };

  return (
    <Popover.Root {...popoverProps}>
      <Popover.Trigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start",
            !date && "text-gray-400",
            "focus-visible:border-gray-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-300 focus-visible:ring-offset-0",
          )}
          {...buttonProps}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          <span>
            {date
              ? format(date, "PPP", { locale: sv })
              : t("date-picker.placeholder")}
          </span>
          {clearable && (
            <X
              className={cn(
                "ml-2 ml-2 ml-2 ml-auto h-4 w-4",
                date ? "visible" : "hidden",
              )}
              onClick={onClear}
            />
          )}
        </Button>
      </Popover.Trigger>
      <Popover.Content className="w-auto shadow-xl">
        <Popover.Arrow
          strokeWidth={1.75}
          strokeLinecap="round"
          className={cn("", "fill-gray-400 stroke-gray-400")}
        />
        <Calendar
          defaultMonth={date}
          locale={sv}
          mode="single"
          className="w-full"
          selected={date}
          onSelect={onDateSelected}
          {...calendarProps}
        />
      </Popover.Content>
    </Popover.Root>
  );
}

export default DatePicker;
