import { Card } from "@primitives/card.tsx";
import { t } from "i18next";
import { Button } from "@primitives/button.tsx";
import { clsx } from "clsx";
import { BookUp, CreditCard, Plus, Printer } from "lucide-react";
import { BridgeDevice } from "../../../../../../api-contracts/bridges";
import {
  BridgeDeviceType,
  BridgeSheetState,
  useBridgesPageContext,
} from "../bridges-context.tsx";
import { BridgeSelectedDropdownMenu } from "./bridge-selected-dropdown-menu.tsx";
import { DeviceSelectedDropdownMenu } from "./device-selected-dropdown-menu.tsx";
import { SettingsCardHeader } from "@components/settings-card-header.tsx";

export const SelectedBridge = () => {
  const {
    selectedBridge,
    setSelectedDevice,
    setSheetState,
    setSelectedBridge,
  } = useBridgesPageContext();

  return (
    <div className="relative flex min-h-full flex-nowrap py-4">
      {selectedBridge && (
        <Card className="relative flex h-full w-[500px] flex-col rounded-xl p-0">
          <SettingsCardHeader
            text={selectedBridge.settings.name}
            description={selectedBridge.settings.description}
            onSecondaryCloseClick={() => {
              setSelectedBridge(null);
            }}
          >
            <div className="flex justify-between">
              <div className="flex space-x-2">
                <Button
                  variant="primary"
                  size="icon"
                  onClick={() => {
                    setSelectedDevice(null);
                    setSheetState(BridgeSheetState.MANAGE_DEVICE);
                  }}
                >
                  <Plus size={17} />
                </Button>
              </div>
              <div className="flex items-center">
                <div className="cursor-pointer hover:bg-gray-100">
                  <BridgeSelectedDropdownMenu />
                </div>
              </div>
            </div>
          </SettingsCardHeader>
          <div className={"grow overflow-x-auto"}>
            {selectedBridge.devices.map((device: BridgeDevice) => (
              <div
                key={device.id}
                className={clsx(
                  "flex h-[80px] cursor-pointer items-center justify-between border-b-[0.5px] border-[#E5E7EB] p-[18px] text-lg font-medium hover:bg-secondary-card-backplate",
                )}
              >
                <div className={"flex gap-2"}>
                  {device.type === BridgeDeviceType.CONTROL_UNIT && <BookUp />}
                  {device.type === BridgeDeviceType.PRINTER && <Printer />}
                  {device.type === BridgeDeviceType.PAYMENT && <CreditCard />}
                  <span>{device.name}</span>
                </div>
                <div className={"flex items-center"}>
                  <DeviceSelectedDropdownMenu device={device} />
                </div>
              </div>
            ))}
          </div>
          <div
            className={clsx(
              "flex flex-col justify-between space-y-3 rounded-b-xl border-t border-[#E7E7E7] bg-secondary-card-backplate p-[18px] font-neue text-xs text-status-disabled",
            )}
          >
            <div className="relative flex justify-between">
              <span>{t("version")}</span>
              <span>{selectedBridge.version}</span>
            </div>
            <div className="relative flex justify-between">
              <span>{t("computer-name")}</span>
              <span>{selectedBridge.computerName}</span>
            </div>
            <div className="relative flex justify-between">
              <span>{t("id")}</span>
              <span>{selectedBridge.id}</span>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};
