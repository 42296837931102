import { createQuery } from "react-query-kit";
import { api } from "./api";
import { currentSite } from "@shared/context/site-context";
import {
  GetAssetGridQueryParams,
  GetAssetGridResponse,
} from "../../../../api-contracts/asset-grid";

export const useGetAssetGrid = createQuery<
  GetAssetGridResponse,
  GetAssetGridQueryParams
>({
  primaryKey: "/api/<siteId>/bookable-grid",
  queryFn: async ({ queryKey: [, { startDate, endDate, types }] }) => {
    const qs = [];
    qs.push(`startDate=${startDate}`);
    qs.push(`endDate=${endDate}`);
    if (types) {
      if (typeof types === "string") {
        qs.push(`types=${types}`);
      } else {
        types.forEach((t) => qs.push(`types=${t}`));
      }
    }
    return api.get<GetAssetGridResponse>(
      `/api/${currentSite?.id}/asset-grid?${qs.join("&")}`,
    );
  },
});
