import { Card, CardTitle } from "@primitives/card.tsx";
import { TabNavbar } from "@components/tab-navbar.tsx";
import { Button } from "@primitives/button.tsx";
import PricingGrid from "@pages/booking/price-rules/components/pricing-grid.tsx";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet.tsx";
import {
  PriceRuleActionType,
  usePriceRulesPageContext,
} from "@pages/booking/price-rules/price-rules-context.tsx";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { PriceRuleFilter } from "@pages/booking/price-rules/components/price-rule-filter.tsx";
import { RateModal } from "@pages/booking/price-rules/components/rate-modal.tsx";

export const PriceRulesBaseView = () => {
  const {
    sharedState: { priceRules, actionType, activeRate, categories },
    sharedFunctions: { setActionType },
  } = usePriceRulesPageContext();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="h-[calc(100vh-320px)] space-y-3">
      <header className="flex justify-between">
        <CardTitle>{t("price-rules-bar-title")}</CardTitle>
      </header>

      <TabNavbar
        value="hotel"
        items={[
          { value: "hotel", label: t("hotel"), disabled: false },
          {
            value: "surface-area",
            label: t("surface-area"),
            disabled: true,
          },
          { value: "hostel", label: t("hostel"), disabled: true },
        ]}
      />
      <div className="flex justify-between">
        <Button
          variant="primary"
          onClick={() => setActionType(PriceRuleActionType.ADD)}
        >
          {t("create-new")}
        </Button>
        <PriceRuleFilter />
      </div>

      <Card className="mt-4 h-full w-full flex-grow overflow-y-auto overflow-x-hidden p-0">
        {priceRules && (
          <PricingGrid
            key="pricing-grid"
            readOnly={false}
            categories={categories}
            display={PriceRuleActionType.DEFAULT}
          />
        )}
      </Card>

      <Sheet open={sidebarOpen} onOpenChange={setSidebarOpen}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle></SheetTitle>
          </SheetHeader>
        </SheetContent>
      </Sheet>

      {activeRate && (
        <>
          <RateModal isOpen={actionType === PriceRuleActionType.DEACTIVATE} />
          <RateModal isOpen={actionType === PriceRuleActionType.ACTIVATE} />
        </>
      )}

      <div className="fixed bottom-3 right-6 flex flex-col items-end justify-end text-sm">
        <span>{t("all-prices-including-vat")}</span>
        <div className="flex items-center justify-end">
          <div className="m-1 h-2 w-2 rounded-full bg-blush" />
          <span>{t("price-per-guest-combination")}</span>
        </div>
      </div>
    </div>
  );
};
