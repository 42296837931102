import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileUploader } from "react-drag-drop-files";
import {
  IMAGES_QUERY_KEY,
  useDeleteImage,
  useSearchImages,
  useUpdateImage,
  useUploadImage,
} from "@api/images";
import { queryClient } from "query-client";
import { cn } from "@utils/css";
import { ArrowLeft, Trash, X } from "lucide-react";
import { useToast } from "@hooks/use-toast";
import { Dialog, DialogContent } from "@primitives/dialog";
import { SearchBar } from "@primitives/search-bar";
import { Button } from "@primitives/button";
import { Input } from "@primitives/input";
import { Image } from "../../../../api-contracts/images";
import { AlertDialog } from "@primitives/alert-dialog";

const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "WEBP", "AVIF"];

export const MediaLibrary: FC<{
  tag: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onImagesSelected: (selectedImages: Omit<Image, "variant">[]) => void;
  initialSelection: Omit<Image, "variant">[];
}> = ({ tag, open, onOpenChange, onImagesSelected, initialSelection }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const [image, setImage] = useState<Omit<Image, "variant">>();
  const [images, setImages] = useState<Omit<Image, "variant">[]>();
  const [selectedImages, setSelectedImages] =
    useState<Omit<Image, "variant">[]>(initialSelection);
  const [draggedImage, setDraggedImage] = useState<Omit<Image, "variant">>();
  const [imagePreview, setImagePreview] = useState<string>();
  const [description, setDescription] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const { toast } = useToast();

  const updateImage = useUpdateImage();
  const uploadImg = useUploadImage();
  const deleteImg = useDeleteImage();

  useEffect(() => {
    if (file) {
      // create the preview
      const objectUrl = URL.createObjectURL(file);
      setImagePreview(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  const {
    data: imagesData,
    isLoading: imagesLoading,
    isRefetching: imagesRefetching,
  } = useSearchImages({ variables: { tag, variant: "thumbnail" } });

  useEffect(() => {
    const imgsCopy = imagesData ? [...imagesData] : undefined;
    imgsCopy?.reverse();
    if (search) {
      setImages(
        imgsCopy?.filter((i) =>
          i.description
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()),
        ),
      );
    } else {
      setImages(imgsCopy);
    }
  }, [search, imagesData]);

  const uploadImage = async (file: File) => {
    try {
      await uploadImg.mutateAsync({
        file: file,
        tags: [tag],
        description: description,
      });

      setImagePreview(undefined);
      setFile(undefined);
      setImage(undefined);
      queryClient.invalidateQueries({ queryKey: [IMAGES_QUERY_KEY] });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  const saveImage = async (image: Omit<Image, "variant">) => {
    try {
      await updateImage.mutateAsync({
        patch: {
          tags: [tag],
          description,
        },
        id: image.id,
      });

      setImagePreview(undefined);
      setFile(undefined);
      setImage(undefined);
      queryClient.invalidateQueries({ queryKey: [IMAGES_QUERY_KEY] });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  const deleteImage = async (image: Omit<Image, "variant">) => {
    try {
      await deleteImg.mutateAsync({
        id: image.id,
      });

      setFile(undefined);
      setImagePreview(undefined);
      setImage(undefined);
      setDescription("");
      setSelectedImages(selectedImages.filter((img) => image.id !== img.id));
      queryClient.invalidateQueries({ queryKey: [IMAGES_QUERY_KEY] });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  const onImageClick = (img: Omit<Image, "variant">) => {
    if (selectedImages.find((i) => i.id === img.id)) {
      setSelectedImages(selectedImages.filter((i) => i.id !== img.id));
      setImagePreview(undefined);
      setImage(undefined);
      setFile(undefined);
      setDescription("");
    } else {
      setSelectedImages([...selectedImages, img]);
      setImagePreview(img.url);
      setImage(img);
      setFile(undefined);
      setDescription(img.description || "");
    }
  };

  const onDragStart = (e: any, i: number) => {
    setDraggedImage(selectedImages[i]);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragOver = (index: number) => {
    const draggedOverItem = selectedImages[index];

    // if the item is dragged over itself, ignore
    if (draggedImage === draggedOverItem) {
      return;
    }

    // filter out the currently dragged item
    let images = selectedImages.filter((item) => item !== draggedImage);

    // add the dragged item after the dragged over item
    if (draggedImage) {
      images.splice(index, 0, draggedImage);
    }

    setSelectedImages(images);
  };

  const onDragEnd = () => {
    setDraggedImage(undefined);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" block h-screen !max-h-full sm:!h-4/5 sm:!w-10/12 sm:!max-w-full">
          <div className=" flex h-full flex-col">
            <h2 className=" mb-6 pl-2 text-2xl font-bold text-primary-text">
              {t("add-media")}
            </h2>

            <div className="flex flex-nowrap">
              <div className=" flex-grow">
                {selectedImages.length > 0 && (
                  <div className=" mb-4">
                    <h3 className=" ml-2 text-base font-bold text-primary-text">
                      {t("selected-images")}: {selectedImages.length}
                    </h3>
                    <p className=" ml-2 text-xs font-normal text-secondary-text">
                      {t("drag-and-drop-the-images-to-adjust-display-order")}
                    </p>

                    <ul className=" ml-2 mt-4 flex flex-nowrap overflow-x-auto">
                      {selectedImages.map((img, i) => (
                        <li key={img.id} onDragOver={() => onDragOver(i)}>
                          <div
                            className=" relative mb-2 mr-2 cursor-move"
                            draggable
                            onDragStart={(e) => onDragStart(e, i)}
                            onDragEnd={onDragEnd}
                          >
                            <img
                              className=" h-[60px] w-[60px] rounded-lg object-cover"
                              src={img.url}
                            />
                            <div
                              className=" absolute right-1 top-1 z-10 cursor-pointer rounded-md bg-primary-card-backplate"
                              onClick={() =>
                                setSelectedImages(
                                  selectedImages.filter((i) => i.id !== img.id),
                                )
                              }
                            >
                              <X
                                size="14px"
                                className=" text-primary-text hover:text-secondary-text"
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <h3 className=" ml-2 text-base font-bold text-primary-text">
                  {t("media-library")}
                </h3>
                <p className=" ml-2 text-xs font-normal text-secondary-text">
                  {t("select-one-or-more-images-from-the-media-library-below")}
                </p>

                <div className=" ml-2 mt-2 max-w-[400px]">
                  <SearchBar
                    placeholder={t("search-media")}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>

                <div
                  className={cn(
                    "mt-4 flex h-[calc(80vh_-_280px)] max-h-[calc(80vh_-_280px)] flex-grow flex-wrap justify-center overflow-hidden overflow-y-auto sm:justify-normal",
                    selectedImages.length > 0 &&
                      "h-[calc(80vh_-_410px)] max-h-[calc(80vh_-_410px)]",
                  )}
                >
                  {images?.map((img) => (
                    <div
                      key={img.id}
                      className={cn(
                        " relative mx-2 my-2 max-h-[210px] w-[210px] cursor-pointer rounded-lg border-[0.5px] border-secondary-text p-4",
                        selectedImages.find((i) => i.id === img.id) &&
                          "border-[1.5px] border-primary-color",
                      )}
                      onClick={() => onImageClick(img)}
                    >
                      <img
                        className=" h-[150px] w-full rounded-lg object-cover"
                        src={img.url}
                      />
                      <h3 className=" mt-2 overflow-hidden text-ellipsis whitespace-nowrap text-base font-extrabold text-primary-text empty:before:inline-block">
                        {img.description}
                      </h3>
                    </div>
                  ))}
                  {!images ||
                    (images.length === 0 && (
                      <div className="flex h-full w-full items-center justify-center">
                        <p className=" text-lg font-normal text-secondary-text">
                          {search.length
                            ? t("no-images-found-matching-search-terms")
                            : t(
                                "no-images-exist-please-first-upload-images-to-be-able-to-select",
                              )}
                        </p>
                      </div>
                    ))}
                </div>
              </div>

              <div className=" h-full w-1/4 min-w-[400px]">
                <FileUploader
                  disabled={imagePreview}
                  handleChange={setFile}
                  name="file"
                  types={fileTypes}
                >
                  <div className=" flex h-full justify-center border border-dashed border-primary-text">
                    {imagePreview && (
                      <div className=" m-4 flex flex-col">
                        {image && (
                          <div className="mb-4 flex justify-between">
                            <Button
                              variant="outline"
                              onClick={() => {
                                setFile(undefined);
                                setImagePreview(undefined);
                                setImage(undefined);
                                setDescription("");
                              }}
                            >
                              <ArrowLeft className="mr-2" />
                              {t("upload-new")}
                            </Button>
                            <Button
                              variant="outline"
                              onClick={() => setShowDeleteDialog(true)}
                            >
                              <Trash />
                            </Button>
                            <AlertDialog.Root
                              open={showDeleteDialog}
                              onOpenChange={setShowDeleteDialog}
                            >
                              <AlertDialog.Content>
                                <AlertDialog.Header>
                                  <AlertDialog.Title>
                                    {t(
                                      "are-you-sure-you-want-to-delete-the-image",
                                    )}
                                  </AlertDialog.Title>
                                  <AlertDialog.Description>
                                    {t(
                                      "this-means-permanent-removal-from-the-media-library-and-from-all-other-objects-it-is-linked-to-this-action-cannot-be-undone",
                                    )}
                                  </AlertDialog.Description>
                                </AlertDialog.Header>
                                <AlertDialog.Footer>
                                  <AlertDialog.Cancel>
                                    {t("cancel")}
                                  </AlertDialog.Cancel>
                                  <AlertDialog.Action
                                    onClick={() => image && deleteImage(image)}
                                  >
                                    {t("delete")}
                                  </AlertDialog.Action>
                                </AlertDialog.Footer>
                              </AlertDialog.Content>
                            </AlertDialog.Root>
                          </div>
                        )}
                        <img
                          src={imagePreview}
                          className=" h-[300px] w-full rounded-lg object-cover"
                        />
                        <div className=" mt-2">
                          <p className=" text-base font-medium text-primary-text">
                            {t("image-description") + " *"}
                          </p>
                          <Input
                            placeholder={t("image-description")}
                            max={30}
                            value={description}
                            onChange={(e) =>
                              setDescription(e.target.value.slice(0, 30))
                            }
                          />
                          <div className=" flex justify-end">
                            <p className=" text-xs font-normal text-secondary-text">
                              {description.length}/30
                            </p>
                          </div>
                        </div>
                        <div className=" flex flex-grow flex-col justify-end">
                          <div className="flex flex-nowrap justify-end space-x-2">
                            <Button
                              variant="outline"
                              onClick={() => {
                                setFile(undefined);
                                setImagePreview(undefined);
                                setImage(undefined);
                                setDescription("");
                              }}
                            >
                              {t("cancel")}
                            </Button>
                            <Button
                              variant="outline"
                              disabled={!description}
                              onClick={() =>
                                file
                                  ? uploadImage(file)
                                  : image && saveImage(image)
                              }
                            >
                              {file ? t("upload") : t("save")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {!imagePreview && (
                      <div className=" mt-10 flex flex-col items-center">
                        <svg
                          width="70"
                          height="87"
                          viewBox="0 0 70 87"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M43.3327 1.83398V26.834H68.3327M68.3327 64.334L63.791 59.7924C62.2297 58.2403 60.1176 57.3691 57.916 57.3691C55.7145 57.3691 53.6024 58.2403 52.041 59.7924L26.666 85.1674M45.416 1.83398H9.99935C7.78921 1.83398 5.6696 2.71196 4.10679 4.27476C2.54399 5.83756 1.66602 7.95718 1.66602 10.1673V76.834C1.66602 79.0441 2.54399 81.1637 4.10679 82.7265C5.6696 84.2893 7.78921 85.1673 9.99935 85.1673H59.9994C62.2095 85.1673 64.3291 84.2893 65.8919 82.7265C67.4547 81.1637 68.3327 79.0441 68.3327 76.834V24.7507L45.416 1.83398ZM34.9994 47.6673C34.9994 52.2697 31.2684 56.0006 26.666 56.0006C22.0636 56.0006 18.3327 52.2697 18.3327 47.6673C18.3327 43.0649 22.0636 39.334 26.666 39.334C31.2684 39.334 34.9994 43.0649 34.9994 47.6673Z"
                            stroke="#18181B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p className=" mt-4 text-sm font-medium text-primary-text">
                          {t("drag-and-drop-an-image-here")}
                        </p>
                        <p className=" mt-4 text-xs font-normal text-secondary-text">
                          {t("or")}
                        </p>
                        <Button variant="outline" className=" mt-4">
                          {t("choose-from-files-locally")}
                        </Button>
                      </div>
                    )}
                  </div>
                </FileUploader>

                <div className=" flex justify-end space-x-2 pt-6">
                  <Button variant="outline" onClick={() => onOpenChange(false)}>
                    {t("cancel")}
                  </Button>
                  <Button onClick={() => onImagesSelected(selectedImages)}>
                    {t("add")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
