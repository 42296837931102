import { createMutation, createQuery } from "react-query-kit";
import { api } from "./api";
import { FilterModel, SortModel } from "@shared/utils/query-string";
import { PaginatedResponse } from "@shared/types/common";
import {
  CreateUserRequest,
  CreateUserResponse,
} from "../../../../api-contracts/admin";
import {
  GetOrgUsersResponse,
  PatchOrgUserRequest,
  PatchOrgUserResponse,
} from "../../../../api-contracts/organizations";
import { queryClient } from "../../query-client";
import {
  GetSiteUsersResponse,
  PutSiteUserRolesRequest,
  PutSiteUserRolesResponse,
} from "../../../../api-contracts/sites";

export const USER_QUERY_KEY = "/users";
export const SITE_USERS_QUERY_KEY = "sites/users";

interface User {
  id: string;
}

interface FindUsersQueryParams {
  offset?: number;
  limit?: number;
  sort?: SortModel[];
  filter?: FilterModel;
}

type FindUsersResponse = PaginatedResponse<"users", User>;

export const useGetUsers = createQuery<FindUsersResponse, FindUsersQueryParams>(
  {
    primaryKey: USER_QUERY_KEY,
    queryFn: ({ queryKey: [path, params] }) =>
      api.get<FindUsersResponse>(path, params as Record<string, string>),
    staleTime: 1000 * 60 * 5,
  },
);

export const useGetUser = createQuery<User>({
  primaryKey: USER_QUERY_KEY,
  queryFn: async ({ queryKey: [path, userId] }) =>
    api.get<User>(`${path}/${userId}`),
});

export const useGetOrganizationUsers = createQuery<
  GetOrgUsersResponse,
  { orgId: string }
>({
  primaryKey: USER_QUERY_KEY,
  queryFn: async ({ queryKey: [, variables] }) =>
    api.get<GetOrgUsersResponse>(`/api/organizations/${variables.orgId}/users`),
});

export const useGetSiteUsers = createQuery<
  GetSiteUsersResponse,
  { siteId: string }
>({
  primaryKey: SITE_USERS_QUERY_KEY,
  queryFn: async ({ queryKey: [, variables] }) =>
    api.get<GetSiteUsersResponse>(`/api/sites/${variables.siteId}/users`),
});

export const useAdminCreateUser = createMutation<
  CreateUserResponse,
  CreateUserRequest
>({
  mutationFn: async (user) => api.post("/api/admin/users/", user),
  onSuccess: () =>
    queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] }),
});

export const useUpdateUser = createMutation<
  PatchOrgUserResponse,
  { user: PatchOrgUserRequest; orgId: string; userId: string }
>({
  mutationFn: async (req) =>
    api.patch(`/api/organizations/${req.orgId}/users/${req.userId}`, req.user),
  onSuccess: () =>
    queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] }),
});

export const useUpdateUserSiteRole = createMutation<
  PutSiteUserRolesResponse,
  { body: PutSiteUserRolesRequest; siteId: string; userId: string }
>({
  mutationFn: async (req) =>
    api.put(`/api/sites/${req.siteId}/users/${req.userId}/roles`, req.body),
  onSuccess: () =>
    queryClient.invalidateQueries({ queryKey: [SITE_USERS_QUERY_KEY] }),
});

export const useDeleteUser = createMutation<User, User>({
  mutationFn: async (user) => api.delete(`${USER_QUERY_KEY}/${user.id}`),
});
