import { useState, lazy, Suspense, useMemo } from "react";
import { Image, LucideProps, X } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import { useTranslation } from "react-i18next";
import { SearchBar } from "@primitives/search-bar";
import React from "react";
import { Tooltip } from "@primitives/tooltip";

const fallback = <div className=" h-12 w-12 bg-secondary-card-backplate" />;

export interface IconProps extends Omit<LucideProps, "ref"> {
  name: keyof typeof dynamicIconImports;
}

export const LucideIcon = React.memo(({ name, ...props }: IconProps) => {
  const LucideIconComponent = useMemo(() => {
    return lazy(dynamicIconImports[name]);
  }, [name]);

  return (
    <Suspense fallback={fallback}>
      <Tooltip delayDuration={100} content={name}>
        <LucideIconComponent {...props} className="text-primary-icon-color" />
      </Tooltip>
    </Suspense>
  );
});

export const SelectedIcon = ({
  selectedIcon,
  setSelectedIcon,
}: {
  selectedIcon: string;
  setSelectedIcon: (name: string) => void;
}) => {
  return (
    <>
      {selectedIcon ? (
        <div className=" relative flex h-[100px] w-[100px] items-center justify-center rounded-lg border-2 border-primary-color  bg-secondary-card-backplate align-middle">
          <LucideIcon
            name={selectedIcon as keyof typeof dynamicIconImports}
            size={29}
            strokeWidth={1}
          />
          <div
            onClick={() => setSelectedIcon("")}
            className="absolute right-[-7px] top-[-6px] z-10 cursor-pointer rounded-full bg-primary-color p-1"
          >
            <X
              size="12px"
              className=" text-primary-icon-color hover:opacity-60"
            />
          </div>
        </div>
      ) : (
        <div className="flex h-[100px] w-[100px] items-center justify-center rounded-lg border-2 border-dashed bg-secondary-card-backplate">
          <Image
            className="text-primary-icon-color"
            strokeWidth={1}
            size={29}
          />
        </div>
      )}
    </>
  );
};

export const IconSelector = ({
  selectedIcon,
  setSelectedIcon,
}: {
  selectedIcon: string;
  setSelectedIcon: (name: string) => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const iconNames =
    searchTerm.length > 0
      ? validIcons.filter((iconName) =>
          iconName.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : validIcons;

  return (
    <>
      <div className="flex flex-col">
        <p className=" mb-2 text-base font-bold text-primary-text">
          {t("choose-icon")}
        </p>
        <SelectedIcon
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
        />
        <div className="mt-4">
          <SearchBar
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`${t("search-icon")}...`}
          />
        </div>
      </div>
      <div className="flex-grow justify-start overflow-y-auto border-solid">
        <div className="grid grid-cols-7 gap-4">
          {iconNames.length > 0 ? (
            iconNames.map((iconName) => (
              <div
                onClick={() => setSelectedIcon(iconName as string)}
                className={`${selectedIcon === iconName && "border-2 border-primary-color"} flex h-12 w-12 cursor-pointer items-center justify-center rounded-lg bg-secondary-card-backplate`}
                key={iconName}
              >
                <LucideIcon
                  name={iconName as keyof typeof dynamicIconImports}
                  size={24}
                  strokeWidth={1}
                />
              </div>
            ))
          ) : (
            <div className="w-[300px]">
              <p className="text-sm text-primary-text"> {t("no-results")}.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const availableIcons: string[] = [
  "accessibility",
  "air-vent",
  "airplay",
  "alarm-clock",
  "alarm-smoke",
  "anchor",
  "antenna",
  "armchair",
  "baby",
  "backpack",
  "baggage-claim",
  "battery",
  "battery-charging",
  "bath",
  "bed",
  "bed-double",
  "beer",
  "bell",
  "bell-electric",
  "bike",
  "bitcoin",
  "blinds",
  "bluetooth",
  "book",
  "book-text",
  "briefcase",
  "bus",
  "cable",
  "cable-car",
  "cake",
  "camera",
  "candy",
  "car",
  "caravan",
  "cast",
  "cat",
  "cctv",
  "chef-hat",
  "church",
  "circle-parking",
  "circle-parking-off",
  "cigarette",
  "cigarette-off",
  "clock",
  "coffee",
  "computer",
  "concierge-bell",
  "cooking-pot",
  "credit-card",
  "croissant",
  "dollar-sign",
  "dog",
  "drill",
  "drum",
  "dumbbell",
  "egg",
  "euro",
  "fence",
  "film",
  "fire-extinguisher",
  "fish",
  "flashlight",
  "flame",
  "flame-kindling",
  "fuel",
  "gamepad",
  "gamepad-2",
  "gift",
  "guitar",
  "hammer",
  "hand-platter",
  "headphones",
  "heater",
  "hospital",
  "indian-rupee",
  "japanese-yen",
  "joystick",
  "key-round",
  "lamp",
  "lamp-desk",
  "land-plot",
  "laptop",
  "laptop-minimal",
  "life-buoy",
  "lightbulb",
  "lock",
  "luggage",
  "mail",
  "mailbox",
  "martini",
  "message-circle",
  "mic",
  "microwave",
  "monitor",
  "mouse",
  "music",
  "newspaper",
  "nfc",
  "notebook",
  "paint-roller",
  "paintbrush-vertical",
  "palette",
  "pen",
  "pencil",
  "phone",
  "piano",
  "plane",
  "plug",
  "popcorn",
  "presentation",
  "printer",
  "projector",
  "radio",
  "recycle",
  "receipt",
  "refrigerator",
  "router",
  "russian-ruble",
  "sailboat",
  "school",
  "scissors",
  "shield",
  "ship",
  "shirt",
  "shopping-basket",
  "shopping-cart",
  "shower-head",
  "smartphone",
  "smile",
  "snowflake",
  "sofa",
  "store",
  "sun",
  "swiss-franc",
  "telescope",
  "tent",
  "theater",
  "thermometer",
  "toy-brick",
  "train-front",
  "tram-front",
  "trash",
  "tree-deciduous",
  "tv",
  "umbrella",
  "utensils",
  "vault",
  "video",
  "volume-2",
  "wallet",
  "washing-machine",
  "waves",
  "warehouse",
  "webcam",
  "wind",
  "wine",
  "wrench",
  "youtube",
  "zap",
];
export const validIcons = availableIcons.filter((iconName) =>
  Object.keys(dynamicIconImports).includes(iconName),
);
