import { t } from "i18next";
import {
  BridgeDeviceType,
  BridgeSheetState,
  useBridgesPageContext,
} from "../bridges-context.tsx";
import { BridgeDevice } from "../../../../../../api-contracts/bridges";
import { DefaultDropdownMenu } from "@primitives/default-dropdown-menu.tsx";
import React, { FC } from "react";
import { Edit } from "lucide-react";
import { DropdownMenu } from "@primitives/dropdown-menu";

export const DeviceSelectedDropdownMenu: FC<{
  device: BridgeDevice;
}> = ({ device }) => {
  const {
    selectedBridge,
    setSelectedDevice,
    setSheetState,
    sendDeleteDeviceCommand,
    sendPrintCommand,
  } = useBridgesPageContext();

  const onRemoveDevice = () => {
    if (selectedBridge) {
      sendDeleteDeviceCommand(selectedBridge.id, device.id);
      setSelectedDevice(null);
    }
  };

  return (
    <DefaultDropdownMenu
      onEdit={() => {
        setSelectedDevice(device);
        setSheetState(BridgeSheetState.MANAGE_DEVICE);
      }}
      onDelete={onRemoveDevice}
      alertTitle={`{${t("delete")} ${device.name || ""}`}
      alertDescription={t("delete-bridge-description")}
      alertSubmitButtonText={t("yes-delete-device", {
        device: device.name || "",
      })}
    >
      {selectedBridge &&
        selectedBridge.isLive &&
        device.type === BridgeDeviceType.PRINTER && (
          <DropdownMenu.Item
            onClick={() =>
              selectedBridge && sendPrintCommand(selectedBridge.id, device.id)
            }
          >
            <Edit className="mr-3 h-[12px] w-[12px] text-xs" />
            {t("test-printing")}
          </DropdownMenu.Item>
        )}
    </DefaultDropdownMenu>
  );
};
