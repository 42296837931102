import {
  TargetGroupsActionType,
  useTargetGroupsPageContext,
} from "../target-groups-context.tsx";
import { useState } from "react";
import { TargetGroup } from "../../../../../../api-contracts/target-groups";
import { useTranslation } from "react-i18next";
import { DefaultListItem } from "@components/default-list.tsx";
import { DefaultAlert } from "@pages/settings/locations/components/locations-list-item.tsx";
import { Switch } from "@primitives/switch.tsx";

export const TargetGroupsCardItem = ({
  targetGroup,
  disabled,
}: {
  targetGroup: TargetGroup;
  disabled?: boolean;
  editable: boolean;
}) => {
  const {
    sharedFunctions: {
      setSelectedTargetGroupDetails,
      updateTargetGroup,
      deleteTargetGroup,
      setAction,
      setCurrent,
    },
  } = useTargetGroupsPageContext();
  const [checked, setChecked] = useState<boolean>(targetGroup.active);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleChange = () => {
    setChecked(() => !checked);
    updateTargetGroup(targetGroup.id, { active: !checked });
  };

  const onEdit = () => {
    setAction(TargetGroupsActionType.EDIT_CURRENT);
    setCurrent(targetGroup);
  };
  const onDelete = () => {
    deleteTargetGroup(targetGroup.id, targetGroup.name);
  };

  return (
    <DefaultListItem
      title={t(targetGroup.name)}
      onClickListItem={(e) =>
        e &&
        e.currentTarget === e.target &&
        setSelectedTargetGroupDetails(targetGroup)
      }
      onEdit={onEdit}
      onDelete={targetGroup.mutable ? () => setAlertOpen(true) : undefined}
    >
      <Switch
        className="mx-2"
        checked={checked}
        onCheckedChange={handleChange}
        disabled={disabled}
      />
      <DefaultAlert
        title={t("are-you-sure-you-want-to-delete-target-group", {
          targetGroup: targetGroup?.name,
        })}
        description={t("target-group-will-be-deleted")}
        primaryButtonText={t("yes-delete-target-group", {
          targetGroup: targetGroup?.name,
        })}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        onPrimaryButton={onDelete}
      />
    </DefaultListItem>
  );
};
