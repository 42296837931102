import { FC, ReactElement, useEffect, useState } from "react";
import { Button } from "@primitives/button.tsx";
import {
  ChevronDownIcon,
  ChevronsUpDownIcon,
  ChevronUpIcon,
  Plus,
} from "lucide-react";
import {
  CreateEdit,
  StandardBedSheet,
} from "@pages/settings/bed-types/components/standard-bed-sheet.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table.tsx";
import {
  Column,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { DropDown } from "@components/default-list.tsx";
import { useTranslation } from "react-i18next";
import { GetBedTypeResponse } from "../../../../../../api-contracts/bed-capacity";
import { ExtraBedSheet } from "@pages/settings/bed-types/components/extra-bed-sheet.tsx";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";
import { Card } from "@primitives/card.tsx";
import { AlertDialog } from "@primitives/alert-dialog";

export type BedTypeTable = "standard" | "extra";
interface Props {
  bedTypes: GetBedTypeResponse[];
  title: string;
  type: BedTypeTable;
}
export const BedTable: FC<Props> = ({
  bedTypes,
  title,
  type,
}): ReactElement => {
  const emptyBed: GetBedTypeResponse = {
    bedAccessories: [],
    capacityFullSize: 0,
    capacityHalfSize: 0,
    cot: false,
    extraBed: false,
    id: "",
    internalPrice: 0,
    length: 0,
    locationInventories: [],
    name: "",
    totalExtraBeds: 0,
    width: 0,
  };
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [bedToDelete, setBedToDelete] = useState<string>("");
  const [createEdit, setCreateEdit] = useState<CreateEdit>("create");
  const [openSheet, setOpenSheet] = useState(false);
  const { deleteBed } = useBedData();
  const [bedData, setBedData] = useState<GetBedTypeResponse>();
  const standardBedColumnsHelper = createColumnHelper<GetBedTypeResponse>();
  const standardBedsColumnDefinitions = [
    standardBedColumnsHelper.accessor("name", {
      header: t("name"),
      id: "name",
    }),
    standardBedColumnsHelper.accessor("width", {
      header: `${t("width")} x ${t("length")} (cm)`,
      id: "width",
      cell: (data) => {
        return (
          <div>
            B {data.row.original.width} x L {data.row.original.length}
          </div>
        );
      },
    }),
    standardBedColumnsHelper.accessor("capacityFullSize", {
      header: `${t("capacity")} (${t("full")}/${t("half")})`,
      id: "capacityFullSize",
      cell: (data) => {
        return (
          <div>
            {data.row.original.capacityFullSize} /{" "}
            {data.row.original.capacityHalfSize}
          </div>
        );
      },
    }),
    standardBedColumnsHelper.display({
      id: "actions",
      maxSize: 40,
      cell: (data) => {
        return (
          <div className={"flex w-full justify-end"}>
            <DropDown
              isVisible={true}
              onEdit={() => {
                setBedData(data.row.original);
                setCreateEdit("edit");
                setOpenSheet(!openSheet);
              }}
              onDelete={() => {
                setBedToDelete(data.row.original.id.toString());
                setIsOpen(!isOpen);
              }}
            />
          </div>
        );
      },
    }),
  ];
  const extraBedColumnsHelper = createColumnHelper<GetBedTypeResponse>();
  const extraBedsComlumnDefinitions = [
    standardBedColumnsHelper.accessor("name", {
      header: t("name"),
      id: "name",
    }),
    standardBedColumnsHelper.accessor("width", {
      header: `${t("width")} x ${t("length")} (cm)`,
      id: "width",
      cell: (data) => {
        return (
          <div>
            B {data.row.original.width} x L {data.row.original.length}
          </div>
        );
      },
    }),
    standardBedColumnsHelper.accessor("capacityFullSize", {
      header: `${t("capacity")} (${t("full")}/${t("half")})`,
      id: "capacityFullSize",
      cell: (data) => {
        return (
          <div>
            {data.row.original.capacityFullSize} /{" "}
            {data.row.original.capacityHalfSize}
          </div>
        );
      },
    }),
    extraBedColumnsHelper.accessor("totalExtraBeds", {
      header: t("total-amount"),
      id: "totalExtraBeds",
      cell: (data) => {
        const capacity = data.getValue();
        return <div>{data.row.original.totalExtraBeds}</div>;
      },
    }),
    extraBedColumnsHelper.display({
      id: "actions",
      maxSize: 40,
      cell: (data) => {
        return (
          <div className={"flex w-full justify-end"}>
            <DropDown
              isVisible={true}
              onEdit={() => {
                setBedData(data.row.original);
                setCreateEdit("edit");
                setOpenSheet(!openSheet);
              }}
              onDelete={() => {
                setBedToDelete(data.row.original.id.toString());
                setIsOpen(!isOpen);
              }}
            />
          </div>
        );
      },
    }),
  ];
  const table = useReactTable({
    data: bedTypes,
    columns:
      type === "standard"
        ? standardBedsColumnDefinitions
        : extraBedsComlumnDefinitions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const getSortIcon = (
    column: Column<GetBedTypeResponse>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };

  const renderSheet = (): ReactElement => {
    return type === "standard" ? (
      <StandardBedSheet
        onOpenChange={setOpenSheet}
        open={openSheet}
        bedData={bedData}
        type={createEdit}
        btnText={createEdit === "create" ? t("create") : t("edit")}
      />
    ) : (
      <ExtraBedSheet
        onOpenChange={setOpenSheet}
        open={openSheet}
        bedData={bedData}
        type={createEdit}
        btnText={createEdit === "create" ? t("create") : t("edit")}
      />
    );
  };
  return (
    <>
      <AlertDialog.Root
        open={isOpen}
        onOpenChange={() => setIsOpen((previousState) => !previousState)}
      >
        <AlertDialog.Content className="z-[2001]">
          <AlertDialog.Header>
            <AlertDialog.Title>{t("delete-bed-title")}</AlertDialog.Title>
            <AlertDialog.Description>
              {t("delete-bed-desc")}
            </AlertDialog.Description>
          </AlertDialog.Header>

          <AlertDialog.Footer className="relative">
            <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

            <AlertDialog.Action onClick={() => deleteBed(bedToDelete)}>
              {t("delete")}
            </AlertDialog.Action>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog.Root>
      <Card className="h-full w-full ">
        <p className={" font-itc text-sm font-bold text-primary-text"}>
          {title}
        </p>
        <div className="py-4">
          <Button
            className={"rounded-xl"}
            variant="primary"
            size="icon"
            onClick={() => {
              setBedData(emptyBed);
              setCreateEdit("create");
              setOpenSheet(!openSheet);
            }}
          >
            <Plus size={17} />
          </Button>
        </div>
        <div className={"h-5/6 overflow-auto"}>
          {bedTypes && (
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableHead className={"select-none"} key={header.id}>
                        {header.isPlaceholder ? null : (
                          <div
                            className="flex cursor-pointer items-center"
                            {...{
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.getCanSort() && (
                              <div className="ml-1">
                                {getSortIcon(
                                  header.column,
                                  table.getState().sorting,
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </TableHead>
                    ))}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {bedTypes && table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      className={"hover:bg-secondary-card-backplate"}
                      key={row.id}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            style={{
                              width: `${cell.column.getSize()}px`,
                              height: "74px",
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={table.getAllLeafColumns().length}
                      className="h-24 text-center"
                    >
                      {t("no-results")}.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
        {renderSheet()}
      </Card>
    </>
  );
};
