import { TitlesActionType, useTitlesPageContext } from "../titles-context.tsx";
import { DefaultInput } from "@primitives/default-input.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { useCreateTitle } from "@api/titles.ts";
import { CreateTitleRequest } from "../../../../../../api-contracts/titles";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const AddTitle = () => {
  const {
    sharedState: { action },
    sharedFunctions: { setAction, handleRefetch },
  } = useTitlesPageContext();
  const {
    sharedFunctions: { toast, setLoading },
  } = useSharedContext();
  const { mutate: createTitle } = useCreateTitle();
  const { t } = useTranslation();

  const addTitle = (req: CreateTitleRequest) => {
    setLoading(true);

    createTitle(req, {
      onError: (e) => {
        toast({
          variant: "destructive",
          title: t("create-title-error", {
            error:
              e.message === "unique_violation"
                ? `${req.name} ${t(e.message)}`
                : e.message,
          }),
        });
      },
      onSuccess: async () => {
        await handleRefetch();
      },
      onSettled: () => {
        setLoading(false);
        setAction(TitlesActionType.DEFAULT);
      },
    });
  };

  return (
    <DefaultSideSheet
      title={t("create-title")}
      open={action === TitlesActionType.ADD}
      onOpenChange={() => setAction(TitlesActionType.DEFAULT)}
    >
      <div className="flex flex-grow flex-col">
        <DefaultInput
          onSubmit={(value) => addTitle({ name: value, active: true })}
          buttonText={`${t("create")}`}
          inputName={"add-title"}
          placeholder={`${t("name")}`}
          required={true}
        />
      </div>
    </DefaultSideSheet>
  );
};
