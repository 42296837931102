import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cn } from "@shared/utils/css";
import { BaseProps } from "../types/props";

interface TooltipProps extends BaseProps {
  content: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  delayDuration?: number;
}

export function Tooltip({
  children,
  content,
  delayDuration = 700,
  className,
}: TooltipProps) {
  return (
    <TooltipPrimitive.Root delayDuration={delayDuration}>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        sideOffset={2}
        className={cn(
          "select-none rounded bg-solid-backplate px-3 py-2 text-xs leading-none text-primary-text shadow will-change-[transform,opacity]",
          "data-[state=delayed-open]:data-[side=top]:animate-tooltip-slide-down-fade",
          "data-[state=delayed-open]:data-[side=right]:animate-tooltip-slide-left-fade",
          "data-[state=delayed-open]:data-[side=left]:animate-tooltip-slide-right-fade",
          "data-[state=delayed-open]:data-[side=bottom]:animate-tooltip-slide-up-fade",
          className,
        )}
      >
        {content}
        <TooltipPrimitive.Arrow
          className="fill-primary-icon-color"
          width={11}
          height={5}
        />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  );
}
