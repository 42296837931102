import React, { ReactElement, useState } from "react";
import {
  LocationProps,
  LocationsHierarchyTree,
} from "../../../settings/locations/components/locations-hierarchy-tree.tsx";
import { CheckedState } from "@radix-ui/react-checkbox";
import { Button } from "@primitives/button.tsx";
import { useLocationsPageContext } from "../locations-context.tsx";
import {
  DropdownMenu,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@primitives/dropdown-menu.tsx";
import { findAllChildrenRecursive } from "../locations-utils.tsx";
import { useOutsideClick } from "@hooks/use-outside-click.tsx";
import { LocationsExpand } from "../../../settings/locations/components/locations-expand.tsx";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label.tsx";
import { useTranslation } from "react-i18next";

type FilterProps = {
  filters: LocationProps[];
  onClick: (filters: string[]) => void;
  children: ReactElement;
  menuLabel: string;
};
export const LocationsFilterDropdown = ({
  filters,
  onClick,
  children,
  menuLabel,
}: FilterProps) => {
  const [filter, setFilter] = useState<LocationProps[]>([]);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<LocationProps[]>([]);

  const {
    sharedState: { currentLocation, locations },
    sharedFunctions: { onClickTree, onNodeExpand },
  } = useLocationsPageContext();
  const { t } = useTranslation();

  const updateChecked = (checked: CheckedState, item: LocationProps) => {
    if (checked)
      setCheckedItems((prev) => {
        return prev.filter((it) => it.location.id === item.location.id)
          ?.length > 0
          ? prev
          : [
              ...prev,
              item,
              ...findAllChildrenRecursive(item.location.id, locations),
            ];
      });
    if (!checked)
      setCheckedItems((prev) =>
        prev === null
          ? locations
          : prev.filter(
              (n) =>
                n.location.id != item.location.id &&
                !findAllChildrenRecursive(item.location.id, prev)
                  .map((it) => it.location.id)
                  .includes(n.location.id),
            ),
      );
  };

  const filterRef = useOutsideClick(() => {
    setOpenMenu(false);
    setCheckedItems(filter);
  });

  const getNodes = () => {
    const root = filters.find(({ isRoot }) => isRoot);
    return root ? [...locations, root] : [...locations];
  };

  return (
    <DropdownMenu.Root open={openMenu}>
      <DropdownMenu.Trigger asChild onClick={() => setOpenMenu(true)}>
        {children}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        ref={filterRef}
        className="absolute z-[300] flex h-[70vh] w-[361px] flex-col justify-between overflow-y-auto"
      >
        <div>
          <DropdownMenuLabel>{menuLabel}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <LocationsHierarchyTree
            nodes={getNodes()}
            onSelect={onClickTree}
            current={currentLocation?.location.id ?? null}
          >
            {(node) =>
              filters.includes(node) || node.isRoot ? (
                <div className="flex h-[50px] items-center ">
                  <DropdownMenu.Item
                    onSelect={(event) => event.preventDefault()}
                  >
                    <CheckboxWithLabel
                      label={node.location.name}
                      key={node.location.id}
                      value={node.location.id}
                      name={node.location.name}
                      id={"filter"}
                      checked={
                        checkedItems &&
                        checkedItems
                          .map(({ location }) => location.id)
                          .includes(node.location.id)
                      }
                      onCheckedChange={(checked) =>
                        updateChecked(checked, node)
                      }
                    />
                  </DropdownMenu.Item>
                  <LocationsExpand
                    location={node}
                    locations={locations}
                    onExpand={onNodeExpand}
                  />
                </div>
              ) : (
                <></>
              )
            }
          </LocationsHierarchyTree>
          <DropdownMenuSeparator />
        </div>
        <div className="flex justify-between">
          <Button
            variant="outline"
            onClick={() => {
              setOpenMenu(false);
              setCheckedItems([]);
              setFilter(filters);
              onClick([]);
            }}
          >
            {t("reset")}
          </Button>
          <Button
            onClick={() => {
              setOpenMenu(false);
              setFilter(checkedItems);
              onClick(checkedItems.map((it) => it.location.id));
            }}
          >
            {t("use")}
          </Button>
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
