import { Button } from "@shared/primitives/button.tsx";
import { X } from "lucide-react";

interface ItemListProps {
  title: string;
  subtitle?: string;
  description?: string;
  onClick: () => void;
  buttonText: string;
  secondaryButtonText?: string;
  clicked?: () => boolean;
}
export const ListItemWithButton = ({
  title,
  subtitle,
  onClick,
  buttonText,
  secondaryButtonText,
  clicked,
}: ItemListProps) => {
  return (
    <div className="border border-l-0 border-r-0 border-t-0 border-b-gray-100 p-4">
      <div className="flex w-full items-center justify-between">
        <div className="flex-col">
          <p>{title}</p>
          {subtitle && <div className="text-xs text-gray-500">{subtitle}</div>}
        </div>
        <Button
          variant="outline"
          onClick={() => onClick()}
          className={`${clicked && clicked() ? "bg-gray-200" : "bg-primary-card-backplate"}`}
        >
          {clicked && clicked() ? (
            <div className="flex items-center">
              <X size="15" /> {secondaryButtonText ?? buttonText}
            </div>
          ) : (
            buttonText
          )}
        </Button>
      </div>
    </div>
  );
};
