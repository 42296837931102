export const BorderedListItem = ({
  leftSideText,
  rightSideText,
  className,
}: {
  leftSideText: string;
  rightSideText?: string;
  className?: string;
}) => {
  const leftTextClassName = "text-xs font-medium";
  const rightTextClassName =
    "rounded-md bg-primary-card-backplate px-2 py-[2px] font-bold";

  return (
    <div
      className={`flex items-center justify-between border-b border-secondary-card-backplate px-2 py-3 ${className}`}
    >
      {leftSideText && <p className={leftTextClassName}>{leftSideText}</p>}
      {rightSideText && <p className={rightTextClassName}>{rightSideText}</p>}
    </div>
  );
};
