import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet.tsx";
import {
  PriceRuleActionType,
  usePriceRulesPageContext,
} from "@pages/booking/price-rules/price-rules-context.tsx";
import { Input } from "@primitives/input.tsx";
import PricingGrid from "@pages/booking/price-rules/components/pricing-grid.tsx";
import { Button } from "@primitives/button.tsx";

type PriceProps = {
  open: boolean;
  title: string;
  onSubmit: () => void;
  namePlaceholder: string;
  display: PriceRuleActionType;
};
export const PriceRulesSheet = ({
  title,
  open,
  onSubmit,
  namePlaceholder,
  display,
}: PriceProps) => {
  const {
    sharedState: { bar, priceRules },
    sharedFunctions: { setActionType, setBar },
  } = usePriceRulesPageContext();

  return (
    <Sheet
      open={open}
      onOpenChange={() => setActionType(PriceRuleActionType.DEFAULT)}
    >
      <SheetContent className="w-[690px] overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        <form onSubmit={onSubmit}>
          <Input
            placeholder={namePlaceholder}
            value={bar.name}
            onChange={(e) =>
              setBar((prev) => {
                return {
                  ...prev,
                  name: e.target.value.trim(),
                };
              })
            }
          />
          <PricingGrid
            readOnly={true}
            categories={priceRules.categories}
            display={display}
          />
          <Button type="submit">Send</Button>
        </form>
      </SheetContent>
    </Sheet>
  );
};
