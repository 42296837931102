import React, { useContext, useReducer } from "react";
import { AdminAction } from "./action";
import { adminReducer } from "./reducer";
import { Organization } from "../../../../../api-contracts/organizations";
import { User } from "../../../../../api-contracts/admin";
import { Site } from "../../../../../api-contracts/sites";

export type AdminState = {
  organization: Organization | null;
  site: Site | null;
  user: User | null;
};

type AdminContextT = {
  state: AdminState;
  dispatch: (arg: AdminAction) => void;
};

type reducerFunc = (state: AdminState, action: AdminAction) => AdminState;

const initialContext: AdminContextT = {
  state: { organization: null, site: null, user: null },
  dispatch: () => console.error("Context not initialized"),
};

const AdminContext = React.createContext<AdminContextT>(initialContext);

interface Props {
  children?: React.ReactNode;
}

export const AdminProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer<reducerFunc>(
    adminReducer,
    initialContext.state,
  );
  return (
    <AdminContext.Provider value={{ dispatch, state }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = (): AdminContextT => useContext(AdminContext);
