import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CombinationType,
  CreateCombinationRequest,
  CreateCombinationResponse,
  GetCombinationResponse,
  GetCombinationsGroupedByCategoryResponse,
  GetCombinationsResponse,
  PatchCombinationRequest,
  PatchCombinationResponse,
} from "../../../../api-contracts/combinations";

export const COMBINATIONS_QUERY_KEY = "combinations";

export const useCreateCombination = createMutation<
  CreateCombinationResponse,
  CreateCombinationRequest
>({
  mutationFn: async (data) =>
    api.post(`/api/${currentSite?.id}/${COMBINATIONS_QUERY_KEY}`, data),
});

export const useUpdateCombination = createMutation<
  PatchCombinationResponse,
  { patch: PatchCombinationRequest; id: string }
>({
  mutationFn: async (data) =>
    api.patch(
      `/api/${currentSite?.id}/${COMBINATIONS_QUERY_KEY}/${data.id}`,
      data.patch,
    ),
});

export const useGetCombination = createQuery<
  GetCombinationResponse,
  { id: string }
>({
  primaryKey: COMBINATIONS_QUERY_KEY,
  queryFn: async ({ queryKey: [, { id }] }) =>
    api.get(`/api/${currentSite?.id}/${COMBINATIONS_QUERY_KEY}/${id}`),
});

export const useGetCombinations = createQuery<GetCombinationsResponse>({
  primaryKey: COMBINATIONS_QUERY_KEY,
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${COMBINATIONS_QUERY_KEY}`),
});

export const useGetCombinationsByCategory = createQuery<
  GetCombinationsResponse | GetCombinationsGroupedByCategoryResponse,
  { showByCategory: boolean; types?: CombinationType[] }
>({
  primaryKey: COMBINATIONS_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { showByCategory, types }] }) => {
    const qs = [];
    if (showByCategory) {
      qs.push("by=category");
    }
    if (types?.length) {
      types.forEach((t) => qs.push(`types=${t}`));
    }
    return api.get(
      `/api/${currentSite?.id}/${COMBINATIONS_QUERY_KEY}?${qs.join("&")}`,
    );
  },
});
