import { Card, CardTitle } from "@primitives/card.tsx";
import { Button } from "@primitives/button.tsx";
import { Info, Plus } from "lucide-react";
import {
  ChannelsActionType,
  useChannelsPageContext,
} from "../channels-context.tsx";
import { Tooltip } from "@primitives/tooltip.tsx";
import { useTranslation } from "react-i18next";
import { DefaultListItem } from "@components/default-list.tsx";
import { DefaultAlert } from "@pages/settings/locations/components/locations-list-item.tsx";
import { useState } from "react";
import { useDeleteChannel } from "@api/channels.ts";
import { useSharedContext } from "@context/shared-context.tsx";
import { Channel } from "../../../../../../api-contracts/channels";

export const ChannelsCard = () => {
  const {
    sharedState: { channels },
    sharedFunctions: { setActionType, setCurrent, handleRefetch },
  } = useChannelsPageContext();
  const {
    sharedFunctions: { toast, setLoading },
  } = useSharedContext();
  const { mutate: delChannel } = useDeleteChannel();
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const onEdit = (channel: Channel) => {
    setActionType(ChannelsActionType.EDIT);
    setCurrent(channel);
  };

  const onDelete = (channel: Channel) => {
    setLoading(true);
    delChannel(
      { id: channel.id },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("delete-channel-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          toast({
            variant: "success",
            title: t("channel-deleted", { channel: channel?.name }),
          });
          await handleRefetch();
        },
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  return (
    <Card className="h-full max-w-[500px]">
      <CardTitle className="flex">
        {t("channels")}
        <Tooltip content={"some info here"}>
          <Info size="20" className="ml-1" />
        </Tooltip>
      </CardTitle>
      <div className="py-4">
        <Button
          variant="primary"
          size="icon"
          onClick={() => setActionType(ChannelsActionType.ADD)}
        >
          <Plus size={17} />
        </Button>
      </div>
      <div className="mx-[-1rem] flex h-[calc(100vh-250px)] max-w-[500px] flex-grow flex-col gap-0.5 overflow-y-auto">
        {channels.map((channel) => (
          <DefaultListItem
            key={channel.id}
            title={channel.name}
            onEdit={() => onEdit(channel)}
            onDelete={() => setAlertOpen(true)}
          >
            <DefaultAlert
              alertOpen={alertOpen}
              setAlertOpen={setAlertOpen}
              title={t("are-you-sure-you-want-to-delete-channel", {
                channel: channel.name,
              })}
              description={t("channel-will-be-deleted")}
              primaryButtonText={t("yes-delete-channel", {
                channel: channel.name,
              })}
              onPrimaryButton={() => onDelete(channel)}
            />
          </DefaultListItem>
        ))}
      </div>
    </Card>
  );
};
