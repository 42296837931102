import { t } from "i18next";
import { Button } from "@primitives/button.tsx";
import {
  AllBridgeDeviceTypes,
  BridgeDeviceType,
  BridgeSheetState,
  useBridgesPageContext,
} from "../bridges-context.tsx";
import { FormField } from "@primitives/form-field.tsx";
import { Input } from "@primitives/input.tsx";
import { Form } from "react-router-dom";
import { BridgeDevice } from "../../../../../../api-contracts/bridges";
import { queryClient } from "../../../../query-client.ts";
import { useGetBridges } from "@api/bridges.ts";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@primitives/select.tsx";
import { SelectValue } from "@radix-ui/react-select";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";

interface DeviceModelInputStructure {
  requiresKey?: string;
  requiresValue?: string;
  key: string;
  unique: string;
  type: string;
  arrayKeyName?: string;
  dropDownOptions?: { value: string; pretty: string }[];
}

export const DeviceModels: Map<string, DeviceModelInputStructure[]> = new Map([
  [
    BridgeDeviceType.PRINTER,
    [
      {
        key: "type",
        unique: "printer-type",
        type: "drop-down",
        dropDownOptions: [
          { value: "text-file", pretty: "Text File" },
          { value: "windows-queue", pretty: "Windows Queue" },
        ],
      },
      {
        requiresKey: "type",
        requiresValue: "windows-queue",
        key: "queueName",
        unique: "printer-queue-name",
        type: "text",
      },
    ],
  ],
  [
    BridgeDeviceType.CONTROL_UNIT,
    [
      {
        key: "type",
        unique: "control-unit-type",
        type: "drop-down",
        dropDownOptions: [
          { value: "axena-posplus", pretty: "Axena Posplus" },
          { value: "axena-westint", pretty: "Axena Westint" },
          { value: "bmc-etax", pretty: "Bmc Etax" },
        ],
      },
      { key: "comPort", unique: "control-unit-com-port", type: "text" },
    ],
  ],
  [
    BridgeDeviceType.PAYMENT,
    [
      {
        key: "type",
        unique: "payment-unit-type",
        type: "drop-down",
        dropDownOptions: [{ value: "surfboard", pretty: "Surfboard" }],
      },
      { key: "terminalId", unique: "payment-unit-terminal-id", type: "text" },
    ],
  ],
]);
export const ManageDeviceSheet = () => {
  const {
    selectedDevice,
    selectedBridge,
    setSheetState,
    sendCreateDeviceCommand,
    sendUpdateDeviceCommand,
    sheetState,
  } = useBridgesPageContext();

  const upsertedDevice = () => {
    queryClient.invalidateQueries({
      queryKey: useGetBridges.getKey(),
    });
    setSheetState(BridgeSheetState.CLOSED);
  };

  const [newDevice, setNewDevice] = useState<Omit<BridgeDevice, "id">>({
    name: "",
    type: "",
    config: {
      deviceId: "",
      type: "",
      settings: {},
    },
  });

  const resetDevice = () => {
    setNewDevice({
      name: selectedDevice?.name || "",
      type: selectedDevice?.type || "",
      config: selectedDevice?.config || {
        type: "",
        settings: {},
      },
    });
  };

  useEffect(() => {
    resetDevice();
  }, [selectedDevice]);

  const setDeviceConfig = (key: string, value: string) => {
    switch (key) {
      case "type":
        setNewDevice({
          ...newDevice,
          config: {
            ...newDevice.config,
            type: value,
          },
        });
        break;
      case "queueName":
      case "comPort":
      case "terminalId":
        setNewDevice({
          ...newDevice,
          config: {
            ...newDevice.config,
            settings: {
              ...newDevice.config.settings,
              [key]: value,
            },
          },
        });
    }
  };

  const getDeviceConfigString = (key: string): string => {
    switch (key) {
      case "type":
        return newDevice.config.type || "";
      case "queueName":
        return newDevice.config.settings.queueName || "";
      case "comPort":
        return newDevice.config.settings.comPort || "";
      case "terminalId":
        return newDevice.config.settings.terminalId || "";
    }

    return "";
  };

  const hasValue = (
    value: string | undefined,
    shouldHave: string | undefined,
  ): boolean => {
    return (
      value !== undefined && (shouldHave === undefined || shouldHave === value)
    );
  };

  const hasRequirement = (
    key: string | undefined,
    value: string | undefined,
  ): boolean => {
    if (key === undefined) {
      return true;
    }
    switch (key) {
      case "type":
        return hasValue(newDevice.config.type, value);
    }

    return false;
  };

  const containsValidModelInfo = () => {
    const model = DeviceModels.get(newDevice.type);
    return (
      model !== undefined &&
      model.filter((req) => {
        if (req.requiresKey !== undefined) {
          const hasValue = getDeviceConfigString(req.requiresKey);
          if (hasValue === "" || hasValue !== req.requiresValue) {
            return false;
          }
        }
        const configValue = getDeviceConfigString(req.key);
        return configValue === undefined || configValue === "";
      }).length === 0
    );
  };

  const hasValidDeviceInfo = () => {
    return (
      newDevice.name !== "" && newDevice.type !== "" && containsValidModelInfo()
    );
  };

  const submitForm = () => {
    if (hasValidDeviceInfo()) {
      if (selectedDevice === null) {
        sendCreateDeviceCommand(selectedBridge!.id, newDevice, upsertedDevice);
      } else {
        sendUpdateDeviceCommand(
          selectedBridge!.id,
          selectedDevice.id,
          newDevice,
          upsertedDevice,
        );
      }
      resetDevice();
    }
  };

  return (
    <DefaultSideSheet
      title={selectedDevice === null ? t("add-device") : t("edit-device")}
      open={sheetState === BridgeSheetState.MANAGE_DEVICE}
      onOpenChange={() => {
        resetDevice();
        setSheetState(BridgeSheetState.CLOSED);
      }}
    >
      <Form
        onSubmit={() => submitForm()}
        className="flex flex-grow flex-col justify-between p-[18px] pt-0"
      >
        <div>
          <div className={"flex flex-col"}>
            <FormField.Label htmlFor="DeviceType" className="pb-2">
              <span>{t("name")}</span>
              <span className={"pl-1 text-red-600"}>*</span>
            </FormField.Label>
            <Input
              className="w-full border"
              name="BridgeName"
              type="text"
              required
              value={newDevice.name}
              placeholder={t("name")}
              onChange={(e) =>
                setNewDevice({
                  ...newDevice,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className={"flex flex-col"}>
            <FormField.Label htmlFor="DeviceType" className="pb-2 pt-4">
              <span>{t("device-type")}</span>
              <span className={"pl-1 text-red-600"}>*</span>
            </FormField.Label>
            <Select
              name={"DeviceType"}
              required
              value={newDevice.type}
              onValueChange={(type) =>
                setNewDevice({
                  ...newDevice,
                  type: type,
                  config: {
                    type: "",
                    settings: {},
                  },
                })
              }
            >
              <SelectTrigger className="font-normal">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className={"z-[200]"}>
                {AllBridgeDeviceTypes.map((b) => (
                  <SelectItem key={b.value} value={b.value} className="text-xs">
                    {b.prettyName}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {newDevice.type &&
            DeviceModels.get(newDevice.type)!.map((model) => (
              <div key={model.key} className={"flex flex-col"}>
                {hasRequirement(model.requiresKey, model.requiresValue) && (
                  <>
                    <FormField.Label htmlFor={model.key} className="pb-2 pt-4">
                      <span>{t(`device-${model.key}`)}</span>
                      <span className={"pl-1 text-red-600"}>*</span>
                    </FormField.Label>
                    {model.type === "text" && (
                      <Input
                        className="w-full border"
                        key={model.unique}
                        name={model.key}
                        type="text"
                        required
                        value={getDeviceConfigString(model.key)}
                        placeholder={t(`device-${model.key}`)}
                        onChange={(e) =>
                          setDeviceConfig(model.key, e.target.value)
                        }
                      />
                    )}
                    {model.type === "drop-down" && (
                      <Select
                        key={model.unique}
                        name={model.key}
                        required
                        value={getDeviceConfigString(model.key)}
                        onValueChange={(value) =>
                          setDeviceConfig(model.key, value)
                        }
                      >
                        <SelectTrigger className="font-normal">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent className={"z-[200]"}>
                          {model.dropDownOptions!.map((b) => (
                            <SelectItem
                              key={b.value}
                              value={b.value}
                              className="text-xs"
                            >
                              {b.pretty}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  </>
                )}
              </div>
            ))}
        </div>
        <div className="flex w-full justify-end">
          <Button
            variant="primary"
            className="w-fit"
            type="submit"
            disabled={!hasValidDeviceInfo()}
          >
            {selectedDevice === null ? t("add") : t("save")}
          </Button>
        </div>
      </Form>
    </DefaultSideSheet>
  );
};
