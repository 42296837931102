import { Button } from "@primitives/button";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label";
import {
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import {
  FilterActiveStatus,
  useCategoriesContext,
} from "../categories-context";
import { Column } from "@tanstack/react-table";
import { FilterIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { SearchBarWithIcon } from "@primitives/search-bar-with-icon";
import { t } from "i18next";
import { SearchBar } from "@primitives/search-bar";

interface ColumnVisibilityToggleProps<RowData> {
  columns: Column<RowData, unknown>[];
}

const ColumnVisibilityToggle = <RowData,>({
  columns,
}: ColumnVisibilityToggleProps<RowData>) => {
  const parentColumns = columns.filter((col) => !col.id.includes("available"));
  const availableColumns = columns.filter((col) =>
    col.id.includes("available"),
  );

  const allAvailableVisible = availableColumns.every((availableCol) =>
    availableCol.getIsVisible(),
  );

  const labelText = allAvailableVisible ? t("deselect-all") : t("select-all");

  const isNameVisible = columns
    .find((col) => col.id === "name")
    ?.getIsVisible();
  const isShortVisible = columns
    .find((col) => col.id === "short")
    ?.getIsVisible();

  const isNameDisabled = isNameVisible && !isShortVisible;
  const isShortDisabled = isShortVisible && !isNameVisible;

  return (
    <div className="px-2">
      {parentColumns.map((col) => {
        if (col.columnDef.enableHiding === false) return null;
        const label = col?.columnDef?.header?.toString();
        const isDisabled =
          (col.id === "name" && isNameDisabled) ||
          (col.id === "short" && isShortDisabled);

        return (
          <div key={col.id} className="flex h-[32px] items-center">
            <CheckboxWithLabel
              disabled={isDisabled}
              key={col.id}
              label={label}
              checked={col.getIsVisible()}
              onCheckedChange={() => col.toggleVisibility()}
            />
          </div>
        );
      })}
      {availableColumns.length > 0 && (
        <DropdownMenuSub>
          <DropdownMenuSubTrigger className="px-[-8px]">
            <div className="flex h-[32px] items-center">
              <CheckboxWithLabel
                label={t("connected-channels")}
                checked={availableColumns.some((availableCol) =>
                  availableCol.getIsVisible(),
                )}
                onCheckedChange={(checked) =>
                  availableColumns.forEach((availableCol) => {
                    if (availableCol.getIsVisible() !== checked) {
                      availableCol.toggleVisibility();
                    }
                  })
                }
              />
            </div>
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent className="px-2">
              {availableColumns.map((col) => {
                const label = col?.columnDef?.header?.toString();
                return (
                  <div key={col.id} className="flex h-[32px] items-center">
                    <CheckboxWithLabel
                      key={col.id}
                      label={label}
                      checked={col.getIsVisible()}
                      onCheckedChange={() => col.toggleVisibility()}
                    />
                  </div>
                );
              })}
              <DropdownMenuSeparator />
              <DropdownMenuLabel
                className="cursor-pointer text-center"
                onClick={() => {
                  const newCheckedState = allAvailableVisible ? false : true;
                  availableColumns.forEach((availableCol) => {
                    if (availableCol.getIsVisible() !== newCheckedState) {
                      availableCol.toggleVisibility();
                    }
                  });
                }}
              >
                {labelText}
              </DropdownMenuLabel>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      )}
    </div>
  );
};

// Not used in new design - Keeping if they change their mind
// const IntervalFilters = () => {
//   const {
//     capacityValue,
//     setCapacityValue,
//     minCapacity,
//     maxCapacity,
//     minBasePrice,
//     maxBasePrice,
//     basePriceValue,
//     setBasePriceValue,
//   } = useCategoriesContext();

//   const resetFilters = () => {
//     setCapacityValue([minCapacity, maxCapacity]);
//     setBasePriceValue([minBasePrice, maxBasePrice]);
//   };

//   useEffect(() => {
//     resetFilters();
//   }, []);

//   const isCapacityChanged =
//     capacityValue[0] !== minCapacity || capacityValue[1] !== maxCapacity;

//   const isFilterChanged =
//     isCapacityChanged ||
//     basePriceValue[0] !== minBasePrice ||
//     basePriceValue[1] !== maxBasePrice;

//   return (
//     <DropdownMenuRoot>
//       <DropdownMenuTrigger asChild>
//         <Button variant="secondary">
//           <div className="flex flex-row items-center">
//             <PlusCircleIcon
//               size="15px"
//               className={`mr-1 ${isFilterChanged && "text-primary-color"}`}
//             />
//             <p>{t("interval")}</p>
//           </div>
//           {isCapacityChanged && (
//             <div className="ml-1 flex rounded-md bg-secondary-card-backplate p-1 px-2">
//               <span className="text-xs text-secondary-text">{`${t("capacity")}: ${capacityValue[0]}-${capacityValue[1]}`}</span>
//             </div>
//           )}
//         </Button>
//       </DropdownMenuTrigger>

//       <DropdownMenuContent className="w-[300px] text-sm" align="end">
//         <Accordion type="single" collapsible className="w-full px-4">
//           <AccordionItem value="item-1">
//             <AccordionTrigger>{t("max-capacity")}</AccordionTrigger>
//             <AccordionContent>
//               <GuestCapacityInput
//                 capacityValue={capacityValue}
//                 setCapacityValue={setCapacityValue}
//                 minCapacity={minCapacity}
//                 maxCapacity={maxCapacity}
//               />
//             </AccordionContent>
//           </AccordionItem>
//           <AccordionItem value="item-2" className="border-b-0">
//             <AccordionTrigger>{t("rack-rate")}</AccordionTrigger>
//             <AccordionContent>
//               <BasePriceInput
//                 basePriceValue={basePriceValue}
//                 setBasePriceValue={setBasePriceValue}
//                 minBasePrice={minBasePrice}
//                 maxBasePrice={maxBasePrice}
//               />
//             </AccordionContent>
//           </AccordionItem>
//         </Accordion>
//         {isFilterChanged && (
//           <div
//             onClick={() => resetFilters()}
//             className="mx-4 flex cursor-pointer flex-col items-center border-t py-4 font-medium transition-all hover:underline"
//           >
//             <p>{t("clear-filtering")}</p>
//           </div>
//         )}
//       </DropdownMenuContent>
//     </DropdownMenuRoot>
//   );
// };

const GroupsFilters = () => {
  const { setSelectedGroupIds, selectedGroupIds, uniqueGroups } =
    useCategoriesContext();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const handleGroupSelection = (groupId: string) => {
    setSelectedGroupIds((prevSelectedIds) =>
      prevSelectedIds.includes(groupId)
        ? prevSelectedIds.filter((id) => id !== groupId)
        : [...prevSelectedIds, groupId],
    );
  };

  const filteredGroups = uniqueGroups.filter((group) =>
    group.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const toggleAllGroups = () => {
    setIsAllSelected(!isAllSelected);

    if (!isAllSelected) {
      const allGroupIds = uniqueGroups.map((group) => group.id);
      setSelectedGroupIds(allGroupIds);
    } else {
      setSelectedGroupIds([]);
    }
  };

  useEffect(() => {
    setIsAllSelected(selectedGroupIds.length === uniqueGroups.length);
  }, [selectedGroupIds, uniqueGroups]);

  const labelText = isAllSelected ? t("deselect-all") : t("select-all");

  return (
    <>
      <div className="flex flex-col">
        <SearchBar
          className="border-0 text-sm"
          placeholder={t("search-group-placeholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <DropdownMenuSeparator />
        <div className="px-2 ">
          {filteredGroups.length === 0 ? (
            <p className="text-center">{t("no-results")}.</p>
          ) : (
            <>
              {filteredGroups.map((group) => (
                <div
                  key={group.id}
                  className="flex h-[32px] flex-row items-center justify-between"
                >
                  <CheckboxWithLabel
                    id={group.id}
                    checked={selectedGroupIds.includes(group.id)}
                    label={group.name}
                    onCheckedChange={() => handleGroupSelection(group.id)}
                  />
                  <span className="text-xs">{group.count}</span>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <>
        <DropdownMenuSeparator />
        <DropdownMenuLabel
          className="cursor-pointer text-center"
          onClick={() => toggleAllGroups()}
        >
          {labelText}
        </DropdownMenuLabel>
      </>
    </>
  );
};

const ActiveFilter = () => {
  const { filterActiveStatus, setFilterActiveStatus } = useCategoriesContext();
  const handleChange = (status: keyof FilterActiveStatus, checked: boolean) => {
    setFilterActiveStatus((prevStatus) => ({
      ...prevStatus,
      [status]: checked,
    }));
  };

  const isActiveDisabled =
    filterActiveStatus.active && !filterActiveStatus.inactive;
  const isInactiveDisabled =
    filterActiveStatus.inactive && !filterActiveStatus.active;

  return (
    <div className="px-2 pt-1">
      <div className="h-[32px] items-center">
        <CheckboxWithLabel
          disabled={isActiveDisabled}
          checked={filterActiveStatus.active}
          onCheckedChange={(checked: boolean) =>
            handleChange("active", checked)
          }
          label={t("active")}
        />
      </div>
      <div className="h-[32px] items-center">
        <CheckboxWithLabel
          disabled={isInactiveDisabled}
          checked={filterActiveStatus.inactive}
          onCheckedChange={(checked: boolean) =>
            handleChange("inactive", checked)
          }
          label={t("inactive")}
        />
      </div>
    </div>
  );
};

const MultiFilters = <RowData,>({
  columns,
}: ColumnVisibilityToggleProps<RowData>) => {
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary">
          <FilterIcon size="15px" className={`mr-1`} />
          <p>{t("show")}</p>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="flex flex-col" align="end">
        <DropdownMenuLabel className="ml-5">{t("status")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <ActiveFilter />
        <DropdownMenuSeparator />

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <DropdownMenuLabel className="ml-3">
              {t("grouping")}
            </DropdownMenuLabel>
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              <GroupsFilters />
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
        <DropdownMenuSeparator />
        <DropdownMenuLabel className="ml-5">{t("columns")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <ColumnVisibilityToggle columns={columns} />
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

export const TableActionGroup = <RowData,>({
  columns,
}: ColumnVisibilityToggleProps<RowData>) => {
  const { setSearchTerm, searchTerm } = useCategoriesContext();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="flex w-full items-center justify-end">
      <SearchBarWithIcon
        isExpanded={isExpanded}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setIsExpanded={setIsExpanded}
      />
      <div className="ml-2">
        <MultiFilters columns={columns} />
      </div>
    </div>
  );
};
