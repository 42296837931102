import { useState } from "react";
import {
  LocationsAction,
  useLocationsPageContext,
} from "../locations-context.tsx";
import {
  createToastMessageOnMove,
  findLocationById,
  getFilter,
  getPathForDisplay,
  sortByTitle,
} from "../locations-utils.tsx";
import { LocationProps } from "../../../settings/locations/components/locations-hierarchy-tree.tsx";
import { LocationsSettingsMenu } from "../../../settings/locations/components/locations-settings-menu.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { ListItemWithButton } from "@primitives/list-item-with-button.tsx";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const MoveLocation = () => {
  const {
    sharedState: { locations, currentLocation, actionType },
    sharedFunctions: {
      updatingLocation,
      setActionType,
      setCurrentLocation,
      refetchLocations,
    },
  } = useLocationsPageContext();
  const {
    sharedFunctions: { toast },
  } = useSharedContext();
  const { t } = useTranslation();

  const locationsWithRoot = [...locations];

  currentLocation?.location.partOf != null &&
    locationsWithRoot.push({
      location: {
        id: "root",
        name: locations[0].location.siteName,
        siteName: locations[0].location.siteName,
        partOf: null,
      },
      isRoot: true,
      path: null,
      isOpen: true,
    });

  const defaultFiltered = locationsWithRoot
    .filter(
      (it) =>
        it.location.id != currentLocation?.location.id &&
        it.location.id != currentLocation?.location.partOf &&
        currentLocation &&
        !it.path?.includes(currentLocation?.location.name),
    )
    .sort((a, b) => sortByTitle(a.location, b.location, a.location.siteName));

  const [filtered, setFiltered] = useState<LocationProps[]>(defaultFiltered);
  const [filteredBySearch, setFilteredBySearch] =
    useState<LocationProps[]>(defaultFiltered);

  const onClick = (data: LocationProps) => {
    if (currentLocation) {
      updatingLocation(
        {
          ...currentLocation,
          location: {
            ...currentLocation.location,
            partOf: data.location.id === "root" ? null : data.location.id,
          },
        },
        data.location.id,
        onSuccess,
      );
    }
  };

  const onSuccess = async (id?: string, parent?: string | null) => {
    await refetchLocations();

    const parentLocation = parent ? findLocationById(parent, locations) : null;
    parentLocation && setCurrentLocation(() => parentLocation);
    currentLocation &&
      parentLocation &&
      id &&
      toast({
        variant: "success",
        title: createToastMessageOnMove(
          parentLocation.location.name,
          currentLocation,
          locations,
          t,
        ),
      });
  };

  const onClickFilter = (filterIds: string[]) => {
    filterIds.length === 0 && setFiltered(defaultFiltered);
    filterIds.length > 0 &&
      setFiltered(
        locationsWithRoot.filter(({ location }) =>
          filterIds.includes(location.id),
        ),
      );
  };

  const onInputSearch = (value: string) => {
    value === "" && setFilteredBySearch(locationsWithRoot);
    value != "" &&
      setFilteredBySearch(
        locationsWithRoot.filter((node) => node.location.name.includes(value)),
      );
  };

  return (
    <DefaultSideSheet
      title={`${t("move")} ${currentLocation?.location.name}`}
      description={`${t("from")} ${
        currentLocation?.location.partOf
          ? findLocationById(currentLocation?.location.partOf, locations)
              ?.location.name
          : currentLocation?.location.siteName
      }`}
      open={actionType === LocationsAction.LOCATION_MOVE}
      onOpenChange={() => setActionType(LocationsAction.DEFAULT)}
      side="left"
      className={"overflow-y-auto"}
    >
      <>
        <LocationsSettingsMenu
          filters={defaultFiltered}
          onSearchInput={onInputSearch}
          onAddFilter={onClickFilter}
          filterMenuLabel={t("filter-locations")}
        />
        <p className="pb-0 text-xs font-bold">{t("locations")} </p>
        <div className="border-gray-150 flex-grow overflow-auto rounded-md border-[1.5px]">
          {getFilter(filtered, filteredBySearch).map((item, i) => (
            <ListItemWithButton
              key={i}
              title={item.location.name}
              subtitle={getPathForDisplay(item)}
              buttonText={t("move")}
              onClick={() => onClick(item)}
            />
          ))}
        </div>
      </>
    </DefaultSideSheet>
  );
};
