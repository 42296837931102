/* Color not defined in Figma color variables - Using purple-200 for now */
export const HighlightText = ({
  text,
  textToHighlight,
}: {
  text: string;
  textToHighlight: string;
}) => {
  const parts = text.split(new RegExp(`(${textToHighlight})`, "gi"));
  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          className={
            part.toLowerCase() === textToHighlight.toLowerCase()
              ? "bg-purple-200"
              : ""
          }
        >
          {part}
        </span>
      ))}
    </>
  );
};
