import {
  BedAccessory,
  BedAgeSpanSettings,
  Bedlinen,
  BedType,
  CreateBedAccessoryRequest,
  CreateBedlinenRequest,
  CreateBedTypeRequest,
  UpsertBedAgeSpanSettingsRequest,
} from "../../../../../api-contracts/bed-capacity";
import { queryClient } from "../../../query-client.ts";
import {
  useDeleteAccessory,
  useDeleteLinen,
  useDeletetBed,
  useGetBedAccessory,
  useGetBedAgeSpanSettings,
  useGetBedLinen,
  useGetBedTypes,
  usePatchAccessorydById,
  usePatchBedById,
  usePatchLinenById,
  usePostAccessory,
  usePostAgeRangeRuleSetting,
  usePostBed,
  usePostLinen,
} from "@api/bed-types.ts";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";

export const useBedData = () => {
  const { t } = useTranslation();
  const { mutateAsync: postBed } = usePostBed();
  const { mutateAsync: patchBed } = usePatchBedById();
  const { mutateAsync: deleteBedById } = useDeletetBed();
  const { mutateAsync: postAgeRangeSetting } = usePostAgeRangeRuleSetting();
  const { mutateAsync: postAccessory } = usePostAccessory();
  const { mutateAsync: patchAccessory } = usePatchAccessorydById();
  const { mutateAsync: deleteAccessoryById } = useDeleteAccessory();
  const { mutateAsync: postLinen } = usePostLinen();
  const { mutateAsync: patchLinen } = usePatchLinenById();
  const { mutateAsync: deleteLinen } = useDeleteLinen();

  const createBed = async (bed: CreateBedTypeRequest) => {
    try {
      await postBed(bed);
      await queryClient.invalidateQueries({
        queryKey: useGetBedTypes.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: bed.name }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const createLinen = async (linen: CreateBedlinenRequest) => {
    try {
      await postLinen(linen);
      await queryClient.invalidateQueries({
        queryKey: useGetBedLinen.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: linen.name }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const createAccessory = async (acc: CreateBedAccessoryRequest) => {
    try {
      await postAccessory(acc);
      await queryClient.invalidateQueries({
        queryKey: useGetBedAccessory.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: acc.name }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const updateBed = async (bed: Partial<BedType>, id: string) => {
    try {
      await patchBed({ id: id, patch: bed });
      await queryClient.invalidateQueries({
        queryKey: useGetBedTypes.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const updateAccessory = async (acc: Partial<BedAccessory>, id: string) => {
    try {
      await patchAccessory({ id: id, patch: acc });
      await queryClient.invalidateQueries({
        queryKey: useGetBedAccessory.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const updateLinen = async (acc: Partial<Bedlinen>, id: string) => {
    try {
      await patchLinen({ id: id, patch: acc });
      await queryClient.invalidateQueries({
        queryKey: useGetBedLinen.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const deleteBed = async (id: string) => {
    try {
      await deleteBedById({ id: id });
      await queryClient.invalidateQueries({
        queryKey: useGetBedTypes.getKey(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLinenById = async (id: string) => {
    try {
      await deleteLinen({ id: id });
      await queryClient.invalidateQueries({
        queryKey: useGetBedLinen.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
      console.log(error);
    }
  };

  const deleteAccessory = async (id: string) => {
    try {
      await deleteAccessoryById({ id: id });
      await queryClient.invalidateQueries({
        queryKey: useGetBedAccessory.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
      console.log(error);
    }
  };

  const postSetting = async (setting: UpsertBedAgeSpanSettingsRequest) => {
    try {
      await postAgeRangeSetting(setting);
      await queryClient.invalidateQueries({
        queryKey: useGetBedAgeSpanSettings.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: "Åldersspann" }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  return {
    updateBed,
    createBed,
    deleteBed,
    postSetting,
    createAccessory,
    updateAccessory,
    deleteAccessory,
    createLinen,
    updateLinen,
    deleteLinenById,
  };
};
