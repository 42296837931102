import { t } from "i18next";
import { Button } from "@primitives/button.tsx";
import {
  BridgeSheetState,
  useBridgesPageContext,
} from "../bridges-context.tsx";
import { FormField } from "@primitives/form-field.tsx";
import { Input } from "@primitives/input.tsx";
import { Form } from "react-router-dom";
import { useEffect, useState } from "react";
import { UpdateBridgeRequest } from "../../../../../../api-contracts/bridges";
import { DefaultSideSheet } from "@primitives/default-sheet";

export const EditBridgeSheet = () => {
  const {
    setSheetState,
    sheetState,
    sendUpdateBridgeCommand,
    selectedBridge,
    setSelectedBridge,
  } = useBridgesPageContext();

  const [bridge, setBridge] = useState<UpdateBridgeRequest>({
    name: selectedBridge?.settings.name || "",
    description: selectedBridge?.settings.description || "",
    receipt: selectedBridge?.settings.receipt || {
      header: "",
      footer: "",
      base64Logo: null,
    },
  });

  useEffect(() => {
    setBridge({
      name: selectedBridge?.settings.name || "",
      description: selectedBridge?.settings.description || "",
      receipt: selectedBridge?.settings.receipt || {
        header: "",
        footer: "",
        base64Logo: null,
      },
    });
  }, [selectedBridge]);

  const hasValidBridgeInfo = () => {
    return bridge.name !== "";
  };

  const submitForm = () => {
    if (hasValidBridgeInfo() && selectedBridge) {
      sendUpdateBridgeCommand(selectedBridge.id, bridge, setSelectedBridge);
      setSheetState(BridgeSheetState.CLOSED);
    }
  };

  return (
    <DefaultSideSheet
      title={t("edit-bridge")}
      open={sheetState === BridgeSheetState.UPDATE_BRIDGE}
      onOpenChange={() => setSheetState(BridgeSheetState.CLOSED)}
    >
      <Form
        onSubmit={() => submitForm()}
        className="flex flex-grow flex-col justify-between p-[18px] pt-0"
      >
        <div>
          <div className={"flex flex-col"}>
            <FormField.Label htmlFor="BridgeName" className="pb-2">
              <span>{t("name")}</span>
              <span className={"pl-1 text-red-600"}>*</span>
            </FormField.Label>
            <Input
              className="w-full border"
              required
              name="BridgeName"
              type="text"
              value={bridge.name}
              placeholder={t("name")}
              onChange={(e) =>
                setBridge({
                  ...bridge,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className={"flex flex-col"}>
            <FormField.Label htmlFor="BridgeDescription" className="pb-2 pt-4">
              <span>{t("description")}</span>
            </FormField.Label>
            <Input
              className="w-full border"
              name="BridgeDescription"
              type="text"
              value={bridge.description}
              placeholder={t("description-voluntary")}
              onChange={(e) =>
                setBridge({
                  ...bridge,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className={"mt-8 border-t-2 pt-2 text-lg"}>
            {t("bridge-receipt")}
          </div>
          <div className={"flex flex-col"}>
            <FormField.Label
              htmlFor="BridgeReceiptHeader"
              className="pb-2 pt-4"
            >
              <span>{t("bridge-receipt-header")}</span>
            </FormField.Label>
            <Input
              className="w-full border"
              name="BridgeReceiptHeader"
              type="text"
              value={bridge.receipt.header || ""}
              placeholder={t("bridge-receipt-header")}
              onChange={(e) =>
                setBridge({
                  ...bridge,
                  receipt: {
                    ...bridge.receipt,
                    header: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className={"flex flex-col"}>
            <FormField.Label
              htmlFor="BridgeReceiptFooter"
              className="pb-2 pt-4"
            >
              <span>{t("bridge-receipt-footer")}</span>
            </FormField.Label>
            <Input
              className="w-full border"
              name="BridgeReceiptFooter"
              type="text"
              value={bridge.receipt.footer || ""}
              placeholder={t("bridge-receipt-footer")}
              onChange={(e) =>
                setBridge({
                  ...bridge,
                  receipt: {
                    ...bridge.receipt,
                    footer: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className={"flex flex-col"}>
            <FormField.Label htmlFor="BridgeReceiptLogo" className="pb-2 pt-4">
              <span>{t("bridge-receipt-image")}</span>
            </FormField.Label>
            <Input
              className="w-full border"
              name="BridgeReceiptLogo"
              type="text"
              value={bridge.receipt.base64Logo || ""}
              placeholder={t("bridge-receipt-image") + " (base-64 encoded)"}
              onChange={(e) =>
                setBridge({
                  ...bridge,
                  receipt: {
                    ...bridge.receipt,
                    base64Logo: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="flex w-full justify-end">
          <Button className="w-fit" type="submit">
            {t("save")}
          </Button>
        </div>
      </Form>
    </DefaultSideSheet>
  );
};
