export const CategoryDetailCard = ({
  title = "",
  children = <></>,
  className = "",
}) => {
  return (
    <div
      className={`w-1/4 rounded-md border border-main-border-color bg-secondary-card-backplate p-4 shadow-sm ${className}`}
    >
      {title && (
        <h3 className="mb-4 text-base font-bold text-primary-text">{title}</h3>
      )}
      {children}
    </div>
  );
};
