import { Card } from "@primitives/card";
import { CardHeader } from "./components/card-header";
import { TagTable } from "./components/tag-table";
import {
  CreateTagRequest,
  CreateTagResponse,
  ExtendedTag,
  PatchTagRequest,
  PatchTagResponse,
} from "../../../../../api-contracts/tags";
import { useEffect, useState } from "react";
import { TagSheet } from "./components/tag-sheet";
import {
  useDeleteTag,
  useGetTags,
  usePatchTagById,
  usePostTag,
} from "@api/tags";
import { toast } from "@hooks/use-toast";
import { t } from "i18next";
import { queryClient } from "query-client";
import { Loading } from "@primitives/loading";

const initialTagState = {
  id: "",
  name: "",
  short: "",
  color: "#FEDB99",
  active: true,
  external: false,
  inUse: false,
};

const TagsPage = () => {
  const [openSheet, setOpenSheet] = useState<boolean>(false);
  const [tagToEdit, setTagToEdit] = useState<ExtendedTag>(initialTagState);
  const [activeTab, setActiveTab] = useState<string>("active");
  const [tags, setTags] = useState<ExtendedTag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const postTag = usePostTag();
  const patchTag = usePatchTagById();
  const deleteTagById = useDeleteTag();

  const { data: tagsData, isLoading: tagsLoading } = useGetTags();

  const filterByActive = (tags: ExtendedTag[]) => {
    const filteredData = tags.filter((tag) =>
      activeTab === "active" ? tag.active : !tag.active,
    );

    setTags(filteredData);
  };

  const onCreateNewTag = () => {
    setTagToEdit(initialTagState);
    setOpenSheet(true);
  };

  const onEditTag = (tag: ExtendedTag) => {
    setTagToEdit(tag);
    setOpenSheet(true);
  };

  const filterTags = async () => {
    if (tagsData) {
      filterByActive(tagsData);
    } else {
      setTags([]);
    }
  };

  useEffect(() => {
    filterTags();
  }, [activeTab, tagsData]);

  useEffect(() => {
    setIsLoading(tagsLoading);
  }, [tagsLoading]);

  const createTag = async (tag: CreateTagRequest) => {
    setIsLoading(true);
    try {
      const response = await postTag.mutateAsync(tag);
      queryClient.invalidateQueries({
        queryKey: usePostTag.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: tag.name }),
        variant: "success",
      });
      return response as CreateTagResponse;
    } catch (error) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(
            decodeURIComponent(
              (error instanceof Error && error.message) || t("no-message"),
            ),
          ),
        className: "text-status-error",
      });
      return {} as CreateTagResponse;
    } finally {
      setIsLoading(false);
    }
  };

  const updateTag = async (id: string, patch: PatchTagRequest) => {
    setIsLoading(true);
    try {
      await patchTag.mutateAsync({ patch, id });
      queryClient.invalidateQueries({
        queryKey: usePostTag.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: patch.name }),
        className: "text-status-success",
        variant: "success",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(
            decodeURIComponent(
              (error instanceof Error && error.message) || t("no-message"),
            ),
          ),
        className: "text-status-error",
      });
      return {} as PatchTagResponse;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTag = async (id: string) => {
    setIsLoading(true);
    try {
      await deleteTagById.mutateAsync({
        id,
      });
      queryClient.invalidateQueries({
        queryKey: useDeleteTag.getKey(),
      });
      toast({
        title: t("deleted"),
        className: "text-status-success",
        variant: "success",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(
            decodeURIComponent(
              (error instanceof Error && error.message) || t("no-message"),
            ),
          ),
        className: "text-status-error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (tag: ExtendedTag) => {
    const isNewTag = tag.id.length < 1;
    const { id, inUse, ...tagWithoutId } = tag;

    if (isNewTag) {
      await createTag(tagWithoutId);
    } else {
      await updateTag(tag.id, tagWithoutId);
    }
    setOpenSheet(false);
  };

  return (
    <>
      <div className="h-full p-4">
        {isLoading && <Loading />}

        <Card className="h-full">
          <CardHeader
            onCreateNew={() => onCreateNewTag()}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className="pt-4">
            <TagTable
              tableData={tags}
              onEdit={onEditTag}
              onPatch={updateTag}
              onDelete={deleteTag}
            />
            <TagSheet
              openSheet={openSheet}
              setOpenSheet={setOpenSheet}
              tagObject={tagToEdit}
              onSave={handleSave}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default TagsPage;
