import { Card, CardContent, CardHeader, CardTitle } from "@primitives/card";
import { SearchItem, SearchSelect } from "./search-select";
import { useState } from "react";
import { ToggleGroup, ToggleGroupItem } from "@primitives/toggle-group";
import { Role } from "../../../../../api-contracts/organizations";
import { Button } from "@primitives/button";
import { User } from "../../../../../api-contracts/admin";

export const AddSiteUserCard = (props: {
  users: User[];
  roles: Role[];
  onSubmit: (userId: string, roleIds: { id: string }[]) => void;
}) => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [roleIds, setRoleIds] = useState<string[]>([]);

  const onSelectUser = (item: SearchItem | null) => {
    if (!item) {
      setSelectedUser(null);
    }
    setSelectedUser(props.users?.find((user) => user.id === item?.id) ?? null);
  };

  const onButtonClicked = () => {
    props.onSubmit(
      selectedUser?.id ?? "",
      roleIds.map((id) => ({ id })),
    );
    setSelectedUser(null);
    setRoleIds([]);
  };

  return (
    <Card className="space-y-6 p-4">
      <CardHeader>
        <CardTitle>Add user to site</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <SearchSelect
          payload={
            props.users?.map((user) => ({
              id: String(user.id),
              value: String(user.email),
            })) ?? []
          }
          placeholder={"Search for user emails"}
          trigger={"Select User"}
          empty={"such empty"}
          value={selectedUser?.email ?? ""}
          onSelect={onSelectUser}
        />
        <ToggleGroup
          type="multiple"
          variant="outline"
          className="flex flex-wrap justify-start"
          onValueChange={setRoleIds}
          value={roleIds}
        >
          {props.roles.map((role) => (
            <ToggleGroupItem
              key={role.id}
              value={role.id}
              aria-label={`Toggle role ${role.name}`}
            >
              <span>{role.name}</span>
            </ToggleGroupItem>
          ))}
        </ToggleGroup>
        <Button
          variant={"purple"}
          key={"add" + selectedUser?.id}
          disabled={!selectedUser || !roleIds.length}
          onClick={onButtonClicked}
        >
          Add
        </Button>
      </CardContent>
    </Card>
  );
};
