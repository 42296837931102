import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CalendarDay,
  CalendarYear,
  GetCategoriesType,
  GetPriceRulesPriceRule,
  SetPriceCalendarRequest,
} from "../../../../../api-contracts/price-calendar";
import { useGetPriceCalendar, useSetPriceCalendar } from "@api/price-calendar";
import {
  fetchWeatherForecast,
  generateYearCalendarData,
} from "./price-calendar.utils";
import { useTranslation } from "react-i18next";
import { toast } from "@hooks/use-toast";
import { CategoryType } from "../../../../../api-contracts/categories";
import { queryClient } from "query-client";

interface PriceCalendarContextProviderProps {
  children: ReactNode;
}
export enum Layouts {
  Vertical = "vertical",
  Horizontal = "horizontal",
  Month = "month",
}

interface DataContextType {
  year: number;
  setYear: Dispatch<SetStateAction<number>>;
  nextYear: number;
  previousYear: number;
  month: number;
  setMonth: Dispatch<SetStateAction<number>>;
  selectedDays: CalendarDay[];
  setSelectedDays: Dispatch<SetStateAction<CalendarDay[]>>;
  selectedCategory: CategoryType[];
  setSelectedCategory: Dispatch<SetStateAction<CategoryType[]>>;
  selectedSubCategory: string;
  setSelectedSubCategory: Dispatch<SetStateAction<string>>;
  availablePriceRules: GetPriceRulesPriceRule[];
  setAvailablePriceRules: Dispatch<SetStateAction<GetPriceRulesPriceRule[]>>;
  currentCalendar: CalendarYear | undefined;
  nextCalendar: CalendarYear | undefined;
  nextNextCalendar: CalendarYear | undefined;
  previousCalendar: CalendarYear | undefined;
  isLoading: boolean;
  availableCategories: GetCategoriesType[];
  handlePriceRuleChange: (payload: SetPriceCalendarRequest) => Promise<void>;
  refetchCalendarYearData: () => Promise<any>;
  showWeather: boolean;
  setShowWeather: Dispatch<SetStateAction<boolean>>;
  showPrices: boolean;
  setShowPrices: Dispatch<SetStateAction<boolean>>;
  showOccupancy: boolean;
  setShowOccupancy: Dispatch<SetStateAction<boolean>>;
  layout: Layouts;
  setLayout: Dispatch<SetStateAction<Layouts>>;
}

const ALL_SELECTED_CATEGORY = { id: "all", name: "All selected" };

const initialContextValue: DataContextType = {
  year: 2024,
  nextYear: 2025,
  previousYear: 2023,
  setYear: () => null,
  month: new Date().getMonth(),
  setMonth: () => null,
  selectedDays: [],
  setSelectedDays: () => null,
  selectedCategory: ["room"],
  setSelectedCategory: () => null,
  selectedSubCategory: ALL_SELECTED_CATEGORY.id,
  setSelectedSubCategory: () => null,
  availablePriceRules: [{ name: "", id: "" }],
  setAvailablePriceRules: () => null,
  currentCalendar: {
    year: 2024,
    months: [],
  },
  previousCalendar: {
    year: 2023,
    months: [],
  },
  nextCalendar: {
    year: 2025,
    months: [],
  },
  nextNextCalendar: {
    year: 2026,
    months: [],
  },
  isLoading: true,
  availableCategories: [
    {
      name: "",
      id: "",
    },
  ],
  handlePriceRuleChange: async () => {},
  refetchCalendarYearData: async () => {},
  showWeather: false,
  setShowWeather: () => {},
  showPrices: false,
  setShowPrices: () => {},
  showOccupancy: false,
  setShowOccupancy: () => {},
  layout: Layouts.Vertical,
  setLayout: () => {},
};

export const PriceCalendarContext =
  createContext<DataContextType>(initialContextValue);

export const usePriceCalendarContext = (): DataContextType => {
  const context = useContext(PriceCalendarContext);

  if (context === undefined) {
    throw new Error(
      "usePriceCalendarContext must be used within a PriceCalendarContextProvider",
    );
  }
  return context;
};

export const PriceCalendarContextProvider: FC<
  PriceCalendarContextProviderProps
> = ({ children }) => {
  const { i18n, t } = useTranslation();
  const [month, setMonth] = useState<number>(initialContextValue.month);
  const [selectedDays, setSelectedDays] = useState<CalendarDay[]>(
    initialContextValue.selectedDays,
  );
  const [selectedCategory, setSelectedCategory] = useState<CategoryType[]>(
    initialContextValue.selectedCategory,
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>(
    initialContextValue.selectedSubCategory,
  );

  const [availablePriceRules, setAvailablePriceRules] = useState<
    GetPriceRulesPriceRule[]
  >(initialContextValue.availablePriceRules);

  const [availableCategories, setAvailableCategories] = useState<
    GetCategoriesType[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showOccupancy, setShowOccupancy] = useState<boolean>(false);
  const [showPrices, setShowPrices] = useState<boolean>(false);
  const [showWeather, setShowWeather] = useState<boolean>(false);
  const [layout, setLayout] = useState<Layouts>(() => {
    const savedLayout = localStorage.getItem("priceCalendarLayout");
    return savedLayout ? (savedLayout as Layouts) : Layouts.Vertical;
  });
  const [currentYear, setCurrentYear] = useState<number>(
    initialContextValue.year,
  );
  const [previousYear, setPreviousYear] = useState<number>(currentYear - 1);
  const [nextYear, setNextYear] = useState<number>(currentYear + 1);
  const [previousCalendar, setPreviousCalendar] = useState<CalendarYear>();
  const [currentCalendar, setCurrentCalendar] = useState<CalendarYear>();
  const [nextCalendar, setNextCalendar] = useState<CalendarYear>();
  const [nextNextCalendar, setNextNextCalendar] = useState<CalendarYear>();
  const [weatherData, setWeatherData] = useState(null);

  const { mutateAsync: setPriceRule } = useSetPriceCalendar();

  const categoryTypesQuery =
    selectedCategory.length > 0
      ? `${selectedCategory.join("&types=")}`
      : `${selectedCategory}`;

  const {
    data: currentYearData,
    isLoading: currentYearIsLoading,
    refetch: refetchCurrentYearData,
  } = useGetPriceCalendar({
    variables: {
      startDate: `${currentYear}-01-01`,
      months: 12,
      types: categoryTypesQuery,
      categoryId:
        selectedSubCategory === "all" ? undefined : selectedSubCategory,
    },
  });

  const {
    data: previousYearData,
    isLoading: previousYearIsLoading,
    refetch: refetchPreviousYearData,
  } = useGetPriceCalendar({
    variables: {
      startDate: `${previousYear}-01-01`,
      months: 12,
      types: categoryTypesQuery,
      categoryId:
        selectedSubCategory === "all" ? undefined : selectedSubCategory,
    },
  });

  const {
    data: nextYearData,
    isLoading: nextYearIsLoading,
    refetch: refetchNextYearData,
  } = useGetPriceCalendar({
    variables: {
      startDate: `${nextYear}-01-01`,
      months: 12,
      types: categoryTypesQuery,
      categoryId:
        selectedSubCategory === "all" ? undefined : selectedSubCategory,
    },
  });

  const {
    data: nextNextYearData,
    isLoading: nextNextYearIsLoading,
    refetch: refetchNextNextYearData,
  } = useGetPriceCalendar({
    variables: {
      startDate: `${currentYear + 1}-01-01`,
      months: 12,
      types: categoryTypesQuery,
      categoryId:
        selectedSubCategory === "all" ? undefined : selectedSubCategory,
    },
    enabled: month === 10 || month === 11,
  });

  useEffect(() => {
    localStorage.setItem("priceCalendarLayout", layout);
  }, [layout]);

  useEffect(() => {
    const processCalendarData = async () => {
      setIsLoading(true);

      try {
        if (currentYearData && selectedSubCategory) {
          let weatherDataToUse = weatherData;

          if (!weatherDataToUse) {
            weatherDataToUse = await fetchWeatherForecast();
            setWeatherData(weatherDataToUse);
          }
          const currentCalendarWithData = await generateYearCalendarData(
            currentYearData,
            currentYear,
            i18n.language,
            weatherDataToUse,
          );
          setCurrentCalendar(currentCalendarWithData);
        }

        if (previousYearData) {
          const previousCalendarWithData = await generateYearCalendarData(
            previousYearData,
            previousYear,
            i18n.language,
          );
          setPreviousCalendar(previousCalendarWithData);
        }

        if (nextYearData) {
          const nextCalendarWithData = await generateYearCalendarData(
            nextYearData,
            nextYear,
            i18n.language,
          );
          setNextCalendar(nextCalendarWithData);
        }

        if (nextNextYearData) {
          const nextNextCalendarWithData = await generateYearCalendarData(
            nextNextYearData,
            nextYear + 1,
            i18n.language,
          );
          setNextNextCalendar(nextNextCalendarWithData);
        }

        if (currentYearData?.rules) {
          setAvailablePriceRules([...currentYearData.rules]);
        }

        if (currentYearData?.categories) {
          setAvailableCategories(currentYearData.categories);
        }
      } catch (error) {
        console.error("Error generating calendar data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    processCalendarData();
  }, [
    currentYearData,
    previousYearData,
    nextYearData,
    nextNextYearData,
    currentYear,
    previousYear,
    nextYear,
    i18n.language,
    selectedSubCategory,
    currentYear,
  ]);
  useEffect(() => {
    if (month === 0) {
      setPreviousYear(currentYear - 1);
    } else if (month === 11) {
      setNextYear(currentYear + 1);
    }
  }, [month]);

  const handlePriceRuleChange = async (payload: SetPriceCalendarRequest) => {
    try {
      await setPriceRule(payload);
      refetchCalendarYearData();

      toast({
        title: t("changes-saved"),
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: useGetPriceCalendar.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const refetchCalendarYearData = async () => {
    try {
      await refetchCurrentYearData();
      if (month === 10) {
        await refetchNextYearData();
        await refetchNextNextYearData();
      }
      if (month === 0) {
        await refetchPreviousYearData();
      }
    } catch (error) {}
  };

  const value: DataContextType = {
    year: currentYear,
    setYear: setCurrentYear,
    nextYear,
    previousYear,
    month,
    setMonth,
    selectedDays,
    setSelectedDays,
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    availablePriceRules,
    setAvailablePriceRules,
    currentCalendar,
    nextCalendar,
    nextNextCalendar,
    previousCalendar,
    isLoading:
      isLoading ||
      currentYearIsLoading ||
      nextYearIsLoading ||
      previousYearIsLoading ||
      nextNextYearIsLoading,
    availableCategories,
    handlePriceRuleChange,
    refetchCalendarYearData,
    showWeather,
    setShowWeather,
    showPrices,
    setShowPrices,
    showOccupancy,
    setShowOccupancy,
    layout,
    setLayout,
  };

  return (
    <PriceCalendarContext.Provider value={value}>
      {children}
    </PriceCalendarContext.Provider>
  );
};
