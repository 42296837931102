import { createMutation, createQuery } from "react-query-kit";
import { GetSitesResponse } from "../../../../api-contracts/admin";
import { api } from "./api";
import { queryClient } from "../../query-client";
import { Site } from "../../../../api-contracts/sites";

export const SITES_ADMIN_QUERY_KEY = "/api/admin/sites";
export const SITES_QUERY_KEY = "/api/sites";

export const useGetAllSitesAdmin = createQuery<
  GetSitesResponse,
  { orgId: string }
>({
  primaryKey: SITES_ADMIN_QUERY_KEY,
  queryFn: async ({ queryKey: [primaryKey, variables] }) =>
    api.get(`${primaryKey}/${variables.orgId}`),
});

export const useCreateSite = createMutation<Site, Omit<Site, "id">>({
  mutationFn: async (site) => api.post(SITES_ADMIN_QUERY_KEY, site),
  onSuccess: () =>
    queryClient.invalidateQueries({ queryKey: [SITES_ADMIN_QUERY_KEY] }),
});

export const useGetSites = createQuery<GetSitesResponse>({
  primaryKey: SITES_QUERY_KEY,
  queryFn: async ({ queryKey: [primaryKey] }) => api.get(primaryKey),
});
