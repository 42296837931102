import { MediaLibrary } from "@components/media-library";
import { SetStateAction } from "react";
import { Image } from "../../../../../../api-contracts/images";
import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";

export const Media = ({
  mediaLibraryOpen,
  setMediaLibraryOpen,
  setMedia,
  media,
}: {
  mediaLibraryOpen: boolean;
  setMediaLibraryOpen: React.Dispatch<SetStateAction<boolean>>;
  setMedia: React.Dispatch<SetStateAction<{ images: Image[] }>>;
  media: { images: Image[] };
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-grow flex-col">
      {mediaLibraryOpen && (
        <MediaLibrary
          open={mediaLibraryOpen}
          onOpenChange={setMediaLibraryOpen}
          tag="category"
          onImagesSelected={(imgs) => {
            setMedia({
              images: imgs.map((i) => ({ ...i, variant: "thumbnail" })),
            });
            setMediaLibraryOpen(false);
          }}
          initialSelection={media.images.map((i) => ({
            ...i,
            id: String(i.id),
          }))}
        />
      )}
      <p className="text-sm">{t("connected-images")}</p>
      {media.images.length === 0 ? (
        <div className="flex h-full items-center justify-center border border-highlighted-backplate bg-secondary-card-backplate p-4">
          <p className="p-5 text-center text-sm text-secondary-text">
            {t(
              "there-are-no-pictures-or-video-connected-to-this-category-right-now",
            )}
          </p>
        </div>
      ) : (
        <div className="flex h-full min-h-[220px] flex-wrap overflow-y-auto rounded-sm border border-highlighted-backplate p-3 ">
          {media.images.map((img) => (
            <img
              key={img.id}
              className={`mr-4 h-[90px] w-[90px] rounded-sm border border-main-border-color object-cover`}
              src={img.url}
            />
          ))}
        </div>
      )}

      <div className="mt-2 flex flex-grow flex-col items-start justify-end">
        <Button onClick={() => setMediaLibraryOpen(true)} variant={"outline"}>
          {t("edit")}
        </Button>
      </div>
    </div>
  );
};
