import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loading } from "@primitives/loading.tsx";
import { PatchTitleRequest, Title } from "../../../../../api-contracts/titles";
import { useGetTitles, useUpdateTitle } from "@api/titles.ts";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const enum TitlesActionType {
  DEFAULT = "DEFAULT",
  ADD = "ADD",
  EDIT = "EDIT",
}

interface SharedStateProps {
  currentTitle: Title | null;
  titles: Title[];
  action: TitlesActionType;
}

interface SharedFunctionsProps {
  setCurrentTitle: Dispatch<SetStateAction<Title | null>>;
  setTitles: Dispatch<SetStateAction<Title[]>>;
  setAction: Dispatch<SetStateAction<TitlesActionType>>;
  handleRefetch: () => Promise<undefined | null | void>;
  updateTitle: (id: string, req: PatchTitleRequest) => void;
}

interface TitlesPageContextProps {
  sharedState: SharedStateProps;
  sharedFunctions: SharedFunctionsProps;
}

interface TitlesPageProviderProps {
  children: ReactNode;
}

const TitlesContext = createContext<TitlesPageContextProps | undefined>(
  undefined,
);

export const useTitlesPageContext = () => {
  const context = useContext(TitlesContext);

  if (!context) {
    throw new Error("context must be used in correct provider");
  }

  return context;
};

export const TitlesPageProvider: React.FC<TitlesPageProviderProps> = ({
  children,
}) => {
  const {
    sharedState: { isLoading },
    sharedFunctions: { setLoading, toast },
  } = useSharedContext();

  const [currentTitle, setCurrentTitle] = useState<Title | null>(null);
  const [titles, setTitles] = useState<Title[]>([]);
  const [action, setAction] = useState<TitlesActionType>(
    TitlesActionType.DEFAULT,
  );

  const { data: titlesRes, refetch } = useGetTitles();
  const { mutate: updTitle } = useUpdateTitle();

  const { t } = useTranslation();

  useEffect(() => {
    titlesRes && setTitles(titlesRes);
  }, [titlesRes, setTitles]);

  const handleRefetch = async () => {
    const res = await refetch();
    if (res.error) {
      toast({
        variant: "destructive",
        title: t("refetch-title-error", { error: res.error }),
      });
      return null;
    }
    return res.data && setTitles(() => res.data);
  };

  const updateTitle = (id: string, req: PatchTitleRequest) => {
    setLoading(true);

    updTitle(
      { id: id, body: req },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("update-title-error", {
              error:
                e.message === "unique_violation"
                  ? `${req.name} ${t(e.message)}`
                  : e.message,
            }),
          });
        },
        onSuccess: async () => {
          await handleRefetch();
        },
        onSettled: () => {
          setLoading(false);
          setAction(TitlesActionType.DEFAULT);
        },
      },
    );
  };

  const sharedState = {
    currentTitle,
    titles,
    action,
  };
  const sharedFunctions = {
    setCurrentTitle,
    setTitles,
    setLoading,
    setAction,
    handleRefetch,
    toast,
    updateTitle,
  };

  return (
    <TitlesContext.Provider value={{ sharedState, sharedFunctions }}>
      {isLoading && <Loading />}
      {action != TitlesActionType.DEFAULT && (
        <div className="backdrop fixed inset-0 z-[100] flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black/75 outline-none focus:outline-none" />
      )}
      <div className="relative z-0 h-full space-y-4 overflow-y-auto p-4">
        {children}
      </div>
    </TitlesContext.Provider>
  );
};
