import { useTranslation } from "react-i18next";
import { useGetBedLinen } from "@api/bed-types.ts";
import {
  Bedlinen,
  GetBedlinenResponse,
} from "../../../../../../api-contracts/bed-capacity";
import { useState } from "react";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";
import { CreateEdit } from "@pages/settings/bed-types/components/standard-bed-sheet.tsx";
import {
  Column,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { DropDown } from "@components/default-list.tsx";
import {
  ChevronDownIcon,
  ChevronsUpDownIcon,
  ChevronUpIcon,
  Plus,
} from "lucide-react";
import { AlertDialog } from "@primitives/alert-dialog.tsx";
import { Card } from "@primitives/card.tsx";
import { Button } from "@primitives/button.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table.tsx";
import { BedLinenSheet } from "@pages/settings/bed-types/components/bed-linen-sheet.tsx";

export const BedLinenTable = () => {
  const emptyLinen: Bedlinen = {
    id: "",
    name: "",
    width: 0,
    length: 0,
    description: "",
  };
  const { t } = useTranslation();
  const [openSheet, setOpenSheet] = useState(false);
  const { data: bedLinen } = useGetBedLinen();
  const [isOpen, setIsOpen] = useState(false);
  const { deleteLinenById } = useBedData();
  const [linenData, setLinenData] = useState<GetBedlinenResponse>();
  const [createEdit, setCreateEdit] = useState<CreateEdit>("create");
  const [linenToDelete, setLinenToDelete] = useState("");
  const bedLinenColumnsHelper = createColumnHelper<GetBedlinenResponse>();
  const bedAccessoriesColumnsDefinitions = [
    bedLinenColumnsHelper.accessor("name", {
      header: t("name"),
      id: "name",
    }),
    bedLinenColumnsHelper.accessor("width", {
      header: `${t("width")} x ${t("length")} (cm)`,
      id: "width",
      cell: (data) => {
        return (
          <div>
            B {data.row.original.width} x L {data.row.original.length}
          </div>
        );
      },
    }),
    bedLinenColumnsHelper.accessor("description", {
      header: t("description"),
      id: "capacityFullSize",
      cell: (data) => {
        return <div>{data.row.original.description}</div>;
      },
    }),
    bedLinenColumnsHelper.display({
      id: "actions",
      maxSize: 40,
      cell: (data) => {
        return (
          <div className={"flex w-full justify-end"}>
            <DropDown
              isVisible={true}
              onEdit={() => {
                setLinenData(data.row.original);
                setCreateEdit("edit");
                setOpenSheet(!openSheet);
              }}
              onDelete={() => {
                setLinenToDelete(data.row.original.id.toString());
                setIsOpen((prevState) => !prevState);
              }}
            />
          </div>
        );
      },
    }),
  ];
  const table = useReactTable({
    data: bedLinen ?? [],
    columns: bedAccessoriesColumnsDefinitions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const getSortIcon = (
    column: Column<GetBedlinenResponse>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };

  return (
    <>
      <AlertDialog.Root
        open={isOpen}
        onOpenChange={() => setIsOpen((previousState) => !previousState)}
      >
        <AlertDialog.Content className="z-[2001]">
          <AlertDialog.Header>
            <AlertDialog.Title>{t("delete-accessory")}</AlertDialog.Title>
            <AlertDialog.Description>
              {t("delete-accessory-desc")}
            </AlertDialog.Description>
          </AlertDialog.Header>
          <AlertDialog.Footer className="relative">
            <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

            <AlertDialog.Action onClick={() => deleteLinenById(linenToDelete)}>
              {t("delete")}
            </AlertDialog.Action>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog.Root>
      <Card className="h-full w-full ">
        <p className={" font-itc text-sm font-bold text-primary-text"}>
          {t("bedlinen")}
        </p>
        <div className="py-4">
          <Button
            className={"rounded-xl"}
            variant="primary"
            size="icon"
            onClick={() => {
              setLinenData(emptyLinen);
              setCreateEdit("create");
              setOpenSheet((prevState) => !prevState);
            }}
          >
            <Plus size={17} />
          </Button>
        </div>
        <div className={"h-5/6 overflow-auto"}>
          {bedLinen && (
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableHead className={"select-none"} key={header.id}>
                        {header.isPlaceholder ? null : (
                          <div
                            className="flex cursor-pointer items-center"
                            {...{
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.getCanSort() && (
                              <div className="ml-1">
                                {getSortIcon(
                                  header.column,
                                  table.getState().sorting,
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </TableHead>
                    ))}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {bedLinen && table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      className={"hover:bg-secondary-card-backplate"}
                      key={row.id}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            style={{
                              width: `${cell.column.getSize()}px`,
                              height: "74px",
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={table.getAllLeafColumns().length}
                      className="h-24 text-center"
                    >
                      {t("no-results")}.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
        {openSheet && (
          <BedLinenSheet
            onOpenChange={setOpenSheet}
            open={openSheet}
            type={createEdit}
            btnText={createEdit === "create" ? t("create") : t("edit")}
            linenData={linenData}
          />
        )}
      </Card>
    </>
  );
};
