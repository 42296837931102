import React, { FC } from "react";
import { findAndSortChildren } from "../locations-utils.tsx";
import { LocationDTO } from "../../../../../../api-contracts/locations";

export type LocationProps = {
  location: LocationDTO;
  isRoot: boolean;
  isOpen: boolean;
  path: string | null;
};

type TreeProps = {
  nodes: LocationProps[];
  onSelect: (node: LocationProps) => void;
  current: string | null;
  children: (node: LocationProps) => JSX.Element;
};

export const LocationsHierarchyTree = (props: TreeProps) => {
  const root = props.nodes.find((node) => node.isRoot);
  const withoutParent = props.nodes.filter(
    (node) => node.location.partOf === null && !node.isRoot,
  );

  const firstLevel =
    root && withoutParent ? [root, ...withoutParent] : withoutParent ?? [];

  return (
    <div className="text-black-400 text-sm/7">
      {firstLevel &&
        firstLevel.map((node) => (
          <ParentNode
            props={props}
            node={node}
            key={node.location.id ?? "locations-root"}
          />
        ))}
    </div>
  );
};

const ParentNode: FC<{ props: TreeProps; node: LocationProps }> = ({
  props,
  node,
}) => {
  return (
    <ul>
      <div className="flex items-center justify-between">
        <div className="flex items-center overflow-x-hidden text-ellipsis">
          {props.children &&
            typeof props.children === "function" &&
            props.children(node)}
        </div>
      </div>
      {node.isOpen && (
        <ChildNode
          props={props}
          childNodes={findAndSortChildren(node.location.id, props.nodes)}
        />
      )}
    </ul>
  );
};

const ChildNode: FC<{ props: TreeProps; childNodes: LocationProps[] }> = ({
  props,
  childNodes,
}) => {
  return (
    <>
      {childNodes.map((child) => (
        <div className="pl-8" key={child.location.id}>
          {findAndSortChildren(child.location.id, props.nodes)?.length === 0 ? (
            <div className="flex items-center">
              {props.children &&
                typeof props.children === "function" &&
                props.children(child)}
            </div>
          ) : (
            <ParentNode node={child} props={props} />
          )}
        </div>
      ))}
    </>
  );
};
