import { createMutation, createQuery } from "react-query-kit";
import { api } from "./api";
import {
  DeletePriceCalendarNoteRequestParams,
  DeletePriceCalendarNoteResponse,
  GetPriceCalendarRequestParams,
  GetPriceCalendarResponse,
  CreatePriceCalendarNoteRequest,
  SetPriceCalendarRequest,
  SetPriceCalendarResponse,
  CreatePriceCalendarNoteResponse,
  UpdatePriceCalendarNoteRequest,
  UpdatePriceCalendarNoteResponse,
} from "../../../../api-contracts/price-calendar";
import { currentSite } from "@shared/context/site-context";

export const useGetPriceCalendar = createQuery<
  GetPriceCalendarResponse,
  GetPriceCalendarRequestParams
>({
  primaryKey: "/api/<siteId>/price-calendar?categoryId",
  queryFn: async ({
    queryKey: [, { categoryId, startDate, months, types }],
  }) => {
    return api.get<GetPriceCalendarResponse>(
      `/api/${currentSite?.id}/price-calendar`,
      {
        categoryId,
        startDate,
        months,
        types,
      },
    );
  },
});

export const useDeletePriceCalendarNote = createMutation<
  DeletePriceCalendarNoteResponse,
  DeletePriceCalendarNoteRequestParams
>({
  mutationFn: async ({ priceCalendarNoteId }) =>
    api.delete<DeletePriceCalendarNoteResponse>(
      `/api/${currentSite?.id}/price-calendar/notes/${priceCalendarNoteId}`,
    ),
});

export const useCreatePriceCalendarNote = createMutation<
  CreatePriceCalendarNoteResponse,
  CreatePriceCalendarNoteRequest
>({
  mutationFn: async ({ startDate, endDate, message }) =>
    api.post<CreatePriceCalendarNoteResponse>(
      `/api/${currentSite?.id}/price-calendar/notes`,
      { startDate, endDate, message },
    ),
});

export const useUpdatePriceCalendarNote = createMutation<
  UpdatePriceCalendarNoteResponse,
  UpdatePriceCalendarNoteRequest
>({
  mutationFn: async ({ priceCalendarNoteIds, message }) =>
    api.put<CreatePriceCalendarNoteResponse>(
      `/api/${currentSite?.id}/price-calendar/notes`,
      { priceCalendarNoteIds, message },
    ),
});

export const useSetPriceCalendar = createMutation<
  SetPriceCalendarResponse,
  SetPriceCalendarRequest
>({
  mutationFn: async ({ months, categoryId, categoryTypes }) =>
    api.put<SetPriceCalendarResponse>(
      `/api/${currentSite?.id}/price-calendar`,
      {
        months,
        categoryId,
        categoryTypes,
      },
    ),
});
