import { IconSelector } from "@components/icon-selector";
import { Button } from "@primitives/button";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { Input } from "@primitives/input";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Amenity } from "../../../../../../api-contracts/amenities";

export const IconSelectorSideSheet = ({
  sheetOpen,
  setSheetOpen,
  amenity,
  onSave,
}: {
  sheetOpen: boolean;
  setSheetOpen: React.Dispatch<SetStateAction<boolean>>;
  amenity: Amenity;
  onSave: (amenity: Amenity) => Promise<void>;
}) => {
  const { t } = useTranslation();

  const [tempAmenity, setTempAmenity] = useState<Amenity>(amenity);
  const enableSave =
    tempAmenity.iconName.length > 0 && tempAmenity.name.length > 0;
  const isNew = tempAmenity.id.length < 1;

  useEffect(() => {
    if (sheetOpen) {
      setTempAmenity(amenity);
    } else {
      setTempAmenity({ name: "", iconName: "", id: "" });
    }
  }, [sheetOpen, amenity]);

  return (
    <DefaultSideSheet
      title={isNew ? t("new-amenity") : t("edit-amenity")}
      open={sheetOpen}
      onOpenChange={setSheetOpen}
    >
      <div className="h-10">
        <Input
          value={tempAmenity.name}
          onChange={(e) =>
            setTempAmenity((prev) => ({ ...prev, name: e.target.value }))
          }
          placeholder={t("name")}
          required
        />
      </div>

      <IconSelector
        selectedIcon={tempAmenity.iconName}
        setSelectedIcon={(name: string) =>
          setTempAmenity((prev) => ({ ...prev, iconName: name }))
        }
      />
      <div className="flex justify-end">
        <Button onClick={() => onSave(tempAmenity)} disabled={!enableSave}>
          {isNew ? t("create") : t("save")}
        </Button>
      </div>
    </DefaultSideSheet>
  );
};
