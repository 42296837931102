import { useAuth } from "@hooks/use-auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

/**
 * Ensure that the user is an admin before rendering the child route.
 */
function ProtectedAdmin() {
  const { user } = useAuth();
  const location = useLocation();
  const returnUrl = location.pathname;
  return user?.type === "admin" ? (
    <Outlet />
  ) : (
    <Navigate to={"/"} replace={true} state={{ returnUrl }} />
  );
}

export default ProtectedAdmin;
