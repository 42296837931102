import { TargetGroupsCard } from "../components/target-groups-card.tsx";
import { useTargetGroupsPageContext } from "../target-groups-context.tsx";
import { TargetGroupsDetailCard } from "@pages/settings/target_groups/components/target-groups-detail-card.tsx";

export const TargetGroupsView = () => {
  const {
    sharedState: { selectedTargetGroupDetails },
  } = useTargetGroupsPageContext();

  return (
    <>
      <div className="flex h-full space-x-[18px]">
        <TargetGroupsCard />
        {selectedTargetGroupDetails != null && <TargetGroupsDetailCard />}
      </div>
    </>
  );
};
