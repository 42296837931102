import { findAndSortChildren } from "../locations-utils.tsx";
import { ChevronDown, ChevronRight } from "lucide-react";
import React from "react";
import { LocationProps } from "../../../settings/locations/components/locations-hierarchy-tree.tsx";

export const LocationsExpand = ({
  location,
  locations,
  onExpand,
}: {
  location: LocationProps;
  locations: LocationProps[];
  onExpand: (location: LocationProps) => void;
}) => {
  return (
    <>
      {findAndSortChildren(location.location.id, locations).length > 0 && (
        <div onClick={() => onExpand(location)}>
          {location.isOpen ? (
            <ChevronDown className="h-[14px] w-[14px]" />
          ) : (
            <ChevronRight className="h-[14px] w-[14px]" />
          )}
        </div>
      )}
    </>
  );
};
