import { PriceRuleHeader } from "@pages/booking/price-rules/components/price-rule-header.tsx";
import { usePriceRulesPageContext } from "@pages/booking/price-rules/price-rules-context.tsx";
import { cn } from "@utils/css.ts";
import {
  GridItem,
  GridRow,
} from "@pages/booking/price-rules/components/pricing-grid.tsx";
import { Collapsible } from "@primitives/collapsible";
import { toCurrencyFormat } from "@pages/booking/price-rules/utils/price-rules-utils.ts";
import {
  GetPriceRuleCategory,
  GetPriceRulesEntry,
} from "../../../../../../api-contracts/price-rules";

export const PriceRulesDisplayGrid = () => {
  const {
    sharedState: {
      rules,
      expanded,
      categories,
      showStatus,
      priceRules,
      activeFilters,
    },
  } = usePriceRulesPageContext();

  const getItems = (oId: string, cId: string) => {
    return priceRules.rules.map((rule) => {
      const entry = rule.entries.find((x) => x.categoryId === cId);

      const price: number | undefined = entry?.overrides.find(
        (x) => x.id === oId,
      )?.price;

      return { id: rule.id, display: price, active: rule.active };
    });
  };

  //If overrides exist, put override for 1 adult as base-price
  const getBasePrice = (
    c: GetPriceRuleCategory,
    e: GetPriceRulesEntry | null,
  ) => {
    const baseOverrideCategory = c.overrides.find(
      (it) =>
        it.adults === 1 &&
        it.infants === 0 &&
        it.teenagers === 0 &&
        it.children === 0,
    );

    return e?.overrides.find((it) => it.id === baseOverrideCategory?.id)?.price;
  };

  return (
    <>
      <PriceRuleHeader hidden={false} />

      {categories.map((c, rowIdx) => (
        <div key={`bt-${c.id}-row-${rowIdx}`}>
          <GridRow>
            {rules.map((rule, colIdx) => {
              const entry = rule.entries.find((x) => x.categoryId === c.id);
              const hasOverrides = Boolean(entry?.overrides?.length || 0);
              return (
                <div key={`bt-${c.id}-row-${rowIdx}-rule-${rule.id}`}>
                  <GridItem
                    as={"button"}
                    data-row={rowIdx + 1}
                    data-col={colIdx + 2}
                    className="relative"
                  >
                    {entry && (entry.price || getBasePrice(c, entry)) && (
                      <div className="flex flex-grow items-center justify-end gap-2 overflow-auto pr-4">
                        <p
                          className={cn(
                            "truncate",
                            showStatus && !rule.active && "text-gray-300",
                          )}
                        >
                          {toCurrencyFormat(
                            getBasePrice(c, entry) ?? entry.price,
                          ) ?? ""}
                        </p>
                        <p
                          className={cn(
                            "text-xs text-secondary-text",
                            showStatus && !rule.active && "text-gray-300",
                          )}
                        >
                          SEK
                        </p>
                      </div>
                    )}
                    <span
                      className="absolute right-3 top-[26px] h-2 w-2 rounded-full bg-blush"
                      hidden={!hasOverrides}
                    />
                  </GridItem>
                </div>
              );
            })}
          </GridRow>
          <Collapsible.Root open={expanded.includes(c.id)}>
            <Collapsible.Content
              style={{
                minWidth: `${rules?.length * 8}rem`,
              }}
            >
              {c.overrides.map((o) => (
                <GridRow key={`bt-${c.id}-row-${rowIdx}-override-${o.id}`}>
                  {getItems(o.id, c.id).map((item, itemI) => (
                    <GridItem key={`bt-${c.id}-row-${itemI}-${o.id}`}>
                      <div
                        className={cn(
                          "flex flex-grow items-center justify-end gap-2  pr-4",
                          activeFilters.includes("status") &&
                            !item.active &&
                            "text-gray-300",
                        )}
                      >
                        <p
                          className={cn(
                            "truncate text-base",
                            item.active && "text-primary-text",
                          )}
                        >
                          {item.display
                            ? toCurrencyFormat(Number(item.display))
                            : ""}
                        </p>
                        <p
                          className={cn(
                            "text-xs",
                            item.active && "text-secondary-text",
                          )}
                        >
                          {item.display && "SEK"}
                        </p>
                      </div>
                    </GridItem>
                  ))}
                </GridRow>
              ))}
            </Collapsible.Content>
          </Collapsible.Root>
        </div>
      ))}
    </>
  );
};
