import {
  useCreateAssetGroup,
  useCreateCategoryGroup,
  useDeleteAssetGroup,
  useDeleteCategoryGroup,
  useGetAssetGroups,
  useGetCategoryGroups,
  useUpdateAssetGroup,
  useUpdateCategoryGroup,
} from "@api/groups";
import { useToast } from "@hooks/use-toast";
import { Button } from "@primitives/button";
import { Plus, Search, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AssetGroup,
  CategoryGroup,
  Group,
} from "../../../../../api-contracts/groups";
import { cn } from "@shared/utils/css";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { Input } from "@primitives/input";
import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs";
import { queryClient } from "query-client";
import { Loading } from "@primitives/loading";
import { SearchBar } from "@primitives/search-bar";
import { Switch } from "@primitives/switch";
import { AlertDialog } from "@primitives/alert-dialog";
import { useGetCategories } from "@api/categories";
import { DefaultListItem } from "@components/default-list";
import { Card, CardTitle } from "@primitives/card";
import { useGetAssetsByCategory } from "@api/assets";
import {
  GetAssetsResponse,
  GetGroupedAssetsResponse,
} from "../../../../../api-contracts/assets";

export const GroupsPage = () => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [newLoading, setNewLoading] = useState<boolean>(false);
  const [updateLoading, setEditLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [newType, setNewType] = useState<"asset" | "category">("asset");
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [selectedType, setSelectedType] = useState<"asset" | "category">();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [groupToDelete, setGroupToDelete] = useState<{
    group: Group;
    type: "asset" | "category";
  }>();
  const [addNewSidebarOpen, setAddNewSidebarOpen] = useState<boolean>(false);
  const [editSidebarOpen, setEditSidebarOpen] = useState<boolean>(false);
  const [editAssetsSidebarOpen, setEditAssetSidebarOpen] =
    useState<boolean>(false);
  const [editCategoriesSidebarOpen, setEditCategoriesSidebarOpen] =
    useState<boolean>(false);
  const [isAssetSearchExpanded, setIsAssetSearchExpanded] =
    useState<boolean>(false);
  const [isCategoriesSearchExpanded, setIsCategoriesSearchExpanded] =
    useState<boolean>(false);
  const [showByCategory, setShowByCategory] = useState<boolean>(false);
  const [assetSearchTerm, setAssetSearchTerm] = useState<string>("");
  const [categorySearchTerm, setCategorySearchTerm] = useState<string>("");
  const selectedAssets = (selectedGroup as AssetGroup)?.assets || [];
  const selectedCategories = (selectedGroup as CategoryGroup)?.categories || [];
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [hovered, setHovered] = useState<Group>();
  const [hoveredType, setHoveredType] = useState<"asset" | "category">();

  const createAssetGroup = useCreateAssetGroup();
  const updateAssetGroup = useUpdateAssetGroup();
  const deleteAssetGroup = useDeleteAssetGroup();
  const createCategoryGroup = useCreateCategoryGroup();
  const updateCategoryGroup = useUpdateCategoryGroup();
  const deleteCategoryGroup = useDeleteCategoryGroup();

  useEffect(() => {
    if (selectedType === "asset") {
      setSelectedIds(
        (selectedGroup as AssetGroup)?.assets?.map((a) => a.id) || [],
      );
    } else {
      setSelectedIds(
        (selectedGroup as CategoryGroup)?.categories?.map((c) => c.id) || [],
      );
    }
    setName(selectedGroup?.name || "");
  }, [selectedGroup]);

  const {
    data: assetGroups,
    error: assetGroupsError,
    isLoading: assetGroupsIsLoading,
  } = useGetAssetGroups({});

  const {
    data: categoryGroups,
    error: categoryGroupsError,
    isLoading: categoryGroupsIsLoading,
  } = useGetCategoryGroups({});

  const {
    data: assetsData,
    isLoading: assetsLoading,
    isRefetching: assetsRefetching,
  } = useGetAssetsByCategory({
    variables: {
      showByCategory,
    },
  });

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    isRefetching: categoriesRefetching,
  } = useGetCategories({
    variables: { categoryTypes: ["room", "area", "bed", "dormitory"] },
  });

  useEffect(() => {
    if (selectedGroup) {
      const activeGroups =
        selectedType === "asset" ? assetGroups : categoryGroups;
      setSelectedGroup(activeGroups?.find((g) => g.id === selectedGroup.id));
    }
  }, [assetGroups, categoryGroups]);

  let assets = showByCategory
    ? (assetsData as GetGroupedAssetsResponse)
    : (assetsData as GetAssetsResponse);

  if (assetSearchTerm) {
    if (showByCategory) {
      assets = (assets as GetGroupedAssetsResponse)?.map((c) => ({
        ...c,
        assets: c.items.filter((a) =>
          a.name.toLowerCase().includes(assetSearchTerm.toLocaleLowerCase()),
        ),
      }));
      assets = (assets as GetGroupedAssetsResponse)?.filter(
        (c) => c.items.length > 0,
      );
    } else {
      assets = (assets as GetAssetsResponse)?.filter((b) =>
        b.name.toLowerCase().includes(assetSearchTerm.toLocaleLowerCase()),
      );
    }
  }

  let categories = categoriesData;

  if (categorySearchTerm) {
    categories = categories?.filter((c) =>
      c.name.toLocaleLowerCase().includes(categorySearchTerm.toLowerCase()),
    );
  }

  const createGroup = async () => {
    setNewLoading(true);
    try {
      if (newType === "asset") {
        await createAssetGroup.mutateAsync({
          name,
        });
      } else {
        await createCategoryGroup.mutateAsync({
          name,
        });
      }

      queryClient.invalidateQueries({
        queryKey: useGetAssetGroups.getKey(),
      });
      queryClient.invalidateQueries({
        queryKey: useGetCategoryGroups.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: name }),
        className: "text-status-success",
        variant: "success",
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
    setNewLoading(false);
  };

  const updateGroup = async () => {
    if (selectedGroup) {
      setEditLoading(true);
      try {
        if (selectedType === "asset") {
          await updateAssetGroup.mutateAsync({
            name,
            id: selectedGroup.id,
            assetIds: selectedIds,
          });
        } else {
          await updateCategoryGroup.mutateAsync({
            name,
            id: selectedGroup.id,
            categoryIds: selectedIds,
          });
        }

        queryClient.invalidateQueries({
          queryKey: useGetAssetGroups.getKey(),
        });
        queryClient.invalidateQueries({
          queryKey: useGetCategoryGroups.getKey(),
        });
        toast({
          title: t("saved-succesfully", { name: name }),
          className: "text-status-success",
          variant: "success",
        });
      } catch (err) {
        toast({
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
          variant: "destructive",
        });
      }
      setEditLoading(false);
    }
  };

  const deleteGroup = async ({
    group,
    type,
  }: {
    group: Group;
    type: "asset" | "category";
  }) => {
    try {
      if (type === "asset") {
        await deleteAssetGroup.mutateAsync({
          id: group.id,
        });
      } else {
        await deleteCategoryGroup.mutateAsync({
          id: group.id,
        });
      }

      queryClient.invalidateQueries({
        queryKey: useGetAssetGroups.getKey(),
      });
      queryClient.invalidateQueries({
        queryKey: useGetCategoryGroups.getKey(),
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  const onEdit = (item: { id: string; name: string }) => {
    setSelectedGroup(item);
    setName(item.name);
    setEditSidebarOpen(true);
  };

  const onDelete = (groupToDelete: {
    group: Group;
    type: "asset" | "category";
  }) => {
    setGroupToDelete(groupToDelete);
    setDeleteDialogOpen(true);
  };

  return (
    <>
      <div className=" relative flex min-h-full flex-nowrap p-4">
        {(assetGroupsIsLoading || categoryGroupsIsLoading) && <Loading />}
        <Card className="min-w-[500px] max-w-[500px] ">
          <CardTitle>{t("groups")}</CardTitle>
          <div className="py-4 ">
            <Button
              variant="primary"
              size="icon"
              onClick={() => setAddNewSidebarOpen(true)}
            >
              <Plus size={17} />
            </Button>
          </div>
          <div className="mx-[-1rem]">
            {assetGroups?.map((g) => (
              <DefaultListItem
                key={g.id}
                title={g.name}
                onClickListItem={() => {
                  setSelectedGroup(g);
                  setSelectedType("asset");
                }}
                onMouseEnter={() => {
                  setHovered(g);
                  setHoveredType("asset");
                }}
                onMouseLeave={() => setHovered(undefined)}
                className={cn({
                  "bg-secondary-card-backplate":
                    selectedGroup?.id === g.id && selectedType === "asset",
                })}
                onDelete={() => onDelete({ group: g, type: "asset" })}
                onEdit={() => onEdit(g)}
                badge={t("asset")}
              />
            ))}

            {categoryGroups?.map((g) => (
              <DefaultListItem
                key={g.id}
                title={g.name}
                onClickListItem={() => {
                  setSelectedGroup(g);
                  setSelectedType("category");
                }}
                onMouseEnter={() => {
                  setHovered(g);
                  setHoveredType("category");
                }}
                onMouseLeave={() => setHovered(undefined)}
                className={cn({
                  "bg-secondary-card-backplate":
                    selectedGroup?.id === g.id && selectedType === "category",
                })}
                onDelete={() => onDelete({ group: g, type: "category" })}
                onEdit={() => onEdit(g)}
                badge={t("category")}
              />
            ))}
          </div>
        </Card>

        {selectedGroup && (
          <section className="m-4 min-h-full min-w-[500px] max-w-[500px] rounded-lg border border-main-border-color bg-primary-card-backplate pt-4 text-primary-text">
            <div className="pb-4 pl-4">
              <h2 className="mb-3 text-2xl font-bold">{selectedGroup.name}</h2>
              <Button
                variant="outline"
                onClick={() =>
                  selectedType === "asset"
                    ? setEditAssetSidebarOpen(true)
                    : setEditCategoriesSidebarOpen(true)
                }
              >
                <Plus size={18} className="mr-1" />
                {selectedType === "asset" ? t("asset") : t("category")}
              </Button>
            </div>

            <div>
              {selectedAssets.map((b) => (
                <div key={b.id} className="overflow-y-auto p-2 px-4">
                  <p className=" text-sm font-medium text-primary-text">
                    {b.name}
                  </p>
                  <p className=" text-xs font-normal text-secondary-text">
                    <>{t(b.type)}</>
                  </p>
                </div>
              ))}
              {selectedCategories.map((b) => (
                <div key={b.id} className="overflow-y-auto p-2 px-4">
                  <p className=" text-sm font-medium text-primary-text">
                    {b.name}
                  </p>
                </div>
              ))}
            </div>
          </section>
        )}
      </div>

      <DefaultSideSheet
        open={addNewSidebarOpen}
        onOpenChange={(open) => setAddNewSidebarOpen(open)}
        side="left"
        title={t("create-group")}
      >
        <div className="flex flex-grow flex-col justify-between">
          <div>
            <Input
              required={true}
              type="text"
              value={name}
              placeholder={t("name") + " *"}
              onChange={(e) => setName(e.target.value)}
            />

            <Tabs
              value={newType}
              className="mt-4"
              onValueChange={(n) => setNewType(n as "asset" | "category")}
            >
              <TabsList className="w-full">
                <TabsTrigger className="w-1/2" value="asset">
                  {t("asset")}
                </TabsTrigger>
                <TabsTrigger className="w-1/2" value="category">
                  {t("category")}
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>

          <div className=" flex justify-end">
            <Button
              variant="primary"
              type="submit"
              disabled={!name || newLoading}
              loading={newLoading}
              onClick={createGroup}
            >
              {t("create")}
            </Button>
          </div>
        </div>
      </DefaultSideSheet>

      <DefaultSideSheet
        open={editSidebarOpen}
        onOpenChange={(open) => setEditSidebarOpen(open)}
        side="left"
        title={t("edit-name")}
      >
        <div className="flex flex-grow flex-col justify-between">
          <div>
            <Input
              required={true}
              type="text"
              value={name}
              placeholder={t("name") + " *"}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className=" flex justify-end">
            <Button
              variant="primary"
              type="submit"
              disabled={!name || updateLoading}
              loading={updateLoading}
              onClick={updateGroup}
            >
              {t("save")}
            </Button>
          </div>
        </div>
      </DefaultSideSheet>

      {selectedType === "asset" && (
        <DefaultSideSheet
          open={editAssetsSidebarOpen}
          onOpenChange={(open) => setEditAssetSidebarOpen(open)}
          side="left"
          title={t("add-asset")}
          description={selectedGroup?.name}
        >
          <div className="flex space-x-2 pb-4">
            {isAssetSearchExpanded || assetSearchTerm ? (
              <SearchBar
                value={assetSearchTerm}
                onChange={(e) => setAssetSearchTerm(e.target.value)}
                className="h-10 w-[320px] rounded-md bg-primary-button-backplate px-4 py-2"
                onBlur={() =>
                  setIsAssetSearchExpanded((prevState) => !prevState)
                }
                autoFocus
              />
            ) : (
              <Button
                variant="secondary"
                className="border px-3"
                onClick={() =>
                  setIsAssetSearchExpanded((prevState) => !prevState)
                }
              >
                <Search size={17} />
              </Button>
            )}
          </div>

          <div className="flex justify-between">
            <p className=" text-sm font-extrabold text-primary-text">
              {t("assets")}
            </p>
            <div className=" flex flex-nowrap items-center space-x-2">
              <p className=" text-sm font-extrabold text-primary-text">
                {t("show-categories")}
              </p>
              <Switch
                checked={showByCategory}
                onCheckedChange={setShowByCategory}
              />
            </div>
          </div>

          <div className="flex-grow overflow-y-auto">
            <div className=" mt-2 bg-secondary-card-backplate">
              {!showByCategory &&
                (assets as GetAssetsResponse)?.map((b) => (
                  <div key={b.id} className="flex justify-between p-2">
                    <div>
                      <p className=" text-sm font-medium text-primary-text">
                        {b.name}
                      </p>
                      <p className=" text-xs font-normal text-secondary-text">
                        {t(b.type)}
                      </p>
                    </div>

                    {selectedIds.some((id) => id === b.id) ? (
                      <Button
                        variant="secondary"
                        className=" bg-highlighted-backplate"
                        onClick={() =>
                          setSelectedIds(
                            selectedIds.filter((id) => id !== b.id),
                          )
                        }
                      >
                        <X size={18} className="mr-2" />
                        {t("added")}
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        onClick={() => setSelectedIds([...selectedIds, b.id])}
                      >
                        {t("add")}
                      </Button>
                    )}
                  </div>
                ))}
              {showByCategory &&
                (assets as GetGroupedAssetsResponse)?.map((c) => (
                  <div key={c.id}>
                    <div className="flex items-center justify-between bg-secondary-card-backplate p-2 py-4">
                      <p className="text-sm font-bold text-primary-text">
                        {c.name}
                      </p>
                      {c.items.some(
                        (b) => !selectedIds.some((id) => b.id === id),
                      ) ? (
                        <Button
                          variant="secondary"
                          onClick={() =>
                            setSelectedIds([
                              ...new Set([
                                ...selectedIds,
                                ...c.items.map((b) => b.id),
                              ]),
                            ])
                          }
                        >
                          {t("add-all")}
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          className=" bg-highlighted-backplate"
                          onClick={() =>
                            setSelectedIds(
                              selectedIds.filter(
                                (id) => !c.items.some((b) => b.id === id),
                              ),
                            )
                          }
                        >
                          {t("remove-all")}
                        </Button>
                      )}
                    </div>
                    {c.items.map((b) => (
                      <div key={b.id} className="flex justify-between p-2">
                        <div>
                          <p className=" text-sm font-medium text-primary-text">
                            {b.name}
                          </p>
                          <p className=" text-xs font-normal text-secondary-text">
                            {t(b.type)}
                          </p>
                        </div>

                        {selectedIds.some((id) => id === b.id) ? (
                          <Button
                            variant="secondary"
                            className=" bg-highlighted-backplate"
                            onClick={() =>
                              setSelectedIds(
                                selectedIds.filter((id) => id !== b.id),
                              )
                            }
                          >
                            <X size={18} className="mr-2" />
                            {t("added")}
                          </Button>
                        ) : (
                          <Button
                            variant="secondary"
                            onClick={() =>
                              setSelectedIds([...selectedIds, b.id])
                            }
                          >
                            {t("add")}
                          </Button>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>

          <div className=" flex justify-end">
            <Button
              variant="primary"
              type="submit"
              disabled={!name || updateLoading}
              loading={updateLoading}
              onClick={updateGroup}
            >
              {t("save")}
            </Button>
          </div>
        </DefaultSideSheet>
      )}

      {selectedType === "category" && (
        <DefaultSideSheet
          open={editCategoriesSidebarOpen}
          onOpenChange={(open) => setEditCategoriesSidebarOpen(open)}
          side="left"
          title={t("add-category")}
          description={selectedGroup?.name}
        >
          <div className="flex space-x-2 pb-4">
            {isCategoriesSearchExpanded || categorySearchTerm ? (
              <SearchBar
                value={categorySearchTerm}
                onChange={(e) => setCategorySearchTerm(e.target.value)}
                className="h-10 w-[320px] rounded-md bg-primary-button-backplate px-4 py-2"
                onBlur={() =>
                  setIsCategoriesSearchExpanded((prevState) => !prevState)
                }
                autoFocus
              />
            ) : (
              <Button
                variant="secondary"
                className="border px-3"
                onClick={() =>
                  setIsCategoriesSearchExpanded((prevState) => !prevState)
                }
              >
                <Search size={17} />
              </Button>
            )}
          </div>

          <div className="flex justify-between">
            <p className=" text-sm font-extrabold text-primary-text">
              {t("categories")}
            </p>
          </div>

          <div className="flex-grow overflow-y-auto">
            <div className=" mt-2 bg-secondary-card-backplate">
              {categories?.map((c) => (
                <div key={c.id} className="flex justify-between p-2">
                  <div>
                    <p className=" text-sm font-medium text-primary-text">
                      {c.name}
                    </p>
                  </div>

                  {selectedIds.some((id) => id === c.id) ? (
                    <Button
                      variant="secondary"
                      className=" bg-highlighted-backplate"
                      onClick={() =>
                        setSelectedIds(selectedIds.filter((id) => id !== c.id))
                      }
                    >
                      <X size={18} className="mr-2" />
                      {t("added")}
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      onClick={() => setSelectedIds([...selectedIds, c.id])}
                    >
                      {t("add")}
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className=" flex justify-end">
            <Button
              variant="primary"
              type="submit"
              disabled={!name || updateLoading}
              loading={updateLoading}
              onClick={updateGroup}
            >
              {t("save")}
            </Button>
          </div>
        </DefaultSideSheet>
      )}

      <AlertDialog.Root
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
      >
        <AlertDialog.Content>
          <AlertDialog.Header>
            <AlertDialog.Title>
              {t("are-you-sure-you-want-to-delete-the-group")}
            </AlertDialog.Title>
            <AlertDialog.Description>
              {t("this-means-permanent-removal-from-all-assets")}
            </AlertDialog.Description>
          </AlertDialog.Header>
          <AlertDialog.Footer>
            <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>
            <AlertDialog.Action
              onClick={() => groupToDelete && deleteGroup(groupToDelete)}
            >
              {t("remove")}
            </AlertDialog.Action>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
};
