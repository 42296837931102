import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { Label } from "@primitives/label";
import { Input } from "@primitives/input";
import { useState } from "react";
import { GetBooking } from "../../../../../../api-contracts/reservations";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (proceed: {
    reason?: string;
    note?: string;
    sendConfirmation: boolean;
  }) => void;
}

const reasonOptions = [
  "some-reason",
  // TODO
];

export const CancelReasonDialog = ({
  open,
  onOpenChange,
  onProceed,
}: Props) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>();
  const [note, setNote] = useState<string>("");

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit min-w-[500px] max-w-fit">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("reason")}
            </DialogTitle>

            <div className="mt-4">
              <Label htmlFor="cancel-reason">
                {t("reason-for-cancellation")}
              </Label>
              <Select value={reason} onValueChange={setReason}>
                <SelectTrigger className=" w-full">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {reasonOptions.map((r) => (
                    <SelectItem key={r} value={r} className=" text-xs">
                      {t(r)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Label htmlFor="cancel-note">{t("note")}</Label>
              <Input
                id="cancel-note"
                type="text"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <div className=" mt-4 flex justify-end space-x-2">
                <Button variant="outline" onClick={() => onOpenChange(false)}>
                  {t("cancel")}
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    onProceed({ note, reason, sendConfirmation: false })
                  }
                >
                  {t("save")}
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    onProceed({ note, reason, sendConfirmation: true })
                  }
                >
                  {t("save-and-send-confirmation")}
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CancelReasonDialog;
