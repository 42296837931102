import { createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import { GetConfirmationSpheres } from "../../../../api-contracts/confirmation-spheres";

export const CONFIRMATION_SPHERES_QUERY_KEY = "confirmation-spheres";

export const useGetConfirmationSpheres = createQuery<GetConfirmationSpheres>({
  primaryKey: CONFIRMATION_SPHERES_QUERY_KEY,

  queryFn: async () => {
    return api.get(`/api/${currentSite?.id}/${CONFIRMATION_SPHERES_QUERY_KEY}`);
  },
});
