import { Badge } from "@shared/primitives/badge.tsx";
import { X } from "lucide-react";
type BadgeProps = {
  text: string;
  id: string;
  onRemove?: (value: string) => void;
};

export const DefaultBadge = ({ ...props }: BadgeProps) => {
  return (
    <Badge>
      {props.text}{" "}
      {props.onRemove && (
        <X
          size="10"
          onClick={() => props.onRemove && props.onRemove(props.id)}
        />
      )}
    </Badge>
  );
};
