import { parseQuery } from "@shared/utils/query-string";
import { RefreshResponse } from "../../../../api-contracts/auth";
import {
  clearAuth,
  isAuthenticated,
  updateToken,
  user,
} from "@shared/utils/auth-manager";
import { isResponse } from "@shared/utils/helpers";
import { ApiError } from "@shared/utils/api-error";
import { Mutex } from "@api/mutex.ts";
import { jwtDecode } from "jwt-decode";

class Api {
  refreshMutex = new Mutex();
  refreshExpiration: Date = new Date();

  constructor() {
    this.setExpirationValue();
  }

  private setExpirationValue() {
    let expiration: Date = new Date();
    if (user.value?.accessToken) {
      const tokenInfo = jwtDecode(user.value.accessToken); // decode token
      if (tokenInfo && tokenInfo.exp) {
        // Converting to milliseconds
        expiration = new Date(tokenInfo.exp * 1000);
      }
    }
    this.refreshExpiration = expiration;
  }

  async get<T>(path: string, query?: Record<string, unknown>): Promise<T> {
    try {
      const response = await fetch(path + parseQuery(query), {
        method: "GET",
        headers: getHeaders(),
      }).catch((error) => {
        throw error;
      });
      return response.status === 401
        ? this.tryRefreshToken(() => this.get(path, query))
        : handleResponse(response);
    } catch (error) {
      throw handleError(error);
    }
  }

  async post<T>(
    path: string,
    data: unknown,
    query?: Record<string, unknown>,
  ): Promise<T> {
    try {
      const response = await fetch(path + parseQuery(query), {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data),
      });

      return response.status === 401
        ? this.tryRefreshToken(() => this.post(path, data, query))
        : handleResponse(response);
    } catch (error) {
      throw handleError(error);
    }
  }

  async upload<T>(
    path: string,
    file: File,
    formData?: { key: string; value: string }[],
    query?: Record<string, unknown>,
  ): Promise<T> {
    const form = new FormData();
    form.append("file", file);
    formData?.forEach(({ key, value }) => {
      form.append(key, value);
    });

    try {
      const response = await fetch(path + parseQuery(query), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.value?.accessToken ?? ""}`, // do not set content-type here
        },
        body: form,
      });

      return response.status === 401
        ? this.tryRefreshToken(() => this.upload(path, file, formData, query))
        : handleResponse(response);
    } catch (error) {
      throw handleError(error);
    }
  }

  async put<T>(
    path: string,
    data: unknown,
    query?: Record<string, unknown>,
  ): Promise<T> {
    try {
      const response = await fetch(path + parseQuery(query), {
        method: "PUT",
        headers: getHeaders(),
        body: JSON.stringify(data),
      });

      return response.status === 401
        ? this.tryRefreshToken(() => this.put(path, data, query))
        : handleResponse(response);
    } catch (error) {
      throw handleError(error);
    }
  }

  async patch<T>(
    path: string,
    data: unknown,
    query?: Record<string, unknown>,
  ): Promise<T> {
    try {
      const response = await fetch(path + parseQuery(query), {
        method: "PATCH",
        headers: getHeaders(),
        body: JSON.stringify(data),
      });

      return response.status === 401
        ? this.tryRefreshToken(() => this.patch(path, data, query))
        : handleResponse(response);
    } catch (error) {
      throw handleError(error);
    }
  }

  async delete<T>(path: string, query?: Record<string, unknown>): Promise<T> {
    try {
      const response = await fetch(path + parseQuery(query), {
        method: "DELETE",
        headers: getHeaders(),
      });

      return response.status === 401
        ? this.tryRefreshToken(() => this.delete(path, query))
        : handleResponse(response);
    } catch (error) {
      throw handleError(error);
    }
  }

  async tryRefreshToken<T>(retry: () => Promise<T>) {
    return this.refreshMutex.runExclusive(async () => {
      if (!isAuthenticated || !user) throw new Error("User is not logged in");

      // Check if refresh was made already
      if (this.refreshExpiration > new Date()) {
        return retry();
      }

      const req = {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({ refreshToken: user.value?.refreshToken }),
      };

      await fetch("/api/auth/refresh", req)
        .then(handleResponse<RefreshResponse>)
        .then(updateToken)
        .then(this.setExpirationValue.bind(this))
        .catch((e) => {
          console.error(
            "Something went wrong when trying to refreshing the token",
            e,
          );
          clearAuth();
        });

      return retry();
    });
  }
}

function getHeaders() {
  const headers = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
  });

  if (isAuthenticated && user) {
    headers.set("Authorization", `Bearer ${user.value?.accessToken ?? ""}`);
  }

  return headers;
}

async function handleError(value: unknown) {
  if (isResponse(value)) {
    value = new ApiError(value);
  }

  throw value;
}

async function handleResponse<T>(response: Response): Promise<T> {
  if (response.ok) {
    if (response.status === 204) {
      return null as T;
    }
    return response.json();
  }

  const err = await response.json();
  throw new ApiError(response, err.message || "No message");
}

export const api = new Api();
