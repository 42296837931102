import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useDeferredValue,
} from "react";
import { cn } from "@shared/utils/css.ts";
import { Input } from "@shared/primitives/input.tsx";
import { Search } from "lucide-react";

export const SearchBar = forwardRef<
  ElementRef<typeof Input>,
  ComponentPropsWithoutRef<typeof Input>
>(({ className, ...props }, ref) => {
  useDeferredValue(props.value);

  const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(event);
  };

  return (
    <div className="relative">
      <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
        <Search className="text-primary-icon-color" size={17} />
      </div>
      <Input
        type="search"
        ref={ref}
        {...props}
        id="default-search"
        className={cn("pe-2 ps-10", className)}
        placeholder={props.placeholder}
        value={props.value}
        onChange={onClick}
        required
      />
    </div>
  );
});
