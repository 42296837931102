export class ApiError extends Error {
  status: number;
  url: string;
  headers: Record<string, string>;
  message: string;

  constructor(response: Response, message?: string) {
    const { status, statusText, url } = response;
    super(statusText);
    this.message = message ?? statusText;
    this.status = status;
    this.url = url;
    this.headers = Object.fromEntries(response.headers.entries());
  }

  toString() {
    return `${this.name}: ${this.message} (${this.status})`;
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      status: this.status,
      url: this.url,
      headers: this.headers,
      stack: this.stack?.split("\n").map((line) => line.trim()),
    };
  }
}
