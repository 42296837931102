import { Form } from "react-router-dom";
import { Input } from "@shared/primitives/input.tsx";
import { Button } from "@shared/primitives/button.tsx";
import { useState } from "react";
import { Asterisk } from "lucide-react";

type InputProps = {
  onSubmit: (value: string) => void;
  buttonText: string;
  inputName: string;
  placeholder: string;
  required: boolean;
  label?: string;
  defaultValue?: string;
};

export const DefaultInput = ({ ...props }: InputProps) => {
  const [value, setValue] = useState<string>(props.defaultValue ?? "");

  return (
    <Form
      onSubmit={() => props.onSubmit(value)}
      className="flex flex-grow flex-col justify-between"
    >
      <div>
        {props.label && (
          <label className="inline-flex px-1 text-xs" htmlFor={props.inputName}>
            {props.label}
            {props.required && <Asterisk size="10" className="text-red-500" />}
          </label>
        )}
        <Input
          required={props.required}
          name={props.inputName}
          type="text"
          value={value}
          placeholder={props.placeholder}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="flex justify-end">
        <Button
          variant="primary"
          type="submit"
          disabled={
            props.defaultValue
              ? value === props.defaultValue || (props.required && value === "")
              : props.required && value === ""
          }
        >
          {props.buttonText}
        </Button>
      </div>
    </Form>
  );
};
