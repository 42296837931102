import { useProfileContext } from "@context/profile-context";
import { Button } from "@primitives/button";
import { CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCategoriesContext } from "../categories-context";
import { CategoryType } from "../../../../../../api-contracts/categories";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
} from "@primitives/dropdown-menu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { ChevronDown } from "lucide-react";

export const CardHeader = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const { categoryTypes, setSelectedCategoryType, selectedCategoryType } =
    useCategoriesContext();

  const getCategoryTitle = (selectedCategoryType: CategoryType[]) => {
    let title;
    if (selectedCategoryType.includes("room")) {
      title = t("room-categories");
    } else if (selectedCategoryType.includes("area")) {
      title = t("area-categories");
    } else if (selectedCategoryType.includes("bed")) {
      title = t("hostel-categories");
    } else if (selectedCategoryType.includes("tent")) {
      title = t("tent-categories");
    } else if (selectedCategoryType.includes("motorhome")) {
      title = t("motor-home-categories");
    } else if (selectedCategoryType.includes("caravan")) {
      title = t("caravan-categories");
    } else if (selectedCategoryType.includes("cabin")) {
      title = t("cabin-categories");
    } else if (selectedCategoryType.includes("glampingtent")) {
      title = t("glamping-categories");
    } else if (selectedCategoryType.includes("misc")) {
      title = t("misc-categories");
    }

    return title;
  };

  const getDropdownMenuTitle = (selectedCategoryType: CategoryType) => {
    let dropdownTitle;

    if (selectedCategoryType.includes("bed")) {
      dropdownTitle = t("new-bed-category");
    }
    if (selectedCategoryType.includes("dormitory")) {
      dropdownTitle = t("new-dormitory-category");
    }
    return dropdownTitle;
  };

  const CreateNewCategoryButton = () => {
    if (categoryTypes.length < 2) {
      return (
        <Button
          onClick={() => onCreateNew(categoryTypes[0])}
          variant={"primary"}
        >
          {t("create-new")}
        </Button>
      );
    } else {
      return (
        <DropdownMenuRoot>
          <DropdownMenuTrigger
            asChild
            className="flex items-center justify-center"
          >
            <Button variant={"primary"}>
              {t("create-new")} <ChevronDown size={15} className="ml-1" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="flex flex-col">
            {categoryTypes.map((categoryType) => {
              return (
                <DropdownMenuItem
                  key={categoryType}
                  onClick={() => onCreateNew(categoryType)}
                >
                  <p>{`${getDropdownMenuTitle(categoryType)}`}</p>
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenuRoot>
      );
    }
  };

  const categoryTitle = getCategoryTitle(categoryTypes);

  const onCreateNew = (selectedCategory: CategoryType) => {
    setSelectedCategoryType(selectedCategory);
    navigate(`/${module}/categories/new`);
  };

  return (
    <>
      <div className="flex items-center justify-between pb-4">
        <div className="">
          <CardTitle>{categoryTitle}</CardTitle>
        </div>
        <div>
          <CreateNewCategoryButton />
        </div>
      </div>
      <div className="mx-[-16px] border-b border-highlighted-backplate" />
    </>
  );
};
