import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import { GetBooking } from "../../../../../../api-contracts/reservations";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { Label } from "@primitives/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useGetPriceRules } from "@api/price-rules";
import { useEffect, useMemo, useState } from "react";
import {
  format,
  eachDayOfInterval,
  isSameDay,
  addDays,
  subDays,
} from "date-fns";
import { useGetCategories } from "@api/categories";
import { useGetAllocableBookables } from "@api/bookables";
import { DateRangePicker } from "@primitives/date-range-picker";
import { DateRange } from "react-day-picker";
import { TimeSlot } from "../../../../../../api-contracts/reservations/timeslots";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import { uniqueId } from "lodash";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  availableOptions?: "asset" | "price" | "dates" | "all";
  onProceed: (updatedBooking: GetBooking) => void;
  booking: GetBooking;
}

export const BookingChangeDrawer = ({
  open,
  onOpenChange,
  availableOptions,
  onProceed,
  booking,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [changedBooking, setChangedBooking] = useState<GetBooking>(booking);
  const [priceOption, setPriceOption] = useState<
    | "original-price"
    | "price-according-to-new-category"
    | "enter-new-price-code"
  >("original-price");

  useEffect(() => {
    setChangedBooking(booking);
    setPriceOption("original-price");
  }, [booking, availableOptions, open]);

  const {
    data: priceRules,
    isLoading: priceRulesIsLoading,
    isRefetching: priceRulesIsRefetching,
  } = useGetPriceRules({});

  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isRefetching: categoriesIsRefetching,
  } = useGetCategories({
    variables: {
      categoryTypes: ["room", "area", "bed", "dormitory"],
    },
  });

  const {
    data: bookables,
    isLoading: bookablesIsLoading,
    isRefetching: bookablesIsRefetching,
  } = useGetAllocableBookables({
    variables: {
      bookingId: changedBooking.id,
      categoryId: changedBooking.category.id,
      startDate: format(changedBooking.checkin, "yyyy-MM-dd"),
      endDate: format(changedBooking.checkout, "yyyy-MM-dd"),
    },
  });

  const numGuests = useMemo(() => {
    return {
      adults: booking.guests.filter((g) => g.ageType === "adult").length,
      teenagers: booking.guests.filter((g) => g.ageType === "teenager").length,
      children: booking.guests.filter((g) => g.ageType === "child").length,
      infants: booking.guests.filter((g) => g.ageType === "infant").length,
    };
  }, [booking]);

  const total = useMemo(() => {
    return changedBooking.slots.reduce((acc, slot) => {
      return acc + slot.price;
    }, 0);
  }, [changedBooking]);

  const handleDatesChange = (values: { range: DateRange }) => {
    if (values.range.from && values.range.to) {
      updateState(
        changedBooking.slots.map((s) => ({
          date: s.start,
          priceRuleId: s.priceRuleId,
          priceOverrideAmount: s.priceOverrideAmount,
          priceAdjustmentPercent: s.priceAdjustmentPercent,
        })),
        changedBooking.category.id,
        changedBooking.bookable?.id || null,
        values.range.from,
        values.range.to,
      );
    }
  };

  const updateState = (
    rules: {
      date: string | Date;
      priceRuleId: string | null;
      priceOverrideAmount: number | null;
      priceAdjustmentPercent: number | null;
    }[],
    categoryId: string,
    bookableId: string | null,
    checkin: Date | string,
    checkout: Date | string,
  ) => {
    const category = categories?.find((c) => c.id === categoryId);
    if (!category) {
      setChangedBooking({ ...changedBooking });
      return;
    }

    const bookable =
      bookables?.bookables.find((b) => b.id === bookableId) ||
      (categoryId === booking.category.id && bookableId === booking.bookable?.id
        ? booking.bookable
        : undefined);

    const dates = eachDayOfInterval({
      start: checkin,
      end: subDays(checkout, 1),
    });
    const newSlots: TimeSlot[] = dates.map((date) => {
      const slot = booking.slots.find((s) => isSameDay(s.start, date));
      const rule = rules.find((r) => isSameDay(date, r.date));
      const priceRuleId = rule?.priceRuleId || null;
      const priceOverrideAmount = rule?.priceOverrideAmount || null;
      const priceAdjustmentPercent = rule?.priceAdjustmentPercent || null;

      const categoryPrice = priceRules?.categories.find(
        (c) => c.id === categoryId,
      );
      const categoryOverride = categoryPrice?.overrides.find(
        (o) =>
          o.adults === numGuests.adults &&
          o.children === numGuests.children &&
          o.teenagers === numGuests.teenagers &&
          o.infants === numGuests.infants,
      );
      const priceRule = priceRules?.rules
        .find((r) => priceRuleId && r.id === priceRuleId)
        ?.entries.find((e) => e.categoryId === categoryId);
      const priceRuleOverride = priceRule?.overrides.find(
        (o) =>
          o.adults === numGuests.adults &&
          o.children === numGuests.children &&
          o.teenagers === numGuests.teenagers &&
          o.infants === numGuests.infants,
      );
      let fullPrice =
        priceRuleOverride?.price ||
        priceRule?.price ||
        categoryOverride?.price ||
        categoryPrice?.basePrice ||
        0;

      let price = priceOverrideAmount || fullPrice;

      if (priceAdjustmentPercent) {
        price = price * (1 - 0.01 * priceAdjustmentPercent);
      }

      return {
        id: slot?.id || "new" + uniqueId(),
        price: price,
        start: format(date, "yyyy-MM-dd"),
        end: format(addDays(date, 1), "yyyy-MM-dd"),
        priceRuleId: priceRuleId,
        fullPrice: fullPrice,
        priceOverrideAmount: priceOverrideAmount,
        priceAdjustmentPercent: priceAdjustmentPercent,
      };
    });

    setChangedBooking({
      ...changedBooking,
      checkin: format(checkin, "yyyy-MM-dd"),
      checkout: format(checkout, "yyyy-MM-dd"),
      slots: newSlots,
      category: category,
      bookable: bookable ? { ...bookable, lockedToBookable: false } : null,
    });
  };

  const handlePriceOptionChange = (
    option:
      | "original-price"
      | "price-according-to-new-category"
      | "enter-new-price-code",
  ) => {
    setPriceOption(option);

    switch (option) {
      case "original-price":
        updateState(
          changedBooking.slots.map((s) => {
            const oldSlot = booking.slots.find((sOld) => sOld.id === s.id);

            return {
              date: s.start,
              priceRuleId: oldSlot?.priceRuleId || null,
              priceOverrideAmount: oldSlot?.priceOverrideAmount || null,
              priceAdjustmentPercent: oldSlot?.priceAdjustmentPercent || null,
            };
          }),
          changedBooking.category.id,
          changedBooking.bookable?.id || null,
          changedBooking.checkin,
          changedBooking.checkout,
        );
        break;
      case "price-according-to-new-category":
      case "enter-new-price-code":
        updateState(
          changedBooking.slots.map((s) => ({
            date: s.start,
            priceRuleId: s.priceRuleId,
            priceOverrideAmount: null,
            priceAdjustmentPercent: null,
          })),
          changedBooking.category.id,
          changedBooking.bookable?.id || null,
          changedBooking.checkin,
          changedBooking.checkout,
        );
        break;
    }
  };

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {availableOptions === "price" && t("change-price-code")}
              {availableOptions === "asset" && t("change-unit")}
              {availableOptions === "dates" && t("change-dates")}
              {availableOptions === "all" && t("change-booking")}
            </SheetTitle>
          </SheetHeader>

          <div className=" flex-1 overflow-y-auto text-primary-text">
            {(availableOptions === "dates" || availableOptions === "all") && (
              <div className="mt-4 bg-secondary-card-backplate p-4">
                <h3 className=" mb-4 text-lg font-bold">{t("change-dates")}</h3>
                <div>
                  <DateRangePicker
                    min={2}
                    initialDateFrom={changedBooking.checkin}
                    initialDateTo={changedBooking.checkout}
                    onUpdate={handleDatesChange}
                    disabled={{
                      before: new Date(),
                    }}
                  />
                </div>
              </div>
            )}

            {(availableOptions === "asset" || availableOptions === "all") && (
              <div className="mt-4 bg-secondary-card-backplate p-4">
                <h3 className=" mb-4 text-lg font-bold">{t("change-unit")}</h3>
                <div>
                  <Label htmlFor="booking-change-category">
                    {t("category")}
                  </Label>
                  <Select
                    key={changedBooking.category.id}
                    disabled={booking.bookable?.lockedToBookable}
                    value={changedBooking.category.id}
                    onValueChange={(value) => {
                      updateState(
                        changedBooking.slots.map((s) => ({
                          date: s.start,
                          priceRuleId: s.priceRuleId,
                          priceOverrideAmount: s.priceOverrideAmount,
                          priceAdjustmentPercent: s.priceAdjustmentPercent,
                        })),
                        value,
                        null,
                        changedBooking.checkin,
                        changedBooking.checkout,
                      );
                    }}
                  >
                    <SelectTrigger
                      className=" w-full"
                      id="booking-change-category"
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {categories?.map((c) => (
                        <SelectItem
                          key={c.id}
                          value={c.id}
                          className=" text-xs"
                        >
                          {t(c.name)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="mt-2">
                  <Label htmlFor="booking-change-asset">{t("unit")}</Label>
                  <Select
                    key={changedBooking.bookable?.id}
                    disabled={booking.bookable?.lockedToBookable}
                    value={changedBooking.bookable?.id}
                    onValueChange={(value) => {
                      updateState(
                        changedBooking.slots.map((s) => ({
                          date: s.start,
                          priceRuleId: s.priceRuleId,
                          priceOverrideAmount: s.priceOverrideAmount,
                          priceAdjustmentPercent: s.priceAdjustmentPercent,
                        })),
                        changedBooking.category.id,
                        value,
                        changedBooking.checkin,
                        changedBooking.checkout,
                      );
                    }}
                  >
                    <SelectTrigger
                      className=" w-full"
                      id="booking-change-asset"
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {booking.bookable &&
                        changedBooking.category.id === booking.category.id && (
                          <SelectItem
                            value={booking.bookable.id}
                            className=" text-xs"
                          >
                            {t(booking.bookable.name)}
                          </SelectItem>
                        )}

                      {bookables?.bookables?.map((b) => (
                        <SelectItem
                          key={b.id}
                          value={b.id}
                          className=" text-xs"
                        >
                          {t(b.name)}
                        </SelectItem>
                      ))}
                      {bookables?.bookables.length === 0 &&
                        (changedBooking.category.id !== booking.category.id ||
                          !booking.bookable) && (
                          <p className="p-2 text-center text-sm font-normal text-secondary-text">
                            {t("no-units-available")}
                          </p>
                        )}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}

            {(availableOptions === "price" ||
              availableOptions === "dates" ||
              availableOptions === "asset" ||
              availableOptions === "all") && (
              <div className="mt-4 bg-secondary-card-backplate p-4">
                <h3 className=" mb-4 text-lg font-bold">
                  {t("change-price-code")}
                </h3>

                {(availableOptions === "dates" ||
                  availableOptions === "asset" ||
                  availableOptions === "all") && (
                  <RadioGroup
                    className="mb-4"
                    value={priceOption}
                    onValueChange={(v) =>
                      handlePriceOptionChange(
                        v as
                          | "original-price"
                          | "price-according-to-new-category"
                          | "enter-new-price-code",
                      )
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value="original-price"
                        id="original-price"
                      />
                      <Label htmlFor="original-price">
                        {t("keep-original-price")}
                      </Label>
                    </div>
                    <div className="mt-2 flex items-center space-x-2">
                      <RadioGroupItem
                        value="price-according-to-new-category"
                        id="price-according-to-new-category"
                      />
                      <Label htmlFor="price-according-to-new-category">
                        {t("price-according-to-new-category")}
                      </Label>
                    </div>
                    <div className="mt-2 flex items-center space-x-2">
                      <RadioGroupItem
                        value="enter-new-price-code"
                        id="enter-new-price-code"
                      />
                      <Label htmlFor="enter-new-price-code">
                        {t("enter-new-price-code")}
                      </Label>
                    </div>
                  </RadioGroup>
                )}

                {priceOption === "enter-new-price-code" && (
                  <>
                    <Label htmlFor="booking-change-price-code">
                      {t("change-price-code-for-all-nights")}
                    </Label>
                    <Select
                      value={
                        changedBooking.slots.every(
                          (s) =>
                            s.priceRuleId ===
                            changedBooking.slots[0].priceRuleId,
                        )
                          ? changedBooking.slots[0].priceRuleId || "base"
                          : undefined
                      }
                      onValueChange={(value) => {
                        if (value === "base") {
                          updateState(
                            changedBooking.slots.map((s) => ({
                              date: s.start,
                              priceRuleId: null,
                              priceOverrideAmount: null,
                              priceAdjustmentPercent: null,
                            })),
                            changedBooking.category.id,
                            changedBooking.bookable?.id || null,
                            changedBooking.checkin,
                            changedBooking.checkout,
                          );
                        } else {
                          updateState(
                            changedBooking.slots.map((s) => ({
                              date: s.start,
                              priceRuleId: value,
                              priceOverrideAmount: null,
                              priceAdjustmentPercent: null,
                            })),
                            changedBooking.category.id,
                            changedBooking.bookable?.id || null,
                            changedBooking.checkin,
                            changedBooking.checkout,
                          );
                        }
                      }}
                    >
                      <SelectTrigger
                        className=" w-full"
                        id="booking-change-price-code"
                      >
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="base" className=" text-xs">
                          {t("base-price")}
                        </SelectItem>
                        {priceRules?.rules
                          .filter((r) => r.active)
                          .map((r) => (
                            <SelectItem
                              key={r.id}
                              value={r.id}
                              className=" text-xs"
                            >
                              {t(r.name)}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>

                    <div className="mt-2">
                      {changedBooking.slots.map((slot, i) => (
                        <div
                          key={i}
                          className=" flex items-center justify-between border-t border-border-color py-2"
                        >
                          <p className=" text-sm font-bold">
                            {format(slot.start, "d MMMM")}
                          </p>
                          <div className=" w-1/2">
                            <Select
                              value={
                                changedBooking.slots[i].priceRuleId || "base"
                              }
                              onValueChange={(value) => {
                                const id = value === "base" ? null : value;
                                updateState(
                                  changedBooking.slots.map((s, j) => ({
                                    date: s.start,
                                    priceRuleId: j === i ? id : s.priceRuleId,
                                    priceOverrideAmount: null,
                                    priceAdjustmentPercent: null,
                                  })),
                                  changedBooking.category.id,
                                  changedBooking.bookable?.id || null,
                                  changedBooking.checkin,
                                  changedBooking.checkout,
                                );
                              }}
                            >
                              <SelectTrigger
                                className=" w-full"
                                id="price-code"
                              >
                                <SelectValue />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="base" className=" text-xs">
                                  {t("base-price")}
                                </SelectItem>
                                {priceRules?.rules
                                  .filter((r) => r.active)
                                  .map((r) => (
                                    <SelectItem
                                      key={r.id}
                                      value={r.id}
                                      className=" text-xs"
                                    >
                                      {t(r.name)}
                                    </SelectItem>
                                  ))}
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className=" flex items-center justify-between space-x-2">
            <p className=" text-md font-bold">
              {t("total")}:{" "}
              {new Intl.NumberFormat(
                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                { maximumFractionDigits: 2 },
              ).format(total)}{" "}
              SEK
            </p>
            <div className=" flex justify-end space-x-2 ">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                onClick={() => {
                  onProceed(changedBooking);
                  onOpenChange(false);
                }}
              >
                {t("apply")}
              </Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default BookingChangeDrawer;
