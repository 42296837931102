import {
  CreateTagRequest,
  CreateTagResponse,
  GetTagsResponse,
  PatchTagRequest,
  PatchTagResponse,
} from "../../../../api-contracts/tags";
import { api } from "./api";
import { currentSite } from "@context/site-context";
import { createMutation, createQuery } from "react-query-kit";

export const TAGS_QUERY_KEY = "tags";

// GET /{siteId}/tags
export const useGetTags = createQuery<GetTagsResponse>({
  queryFn: async () => api.get(`/api/${currentSite?.id}/${TAGS_QUERY_KEY}`),
  primaryKey: TAGS_QUERY_KEY,
});

// POST /{siteId}/tags
export const usePostTag = createMutation<CreateTagResponse, CreateTagRequest>({
  mutationFn: async (tag) =>
    api.post(`/api/${currentSite?.id}/${TAGS_QUERY_KEY}`, tag),
});

// PATCH /{siteId}/tags/:id
export const usePatchTagById = createMutation<
  PatchTagResponse,
  { patch: PatchTagRequest; id: string }
>({
  mutationFn: async (tag) =>
    api.patch(`/api/${currentSite?.id}/${TAGS_QUERY_KEY}/${tag.id}`, tag.patch),
});

// DELETE /{siteId}/tags/:id
export const useDeleteTag = createMutation<null, { id: string }>({
  mutationFn: async (tag) =>
    api.delete(`/api/${currentSite?.id}/${TAGS_QUERY_KEY}/${tag.id}`),
});
