import { NavigationArrows } from "@components/navigation-arrows";
import { TabNavbar } from "@components/tab-navbar";
import { Button } from "@primitives/button";
import { Card } from "@primitives/card";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label";
import {
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { SearchBarWithIcon } from "@primitives/search-bar-with-icon";
import { ROUTES } from "@shared/types/navigation";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { Filter } from "lucide-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CaretSortIcon } from "@radix-ui/react-icons";
import {
  useAllocateAllReservations,
  useGetUnallocatedReservations,
} from "@api/reservations";
import { useProfileContext } from "@context/profile-context";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import { UnallocatedBooking } from "../../../../../api-contracts/reservations/allocation/index";
import { bookableStatusToColor } from "../category-grid/index.tsx";
import { AllocateBookableDialog } from "./components/allocate-bookable-dialog.tsx";
import { queryClient } from "../../../query-client.ts";
import { useToast } from "@hooks/use-toast";
import { ConfirmDialog } from "@pages/settings/categories/components/confirm-dialog.tsx";

type ColumnsShown = {
  booking: boolean;
  bookingStatus: boolean;
  arrivalDate: boolean;
  departureDate: boolean;
  numNights: boolean;
  category: boolean;
  firstName: boolean;
  lastName: boolean;
  guests: boolean;
  paymentLeft: boolean;
  allocation: boolean;
};

const UnallocatedPage = () => {
  const [t, i18n] = useTranslation();
  const params = useParams();
  const [search, setSearch] = useState<string>("");
  const [allocateReservation, setAllocateReservation] =
    useState<UnallocatedBooking>();
  const [allocateAllOpen, setAllocateAllOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [columnsShown, setColumnsShown] = useState<ColumnsShown>({
    booking: true,
    bookingStatus: true,
    arrivalDate: true,
    departureDate: true,
    numNights: true,
    category: true,
    firstName: true,
    lastName: true,
    guests: true,
    paymentLeft: true,
    allocation: true,
  });
  const { toast } = useToast();

  const allocateAll = useAllocateAllReservations();

  const from = params.from ? new Date(params.from) : new Date();
  const to = params.to ? new Date(params.to) : new Date();

  const { data, isLoading, isRefetching } = useGetUnallocatedReservations({
    variables: {
      startDate: from,
      endDate: to,
    },
  });

  const bookings: UnallocatedBooking[] = useMemo(() => {
    if (search && data) {
      const searchLower = search.toLowerCase();
      return data?.bookings.filter(
        (r) =>
          r.arrivalDate?.toLowerCase().includes(searchLower) ||
          r.departureDate?.toLowerCase().includes(searchLower) ||
          r.categoryShortName?.toLowerCase().includes(searchLower) ||
          r.holderFirstName?.toLowerCase().includes(searchLower) ||
          r.holderLastName?.toLowerCase().includes(searchLower) ||
          r.paymentLeft?.toString()?.toLowerCase().includes(searchLower) ||
          t(r.bookingStatus)?.toLowerCase().includes(searchLower) ||
          r.numNights.toString()?.toLowerCase().includes(searchLower) ||
          r.id.toString()?.toLowerCase().includes(searchLower),
      );
    }
    return data?.bookings || [];
  }, [search, data]);

  const allocate = async () => {
    try {
      const response = await allocateAll.mutateAsync({
        start: format(from, "yyyy-MM-dd"),
        end: format(to, "yyyy-MM-dd"),
      });

      queryClient.invalidateQueries({
        queryKey: useGetUnallocatedReservations.getKey(),
      });

      if (response.nonAllocated.length) {
        toast({
          title: t("non-complete-allocation"),
          description: t(
            "could-not-allocate-all-please-allocate-the-remaining-reservations-manually",
          ),
          className: "text-status-success",
          variant: "success",
        });
      } else {
        toast({
          title: t("units-assigned"),
          description: t(
            "all-bookings-with-arrivals-between-from-and-to-are-now-assigned-units",
            { from: format(from, "yyyy-MM-dd"), to: format(to, "yyyy-MM-dd") },
          ),
          className: "text-status-success",
          variant: "success",
        });
      }
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  const columns: ColumnDef<UnallocatedBooking>[] = [];
  if (columnsShown.booking) {
    columns.push({
      accessorKey: "id",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("booking")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "id",
    });
  }
  if (columnsShown.bookingStatus) {
    columns.push({
      accessorKey: "bookingStatus",
      cell: (row: any) => (
        <p
          className={` inline-block rounded-s p-[6px] text-xs font-extrabold bg-${bookableStatusToColor(row.row.original.bookingStatus)}-100 text-${bookableStatusToColor(row.row.original.bookingStatus)}`}
        >
          {t(row.row.original.bookingStatus)}
        </p>
      ),
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("booking-status")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "bookingStatus",
    });
  }
  if (columnsShown.arrivalDate) {
    columns.push({
      accessorKey: "arrivalDate",
      cell: (row: any) => format(row.row.original.arrivalDate, "yyyy-MM-dd"),
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("arrival-date")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "arrivalDate",
    });
  }
  if (columnsShown.departureDate) {
    columns.push({
      accessorKey: "departureDate",
      cell: (row: any) => format(row.row.original.departureDate, "yyyy-MM-dd"),
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("departure-date")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "departureDate",
    });
  }
  if (columnsShown.numNights) {
    columns.push({
      accessorKey: "numNights",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("number-of-nights")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "numNights",
    });
  }
  if (columnsShown.category) {
    columns.push({
      accessorKey: "categoryShortName",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("category")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "categoryShortName",
    });
  }
  if (columnsShown.firstName) {
    columns.push({
      accessorKey: "holderFirstName",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("first-name")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "holderFirstName",
    });
  }
  if (columnsShown.lastName) {
    columns.push({
      accessorKey: "holderLastName",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("last-name")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "holderLastName",
    });
  }
  if (columnsShown.guests) {
    columns.push({
      accessorKey: "adults",
      cell: (row: any) => (
        <>
          <p>
            {row.row.original.adults ? (
              <>
                {row.row.original.adults}({t("adults-short")}){" "}
              </>
            ) : undefined}
            {row.row.original.teenagers ? (
              <>
                {row.row.original.teenagers}({t("teenagers-short")}){" "}
              </>
            ) : undefined}
            {row.row.original.children ? (
              <>
                {row.row.original.children}({t("children-short")}){" "}
              </>
            ) : undefined}
            {row.row.original.infants ? (
              <>
                {row.row.original.infants}({t("infants-short")}){" "}
              </>
            ) : undefined}
          </p>
        </>
      ),
      accessorFn: (row: UnallocatedBooking) =>
        `${row.adults ? `${row.adults}${t("adults-short")} ` : ""}${row.teenagers ? `${row.teenagers}${t("teenagers-short")} ` : ""}${row.children ? `${row.children}${t("children-short")} ` : ""}${row.infants ? `${row.infants}${t("infants-short")} ` : ""}`,
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("guests")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "adults",
    });
  }
  if (columnsShown.paymentLeft) {
    columns.push({
      accessorKey: "paymentLeft",
      cell: (row: any) =>
        row.row.original.paymentLeft ? (
          <Button variant="outline" size="sm">
            {row.row.original.paymentLeft} SEK
          </Button>
        ) : (
          <p>{row.row.original.paymentLeft} SEK</p>
        ),
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("payment-left")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "paymentLeft",
    });
  }
  if (columnsShown.allocation) {
    columns.push({
      cell: (row: any) => (
        <>
          <Button
            variant="outline"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAllocateReservation(row.row.original);
            }}
          >
            {t("assign")}
          </Button>
        </>
      ),
      header: t("allocation"),
      enableSorting: false,
      size: 1,
      id: "allocation",
    });
  }

  return (
    <>
      <div className="p-4 text-primary-text">
        <NavigationArrows rootPath={`/${ROUTES.CATEGORY_GRID_HOTEL}`} />
        <div className="mt-4">
          <TabNavbar
            value="hotel"
            items={[
              { value: "hotel", label: t("hotel"), disabled: false },
              {
                value: "surface-area",
                label: t("surface-area"),
                disabled: true,
              },
              { value: "hostel", label: t("hostel"), disabled: true },
            ]}
          />
        </div>
        <Card className="mt-4">
          <div className="mt-2 flex flex-nowrap justify-between">
            <div>
              <h1 className=" text-3xl font-bold">
                {t("bookings-without-allocated-units")}
              </h1>
              <p className=" text-sm font-medium text-secondary-text">
                {t("period")}: {format(from, "yyyy-MM-dd")} -{" "}
                {format(to, "yyyy-MM-dd")}
              </p>
            </div>
            <Button onClick={() => setAllocateAllOpen(true)}>
              {t("assign-all")}
            </Button>
          </div>

          <div className="mt-4 flex flex-nowrap justify-between">
            <SearchBarWithIcon
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              setSearchTerm={setSearch}
              searchTerm={search}
            />
            <DropdownMenuRoot>
              <DropdownMenuTrigger asChild>
                <Button variant="secondary">
                  <Filter className="mr-2" size={17} />
                  {t("show")}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>{t("show-columns")}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <div className=" px-4">
                  <CheckboxWithLabel
                    checked={columnsShown.booking}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        booking: v as boolean,
                      })
                    }
                    label={t("booking")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.bookingStatus}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        bookingStatus: v as boolean,
                      })
                    }
                    label={t("booking-status")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.arrivalDate}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        arrivalDate: v as boolean,
                      })
                    }
                    label={t("arrival-date")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.departureDate}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        departureDate: v as boolean,
                      })
                    }
                    label={t("departure-date")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.numNights}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        numNights: v as boolean,
                      })
                    }
                    label={t("number-of-nights")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.category}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        category: v as boolean,
                      })
                    }
                    label={t("category")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.firstName}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        firstName: v as boolean,
                      })
                    }
                    label={t("first-name")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.lastName}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        lastName: v as boolean,
                      })
                    }
                    label={t("last-name")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.guests}
                    onCheckedChange={(v) =>
                      setColumnsShown({ ...columnsShown, guests: v as boolean })
                    }
                    label={t("guests")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.paymentLeft}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        paymentLeft: v as boolean,
                      })
                    }
                    label={t("payment-left")}
                  />
                  <CheckboxWithLabel
                    checked={columnsShown.allocation}
                    onCheckedChange={(v) =>
                      setColumnsShown({
                        ...columnsShown,
                        allocation: v as boolean,
                      })
                    }
                    label={t("allocation")}
                  />
                </div>
              </DropdownMenuContent>
            </DropdownMenuRoot>
          </div>

          <div className="mt-4">
            <ReservationsTable columns={columns} data={bookings} />
          </div>
        </Card>
      </div>
      {allocateReservation && (
        <AllocateBookableDialog
          from={new Date(allocateReservation.arrivalDate)}
          to={new Date(allocateReservation.departureDate)}
          open={!!allocateReservation}
          onOpenChange={() => setAllocateReservation(undefined)}
          reservationId={allocateReservation.id}
          holderName={`${allocateReservation.holderFirstName} ${allocateReservation.holderLastName}`}
          reservationCategoryId={allocateReservation.categoryId}
        />
      )}
      <ConfirmDialog
        proceedBtnText={t("assign")}
        title={t("assign-units")}
        description={t(
          "are-you-sure-you-want-to-assign-units-to-all-bookings-with-arrivals-between-from-and-to?",
          { from: format(from, "yyyy-MM-dd"), to: format(to, "yyyy-MM-dd") },
        )}
        onOpenChange={setAllocateAllOpen}
        isOpen={allocateAllOpen}
        onProceed={allocate}
      />
    </>
  );
};

interface ReservationsTableProps {
  columns: ColumnDef<UnallocatedBooking>[];
  data: UnallocatedBooking[];
}

const ReservationsTable: React.FC<ReservationsTableProps> = ({
  columns,
  data,
}) => {
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });
  const { t } = useTranslation();

  return (
    <div className=" rounded-md border border-main-border-color bg-primary-card-backplate">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className=" cursor-pointer text-primary-text"
                data-state={row.getIsSelected() && "selected"}
                onClick={() =>
                  navigate(`/${module}/reservations/${row.original.id}`)
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="h-24 text-center text-primary-text"
              >
                {t("no-results")}.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default UnallocatedPage;
