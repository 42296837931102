import {
  CreateAssetGroupResponse,
  CreateAssetGroupRequest,
  DeleteAssetGroupResponse,
  PatchAssetGroupRequest,
  PatchAssetGroupResponse,
  CreateCategoryGroupRequest,
  CreateCategoryGroupResponse,
  GetCategoryGroupsResponse,
  DeleteCategoryGroupResponse,
  PatchCategoryGroupResponse,
  PatchCategoryGroupRequest,
  GetAssetsGroupsResponse,
} from "../../../../api-contracts/groups";
import { currentSite } from "@shared/context/site-context.tsx";
import { api } from "./api.ts";
import { createMutation, createQuery } from "react-query-kit";

export const BOOKABLE_GROUPS_QUERY_KEY = "asset-groups";
export const CATEGORY_GROUPS_QUERY_KEY = "category-groups";

// GET /{siteId}/asset-groups
export const useGetAssetGroups = createQuery<GetAssetsGroupsResponse>({
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${BOOKABLE_GROUPS_QUERY_KEY}`),
  primaryKey: BOOKABLE_GROUPS_QUERY_KEY,
});

// POST /{siteId}/asset-groups
export const useCreateAssetGroup = createMutation<
  CreateAssetGroupResponse,
  CreateAssetGroupRequest
>({
  mutationFn: async (data) =>
    api.post(`/api/${currentSite?.id}/${BOOKABLE_GROUPS_QUERY_KEY}`, data),
});

// PATCH /{siteId}/asset-groups/:id
export const useUpdateAssetGroup = createMutation<
  PatchAssetGroupResponse,
  PatchAssetGroupRequest & { id: string }
>({
  mutationFn: async ({ id, ...data }) =>
    api.patch(
      `/api/${currentSite?.id}/${BOOKABLE_GROUPS_QUERY_KEY}/${id}`,
      data,
    ),
});

// DELETE /{siteId}/asset-groups/{id}
export const useDeleteAssetGroup = createMutation<
  DeleteAssetGroupResponse,
  { id: string }
>({
  mutationFn: async (data) =>
    api.delete(
      `/api/${currentSite?.id}/${BOOKABLE_GROUPS_QUERY_KEY}/${data.id}`,
    ),
});

// GET /{siteId}/category-groups
export const useGetCategoryGroups = createQuery<GetCategoryGroupsResponse>({
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${CATEGORY_GROUPS_QUERY_KEY}`),
  primaryKey: CATEGORY_GROUPS_QUERY_KEY,
});

// POST /{siteId}/category-groups
export const useCreateCategoryGroup = createMutation<
  CreateCategoryGroupResponse,
  CreateCategoryGroupRequest
>({
  mutationFn: async (data) =>
    api.post(`/api/${currentSite?.id}/${CATEGORY_GROUPS_QUERY_KEY}`, data),
});

// PATCH /{siteId}/category-groups/:id
export const useUpdateCategoryGroup = createMutation<
  PatchCategoryGroupResponse,
  PatchCategoryGroupRequest & { id: string }
>({
  mutationFn: async ({ id, ...data }) =>
    api.patch(
      `/api/${currentSite?.id}/${CATEGORY_GROUPS_QUERY_KEY}/${id}`,
      data,
    ),
});

// DELETE /{siteId}/category-groups/{id}
export const useDeleteCategoryGroup = createMutation<
  DeleteCategoryGroupResponse,
  { id: string }
>({
  mutationFn: async (data) =>
    api.delete(
      `/api/${currentSite?.id}/${CATEGORY_GROUPS_QUERY_KEY}/${data.id}`,
    ),
});
