import { Banana } from "lucide-react";
import { t } from "i18next";
import {
  BridgeSheetState,
  useBridgesPageContext,
} from "../bridges-context.tsx";
import { ReconnectBridgeModal } from "./reconnect-bridge-modal.tsx";
import { DefaultDropdownMenu } from "@primitives/default-dropdown-menu.tsx";
import React from "react";

export const BridgeSelectedDropdownMenu = () => {
  const {
    selectedBridge,
    setSelectedBridge,
    setSheetState,
    sendDeleteBridgeCommand,
  } = useBridgesPageContext();

  const onRemoveBridge = () => {
    if (selectedBridge) {
      sendDeleteBridgeCommand(selectedBridge.id);
      setSelectedBridge(null);
    }
  };

  return (
    <DefaultDropdownMenu
      onEdit={() => setSheetState(BridgeSheetState.UPDATE_BRIDGE)}
      onDelete={onRemoveBridge}
      alertTitle={`${t("delete")}, ${selectedBridge?.settings.name || "bryggan"}`}
      alertDescription={t("delete-bridge-description")}
      alertSubmitButtonText={t("yes-delete", {
        title: selectedBridge?.settings.name || "bryggan",
      })}
    >
      <div className="m-0 flex h-[30px] w-full items-center p-0 hover:bg-gray-100">
        <Banana className="ml-2 h-[13px] w-[13px] p-0 text-xs" />
        <ReconnectBridgeModal key={"reconnect-bridge"} />
      </div>
    </DefaultDropdownMenu>
  );
};
