import { CardDescription, CardTitle } from "@shared/primitives/card.tsx";
import { ReactNode } from "react";
import { X } from "lucide-react";

interface SettingsCardHeaderProps {
  text: string;
  description?: string;
  onSecondaryCloseClick?: () => void;
  children?: ReactNode;
}

export const SettingsCardHeader = ({
  text,
  description,
  onSecondaryCloseClick,
  children,
}: SettingsCardHeaderProps) => {
  return (
    <div className={"p-6 pb-4"}>
      <div>
        {text && (
          <CardTitle className="text-2xl/9">
            <span className={"block w-[100%] truncate pr-6"}>{text}</span>
            {description && <CardDescription>{description}</CardDescription>}
            {!!onSecondaryCloseClick && (
              <X
                className="absolute right-2 top-2 h-[16px] w-[16px] cursor-pointer hover:text-secondary-text"
                onClick={onSecondaryCloseClick}
              />
            )}
          </CardTitle>
        )}
      </div>
      {children}
    </div>
  );
};
