import * as React from "react";
import { cn } from "@shared/utils/css";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        // prettier-ignore
        className={cn(
          "flex rounded-md w-full text-sm font-bold h-10 ps-4 pr-2", 
          "border border-highlighted-backplate",
          "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-highlighted-backplate",
          "placeholder:text-secondary-text text-primary-text",
          "disabled:cursor-not-allowed disabled:opacity-60", 
          "file:border-1 file:bg-transparent file:text-sm file:font-medium",
          "focus:bg-primary-button-backplate bg-primary-button-backplate",
          className)}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = "Input";
