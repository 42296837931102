export const loadCSS = (href: string) => {
  let linkElement = document.querySelector(
    'link[data-module="dynamic"]',
  ) as HTMLLinkElement;

  if (linkElement) {
    linkElement.href = href;
  } else {
    linkElement = document.createElement("link");
    linkElement.rel = "stylesheet";
    linkElement.type = "text/css";
    linkElement.href = href;
    linkElement.setAttribute("data-module", "dynamic");
    document.head.appendChild(linkElement);
  }
};
