import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@primitives/simpleTooltip";
import { usePriceCalendarContext } from "../price-calendar-context";
import { isBefore, startOfDay } from "date-fns";
import { TooltipCard } from "./tooltip-card";
import { MonthCalendarCell } from "./month-calendar-cell";
import {
  CalendarDay,
  CalendarMonth,
} from "../../../../../../api-contracts/price-calendar";

export const MonthCalendar = ({
  currentMonthIndex,
  month,
  weekDayShort,
  onSelect,
  openNoteDialog,
}: {
  currentMonthIndex: number;
  month: CalendarMonth | null;
  weekDayShort: string[];
  onSelect: (day: CalendarDay) => void;
  openNoteDialog: (day: CalendarDay) => void;
}) => {
  if (!month) return null;

  const { selectedDays } = usePriceCalendarContext();
  const today = startOfDay(new Date());

  return (
    <div className="flex h-full flex-col">
      <div className="flex min-w-[104px] justify-center gap-x-2">
        {weekDayShort.map((dayName, index) => (
          <div key={index} className="h-10 flex-1 text-center align-middle">
            <p className="text-sm  font-medium text-secondary-text">
              {dayName}
            </p>
          </div>
        ))}
      </div>

      <div className="grid h-full min-w-[104px] grid-cols-7 gap-2">
        {month.weeks.map((week, weekIndex) =>
          week.map((day, dayIndex) => {
            const isSelected = selectedDays.some(
              (selectedDay) => selectedDay.date === day?.date,
            );
            const dateToCheck = startOfDay(day ? day.date : "");
            const isPastDate = isBefore(dateToCheck, today);
            const isSunday = dayIndex === 6;
            const isAdjacent =
              day && new Date(day.date).getMonth() !== currentMonthIndex;

            return (
              <div
                key={`${weekIndex}-${dayIndex}`}
                className={`relative flex aspect-square h-full min-h-[125px] w-full min-w-[104px] items-center justify-center rounded-lg p-1 shadow-sm
            ${
              !day
                ? "bg-secondary-card-backplate"
                : isSunday && isSelected
                  ? "bg-accent-amber-500 hover:bg-accent-amber-500/50"
                  : isSunday
                    ? "bg-accent-amber-500/10 hover:bg-accent-amber-500/20"
                    : isAdjacent
                      ? "bg-gray-300/5"
                      : isSelected
                        ? "bg-accent-amber-500 hover:bg-accent-amber-500/50"
                        : isPastDate
                          ? "bg-gray-300/10"
                          : "bg-solid-backplate hover:bg-hover"
            }
          `}
                onClick={() =>
                  day && !isAdjacent && !isPastDate && onSelect(day)
                }
              >
                {day && (
                  <>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="h-full w-full flex-grow">
                            <MonthCalendarCell
                              isAdjacent={isAdjacent as boolean}
                              today={today}
                              day={day}
                              isSelected={isSelected}
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent
                          onClick={(e) => e.stopPropagation()}
                          className={`z-10 rounded-lg bg-solid-backplate p-2 shadow-md ${
                            (isPastDate || isAdjacent) && "pointer-events-none"
                          }`}
                        >
                          <TooltipCard
                            day={day}
                            openNoteDialog={() => openNoteDialog(day)}
                          />
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </>
                )}
              </div>
            );
          }),
        )}
      </div>
    </div>
  );
};
