import React, { ChangeEvent, FormEvent, useState } from "react";
import { useCreateOrganization } from "@api/organizations";
import { useCreateSite } from "@api/sites";
import { Organization } from "../../../../../../api-contracts/organizations";

import { Button } from "@primitives/button";
import { UseMutateFunction } from "@tanstack/react-query";
import { useAdmin } from "../../context";

export const OrganizationForm: React.FC<{
  onCreated: (org: Organization) => void;
}> = ({ onCreated }) => {
  const { mutate } = useCreateOrganization();

  return (
    <BaseForm
      mutate={mutate}
      placeholder="Organization name"
      onCreated={onCreated}
      additions={{}}
    />
  );
};

export const SitesForm: React.FC<{
  onCreated: (org: Organization) => void;
}> = ({ onCreated }) => {
  const { mutate } = useCreateSite();
  const { state } = useAdmin();

  return (
    <BaseForm
      mutate={mutate}
      placeholder="Site name"
      onCreated={onCreated}
      additions={{ orgId: state.organization?.id }}
    />
  );
};
type BaseType = { id: string; name: string };
type FormProps = {
  onCreated: (data: BaseType) => void;
  mutate: UseMutateFunction<BaseType, Error, Omit<BaseType, "id">, unknown>;
  additions: Record<string, unknown>;
  placeholder: string;
};

export const BaseForm = ({
  onCreated,
  mutate,
  placeholder,
  additions,
}: FormProps) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(() => e.target.value);
  };
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    mutate(
      { ...additions, name },
      {
        onSuccess: (data) => onCreated(data),
        onSettled: () => {
          setLoading(false);
          setName("");
        },
      },
    );
  };
  return (
    <form className="max-w-[400px]" onSubmit={onSubmit}>
      <div className="mb-4 flex">
        <label
          className="h-100  flex items-center rounded-l border border-r-0 bg-gray-50 p-1 pl-4 pr-4 text-sm font-bold text-gray-700"
          htmlFor="name"
        >
          Name
        </label>
        <input
          className="w-full appearance-none rounded-r border px-3 py-2 focus:outline-none"
          id="name"
          type="text"
          value={name}
          onChange={onNameChange}
          placeholder={placeholder}
        />
      </div>
      <div className="flex items-center justify-between ">
        <Button
          loading={loading}
          variant={"purple"}
          disabled={name.length === 0}
          type="submit"
        >
          Submit
        </Button>
      </div>
    </form>
  );
};
