import { PriceRulesSheet } from "@pages/booking/price-rules/components/price-rules-sheet.tsx";
import {
  PriceRuleActionType,
  usePriceRulesPageContext,
} from "@pages/booking/price-rules/price-rules-context.tsx";
import { useTranslation } from "react-i18next";
import { useCreatePriceRule } from "@api/price-rules.ts";

export const AddPriceRules = () => {
  const {
    sharedState: { actionType, bar, priceRules },
    sharedFunctions: { setActionType, setPriceRules },
  } = usePriceRulesPageContext();
  const { t } = useTranslation();

  const { mutate } = useCreatePriceRule();

  const getNextBarSeq = () => {
    const bars = priceRules.rules.filter(({ name }) => name.startsWith("BAR"));
    const extractNumbers = bars.map(({ name }) => Number(name.match(/\d+/g)));
    const nextSeq = (extractNumbers ? Math.max(...extractNumbers) : 0) + 1;
    return `Bar ${nextSeq}`;
  };

  const onSubmit = () => {
    const req =
      bar.name !== ""
        ? bar
        : {
            entries: bar.entries,
            inactivationDate: bar.inactivationDate,
            reactivationDate: bar.reactivationDate,
          };

    req &&
      mutate(req, {
        onSuccess(rule) {
          setActionType(PriceRuleActionType.DEFAULT);
          setPriceRules((prev) => {
            return { ...prev, rules: [...prev.rules, rule] };
          });
        },
      });
  };

  return (
    <PriceRulesSheet
      open={actionType === PriceRuleActionType.ADD}
      title={t("price-rules-new-bar")}
      onSubmit={onSubmit}
      namePlaceholder={getNextBarSeq()}
      display={PriceRuleActionType.ADD}
    />
  );
};
