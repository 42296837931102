import { AdminState } from ".";
import { AdminAction } from "./action";

export const adminReducer = (
  state: AdminState,
  action: AdminAction,
): AdminState => {
  switch (action.type) {
    case "ORG_SELECT": {
      if (state.organization?.id == action.payload?.id) {
        return state;
      }
      return { organization: action.payload, site: null, user: null };
    }
    case "SITE_SELECT": {
      if (state.site?.id == action.payload?.id) {
        return state;
      }
      return { ...state, site: action.payload, user: null };
    }
    case "USER_SELECT":
      return state;
    default:
      return state;
  }
};
