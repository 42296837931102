import * as React from "react";
import { Checkbox } from "./checkbox";

export const CheckboxWithLabel = React.forwardRef<
  HTMLDivElement,
  { label: string | undefined } & React.ComponentPropsWithoutRef<
    typeof Checkbox
  >
>(({ label, ...props }, ref) => {
  return (
    <div className="flex items-center py-1" ref={ref}>
      <Checkbox {...props} />
      <label className="mx-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        {label}
      </label>
    </div>
  );
});
