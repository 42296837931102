import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CreateTargetGroupRequest,
  GetTargetGroupResponse,
  GetTargetGroupsResponse,
  PatchTargetGroupRequest,
} from "../../../../api-contracts/target-groups";

export const TARGET_GROUPS_QUERY_KEY = "target-groups";

export const useGetTargetGroup = createQuery<GetTargetGroupResponse>({
  primaryKey: TARGET_GROUPS_QUERY_KEY,
  queryFn: async ({ queryKey: [targetGroupId] }) =>
    api.get(
      `/api/${currentSite?.id}/${TARGET_GROUPS_QUERY_KEY}/${targetGroupId}`,
    ),
});
export const useGetTargetGroups = createQuery<GetTargetGroupsResponse>({
  primaryKey: TARGET_GROUPS_QUERY_KEY,
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${TARGET_GROUPS_QUERY_KEY}`),
});

export const useCreateTargetGroup = createMutation<
  GetTargetGroupResponse,
  CreateTargetGroupRequest
>({
  mutationFn: async (data: CreateTargetGroupRequest) =>
    api.post(`/api/${currentSite?.id}/${TARGET_GROUPS_QUERY_KEY}`, data),
});

export const useUpdateTargetGroup = createMutation<
  GetTargetGroupResponse,
  { id: string; body: PatchTargetGroupRequest }
>({
  mutationFn: async (data) =>
    api.patch(
      `/api/${currentSite?.id}/${TARGET_GROUPS_QUERY_KEY}/${data.id}`,
      data.body,
    ),
});

export const useDeleteTargetGroup = createMutation<null, { id: string }>({
  mutationFn: async (data) =>
    api.delete(`/api/${currentSite?.id}/${TARGET_GROUPS_QUERY_KEY}/${data.id}`),
});
