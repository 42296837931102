type UserLike = {
  firstName: string;
  lastName: string;
};

export function getInitials(user?: UserLike) {
  if (!user) return "";
  return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
}

export function getFullName(user?: UserLike) {
  if (!user) return "";
  return `${user.firstName} ${user.lastName}`;
}
