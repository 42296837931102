import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import { GetBooking } from "../../../../../../api-contracts/reservations";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { useEffect, useMemo, useState } from "react";
import { max, addDays, isBefore, format } from "date-fns";
import DatePicker from "@primitives/date-picker";
import { enGB, sv } from "date-fns/locale";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (updatedBooking: GetBooking) => void;
  booking: GetBooking;
}

export const BookingChangeDepartureDrawer = ({
  open,
  onOpenChange,
  onProceed,
  booking,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [changedBooking, setChangedBooking] = useState<GetBooking>(booking);

  useEffect(() => {
    setChangedBooking(booking);
  }, [booking, open]);

  const handleToChange = (date?: Date) => {
    if (date) {
      setChangedBooking({
        ...booking,
        checkout: format(date, "yyyy-MM-dd"),
        slots: booking.slots.filter((s) => isBefore(s.end, addDays(date, 1))),
      });
    }
  };

  const total = useMemo(() => {
    return changedBooking.slots.reduce((acc, slot) => {
      return acc + slot.price;
    }, 0);
  }, [changedBooking]);

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {t("change-departure")}
            </SheetTitle>
          </SheetHeader>

          <div className=" flex-1 overflow-y-auto text-primary-text">
            <div className="mt-4 bg-secondary-card-backplate p-4">
              <h3 className=" mb-4 text-lg font-bold">
                {t("change-departure")}
              </h3>
              <div>
                <DatePicker
                  value={new Date(changedBooking.checkout)}
                  onValueChange={handleToChange}
                  clearable={false}
                  calendarProps={{
                    disabled: {
                      before: max([
                        new Date(),
                        addDays(changedBooking.checkin, 1),
                      ]),
                      after: new Date(booking.checkout),
                    },
                  }}
                />
                <p className=" ml-2 mt-2 text-sm font-normal">
                  {t("original-departure")}:{" "}
                  {format(booking.checkout, "do MMMM", {
                    locale: i18n.language === "sv-se" ? sv : enGB,
                  })}
                </p>
              </div>
            </div>
          </div>

          <div className=" flex items-center justify-between space-x-2">
            <p className=" text-md font-bold">
              {t("total")}:{" "}
              {new Intl.NumberFormat(
                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                { maximumFractionDigits: 2 },
              ).format(total)}{" "}
              SEK
            </p>
            <div className=" flex justify-end space-x-2 ">
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t("cancel")}
              </Button>
              <Button
                onClick={() => {
                  onProceed(changedBooking);
                  onOpenChange(false);
                }}
              >
                {t("apply")}
              </Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default BookingChangeDepartureDrawer;
