import { createMutation, createQuery } from "react-query-kit";
import {
  CreateLocationRequest,
  CreateLocationResponse,
  DeleteLocationResponse,
  GetLocationResponse,
  GetLocationsResponse,
  UpdateLocationRequest,
  UpdateLocationResponse,
} from "../../../../api-contracts/locations";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";

export const LOCATIONS_QUERY_KEY = "locations";

export const useGetLocation = createQuery<GetLocationResponse>({
  primaryKey: LOCATIONS_QUERY_KEY,
  queryFn: async ({ queryKey: [locationId] }) =>
    api.get(`/api/${currentSite?.id}/${LOCATIONS_QUERY_KEY}/${locationId}`),
});

export const useGetLocations = createQuery<GetLocationsResponse>({
  primaryKey: LOCATIONS_QUERY_KEY,
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${LOCATIONS_QUERY_KEY}`),
});

export const useCreateLocation = createMutation<
  CreateLocationResponse,
  CreateLocationRequest
>({
  mutationFn: async (data: CreateLocationRequest) =>
    api.post(`/api/${currentSite?.id}/${LOCATIONS_QUERY_KEY}`, {
      name: data.name,
      partOf: data.partOf,
    }),
});

export const useUpdateLocation = createMutation<
  UpdateLocationResponse,
  UpdateLocationRequest
>({
  mutationFn: async (data) =>
    api.put(`/api/${currentSite?.id}/${LOCATIONS_QUERY_KEY}`, {
      id: data.id,
      name: data.name,
      partOf: data.partOf,
    }),
});

export const useDeleteLocation = createMutation<
  DeleteLocationResponse,
  { id: string }
>({
  mutationFn: async (data) =>
    api.delete(`/api/${currentSite?.id}/${LOCATIONS_QUERY_KEY}/${data.id}`),
});
