import { PopoverRoot } from "@primitives/popover";
import { ReservationState } from "../../../../../../api-contracts/reservations";
import { useState } from "react";
import { PopoverAnchor, PopoverContent } from "@radix-ui/react-popover";
import { ChevronDown, ChevronUp } from "lucide-react";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@primitives/command";
import { useTranslation } from "react-i18next";
import { reservationStateToColor } from ".";

interface Props {
  value: ReservationState;
  onValueChange: (value: ReservationState) => void;
}

const states: ReservationState[] = [
  "confirmed",
  "unconfirmed",
  "preliminary",
  "guaranteed",
  "lost",
];

const ReservationStateSelect = ({ value, onValueChange }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleStateSelect = (state: ReservationState) => {
    onValueChange(state);
    setOpen(false);
  };

  return (
    <PopoverRoot
      open={open}
      onOpenChange={(o) => {
        setOpen(o);
      }}
    >
      <PopoverAnchor asChild>
        <div
          onClick={() => setOpen(true)}
          className={` flex cursor-pointer items-center space-x-2 rounded-lg p-2 bg-${reservationStateToColor(value)}-100 text-${reservationStateToColor(value)}`}
        >
          <p className={`text-lg font-extrabold`}>{t(value as string)}</p>
          {!open && <ChevronDown size={18} />}
          {open && <ChevronUp size={18} />}
        </div>
      </PopoverAnchor>
      <PopoverContent
        align="start"
        avoidCollisions={false}
        className="p-0"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <Command>
          <CommandList className="max-h-none">
            <CommandGroup>
              {states?.map((state) => (
                <CommandItem
                  key={state}
                  value={state}
                  onSelect={() => handleStateSelect(state)}
                >
                  <div
                    className={`rounded-lg p-2 bg-${reservationStateToColor(state)}-100  w-full `}
                  >
                    <p
                      className={` text-lg font-extrabold text-${reservationStateToColor(state)}`}
                    >
                      {t(state as string)}
                    </p>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </PopoverRoot>
  );
};

export default ReservationStateSelect;
