import { createMutation } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CreateNoteRequest,
  PatchNoteRequest,
  PatchNoteResponse,
} from "../../../../api-contracts/notes";

export const NOTES_QUERY_KEY = "notes";

export const useUpdateNote = createMutation<
  PatchNoteResponse,
  { patch: PatchNoteRequest; id: string }
>({
  mutationFn: async (data) =>
    api.patch(
      `/api/${currentSite?.id}/${NOTES_QUERY_KEY}/${data.id}`,
      data.patch,
    ),
});

export const useCreateNote = createMutation<
  PatchNoteResponse,
  CreateNoteRequest
>({
  mutationFn: async (data) =>
    api.post(`/api/${currentSite?.id}/${NOTES_QUERY_KEY}`, data),
});
