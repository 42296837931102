import { resizePropotionally } from "@shared/utils/helpers";
import { ComponentProps } from "react";

export function ModularVisitSymbol({
  width,
  height,
  ...rest
}: ComponentProps<"svg">) {
  const org = { width: 1920, height: 1036.44 };
  const size = resizePropotionally(
    org.width,
    org.height,
    width ? parseInt(width.toString()) : undefined,
    height ? parseInt(height.toString()) : undefined,
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox={`0 0 ${org.width} ${org.height}`}
      className="fill-mv-logo-symbol"
      {...rest}
    >
      <g>
        <path d="m584.14,34.56L12.35,914.25c-32.72,50.33,3.41,116.9,63.44,116.9h571.79c41.79,0,75.66-33.87,75.66-75.66V75.8c0-75.32-98.05-104.38-139.1-41.23Z" />
        <path d="m1780.9,34.56l-571.79,879.68c-32.72,50.33,3.41,116.9,63.44,116.9h571.79c41.79,0,75.66-33.87,75.66-75.66V75.8c0-75.32-98.05-104.38-139.1-41.23Z" />
        <path d="m998.02,1001.88L1569.81,122.19c32.72-50.33-3.41-116.9-63.44-116.9h-571.79c-41.79,0-75.66,33.87-75.66,75.66v879.68c0,75.32,98.05,104.38,139.1,41.23Z" />
      </g>
    </svg>
  );
}

type LogoProps = ComponentProps<"svg"> & {
  symbolFill?: string;
  wordmarkFill?: string;
};

export function RoomrLogo({ width, height, ...rest }: LogoProps) {
  const org = { width: 995, height: 135 };
  const size = resizePropotionally(
    org.width,
    org.height,
    width ? parseInt(width.toString()) : undefined,
    height ? parseInt(height.toString()) : undefined,
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox={`0 0 ${org.width} ${org.height}`}
      {...rest}
    >
      <g className="fill-roomr-logo-wordmark-dark dark:fill-roomr-logo-wordmark-light">
        <path d="m373.61 132.44-30.03-41.97h-.35v41.97h-31.78V2.49h42.49c13.52 0 23.88 1.76 30.73 4.74 14.05 6.32 23.88 21.6 23.88 40.04 0 20.54-12.29 37.4-32.31 40.91l35.29 44.25H373.6l.01.01Zm-18.09-64.62c13.17 0 21.25-6.15 21.25-18.61 0-11.24-8.43-17.56-20.9-17.56h-12.64v36.17h12.29Z" />
        <path d="M414.89 70.45c0-40.21 28.98-70.41 68.49-70.41 39.51 0 68.66 29.33 68.66 66.55s-29.85 68.31-67.08 68.31-70.06-25.99-70.06-64.44l-.01-.01Zm103.95-2.81c0-20.37-14.58-38.46-35.47-38.46s-35.3 17.38-35.3 37.93 14.58 38.63 35.82 38.63c21.24 0 34.94-17.38 34.94-38.11l.01.01Z" />
        <path d="M558.83 70.45c0-40.21 28.97-70.41 68.48-70.41 39.51 0 68.66 29.33 68.66 66.55s-29.85 68.31-67.08 68.31-70.06-25.99-70.06-64.44v-.01Zm103.95-2.81c0-20.37-14.57-38.46-35.47-38.46s-35.29 17.38-35.29 37.93 14.57 38.63 35.82 38.63 34.94-17.38 34.94-38.11v.01Z" />
        <path d="M847.71 132.44V55h-.35l-54.26 77.44h-27.57V55h-.35l-50.4 77.44h-38.11L765.88 2.49h32.84v71.47h.35l48.99-71.47h32.84v129.94h-33.19v.01Z" />
        <path d="m956.79 132.44-30.03-41.97h-.35v41.97h-31.78V2.49h42.49c13.52 0 23.88 1.76 30.73 4.74 14.05 6.32 23.88 21.6 23.88 40.04 0 20.54-12.29 37.4-32.31 40.91l35.29 44.25h-37.93l.01.01ZM938.7 67.82c13.17 0 21.25-6.15 21.25-18.61 0-11.24-8.43-17.56-20.9-17.56h-12.64v36.17h12.29Z" />
      </g>
      <g className="fill-roomr-logo-symbol">
        <path d="M76.03 4.5 1.61 119c-4.26 6.55.44 15.22 8.26 15.22H84.3c5.44 0 9.85-4.41 9.85-9.85V9.87c0-9.8-12.76-13.59-18.11-5.37h-.01Z" />
        <path d="M231.81 4.5 157.38 119c-4.26 6.55.44 15.22 8.26 15.22h74.43c5.44 0 9.85-4.41 9.85-9.85V9.87c0-9.8-12.76-13.59-18.11-5.37Z" />
        <path d="m129.91 130.41 74.42-114.5c4.26-6.55-.44-15.22-8.26-15.22h-74.43c-5.44 0-9.85 4.41-9.85 9.85v114.5c0 9.8 12.76 13.59 18.11 5.37h.01Z" />
      </g>
    </svg>
  );
}

export function ModularVisitLogo({ width, height, ...rest }: LogoProps) {
  const org = { width: 1349, height: 136 };
  const size = resizePropotionally(
    org.width,
    org.height,
    width ? parseInt(width.toString()) : undefined,
    height ? parseInt(height.toString()) : undefined,
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox={`0 0 ${org.width} ${org.height}`}
      className="fill-mv-logo-symbol"
      fill="currentColor"
      {...rest}
    >
      <g className="fill-mv-logo-wordmark-dark dark:fill-mv-logo-wordmark-light">
        <path d="M452.48 132.44V55h-.35l-54.26 77.44H370.3V55h-.35l-50.4 77.44h-38.11L370.65 2.49h32.84v71.47h.35l48.99-71.47h32.84v129.94h-33.19v.01Z" />
        <path d="M542.56 135.42c-29.68 0-52.5-21.95-52.5-51.8 0-29.85 23.35-51.8 52.5-51.8s52.5 21.95 52.5 51.45c0 29.5-23.18 52.15-52.5 52.15Zm23.35-51.8c0-12.99-9.83-24.58-23.53-24.58-12.82 0-23.18 11.24-23.18 24.58 0 13.34 10.19 24.58 23.36 24.58s23.35-11.59 23.35-24.58Z" />
        <path d="M675.42 132.44V121.9h-.35c-5.44 8.96-15.28 13.7-29.15 13.7-29.5 0-48.82-22.65-48.82-52.15s19.84-51.8 48.29-51.8c11.24 0 20.37 3.34 28.62 11.59V2.49h29.15v129.94h-27.74v.01Zm.18-49.17c0-13.52-10.36-24.41-24.76-24.41-14.4 0-24.58 10.18-24.58 24.41s10.36 25.11 24.41 25.11 24.94-10.71 24.94-25.11h-.01Z" />
        <path d="M777.99 132.44v-10.36h-.35c-4.21 8.96-11.94 13.52-25.11 13.52-20.54 0-37.58-12.29-37.58-40.04V34.98h29.15v54.61c0 12.82 4.56 18.79 15.63 18.79s16.68-7.38 16.68-19.32V34.98h29.15v97.46h-27.57Z" />
        <path d="M817.72 132.44V2.49h29.15v129.94h-29.15v.01Z" />
        <path d="M931.93 132.44V121.9h-.35c-4.21 8.6-15.8 13.87-28.62 13.87-28.8 0-48.82-22.65-48.82-52.15s20.9-51.98 48.82-51.98c11.94 0 23 4.74 28.62 13.7h.35V34.98h29.15v97.46h-29.15Zm0-48.82c0-13.7-11.06-24.76-24.76-24.76-13.7 0-23.88 11.06-23.88 25.11 0 14.05 10.71 24.58 24.23 24.58s24.41-10.89 24.41-24.93Z" />
        <path d="M973.27 132.44V34.98h27.39v10.36h.35c5.09-10.36 13.17-13.7 24.41-13.7v29.33c-17.38.18-23 6.67-23 17.74v53.73h-29.15Z" />
        <path d="M1061.8 132.44 1018.43 2.49h34.07l26.16 91.14h.35l26.34-91.14h34.24l-43.55 129.94h-34.24v.01Z" />
        <path d="M1142.28 24.62V2.49h29.15v22.12h-29.15v.01Zm0 107.82V34.98h29.15v97.46h-29.15Z" />
        <path d="M1225.67 62.37c-.18-5.62-4.21-7.38-8.96-7.38-4.75 0-8.43 2.81-8.43 6.5 0 5.27 4.74 7.9 18.26 11.41 23.18 6.15 30.73 15.45 30.73 29.68 0 20.37-17.56 33.19-39.68 33.19s-37.58-12.47-39.69-32.31h28.97c.88 6.15 5.27 8.96 11.06 8.96 5.09 0 10.18-2.99 10.18-7.55 0-5.44-3.34-8.25-17.91-12.82-24.58-7.55-31.08-17.21-31.08-28.62 0-19.32 18.09-31.78 37.75-31.78 21.07 0 36 11.59 37.4 30.73h-28.62l.02-.01Z" />
        <path d="M1263.86 24.62V2.49h29.15v22.12h-29.15v.01Zm0 107.82V34.98h29.15v97.46h-29.15Z" />
        <path d="M1304.93 132.44V2.49h29.15v32.49h14.05V57.1h-14.05v75.33h-29.15v.01Z" />
      </g>
      <g className="fill-mv-logo-symbol">
        <path d="M76.03 4.5 1.61 119c-4.26 6.55.44 15.22 8.26 15.22H84.3c5.44 0 9.85-4.41 9.85-9.85V9.87c0-9.8-12.76-13.59-18.11-5.37h-.01Z" />
        <path d="M231.81 4.5 157.38 119c-4.26 6.55.44 15.22 8.26 15.22h74.43c5.44 0 9.85-4.41 9.85-9.85V9.87c0-9.8-12.76-13.59-18.11-5.37Z" />
        <path d="m129.91 130.41 74.42-114.5c4.26-6.55-.44-15.22-8.26-15.22h-74.43c-5.44 0-9.85 4.41-9.85 9.85v114.5c0 9.8 12.76 13.59 18.11 5.37h.01Z" />
      </g>
    </svg>
  );
}
