import { Input } from "@primitives/input";
import { ChangeEventHandler } from "react";

export const PlaceholderInput = ({
  placeholder = "st",
  type = "number",
  value,
  onChange,
  defaultValue,
  min = 0,
}: {
  placeholder?: string;
  type?: string;
  value: string | number | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  defaultValue?: string;
  min?: number;
}) => {
  return (
    <div className="relative flex flex-row">
      <Input
        className={"font-neue text-[15px] font-normal text-secondary-text"}
        placeholder={defaultValue}
        min={min}
        type={type}
        value={value === "0" ? defaultValue : value}
        onChange={onChange}
      />
      <p className="absolute bottom-3 right-5 text-xs text-secondary-text">
        {placeholder}
      </p>
    </div>
  );
};
