import { Card } from "@primitives/card";
import { CardHeader } from "../../settings/categories/components/card-header";
import CategoryTable from "./components/category-table";
import { NavigationArrows } from "@components/navigation-arrows";
import { useProfileContext } from "@context/profile-context";
import { CategoryTypeTabs } from "./components/category-type-tabs";

const CategoriesPage = () => {
  const { module } = useProfileContext();
  return (
    <div className="p-4">
      <div className="pb-4">
        <NavigationArrows rootPath={`/${module}/categories`} />
      </div>
      <div className="flex flex-shrink bg-transparent px-[-16px] pb-4">
        <CategoryTypeTabs />
      </div>
      <Card>
        <CardHeader />
        <CategoryTable />
      </Card>
    </div>
  );
};
export default CategoriesPage;
