import {
  LocationsAction,
  useLocationsPageContext,
} from "../locations-context.tsx";
import { useCreateLocation } from "@api/locations.ts";
import { CreateLocationRequest } from "../../../../../../api-contracts/locations";
import { DefaultInputWithCancel } from "@primitives/default-input-with-cancel.tsx";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const AddLocation = () => {
  const { mutate: createLocation } = useCreateLocation();
  const {
    sharedState: { currentLocation },
    sharedFunctions: { setActionType, refetchLocations },
  } = useLocationsPageContext();
  const {
    sharedFunctions: { setLoading, toast },
  } = useSharedContext();
  const { t } = useTranslation();

  const onSubmit = (req: CreateLocationRequest) => {
    setLoading(true);

    createLocation(req, {
      onError: (e) => {
        toast({
          variant: "destructive",
          title: t("create-location-error", { error: e.message }),
        });
      },
      onSuccess: async () => {
        await refetchLocations();
      },
      onSettled: () => {
        setLoading(false);
        setActionType(LocationsAction.DEFAULT);
      },
    });
  };

  return (
    <div>
      <DefaultInputWithCancel
        onSubmit={(value) => {
          onSubmit({
            name: value,
            partOf: currentLocation?.location.id,
          } as CreateLocationRequest);
        }}
        onCancel={() => setActionType(LocationsAction.DEFAULT)}
        primaryButtonText={t("add")}
        secondaryButtonText={t("cancel")}
        buttonOrder={"secondary_first"}
        inputName={"add-location"}
        placeholder={t("location-name")}
        required={true}
      />
    </div>
  );
};
