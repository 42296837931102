import { useProfileContext } from "@context/profile-context";
import { Button } from "@primitives/button";
import { H1, P } from "@primitives/typography";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const nav = useNavigate();
  const { module } = useProfileContext();

  return (
    <div className="flex h-screen flex-col items-center justify-center dark:bg-gray-950 dark:text-gray-300">
      <div>
        <H1>Oups! We couldn't find that page.</H1>
        <P className="text-gray-400">
          This has been logged and we will look into it.
        </P>
        <P>
          <Button variant={"ghost"} onClick={() => nav(`/${module}`)}>
            <ChevronLeft size={20} className="mr-2" />
            Return to dashboard
          </Button>
        </P>
      </div>
    </div>
  );
}

export default NotFound;
