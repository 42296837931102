import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useMemo,
  FC,
  useCallback,
} from "react";
import {
  Category,
  CategoryType,
  CreateCategoryRequest,
  CreateCategoryResponse,
  PatchCategoryRequest,
} from "../../../../../api-contracts/categories";
import {
  usePostCategory,
  useGetCategories,
  usePatchCategoryById,
} from "@api/categories";
import { SortingState, VisibilityState } from "@tanstack/react-table";
import { queryClient } from "query-client";
import {
  filterMinMaxBasePrice,
  filterMinMaxCapacity,
} from "./categories-utils";
import { useToast } from "@hooks/use-toast";
import { t } from "i18next";

export interface FilterActiveStatus {
  active: true;
  inactive: false;
}

interface GroupCount {
  [key: string]: number;
}

interface GroupType {
  id: string;
  name: string;
  count: number;
}

interface DataContextType {
  categories: Category[];
  isLoading: boolean;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  columnVisibility: VisibilityState;
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>;
  setFilterActiveStatus: React.Dispatch<
    React.SetStateAction<FilterActiveStatus>
  >;
  filterActiveStatus: FilterActiveStatus;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  patchCategory: (id: string, patch: PatchCategoryRequest) => Promise<void>;
  capacityValue: [number, number];
  setCapacityValue: React.Dispatch<React.SetStateAction<[number, number]>>;
  minCapacity: number;
  maxCapacity: number;
  basePriceValue: [number, number];
  setBasePriceValue: React.Dispatch<React.SetStateAction<[number, number]>>;
  minBasePrice: number;
  maxBasePrice: number;
  setSelectedGroupIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedGroupIds: string[];
  uniqueGroups: GroupType[];
  createCategory: (
    category: CreateCategoryRequest,
  ) => Promise<CreateCategoryResponse>;
  categoryTypes: CategoryType[];
  setCategoryTypes: React.Dispatch<React.SetStateAction<CategoryType[]>>;
  selectedCategoryType: CategoryType;
  setSelectedCategoryType: React.Dispatch<React.SetStateAction<CategoryType>>;
}
interface CategoriesContextProviderProps {
  children: ReactNode;
}

const initialContextValue: DataContextType = {
  categories: [],
  isLoading: false,
  sorting: [],
  setSorting: () => null,
  columnVisibility: {},
  setColumnVisibility: () => null,
  setFilterActiveStatus: () => null,
  filterActiveStatus: { active: true, inactive: false },
  searchTerm: "",
  setSearchTerm: () => null,
  patchCategory: async () => {},
  capacityValue: [0, 100],
  setCapacityValue: () => null,
  minCapacity: 0,
  maxCapacity: 100,
  basePriceValue: [0, 10000],
  setBasePriceValue: () => null,
  minBasePrice: 0,
  maxBasePrice: 10000,
  setSelectedGroupIds: () => null,
  selectedGroupIds: [""],
  uniqueGroups: [{ id: "", name: "", count: 0 }],
  createCategory: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    category: CreateCategoryRequest,
  ): Promise<CreateCategoryResponse> => {
    return {} as CreateCategoryResponse;
  },
  categoryTypes: ["room"],
  setCategoryTypes: () => {},
  selectedCategoryType: "room",
  setSelectedCategoryType: () => {},
};

export const CategoriesContext =
  createContext<DataContextType>(initialContextValue);

export const useCategoriesContext = (): DataContextType => {
  const context = useContext(CategoriesContext);
  if (context === undefined) {
    throw new Error(
      "useCategoriesContext must be used within a CategoriesContextProvider",
    );
  }
  return context;
};

export const CategoriesContextProvider: FC<CategoriesContextProviderProps> = ({
  children,
}) => {
  const [categoryTypes, setCategoryTypes] = useState<CategoryType[]>(["room"]);
  const [selectedCategoryType, setSelectedCategoryType] =
    useState<CategoryType>(() =>
      JSON.parse(localStorage.getItem("categoryType") || "[]"),
    );

  const [sorting, setSorting] = useState<SortingState>(() =>
    JSON.parse(localStorage.getItem("tableSorting") || "[]"),
  );
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    () => JSON.parse(localStorage.getItem("columnVisibility") || "{}"),
  );
  const [filterActiveStatus, setFilterActiveStatus] =
    useState<FilterActiveStatus>({
      active: true,
      inactive: false,
    });
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [capacityValue, setCapacityValue] = useState<[number, number]>([0, 1]);
  const [basePriceValue, setBasePriceValue] = useState<[number, number]>([
    0, 1,
  ]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);

  const { data, isLoading } = useGetCategories({
    variables: { categoryTypes: categoryTypes },
  });
  const updateCategory = usePatchCategoryById();
  const postCategory = usePostCategory();

  const { toast } = useToast();

  const filterByActiveCategories = (
    category: Category,
    filterStatus: { active: boolean; inactive: boolean },
  ) => {
    if (filterStatus.active && filterStatus.inactive) {
      return true;
    }
    if (filterStatus.active) {
      return category.active;
    }
    if (filterStatus.inactive) {
      return !category.active;
    }
    return false;
  };

  const filterBySearchTerm = (category: Category, searchTerm: string) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      category.name.toLowerCase().includes(lowerCaseSearchTerm) ||
      category.short.toLowerCase().includes(lowerCaseSearchTerm) ||
      (category.description.internal &&
        category.description.internal
          .toLowerCase()
          .includes(lowerCaseSearchTerm))
    );
  };

  const filterByBasePrice = (
    category: Category,
    minBasePrice: number,
    maxBasePrice: number,
  ) => {
    return (
      category.basePrice >= minBasePrice && category.basePrice <= maxBasePrice
    );
  };

  const filterByCapacity = (
    category: Category,
    minCapacity: number,
    maxCapacity: number,
  ) => {
    return (
      category.capacity.total.max >= minCapacity &&
      category.capacity.total.max <= maxCapacity
    );
  };

  const { minCapacity, maxCapacity } = filterMinMaxCapacity(data ? data : []);
  const { minBasePrice, maxBasePrice } = filterMinMaxBasePrice(
    data ? data : [],
  );

  const filterByGroups = (category: Category) => {
    if (selectedGroupIds.length === 0) return true;
    return category.groups.some((group) => selectedGroupIds.includes(group.id));
  };

  const groups = data?.map((category) => category.groups);
  const flatGroups = groups ? groups.flat() : [];

  const groupCounts: GroupCount = flatGroups.reduce(
    (counts: GroupCount, group) => {
      counts[group.id] = (counts[group.id] || 0) + 1;
      return counts;
    },
    {},
  );

  const uniqueGroups: GroupType[] = Object.keys(groupCounts)
    .map((groupId) => {
      const group = flatGroups.find((g) => g.id === groupId);
      return group
        ? {
            id: groupId,
            name: group.name,
            count: groupCounts[groupId],
          }
        : null;
    })
    .filter(Boolean) as GroupType[];

  uniqueGroups.sort((a, b) => b.count - a.count);
  const displayedData = useMemo(() => {
    return data
      ? data.filter(
          (category: Category) =>
            filterByActiveCategories(category, filterActiveStatus) &&
            (!searchTerm || filterBySearchTerm(category, searchTerm)) &&
            filterByGroups(category),
        )
      : [];
  }, [
    data,
    filterActiveStatus,
    searchTerm,
    capacityValue,
    basePriceValue,
    selectedGroupIds,
  ]);

  useEffect(() => {
    localStorage.setItem("tableSorting", JSON.stringify(sorting));
  }, [sorting]);

  useEffect(() => {
    localStorage.setItem("columnVisibility", JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  useEffect(() => {
    localStorage.setItem("categoryType", JSON.stringify(selectedCategoryType));
  }, [selectedCategoryType]);

  const patchCategory = useCallback(
    async (id: string, patch: PatchCategoryRequest) => {
      try {
        await updateCategory.mutateAsync({
          patch,
          id,
        });
        queryClient.invalidateQueries({
          queryKey: useGetCategories.getKey(),
        });

        toast({
          title: t("saved-succesfully", { name: patch.name }),
          className: "text-status-success",
          variant: "success",
        });
      } catch (error) {
        toast({
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((error as any)?.message || t("no-message"))),
          className: "text-status-error",
          variant: "destructive",
        });
      }
    },
    [queryClient],
  );

  const createCategory = useCallback(
    async (
      category: CreateCategoryRequest,
    ): Promise<CreateCategoryResponse> => {
      try {
        const response = await postCategory.mutateAsync(category);
        queryClient.invalidateQueries({
          queryKey: usePostCategory.getKey(),
        });
        toast({
          title: t("saved-succesfully", { name: category.name }),
          className: "text-status-success",
          variant: "success",
        });
        return response as CreateCategoryResponse;
      } catch (error) {
        toast({
          title:
            t("request-failed-with") +
            ": " +
            t(
              decodeURIComponent(
                (error instanceof Error && error.message) || t("no-message"),
              ),
            ),
          className: "text-status-error",
          variant: "destructive",
        });
        return {} as CreateCategoryResponse;
      }
    },
    [queryClient],
  );

  const value = useMemo(
    () => ({
      categories: displayedData ?? [],
      isLoading,
      sorting,
      setSorting,
      columnVisibility,
      setColumnVisibility,
      setFilterActiveStatus,
      filterActiveStatus,
      searchTerm,
      setSearchTerm,
      patchCategory,
      basePriceValue,
      setBasePriceValue,
      minBasePrice,
      maxBasePrice,
      capacityValue,
      setCapacityValue,
      minCapacity,
      maxCapacity,
      setSelectedGroupIds,
      selectedGroupIds,
      uniqueGroups,
      createCategory,
      categoryTypes,
      setCategoryTypes,
      selectedCategoryType,
      setSelectedCategoryType,
    }),
    [
      displayedData,
      isLoading,
      sorting,
      columnVisibility,
      filterActiveStatus,
      searchTerm,
      patchCategory,
      basePriceValue,
      minBasePrice,
      maxBasePrice,
      capacityValue,
      minCapacity,
      maxCapacity,
      selectedGroupIds,
      uniqueGroups,
      createCategory,
    ],
  );

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
};
