import { SelectedBridge } from "./components/bridge-selected.tsx";
import { BridgesProvider } from "./bridges-context.tsx";
import { BridgesList } from "./components/bridges-list.tsx";

export const BridgesPage = () => {
  return (
    <BridgesProvider>
      <div className="relative h-full overflow-y-auto font-neue">
        <div className="flex h-full space-x-3">
          <BridgesList />
          <SelectedBridge />
        </div>
      </div>
    </BridgesProvider>
  );
};
