import { DefaultSideSheet } from "@primitives/default-sheet";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Fixture,
  FixtureGroupWithFixtures,
} from "../../../../../../api-contracts/fixtures-and-items";
import { Input } from "@primitives/input";
import { Button } from "@primitives/button";
import { Added, Available } from "./sheet-components";

const initialGroup: FixtureGroupWithFixtures = {
  id: "",
  name: "",
  fixtures: [],
};
export const FixtureGroupSheet = ({
  openSheet,
  setOpenSheet,
  availableFixtures,
  groupToEdit,
  onSave,
}: {
  openSheet: boolean;
  setOpenSheet: React.Dispatch<SetStateAction<boolean>>;
  availableFixtures: Fixture[];
  groupToEdit: FixtureGroupWithFixtures;
  onSave: (groupWithFixtures: FixtureGroupWithFixtures) => void;
}) => {
  const { t } = useTranslation();
  const [tempGroupWithFixtures, setTempGroupWithFixtures] =
    useState<FixtureGroupWithFixtures>(initialGroup);
  const [addedFixtures, setAddedFixtures] = useState<Fixture[]>([]);

  const saveIsEnabled: boolean = tempGroupWithFixtures.name.length > 0;

  const handleAddFixtureGroup = (fixture: Fixture) => {
    setAddedFixtures((prev) => {
      if (!prev.some((g) => g.id === fixture.id)) {
        return [...prev, fixture];
      }
      return prev;
    });
  };

  const handleRemoveFixture = (fixture: Fixture) => {
    setAddedFixtures((prev) => {
      return prev.filter((g) => g.id !== fixture.id);
    });
  };

  useEffect(() => {
    if (openSheet) {
      setTempGroupWithFixtures(groupToEdit);
      setAddedFixtures(groupToEdit.fixtures || []);
    } else {
      setTempGroupWithFixtures(initialGroup);
      setAddedFixtures([]);
    }
  }, [openSheet, groupToEdit]);

  const handleSave = () => {
    const data = {
      id: tempGroupWithFixtures.id,
      name: tempGroupWithFixtures.name,
      fixtures: addedFixtures,
    };
    onSave(data);
  };

  return (
    <DefaultSideSheet
      className="sm:w-[600px]"
      open={openSheet}
      onOpenChange={setOpenSheet}
      title={tempGroupWithFixtures.id ? t("edit-group") : t("create-group")}
    >
      <div>
        <Input
          required
          className="bg-primary-card-backplate"
          type="text"
          minLength={1}
          maxLength={20}
          placeholder={t("name")}
          value={tempGroupWithFixtures.name}
          onChange={(e) =>
            setTempGroupWithFixtures((prev) => ({
              ...prev,
              name: e.target.value,
            }))
          }
        />
      </div>
      <p className="text-base font-bold text-primary-text">
        {t("add-fixtures")}
      </p>
      <div className="flex flex-grow gap-4 overflow-auto">
        {availableFixtures && (
          <Available
            addedItems={addedFixtures}
            availableItems={availableFixtures}
            onAdd={handleAddFixtureGroup}
          />
        )}
        <Added addedItems={addedFixtures} onRemove={handleRemoveFixture} />
      </div>
      <div className="flex justify-end">
        <Button disabled={!saveIsEnabled} onClick={handleSave}>
          {tempGroupWithFixtures.id ? t("save") : t("create")}
        </Button>
      </div>
    </DefaultSideSheet>
  );
};
