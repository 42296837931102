import { cn } from "@shared/utils/css";
import { cva, type VariantProps } from "class-variance-authority";
import type { BaseProps, PropsOf } from "../types/props";

const formFieldVariants = cva(
  cn("group gap-2 [&:not(:first-child)]:mt-6 last:mb-6"),
  {
    variants: {
      variant: {
        stacked: cn("grid grid-flow-row]"),
        inline: cn("grid grid-cols-[1fr,4fr] items-center"),
      },
      size: {
        default: cn(""),
      },
    },
    defaultVariants: {
      variant: "stacked",
      size: "default",
    },
  },
);

interface FormFieldProps
  extends VariantProps<typeof formFieldVariants>,
    BaseProps {}

export function FormFieldRoot({ variant, size, ...rest }: FormFieldProps) {
  return (
    <div
      data-layout={variant === "inline" ? "inline" : "stacked"}
      className={cn(formFieldVariants({ variant, size }))}
      {...rest}
    />
  );
}

export function FormFieldLabel({ className, ...rest }: PropsOf<"label">) {
  return (
    <label
      className={cn(
        "text-sm font-medium text-gray-900 dark:text-gray-300",
        "overflow-hidden text-ellipsis whitespace-nowrap",
        className,
      )}
      {...rest}
    />
  );
}

export function FormFieldDescription({ className, ...rest }: PropsOf<"div">) {
  return (
    <div
      className={cn(
        "text-xs text-gray-500 group-data-[layout=inline]:col-start-2",
        className,
      )}
      {...rest}
    />
  );
}

export function FormFieldError({ className, ...rest }: PropsOf<"div">) {
  return (
    <div
      className={cn(
        "text-xs text-red-500 group-data-[layout=inline]:col-start-2",
        className,
      )}
      {...rest}
    />
  );
}

export function FormFieldControl({ className, ...rest }: PropsOf<"div">) {
  return <div className={cn("", className)} {...rest} />;
}

// prettier-ignore
export const FormField = Object.assign({}, {
  Root: FormFieldRoot,
  Label: FormFieldLabel,
  Description: FormFieldDescription,
  Error: FormFieldError,
  Control: FormFieldControl,
});
