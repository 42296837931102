import {
  TargetGroupsActionType,
  useTargetGroupsPageContext,
} from "../target-groups-context.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { TargetGroupsForm } from "../components/target-groups-form.tsx";
import { FormEvent } from "react";
import { useTranslation } from "react-i18next";

export const EditTargetGroup = () => {
  const {
    sharedState: { action, current, selectedTargetGroupDetails },
    sharedFunctions: { setAction, updateTargetGroup },
  } = useTargetGroupsPageContext();
  const { t } = useTranslation();

  const item =
    action === TargetGroupsActionType.EDIT_CURRENT
      ? current
      : action === TargetGroupsActionType.EDIT_SELECTED
        ? selectedTargetGroupDetails
        : null;

  const onSubmit = (
    name: string,
    channelIds: string[],
    e: FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    item &&
      updateTargetGroup(item.id, {
        name: name !== item.name && name !== "" ? name : undefined,
        channelIds:
          channelIds !== item.channels.map(({ id }) => id)
            ? channelIds
            : undefined,
      });
  };

  const disabled = (name?: string, channelIds?: string[]) => {
    return (
      name === item?.name &&
      channelIds?.toString() === item?.channels.map(({ id }) => id).toString()
    );
  };

  return (
    <DefaultSideSheet
      title={t("edit-target-group")}
      open={
        action === TargetGroupsActionType.EDIT_CURRENT ||
        action === TargetGroupsActionType.EDIT_SELECTED
      }
      onOpenChange={() => setAction(TargetGroupsActionType.DEFAULT)}
    >
      <TargetGroupsForm
        onSubmit={onSubmit}
        buttonText={t("save")}
        disabled={disabled}
        nameRequired={false}
        item={item}
      />
    </DefaultSideSheet>
  );
};
