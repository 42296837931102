import {
  TargetGroupsActionType,
  useTargetGroupsPageContext,
} from "../target-groups-context.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { TargetGroupsForm } from "../components/target-groups-form.tsx";
import { FormEvent } from "react";
import { CreateTargetGroupRequest } from "../../../../../../api-contracts/target-groups";
import { useCreateTargetGroup } from "@api/target-groups.ts";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const AddTargetGroup = () => {
  const {
    sharedState: { action },
    sharedFunctions: { setAction, handleRefetch },
  } = useTargetGroupsPageContext();
  const {
    sharedFunctions: { toast, setLoading },
  } = useSharedContext();
  const { mutate: addTargetGroup } = useCreateTargetGroup();
  const { t } = useTranslation();

  const createTargetGroup = (req: CreateTargetGroupRequest) => {
    setLoading(true);
    addTargetGroup(req, {
      onError: (e: Error) => {
        toast({
          variant: "destructive",
          title: t("create-target-group-error", { error: e.message }),
        });
      },
      onSuccess: async (res) => {
        toast({
          variant: "success",
          title: t("target-group-created", { targetGroup: res.name }),
        });
        await handleRefetch();
      },
      onSettled: () => {
        setLoading(false);
        setAction(TargetGroupsActionType.DEFAULT);
      },
    });
  };

  const onSubmit = (
    name: string,
    channelIds: string[],
    e: FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    createTargetGroup({
      name: name,
      active: true,
      channelIds: channelIds,
    });
  };

  const disabled = (name?: string) => {
    return name === "";
  };

  return (
    <DefaultSideSheet
      title={t("create-target-group")}
      open={action === TargetGroupsActionType.ADD}
      onOpenChange={() => setAction(TargetGroupsActionType.DEFAULT)}
    >
      <TargetGroupsForm
        onSubmit={onSubmit}
        buttonText={t("create")}
        disabled={disabled}
        nameRequired={true}
      />
    </DefaultSideSheet>
  );
};
