import { createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import { GetConfigurationLanguages } from "../../../../api-contracts/configuration-languages";

export const CONFIGURATION_LANGUAGES_QUERY_KEY = "configuration-languages";

export const useGetConfigurationLanguages =
  createQuery<GetConfigurationLanguages>({
    primaryKey: CONFIGURATION_LANGUAGES_QUERY_KEY,

    queryFn: async () => {
      return api.get(
        `/api/${currentSite?.id}/${CONFIGURATION_LANGUAGES_QUERY_KEY}`,
      );
    },
  });
