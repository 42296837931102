import { createQuery } from "react-query-kit";
import { GetContextResponse } from "../../../../api-contracts/profile";
import { api } from "./api";

export const PROFILE_CONTEXT_QUERY_KEY = "/api/profile/context";

// GET /api/profile/context
export const useGetProfileContext = createQuery<GetContextResponse>({
  queryFn: async () => api.get(`${PROFILE_CONTEXT_QUERY_KEY}`),
  primaryKey: PROFILE_CONTEXT_QUERY_KEY,
});
