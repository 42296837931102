import {
  useCancelReservation,
  useGetReservation,
  usePatchReservation,
} from "@api/reservations";
import { NavigationArrows } from "@components/navigation-arrows";
import { useProfileContext } from "@context/profile-context";
import { Button } from "@primitives/button";
import { Input } from "@primitives/input";
import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs";
import { History, MessageSquare } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  GetBooking,
  GetReservation,
  ReservationState,
} from "../../../../../../api-contracts/reservations";
import {
  addMinutes,
  format,
  formatDistanceToNowStrict,
  isAfter,
} from "date-fns";
import ChannelSelect from "./channel-select";
import { Loading } from "@primitives/loading";
import TargetGroupSelect from "./target-group-select";
import GuestOrganizationSelect from "./guest-organization-select";
import ConfirmationSphereSelect from "./confirmation-sphere-select";
import ConfigurationLanguageSelect from "./configuration-language-select";
import TravelAgencySelect from "./travel-agency-select";
import GuestSelect from "./guest-select";
import NotesSelect from "./notes-select";
import { ConfirmReservationDialog } from "./confirm-reservation-dialog";
import { queryClient } from "query-client";
import { useToast } from "@hooks/use-toast";
import { ConfirmDialog } from "@pages/settings/categories/components/confirm-dialog";
import ReservationBooking from "./reservation-booking";
import { enGB, sv } from "date-fns/locale";
import AddBookingDrawer from "./add-booking-drawer";
import ReservationStateSelect from "./reservation-state-select";
import AddReservationNoteDialog from "./add-reservation-note-dialog";
import DebitReservationDialog from "./debit-reservation-dialog";
import ReservationCommunicationDrawer from "./reservation-communication-drawer";
import ReservationHistoryDrawer from "./reservation-history-drawer";
import CancelReservationDialog from "./cancel-reservation-dialog";
import CancelReasonDialog from "./cancel-reason-dialog";

export const reservationStateToColor = (state?: ReservationState) => {
  switch (state) {
    case "ongoing":
      return "booking-ongoing";
    case "preliminary":
      return "booking-tentative";
    case "unconfirmed":
      return "booking-unconfirmed";
    case "confirmed":
      return "booking-confirmed";
    case "guaranteed":
      return "booking-guaranteed";
    case "inquiry":
      return "booking-inquiry";
    case "waiting-list":
      return "booking-waiting-list";
    case "lost":
      return "booking-lost";
    case "cancelled":
      return "booking-cancelled";
    case "turn-away":
      return "booking-turn-away";
    case "aborted":
      return "booking-aborted";
    default:
      return "status-disabled";
  }
};

const ReservationPage = () => {
  const { module } = useProfileContext();
  const [t, i18n] = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const { toast } = useToast();
  const [countdown, setCountdown] = useState<string>();
  const [detailsTab, setDetailsTab] = useState<"roomr" | "campr" | "extra">(
    "roomr",
  );
  const [reservation, setReservation] = useState<GetReservation>();
  const [cancelReservationOpen, setCancelReservationOpen] =
    useState<boolean>(false);
  const [cancelReasonOpen, setCancelReasonOpen] = useState<boolean>(false);
  const [cancelOngoingReservationOpen, setCancelOngoingReservationOpen] =
    useState<boolean>(false);
  const [addBookingOpen, setAddBookingOpen] = useState<boolean>(false);
  const [addReservationNoteOpen, setAddReservationNoteOpen] =
    useState<boolean>(false);
  const [debitOpen, setDebitOpen] = useState<boolean>(false);
  const [debitCancelOpen, setDebitCancelOpen] = useState<boolean>(false);

  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const [communcationOpen, setCommunicationOpen] = useState<boolean>(false);

  const { id } = useParams();

  const updateReservation = usePatchReservation();
  const cancelReservation = useCancelReservation();

  const {
    data: fetchedReservation,
    isLoading: reservationIsLoading,
    isRefetching: reservationIsRefetching,
  } = useGetReservation({
    variables: {
      id: id as string,
    },
  });

  useEffect(() => {
    setReservation(
      fetchedReservation
        ? { ...fetchedReservation, bookings: [...fetchedReservation.bookings] }
        : undefined,
    );
    if (fetchedReservation?.state === "ongoing") {
      setIsEditing(true);
    }

    if (fetchedReservation?.state === "ongoing") {
      const updateExpiry = () => {
        const expiry = addMinutes(new Date(fetchedReservation.createdAt), 10);
        if (isAfter(new Date(), expiry)) {
          setCountdown(
            formatDistanceToNowStrict(new Date(), {
              locale: i18n.language === "sv-se" ? sv : enGB,
            }),
          );
        } else {
          setCountdown(
            formatDistanceToNowStrict(
              addMinutes(new Date(fetchedReservation.createdAt), 10),
              { locale: i18n.language === "sv-se" ? sv : enGB },
            ),
          );
        }
      };
      updateExpiry();
      const interval = setInterval(() => {
        updateExpiry();
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [fetchedReservation]);

  const [accomodationTotalPrice, accomodationTotalPaid] = useMemo(() => {
    let price = 0;
    let paid = 0;
    reservation?.bookings.forEach((b) => {
      price += b.slots?.reduce((acc, slot) => acc + slot.price, 0) ?? 0;
      paid += b.paid;
    });
    return [price, paid];
  }, [reservation]);

  const totalPrice = accomodationTotalPrice;
  const totalPaid = accomodationTotalPaid;
  const totalPaymentRemaining = accomodationTotalPrice - accomodationTotalPaid;

  if (!reservation) {
    return <Loading />;
  }

  const handleSave = async (confirm: boolean) => {
    if (confirm) {
      setConfirmDialogOpen(true);
      return;
    }
    try {
      await updateReservation.mutateAsync({
        id: reservation.id,
        patch: {
          confirmationSphereId: reservation.confirmationSphere?.id || null,
          holderId: reservation.holder?.id || null,
          targetGroupId: reservation.targetGroup?.id || null,
          travelAgencyId: reservation.travelAgency?.id || null,
          guestOrganizationId: reservation.guestOrganization?.id || null,
          langCode: reservation.langCode || null,
          noteIds: reservation.notes.map((n) => n.id),
          bookings: reservation.bookings.map((b) => ({
            id: b.id.startsWith("new") ? undefined : b.id,
            slots: b.slots.map((s) => ({
              id: s.id.startsWith("new") ? undefined : s.id,
              startTime: "14:00:00", // TODO use category starttime
              startDate: format(s.start, "yyyy-MM-dd"),
              priceRuleId: s.priceRuleId,
              priceOverrideAmount: s.priceOverrideAmount,
              priceAdjustmentPercent: s.priceAdjustmentPercent,
            })),
            categoryId: b.category.id,
            guests: b.guests.map((g) => ({
              id: g.id.startsWith("new") ? undefined : g.id,
              name: g.name?.trim() || null,
              surname: g.surname?.trim() || null,
              passportNumber: g.passportNumber?.trim() || null,
              address: g.address?.trim() || null,
              address2: g.address2?.trim() || null,
              city: g.city?.trim() || null,
              zip: g.zip?.trim() || null,
              state: g.state?.trim() || null,
              countryCode: g.countryCode?.trim() || null,
              phone: g.phone?.trim() || null,
              phone2: g.phone2?.trim() || null,
              email: g.email?.trim() || null,
              titleId: g.title?.id || null,
              orgId: g.organization?.id || null,
              checkin: g.checkin, // TODO add category starttime ?
              checkout: g.checkout, // TODO add category endtime ?
              bedPreferenceId: g.bedPreferenceId || null,
              ageType: g.ageType,
            })),
            numAdults: b.guests.filter((g) => g.ageType === "adult").length,
            numTeenagers: b.guests.filter((g) => g.ageType === "teenager")
              .length,
            numChildren: b.guests.filter((g) => g.ageType === "child").length,
            numInfants: b.guests.filter((g) => g.ageType === "infant").length,
            bookableId: b.bookable ? b.bookable.id : null,
            lockedBookable: b.bookable?.lockedToBookable || false,
            noteIds: b.notes.map((n) => n.id),
          })),
          // cardNumber
        },
      });
      queryClient.invalidateQueries({
        queryKey: useGetReservation.getKey(),
      });
      toast({
        variant: "success",
        title: t("saved-succesfully", {
          name: t("reservation-{{id}}", { id: reservation.id }),
        }),
        className: "text-status-success",
      });
      setIsEditing(false);
    } catch (err) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
      });
    }
  };

  const handleCancelReservation = async ({
    reason,
    note,
    sendConfirmation,
  }: {
    reason?: string;
    note?: string;
    sendConfirmation: boolean;
  }) => {
    try {
      await cancelReservation.mutateAsync({
        id: reservation.id,
        reason: reason || undefined,
        note: note?.trim() || undefined,
        sendConfirmation,
      });
      setCancelReasonOpen(false);

      // TODO save ?

      queryClient.invalidateQueries({
        queryKey: useGetReservation.getKey(),
      });
      setIsEditing(false);
    } catch (err) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
      });
    }
  };

  const handleAbortEdit = () => {
    if (reservation.state === "ongoing") {
      setCancelOngoingReservationOpen(true);
    } else {
      setIsEditing(false);
      setReservation(fetchedReservation);
    }
  };

  const handleBookingChange = (booking: GetBooking) => {
    const index = reservation.bookings.findIndex((b) => b.id === booking.id);
    reservation.bookings[index] = booking;
    setReservation({ ...reservation });
  };

  const addBooking = (booking: GetBooking) => {
    reservation.bookings.push(booking);
    setReservation({ ...reservation });
  };

  return (
    <>
      <div className="min-h-[1500px] bg-solid-background-backplate p-4 text-primary-text">
        <div className=" flex items-center justify-between">
          <NavigationArrows rootPath={`/${module}/rooms`} />
          <p className=" text-xs font-normal italic text-secondary-text ">
            {t("reservation-created-{{date}}-by-{{name}}", {
              date: format(reservation.createdAt, "yyyy-MM-dd, HH:mm"),
              name: `${reservation.createdBy?.name} ${reservation.createdBy?.surname}`,
            })}
          </p>
        </div>

        <div className="sticky -top-1 z-20 flex items-center justify-between bg-solid-background-backplate py-4">
          {!isEditing ||
          ["ongoing", "aborted", "cancelled", "turn-away"].includes(
            reservation.state,
          ) ? (
            <div
              className={`rounded-lg p-2 bg-${reservationStateToColor(reservation.state)}-100`}
            >
              <p
                className={` text-lg font-extrabold text-${reservationStateToColor(reservation.state)}`}
              >
                {t(reservation.state as string)}
              </p>
              {reservation.state === "ongoing" && (
                <p className=" text-xs font-normal">
                  {t("the-reservation-expires-in", { countdown })}
                </p>
              )}
            </div>
          ) : (
            <ReservationStateSelect
              value={reservation.state}
              onValueChange={(state) => {
                setReservation({ ...reservation, state: state });
                setAddReservationNoteOpen(true);
              }}
            />
          )}

          <div className=" flex flex-nowrap space-x-2">
            <Button variant="secondary" onClick={() => setHistoryOpen(true)}>
              <History size={18} />
            </Button>
            <Button
              variant="secondary"
              onClick={() => setCommunicationOpen(true)}
            >
              <MessageSquare size={18} />
            </Button>

            {isEditing &&
              !["lost", "cancelled", "aborted", "ongoing"].includes(
                reservation.state,
              ) && (
                <div className="pl-2">
                  <Button
                    variant="destructive"
                    onClick={() => setCancelReservationOpen(true)}
                  >
                    {t("cancel-the-reservation")}
                  </Button>
                </div>
              )}
            <div className="pl-4">
              {!isEditing &&
                ![
                  "lost",
                  "cancelled",
                  "aborted",
                  "ongoing",
                  "turn-away",
                ].includes(reservation.state) && (
                  <Button variant="secondary">{t("send-confirmation")}</Button>
                )}
              {isEditing && (
                <Button
                  variant="primary"
                  onClick={() => handleSave(reservation.state === "ongoing")}
                >
                  {reservation.state === "ongoing"
                    ? t("save-as...")
                    : t("save")}
                </Button>
              )}
            </div>
            {!isEditing && (
              <Button
                variant="primary"
                disabled={reservation.state === "cancelled"}
                onClick={() => setIsEditing(true)}
              >
                {t("edit")}
              </Button>
            )}
            {isEditing && (
              <Button variant="secondary" onClick={handleAbortEdit}>
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>

        <div className="flex flex-nowrap space-x-4">
          <div className=" flex-1 rounded-lg border border-main-border-color bg-secondary-card-backplate p-4">
            <p className=" text-sm font-bold">#{reservation.id}</p>

            <label
              className=" mt-4 block text-xs font-medium text-secondary-text"
              htmlFor="externalBookingNo"
            >
              {t("external-reservation-number")}
            </label>
            <Input
              disabled
              id="externalBookingNo"
              value={reservation.externalId || undefined}
            />

            <label
              className=" mt-4 block text-xs font-medium text-secondary-text"
              htmlFor="channel"
            >
              {t("reservation-channel")}
            </label>
            <ChannelSelect
              disabled={true}
              value={reservation.channel || undefined}
              onValueChange={(v) =>
                setReservation({ ...reservation, channel: v || null })
              }
              id="channel"
            />

            <label
              className=" mt-4 block text-xs font-medium text-secondary-text"
              htmlFor="targetAudience"
            >
              {t("target-audience")}
            </label>
            <TargetGroupSelect
              disabled={!isEditing}
              value={reservation.targetGroup || undefined}
              onValueChange={(v) =>
                setReservation({ ...reservation, targetGroup: v || null })
              }
              id="targetAudience"
            />

            <label
              className=" mt-4 block text-xs font-medium text-secondary-text"
              htmlFor="confirmationSphere"
            >
              {t("confirmation-sphere")}
            </label>
            <ConfirmationSphereSelect
              disabled={!isEditing}
              value={reservation.confirmationSphere || undefined}
              onValueChange={(v) =>
                setReservation({
                  ...reservation,
                  confirmationSphere: v || null,
                })
              }
              id="confirmationSphere"
            />

            <label
              className=" mt-4 block text-xs font-medium text-secondary-text"
              htmlFor="language"
            >
              {t("language")}
            </label>
            <ConfigurationLanguageSelect
              disabled={!isEditing}
              value={reservation.langCode || undefined}
              onValueChange={(v) =>
                setReservation({ ...reservation, langCode: v || null })
              }
              id="language"
            />

            <label
              className=" mt-4 block text-xs font-medium text-secondary-text"
              htmlFor="card"
            >
              {t("card")}
            </label>
            <Input disabled={true} id="card" placeholder={t("no-card-added")} />
          </div>
          <div className=" flex flex-1 flex-col rounded-lg border border-main-border-color bg-secondary-card-backplate p-4">
            <p className=" text-sm font-bold">{t("booker")}</p>

            <label
              className=" mt-4 block text-xs font-medium text-secondary-text"
              htmlFor="guestOrganization"
            >
              {t("organization")}
            </label>
            <GuestOrganizationSelect
              disabled={!isEditing}
              value={reservation.guestOrganization || undefined}
              onValueChange={(v) =>
                setReservation({ ...reservation, guestOrganization: v || null })
              }
              id="guestOrganization"
            />

            <label
              className=" mt-4 block text-xs font-medium text-secondary-text"
              htmlFor="travelAgency"
            >
              {t("travel-agency")}
            </label>
            <TravelAgencySelect
              disabled={!isEditing}
              value={reservation.travelAgency || undefined}
              onValueChange={(v) =>
                setReservation({ ...reservation, travelAgency: v || null })
              }
              id="travelAgency"
            />

            <label
              className=" mt-4 block text-xs font-medium text-secondary-text"
              htmlFor="booker"
            >
              {t("booker")}
            </label>
            <GuestSelect
              disabled={!isEditing}
              value={reservation.holder || undefined}
              onValueChange={(v) => {
                setReservation({ ...reservation, holder: v || null });
              }}
              id="booker"
            />

            <label className=" my-4 block text-sm font-bold" htmlFor="notes">
              {t("notes")}
            </label>
            <NotesSelect
              disabled={!isEditing}
              value={reservation.notes}
              onValueChange={(v) => {
                setReservation({ ...reservation, notes: v });
              }}
              noteType="reservation"
            />
          </div>
          <div className=" flex flex-1 flex-col rounded-lg border border-main-border-color bg-secondary-card-backplate p-4">
            <p className=" text-sm font-bold">{t("payment")}</p>

            <div className=" mt-8 flex-1 overflow-y-auto rounded-lg border border-main-border-color bg-primary-card-backplate p-4">
              <div>
                <div className=" flex flex-nowrap justify-between">
                  <p className=" text-xs font-normal">{t("accommodation")}</p>
                  <p className=" text-xs font-normal">
                    {new Intl.NumberFormat(
                      i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                      { maximumFractionDigits: 2 },
                    ).format(accomodationTotalPrice)}{" "}
                    SEK
                  </p>
                </div>
              </div>

              <div>
                <div className=" flex flex-nowrap justify-between py-4">
                  <p className=" text-sm font-extrabold">{t("total-cost")}</p>
                  <p className=" text-sm font-extrabold">
                    {new Intl.NumberFormat(
                      i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                      { maximumFractionDigits: 2 },
                    ).format(totalPrice)}{" "}
                    SEK
                  </p>
                </div>

                <div className=" flex flex-nowrap justify-between border-t border-highlighted-backplate py-4">
                  <p className=" text-sm font-extrabold">{t("paid")}</p>
                  <p className=" text-sm font-extrabold">
                    {new Intl.NumberFormat(
                      i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                      { maximumFractionDigits: 2 },
                    ).format(totalPaid)}{" "}
                    SEK
                  </p>
                </div>

                <div className=" flex flex-nowrap justify-between border-t border-highlighted-backplate py-4">
                  <p className=" text-sm font-extrabold">
                    {t("payment-remaining")}
                  </p>
                  <p className=" text-sm font-extrabold">
                    {new Intl.NumberFormat(
                      i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                      { maximumFractionDigits: 2 },
                    ).format(totalPaymentRemaining)}{" "}
                    SEK
                  </p>
                </div>
              </div>
            </div>

            <Button
              variant="secondary"
              className=" mt-4 w-full"
              onClick={() => setDebitOpen(true)}
            >
              {t("go-to-folio")}
            </Button>
          </div>
        </div>

        <div className=" mt-8">
          <h2 className=" text-lg font-extrabold">{t("booking-details")}</h2>
          <div className=" mt-2 flex flex-nowrap items-center justify-between">
            <Tabs
              defaultValue="hotel"
              value={detailsTab}
              onValueChange={setDetailsTab as any}
            >
              <TabsList>
                <TabsTrigger value="roomr">{t("roomr")}</TabsTrigger>
                <TabsTrigger disabled value="campr">
                  {t("campr")}
                </TabsTrigger>
                <TabsTrigger disabled value="extra">
                  {t("extra-products")}
                </TabsTrigger>
              </TabsList>
            </Tabs>
            <div>
              <Button
                variant="primary"
                disabled={!isEditing}
                onClick={() => setAddBookingOpen(true)}
              >
                {t("add-unit")}
              </Button>
            </div>
          </div>
        </div>

        <div className=" mb-[500px]">
          {reservation?.bookings.map((b) => (
            <ReservationBooking
              reservation={reservation}
              key={b.id}
              booking={b}
              holder={reservation.holder || undefined}
              onChange={handleBookingChange}
              isEditing={isEditing}
              onSave={() => handleSave(false)}
              onNewBooking={addBooking}
            />
          ))}
        </div>
      </div>
      <ConfirmReservationDialog
        open={confirmDialogOpen}
        onOpenChange={setConfirmDialogOpen}
        reservationId={reservation?.id}
        onProceed={() => handleSave(false)}
      />
      <CancelReservationDialog
        onOpenChange={setCancelReservationOpen}
        open={cancelReservationOpen}
        reservation={reservation}
        onProceed={(debit) =>
          debit ? setDebitCancelOpen(true) : setCancelReasonOpen(true)
        }
      />
      <CancelReasonDialog
        open={cancelReasonOpen}
        onOpenChange={setCancelReasonOpen}
        onProceed={handleCancelReservation}
      />
      <ConfirmDialog
        proceedBtnText={t("cancel-reservation")}
        title={t("do-you-really-want-to-cancel-the-reservation?")}
        description={t(
          "the-reservation-is-saved-as-canceled-and-will-no-longer-affect-the-category-or-room-availability",
        )}
        onOpenChange={setCancelOngoingReservationOpen}
        isOpen={cancelOngoingReservationOpen}
        onProceed={() => handleCancelReservation({ sendConfirmation: false })}
      />
      <AddBookingDrawer
        open={addBookingOpen}
        onOpenChange={setAddBookingOpen}
        onProceed={(newBooking) =>
          setReservation({
            ...reservation,
            bookings: [...reservation.bookings, newBooking],
          })
        }
        defaultCheckin={reservation.bookings[0]?.checkin}
        defaultCheckout={reservation.bookings[0]?.checkout}
      />
      <AddReservationNoteDialog
        open={addReservationNoteOpen}
        onOpenChange={setAddReservationNoteOpen}
        onProceed={(note) =>
          setReservation({
            ...reservation,
            notes: note ? [...reservation.notes, note] : reservation.notes,
          })
        }
      />
      <DebitReservationDialog
        open={debitOpen}
        onOpenChange={setDebitOpen}
        onProceed={() => {
          /* TODO */
        }}
        reservation={reservation}
      />
      <DebitReservationDialog
        open={debitCancelOpen}
        onOpenChange={setDebitCancelOpen}
        onProceed={() => {
          /* TODO */
          setCancelReasonOpen(true);
        }}
        reservation={reservation}
      />
      <ReservationCommunicationDrawer
        open={communcationOpen}
        onOpenChange={setCommunicationOpen}
      />
      <ReservationHistoryDrawer
        open={historyOpen}
        onOpenChange={setHistoryOpen}
      />
    </>
  );
};

export default ReservationPage;
