import {
  LocationsAction,
  useLocationsPageContext,
} from "../locations-context.tsx";
import { useEffect, useState } from "react";
import { AssetOverview } from "../../../../../../api-contracts/assets";
import { LocationProps } from "../../../settings/locations/components/locations-hierarchy-tree.tsx";
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "@primitives/card.tsx";
import { Button } from "@primitives/button.tsx";
import { LocationsSettingsMenu } from "../../../settings/locations/components/locations-settings-menu.tsx";
import {
  LocationsDropdownMenu,
  LocationsInputType,
} from "../../../settings/locations/components/locations-dropdown-menu.tsx";
import { AddLocation } from "../../../settings/locations/sections/add-location.tsx";
import { MoveLocation } from "../../../settings/locations/sections/move-location.tsx";
import { EditLocation } from "../../../settings/locations/sections/edit-location.tsx";
import { LocationsCardList } from "../../../settings/locations/components/locations-card-list.tsx";
import { HierarchyTreeImage } from "../../../settings/locations/images/hierarcy-tree-image.tsx";
import { AddAsset } from "../../../settings/locations/sections/assets/add-asset.tsx";
import { MoveAssets } from "../../../settings/locations/sections/assets/move-assets.tsx";
import { HotelIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

export const LocationsCard = () => {
  const {
    sharedState: {
      actionType,
      currentLocation,
      locationChildren,
      assets,
      locations,
    },
    sharedFunctions: { setActionType },
  } = useLocationsPageContext();
  const { t } = useTranslation();

  const [filteredLocations, setFilteredLocations] = useState<LocationProps[]>(
    [],
  );
  const [filteredAssets, setFilteredAssets] = useState<AssetOverview[]>([]);

  const defaultAssets = assets.filter(({ locations }) =>
    locations?.find((l) => l.id === currentLocation?.location.id),
  );

  const defaultLocations =
    currentLocation === null
      ? locations.filter(({ location }) => location.partOf === null)
      : locationChildren;

  useEffect(() => {
    setFilteredAssets(
      assets.filter((asset) =>
        asset.locations?.find((l) => l.id === currentLocation?.location.id),
      ),
    );
    setFilteredLocations(
      currentLocation === null
        ? locations.filter(({ location }) => location.partOf === null)
        : locationChildren,
    );
  }, [locationChildren, assets, locations, currentLocation]);

  const onAddLocation = () => {
    setActionType(() => LocationsAction.LOCATION_ADD);
  };

  const onAddAsset = () => {
    setActionType(() => LocationsAction.ASSET_ADD);
  };

  const onSearch = (value: string) => {
    if (value === "") {
      setFilteredLocations(defaultLocations);
      setFilteredAssets(defaultAssets);
    }

    if (value !== "") {
      setFilteredLocations(
        defaultLocations.filter(({ location }) =>
          location.name.toLowerCase().includes(value.toLowerCase().trim()),
        ),
      );
      setFilteredAssets(
        defaultAssets.filter((it) =>
          it.name.toLowerCase().includes(value.toLowerCase().trim()),
        ),
      );
    }
  };

  const renderByActionType = () => {
    switch (actionType) {
      case LocationsAction.ASSET_ADD:
        return <AddAsset />;
      case LocationsAction.ASSET_MOVE:
        return <MoveAssets />;
      case LocationsAction.LOCATION_ADD:
        return <AddLocation />;
      case LocationsAction.LOCATION_MOVE:
        return <MoveLocation />;
      case LocationsAction.LOCATION_EDIT:
        return <EditLocation />;
      default:
        return <></>;
    }
  };

  return (
    <Card className="flex h-full max-w-[500px] flex-grow flex-col">
      <CardTitle>{currentLocation?.location.name ?? t("locations")}</CardTitle>
      <CardDescription>
        {`${locationChildren.length > 0 ? locationChildren.length : 0} ${t("locations").toLowerCase()}, 
        ${filteredAssets.length} ${t("bookables").toLowerCase()}`}
      </CardDescription>
      <div className="flex w-full  items-center justify-between py-4">
        <div className="mr-3 flex min-w-0 flex-shrink space-x-3">
          <Button variant="primary" onClick={onAddLocation}>
            {t("create-location")}
          </Button>
          {currentLocation && (
            <Button variant="secondary" onClick={onAddAsset}>
              {t("add-bookable")}
            </Button>
          )}
        </div>
        <div className="flex items-center space-x-2">
          <LocationsSettingsMenu onSearchInput={onSearch} />
          {currentLocation && (
            <LocationsDropdownMenu
              item={currentLocation}
              type={LocationsInputType.LOCATION}
            />
          )}
        </div>
      </div>
      {renderByActionType()}
      {filteredLocations.length < 1 &&
        filteredAssets.length < 1 &&
        actionType != LocationsAction.LOCATION_ADD &&
        currentLocation && (
          <CardContent className="flex flex-grow flex-col items-center justify-center bg-primary-card-backplate">
            <p className="bold pb-5 text-gray-300">
              {t("no-locations-or-bookables-allocated-here")}
            </p>
            <HotelIcon size={100} className="text-secondary-card-backplate" />
          </CardContent>
        )}
      <LocationsCardList
        assets={filteredAssets}
        locations={filteredLocations}
      />
      {locationChildren.length === 0 && !currentLocation && (
        <div className="flex flex-grow flex-col items-center justify-center space-y-5">
          {HierarchyTreeImage()}
          <p className="w-[150px] text-center text-xs ">
            {t("create-new-location-to-start-hierarchy")}
          </p>
        </div>
      )}
    </Card>
  );
};
