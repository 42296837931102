import { Form } from "react-router-dom";
import { Input } from "@shared/primitives/input.tsx";
import { Button } from "@shared/primitives/button.tsx";
import { useState } from "react";

type InputProps = {
  onSubmit: (value: string) => void;
  onCancel: () => void;
  defaultValue?: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  buttonOrder: "primary_first" | "secondary_first";
  inputName: string;
  placeholder: string;
  required: boolean;
  label?: string;
};

export const DefaultInputWithCancel = ({ ...props }: InputProps) => {
  const [value, setValue] = useState<string>(props.defaultValue ?? "");

  const secondary = (
    <Button variant="secondary" type="reset" onClick={() => props.onCancel()}>
      {props.secondaryButtonText}
    </Button>
  );

  const primary = (
    <Button
      variant="primary"
      type="submit"
      disabled={
        props.defaultValue
          ? value === props.defaultValue || (props.required && value === "")
          : props.required && value === ""
      }
    >
      {props.primaryButtonText}
    </Button>
  );

  return (
    <Form
      onSubmit={() => props.onSubmit(value)}
      className="flex flex-grow flex-col"
    >
      <div>
        <label className="px-1 text-xs" htmlFor={props.inputName}>
          {props.label}
        </label>
        <Input
          autoFocus
          required={props.required}
          name={props.inputName}
          type="text"
          value={value}
          placeholder={props.placeholder}
          onChange={(e) => setValue(e.target.value)}
        />
        {props.buttonOrder === "primary_first" ? (
          <div className="mb-3 mt-3 flex items-center space-x-2">
            {primary}
            {secondary}
          </div>
        ) : (
          <div className="mb-3 mt-3 flex items-center space-x-2">
            {secondary}
            {primary}
          </div>
        )}
      </div>
    </Form>
  );
};
