import { LocationProps } from "../../../settings/locations/components/locations-hierarchy-tree.tsx";
import { LocationsListItem } from "../../../settings/locations/components/locations-list-item.tsx";
import { LocationsInputType } from "../../../settings/locations/components/locations-dropdown-menu.tsx";
import { AssetOverview } from "../../../../../../api-contracts/assets";

export const LocationsCardList = ({
  assets,
  locations,
}: {
  assets: AssetOverview[];
  locations: LocationProps[];
}) => {
  return (
    <div className="mx-[-1rem]">
      {locations.map((it) => (
        <LocationsListItem<LocationProps>
          key={it.location.id}
          label={it.location.name}
          item={it}
          type={LocationsInputType.LOCATION}
        />
      ))}
      {assets?.map((asset) => (
        <LocationsListItem<AssetOverview>
          item={asset}
          key={asset.id}
          label={asset.name}
          type={LocationsInputType.ASSET}
        />
      ))}
    </div>
  );
};
