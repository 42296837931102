import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { useCreateNote } from "@api/notes";
import { Note } from "../../../../../../api-contracts/notes";
import { useToast } from "@hooks/use-toast";
import { Textarea } from "@primitives/textarea";

export const AddReservationNoteDialog: FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProceed: (note?: Note) => void;
}> = ({ open, onOpenChange, onProceed }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const createNote = useCreateNote();

  const handleProceedWithNote = async () => {
    try {
      setCreateLoading(true);
      const note = await createNote.mutateAsync({
        message,
      });
      onProceed?.(note);
      onOpenChange(false);
      setMessage("");
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setCreateLoading(false);
  };

  const handleProceedWithoutNote = async () => {
    onProceed?.();
    onOpenChange(false);
    setMessage("");
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" h-fit w-fit min-w-[500px] max-w-fit">
          <div className=" flex h-full flex-col">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("note")}
            </DialogTitle>

            <p className=" mb-4 mt-2 text-sm font-normal">
              {t("add-note-about-update")}
            </p>

            <Textarea
              placeholder={t("note")}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <div className=" mt-6 flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={handleProceedWithoutNote}
                loading={createLoading}
              >
                {t("change-without-note")}
              </Button>
              <Button
                variant="primary"
                disabled={!message.trim()}
                onClick={handleProceedWithNote}
              >
                {t("add")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddReservationNoteDialog;
