import z from "zod";

function isFalsy(value: unknown) {
  return value === undefined || value === null || value === "";
}

export function parseQuery(query?: Record<string, unknown>) {
  if (!query) return "";

  const parsed = Object.entries(query)
    .map(([key, value]) => {
      if (isFalsy(value) || isFalsy(key)) return;

      return `${key}=${value}`;
    })
    .filter(Boolean)
    .join("&");

  return parsed.length !== 0 ? `?${parsed}` : "";
}

export function ensureLeadingSlash(...paths: string[]) {
  return paths.map((path) => (path.startsWith("/") ? path : `/${path}`));
}

export function sortToQueryString(sort: SortModel[]) {
  return sort
    .map((sortModel, index) => {
      return Object.entries(sortModel)
        .map(([key, value]) => {
          return `sort[${index}][${key}]=${value}`;
        })
        .join("&");
    })
    .join("&");
}

export function filterToQueryString(filter: FilterModel) {
  const query: string[] = [];

  if (filter.mode) {
    query.push(`filter[mode]=${filter.mode}`);
  }

  if (filter.rules) {
    filter.rules.forEach((rule, index) => {
      Object.entries(rule).forEach(([key, value]) => {
        query.push(`filter[rules][${index}][${key}]=${value}`);
      });
    });
  }

  return query.join("&");
}

export function isSortModelArray(value: unknown): value is SortModel[] {
  return z.array(sortSchema).nonempty().safeParse(value).success;
}

const sortOrder = z.enum(["asc", "desc"]);
export type SortOrder = z.infer<typeof sortOrder>;

const sortSchema = z.object({
  field: z.string(),
  sort: sortOrder,
});
export type SortModel = z.infer<typeof sortSchema>;

const filterOperator = z.enum([
  "contains",
  "startsWith",
  "endsWith",
  "equal",
  "isEmpty",
  "isNotEmpty",
  "isAnyOf",
]);
export type FilterOperator = z.infer<typeof filterOperator>;

export const filterRuleSchema = z.object({
  field: z.string(),
  operator: filterOperator.default("equal"),
  value: z.any(),
});
export type FilterRule = z.infer<typeof filterRuleSchema>;

const filterMode = z.enum(["and", "or"]);
export type FilterMode = z.infer<typeof filterMode>;

export const filterSchema = z.object({
  mode: filterMode.optional(),
  rules: z.array(filterRuleSchema).nonempty(),
});
export type FilterModel = z.infer<typeof filterSchema>;

export function isFilterModel(value: unknown): value is FilterModel {
  return filterSchema.safeParse(value).success;
}
