import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useGetProfileContext } from "@api/profile-site-module-context";
import { ACTIVE_SITE_STORAGE_KEY } from "./site-context";
import { useAuth } from "@hooks/use-auth";
import { Loading } from "@primitives/loading";
import { queryClient } from "query-client";
import { SiteWithImage } from "../../../../api-contracts/admin";
import { useNavigate } from "react-router-dom";

export type ModuleType = "roomr" | "campr" | "default";

interface ProfileContextType {
  module: ModuleType;
  setModule: (module: ModuleType) => void;
  modulePermissions: string[];
  routePermissions: string[];
  useHasPermission: (requiredPermissons: string[]) => boolean;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);
ProfileContext.displayName = "ProfileContext";

export const ACTIVE_MODULE_STORAGE_KEY = "activeModule";

export const ProfileContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { user, isAuthenticated } = useAuth();
  const { data: sites } = useGetProfileContext({
    enabled: isAuthenticated,
  });

  const getInitialModule = (): ModuleType => {
    const savedModule = localStorage.getItem(
      ACTIVE_MODULE_STORAGE_KEY,
    ) as ModuleType;
    return savedModule || "default";
  };
  const [module, setModule1] = useState<ModuleType>(getInitialModule());
  const [modulePermissions, setModulePermissions] = useState<string[]>([]);
  const [routePermissions, setRoutePermissions] = useState<string[]>([]);

  const setModule = (module: ModuleType) => {
    localStorage.setItem(ACTIVE_MODULE_STORAGE_KEY, module);
    setModule1(module);
  };

  useEffect(() => {
    if (isAuthenticated && user && sites) {
      const activeSiteId = localStorage.getItem(ACTIVE_SITE_STORAGE_KEY);
      const site =
        sites.find((s: SiteWithImage) => s.id === activeSiteId) || sites[0];

      setModulePermissions(site.modules);
      setRoutePermissions(site.permissions);

      const savedModule = localStorage.getItem(ACTIVE_MODULE_STORAGE_KEY);
      let initialModule: ModuleType;

      if (savedModule && site.modules.includes(savedModule)) {
        initialModule = savedModule as ModuleType;
      } else {
        initialModule = site.modules[0] as ModuleType;
      }

      const pathModule = window.location.pathname.split("/")[1] as ModuleType;

      if (!site.modules.includes(pathModule)) {
        setModule(initialModule);
        window.location.replace(`/${initialModule}`);
      } else if (pathModule !== initialModule) {
        setModule(pathModule);
      } else {
        setModule(initialModule);
      }
    }
  }, [sites, isAuthenticated]);

  const proceed = !!sites || !!module || !!isAuthenticated;

  //** Checking if site has permission - Useable in FE to hide/show components */
  const useHasPermission = (requiredPermissions: string[]): boolean => {
    const navigate = useNavigate();
    const [hasPermission, setHasPermission] = useState<boolean>(false);

    useEffect(() => {
      const missingPermissions = requiredPermissions.filter(
        (permission) => !routePermissions.includes(permission),
      );

      if (missingPermissions.length > 0) {
        console.log(`Missing permissions: ${missingPermissions.join(", ")}`);
        setHasPermission(false);
      } else {
        setHasPermission(true);
      }
    }, [requiredPermissions, navigate]);
    return hasPermission;
  };

  return (
    <ProfileContext.Provider
      value={{
        module,
        setModule,
        modulePermissions,
        routePermissions,
        useHasPermission,
      }}
    >
      {proceed ? children : <Loading />}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error(
      "useProfileContext must be used within a ProfileContextProvider",
    );
  }
  return context;
};
