import { Dispatch, Fragment, useState } from "react";
import { Check, ChevronDown } from "lucide-react";
import { Listbox, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { SearchBar } from "./search-bar";

interface Props<Option> {
  options: Option[];
  value: Option[];
  onChange: (value: Option[]) => void;
  getName: (opt: Option) => string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

function MultiSelect<T>({
  value,
  onChange,
  options,
  getName,
  placeholder,
  className,
  disabled,
}: Props<T>) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();

  const filteredOptions = options.filter((opt) =>
    getName(opt).toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Listbox value={value} onChange={onChange} multiple disabled={disabled}>
      <div className={`relative ${className}`}>
        <Listbox.Button className=" focus-visible:border-indigo-500 focus-visible:ring-offset-orange-300 relative h-10 w-full cursor-default rounded-md border border-highlighted-backplate bg-primary-button-backplate py-2 pl-3 pr-10 text-left hover:cursor-pointer focus:bg-primary-button-backplate focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 disabled:opacity-60 sm:text-sm">
          <span className="block truncate text-xs text-primary-text">
            {value?.map((v) => getName(v)).join(", ") || placeholder}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDown
              className="h-5 w-5 text-primary-icon-color"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-solid-backplate px-1 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            <SearchBar
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={`${t("search")}...`}
              className=" rounded-none border-l-0 border-r-0 border-t-0 bg-transparent text-sm font-normal  focus-visible:ring-0"
            />
            {filteredOptions.map((opt, idx) => (
              <Listbox.Option
                key={idx}
                className={`relative cursor-pointer select-none  py-2 pl-10 pr-4 text-primary-text`}
                value={opt}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate text-xs ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {getName(opt)}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-icon-color">
                        <Check className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export { MultiSelect };
