import { ChannelsPageProvider } from "./channels-context.tsx";
import { ChannelsCard } from "../../settings/channels/components/channels-card.tsx";
import { AddChannel } from "../../settings/channels/components/add-channel.tsx";
import { EditChannel } from "../../settings/channels/components/edit-channel.tsx";

const ChannelsPage = () => {
  return (
    <ChannelsPageProvider>
      <AddChannel />
      <EditChannel />
      <ChannelsCard />
    </ChannelsPageProvider>
  );
};

export default ChannelsPage;
