import { useGetGuestOrganizations } from "@api/guest-organizations";
import { useDebounce } from "@hooks/use-debounce";
import { SearchBar } from "@primitives/search-bar";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value?: { id: string; name: string };
  onValueChange?: (value?: { id: string; name: string }) => void;
  id?: string;
  disabled?: boolean;
}

const GuestOrganizationSimpleSelect = ({
  value,
  onValueChange,
  id,
  disabled,
}: Props) => {
  const [t] = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const {
    data: guestOrgsData,
    isLoading: guestOrgsIsLoading,
    isRefetching: guestOrgsIsRefetching,
  } = useGetGuestOrganizations({
    variables: {
      limit: 200,
      page: 1,
      pattern: debouncedSearchTerm || "",
    },
  });

  return (
    <Select
      disabled={disabled}
      value={value?.id}
      onValueChange={(value) =>
        onValueChange &&
        onValueChange(guestOrgsData?.find((c) => c.id === value))
      }
    >
      <SelectTrigger id={id} className="text-xs">
        <SelectValue>{value?.name}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        <div className=" sticky -top-1 z-10 w-full bg-solid-backplate">
          <SearchBar
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`${t("search")}...`}
            className="rounded-none border-l-0 border-r-0 border-t-0 text-sm font-normal  focus-visible:ring-0"
          />
        </div>
        {guestOrgsData?.map((c, i) => (
          <SelectItem key={i} value={c.id} className=" text-xs">
            {t(c.name)}
          </SelectItem>
        ))}
        {!guestOrgsData?.length ? (
          <p className=" p-2 text-center text-xs">
            {t("no-organizations-found")}
          </p>
        ) : null}
      </SelectContent>
    </Select>
  );
};

export default GuestOrganizationSimpleSelect;
