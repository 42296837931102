import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Bedlinen } from "../../../../../../api-contracts/bed-capacity";
import { CreateEdit } from "@pages/settings/bed-types/components/standard-bed-sheet.tsx";
import { useTranslation } from "react-i18next";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { Card } from "@primitives/card.tsx";
import { Input } from "@primitives/input.tsx";
import { Space } from "@components/space.tsx";
import { PlaceholderInput } from "@pages/settings/categories/components/placeholder-input.tsx";
import { Textarea } from "@primitives/textarea.tsx";
import { Button } from "@primitives/button.tsx";

interface Props {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  linenData: Bedlinen | undefined;
  type: CreateEdit;
  btnText: string;
}

export const BedLinenSheet: FC<Props> = ({
  open,
  onOpenChange,
  linenData,
  type,
  btnText,
}): ReactElement => {
  const emptyLinen: Bedlinen = {
    id: "",
    name: "",
    width: 0,
    length: 0,
    description: "",
  };
  const { t } = useTranslation();
  const { createLinen, updateLinen } = useBedData();
  const [linen, setLinen] = useState<Bedlinen>(emptyLinen);

  useEffect(() => {
    setLinen(linenData ?? emptyLinen);
  }, [linenData]);

  return (
    <DefaultSideSheet
      title={type === "edit" ? t("edit") : t("create-linen")}
      open={open}
      onOpenChange={onOpenChange}
    >
      <div className={"flex h-full flex-col justify-between overflow-auto"}>
        <div>
          <Card className={" mt-0 bg-secondary-card-backplate"}>
            <div>
              <p className={"font-extrabold"}>{t("linen-information")}</p>
            </div>
            <div className={"mt-3"}>
              <div className={"flex"}>
                <p>{t("name")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <Input
                className={
                  "font-neue text-[15px] font-normal text-secondary-text"
                }
                type={"text"}
                value={linen.name ?? t("name")}
                onChange={(event) =>
                  setLinen({ ...linen, name: event.target.value })
                }
                placeholder={t("name")}
              />
              <Space h={8} />
              <div className={"flex"}>
                <p>{t("width")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                defaultValue={t("width")}
                placeholder={"cm"}
                value={linen.width === 0 ? t("width") : linen.width?.toString()}
                onChange={(event) => {
                  setLinen({
                    ...linen,
                    width: Number(event.target.value),
                  });
                }}
              />
              <Space h={8} />
              <div className={" flex"}>
                <p>{t("length")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                defaultValue={t("length")}
                placeholder={"cm"}
                value={
                  linen.length === 0 ? t("length") : linen.length?.toString()
                }
                onChange={(event) =>
                  setLinen({
                    ...linen,
                    length: Number(event.target.value),
                  })
                }
              />
            </div>
            <Space h={20} />
            <Textarea
              placeholder={t("description")}
              disabled={false}
              value={linen.description ?? ""}
              onChange={(e) =>
                setLinen({ ...linen, description: e.target.value })
              }
            />
          </Card>
        </div>
        <div className="flex justify-end">
          <Button
            className={"rounded-xl"}
            onClick={() => {
              const linenToAdd = {
                name: linen.name,
                width: linen.width,
                length: linen.length,
                description: linen.description,
              };

              if (type === "create") {
                createLinen(linenToAdd).then(() => setLinen(emptyLinen));
              } else {
                updateLinen(linenToAdd, linen.id).then(() =>
                  setLinen(emptyLinen),
                );
              }
              onOpenChange(!open);
            }}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </DefaultSideSheet>
  );
};
