import {
  LocationsAction,
  useLocationsPageContext,
} from "../locations-context.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { DefaultInput } from "@primitives/default-input.tsx";
import { useTranslation } from "react-i18next";

export const EditLocation = () => {
  const {
    sharedState: { currentLocation, actionType },
    sharedFunctions: { updatingLocation, refetchLocations, setActionType },
  } = useLocationsPageContext();
  const { t } = useTranslation();

  const onSuccess = async () => {
    await refetchLocations();
  };

  return (
    <DefaultSideSheet
      open={actionType === LocationsAction.LOCATION_EDIT}
      onOpenChange={() => setActionType(LocationsAction.DEFAULT)}
      side="left"
      title={t("edit")}
      description={currentLocation?.location.name ?? ""}
    >
      <DefaultInput
        onSubmit={(value: string) =>
          currentLocation &&
          updatingLocation(
            {
              ...currentLocation,
              location: { ...currentLocation.location, name: value },
            },
            currentLocation.location.partOf,
            onSuccess,
          )
        }
        buttonText={t("done")}
        inputName={t("edit-location")}
        placeholder={t("location-name")}
        required={true}
        label={t("name")}
      />
    </DefaultSideSheet>
  );
};
