import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CreateAssetRequest,
  CreateAssetResponse,
  GetAssetWithoutBookablesRequest,
  GetAssetResponse,
  DeleteAssetResponse,
  GetAssetsResponse,
  GetGroupedAssetsResponse,
  PatchAssetRequest,
  PatchAssetResponse,
  AssetType,
} from "../../../../api-contracts/assets";
import { AssertType } from "vitest";

export const ASSETS_QUERY_KEY = "assets";

export const useGetAssets = createQuery<
  GetAssetsResponse,
  { types?: string[] }
>({
  primaryKey: ASSETS_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { types }] }) =>
    api.get(
      `/api/${currentSite?.id}/${ASSETS_QUERY_KEY}`,
      types !== undefined ? { types: types } : undefined,
    ),
});

export const useGetAsset = createQuery<
  GetAssetResponse,
  GetAssetWithoutBookablesRequest
>({
  primaryKey: ASSETS_QUERY_KEY,
  queryFn: async ({ queryKey: [, { id }] }) =>
    api.get(`/api/${currentSite?.id}/${ASSETS_QUERY_KEY}/${id}`, {
      with: "bookables",
    }),
});

export const useGetAssetsByCategory = createQuery<
  GetAssetsResponse | GetGroupedAssetsResponse,
  { showByCategory: boolean; types?: AssetType[] }
>({
  primaryKey: ASSETS_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { showByCategory, types }] }) => {
    const qs = [];
    if (showByCategory) {
      qs.push("by=category");
    }
    if (types?.length) {
      types.forEach((t) => qs.push(`types=${t}`));
    }
    return api.get(
      `/api/${currentSite?.id}/${ASSETS_QUERY_KEY}?${qs.join("&")}`,
    );
  },
});

export const useCreateAsset = createMutation<
  CreateAssetResponse,
  CreateAssetRequest
>({
  mutationFn: async (data) =>
    api.post(`/api/${currentSite?.id}/${ASSETS_QUERY_KEY}`, data),
});

export const useUpdateAsset = createMutation<
  PatchAssetResponse,
  { patch: PatchAssetRequest; id: string }
>({
  mutationFn: async (data) =>
    api.patch(
      `/api/${currentSite?.id}/${ASSETS_QUERY_KEY}/${data.id}`,
      data.patch,
    ),
});

export const useRemoveLocationFromAsset = createMutation<
  DeleteAssetResponse,
  { id: string }
>({
  mutationFn: async (data) =>
    api.delete(
      `/api/${currentSite?.id}/${ASSETS_QUERY_KEY}/${data.id}/location`,
    ),
});

export const useDeleteAsset = createMutation<
  DeleteAssetResponse,
  { id: string }
>({
  mutationFn: async (data) =>
    api.delete(`/api/${currentSite?.id}/${ASSETS_QUERY_KEY}/${data.id}`),
});
