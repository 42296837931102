import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loading } from "@primitives/loading.tsx";
import { useGetPriceRules } from "@api/price-rules.ts";
import {
  CreatePriceRuleRequest,
  GetPriceRule,
  GetPriceRuleCategory,
  GetPriceRulesResponse,
} from "../../../../../api-contracts/price-rules";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const enum PriceRuleActionType {
  DEFAULT = "DEFAULT",
  ADD = "ADD",
  EDIT = "EDIT",
  ACTIVATE = "ACTIVATE",
  DEACTIVATE = "DEACTIVATE",
}
interface SharedStateProps {
  priceRules: GetPriceRulesResponse;
  priceRuleId: string | null;
  actionType: PriceRuleActionType;
  activeRate: GetPriceRule | undefined;
  categories: GetPriceRuleCategory[];
  rules: GetPriceRule[];
  filter: {
    categories: string[];
    bar: string[];
    status: ("active" | "inactive")[];
  };
  activeFilters: string[];
  expanded: string[];
  showStatus: boolean;
  bar: CreatePriceRuleRequest;
}

interface SharedFunctionsProps {
  setPriceRules: Dispatch<SetStateAction<GetPriceRulesResponse>>;
  setFilter: Dispatch<
    SetStateAction<{
      categories: string[];
      bar: string[];
      status: ("active" | "inactive")[];
    }>
  >;
  setActionType: Dispatch<SetStateAction<PriceRuleActionType>>;
  setActiveFilters: Dispatch<SetStateAction<string[]>>;
  handleRefetch: () => Promise<void | undefined | null>;
  onDropDownItemClick: (rateId: string, action: string) => void | Promise<void>;
  openModal: (action: "enable" | "disable") => void;
  closeModal: () => void;
  modifyRate: (rateId: string) => void;
  setFilterDefault: () => void;
  setExpanded: Dispatch<SetStateAction<string[]>>;
  setShowStatus: Dispatch<SetStateAction<boolean>>;
  setBar: Dispatch<SetStateAction<CreatePriceRuleRequest>>;
  enableRate: (rateId: string) => void;
}

interface PriceRulesPageContextProps {
  sharedState: SharedStateProps;
  sharedFunctions: SharedFunctionsProps;
}

interface PriceRulesPageProviderProps {
  children: ReactNode;
}

const PriceRulesContext = createContext<PriceRulesPageContextProps | undefined>(
  undefined,
);

export const usePriceRulesPageContext = () => {
  const context = useContext(PriceRulesContext);

  if (!context) {
    throw new Error("context must be used in correct provider");
  }

  return context;
};

export const PriceRulesPageProvider: React.FC<PriceRulesPageProviderProps> = ({
  children,
}) => {
  const {
    sharedState: { isLoading },
    sharedFunctions: { toast },
  } = useSharedContext();

  const [categories, setCategories] = useState<GetPriceRuleCategory[]>([]);
  const [rules, setRules] = useState<GetPriceRule[]>([]);
  const [filter, setFilter] = useState<{
    categories: string[];
    bar: string[];
    status: ("active" | "inactive")[];
  }>({ categories: [], bar: [], status: ["active"] });
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const [activeRate, setActiveRate] = useState<GetPriceRule | undefined>();
  const [actionType, setActionType] = useState<PriceRuleActionType>(
    PriceRuleActionType.DEFAULT,
  );
  const [priceRuleId, setPriceRuleId] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [bar, setBar] = useState<CreatePriceRuleRequest>({
    entries: [],
  });

  const { data: getPriceRules, refetch } = useGetPriceRules();

  const [priceRules, setPriceRules] = useState<GetPriceRulesResponse>(
    getPriceRules ?? { categories: [], rules: [] },
  );
  const [showStatus, setShowStatus] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    actionType === PriceRuleActionType.DEFAULT &&
      setBar({ name: "", entries: [] });
  }, [actionType, setBar]);

  useEffect(() => {
    setShowStatus(
      () => rules.some((it) => !it.active) && activeFilters.includes("status"),
    );
  }, [rules, activeFilters]);

  useEffect(() => {
    getPriceRules && setPriceRules(() => getPriceRules);
    setCategories(priceRules.categories);
    setRules(priceRules.rules);
    setFilterDefault();
  }, [priceRules, getPriceRules]);

  useEffect(() => {
    filter.categories.length === 0 || !activeFilters.includes("categories")
      ? setCategories(priceRules.categories)
      : setCategories(
          priceRules.categories.filter((it) =>
            filter.categories.includes(it.id),
          ),
        );
    setRules(() => {
      let newList = priceRules.rules;

      if (
        activeFilters.includes("status") &&
        filter.status.every((it) => it === "active")
      ) {
        newList = priceRules.rules.filter((it) => it.active);
      }
      if (
        activeFilters.includes("status") &&
        filter.status.every((it) => it === "inactive")
      ) {
        newList = priceRules.rules.filter((it) => !it.active);
      }

      if (activeFilters.includes("bar") && filter.bar.length > 0) {
        return newList.filter((it) =>
          filter.bar.some((name) => name === it.name),
        );
      }
      return newList;
    });
  }, [filter, setCategories, setRules, activeFilters]);

  const setFilterDefault = () => {
    setActiveFilters(["categories", "bar"]);
    setFilter({
      categories: priceRules.categories.map(({ id }) => id),
      status: ["active"],
      bar: priceRules.rules.map(({ name }) => name),
    });
  };

  const openModal = (action: "enable" | "disable") => {
    action === "enable" && setActionType(PriceRuleActionType.ACTIVATE);
    action === "disable" && setActionType(PriceRuleActionType.DEACTIVATE);
  };

  const closeModal = () => {
    setActiveRate(undefined);
    setActionType(PriceRuleActionType.DEFAULT);
  };

  useEffect(() => {
    getPriceRules && setPriceRules(() => getPriceRules);
  }, [getPriceRules, setPriceRules]);

  const handleRefetch = async () => {
    const res = await refetch();
    if (res.error) {
      toast({
        variant: "destructive",
        title: t("refetch-priceRules-error", { error: res.error }),
      });
      return null;
    }
    return res.data && setPriceRules(() => res.data);
  };

  const duplicateRate = (rateId: string) => {
    const rate = priceRules?.rules.find((rule) => rule.id === rateId);
    rate &&
      setBar({
        inactivationDate: rate.inactivationDate,
        reactivationDate: rate.reactivationDate,
        entries: rate.entries.map((entry) => {
          return {
            ...entry,
            overrides: entry.overrides.map((override) => {
              return {
                overrideId: override.id,
                price: override.price,
              };
            }),
          };
        }),
      });
    setActionType(PriceRuleActionType.ADD);
  };

  const modifyRate = (rateId: string) => {
    const rate = priceRules?.rules.find((rule) => rule.id === rateId);
    rate &&
      setBar({
        name: rate.name,
        inactivationDate: rate.inactivationDate,
        reactivationDate: rate.reactivationDate,
        entries: rate.entries.map((entry) => {
          return {
            ...entry,
            overrides: entry.overrides.map((override) => {
              return {
                overrideId: override.id,
                price: override.price,
              };
            }),
          };
        }),
      });
    rate && setPriceRuleId(rateId);
    setActionType(PriceRuleActionType.EDIT);
  };

  const enableRate = (rateId: string) => {
    const rate = priceRules?.rules.find((rule) => rule.id === rateId);
    setActiveRate(rate);
    openModal("enable");
  };

  const disableRate = (rateId: string) => {
    const rate = priceRules?.rules.find((rule) => rule.id === rateId);
    setActiveRate(rate);
    openModal("disable");
  };

  const onDropDownItemClick = (rateId: string, action: string) => {
    switch (action) {
      case "duplicate":
        return duplicateRate(rateId);
      case "modify":
        return modifyRate(rateId);
      case "disable":
        return disableRate(rateId);
      case "enable":
        return enableRate(rateId);
    }
  };

  const sharedState = {
    priceRules,
    priceRuleId,
    actionType,
    activeRate,
    categories,
    rules,
    filter,
    activeFilters,
    expanded,
    showStatus,
    bar,
  };

  const sharedFunctions = {
    setPriceRules,
    handleRefetch,
    onDropDownItemClick,
    openModal,
    closeModal,
    modifyRate,
    setFilter,
    setFilterDefault,
    setActiveFilters,
    setActionType,
    setExpanded,
    setShowStatus,
    setBar,
    enableRate,
  };

  return (
    <PriceRulesContext.Provider value={{ sharedState, sharedFunctions }}>
      {isLoading && <Loading />}
      {actionType !== PriceRuleActionType.DEFAULT && (
        <div className="backdrop fixed inset-0 z-[50] flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black/75 outline-none focus:outline-none" />
      )}
      <div className="z-200 relative h-full space-y-4 overflow-y-auto p-4">
        {children}
      </div>
    </PriceRulesContext.Provider>
  );
};
