import { useState } from "react";
import { startOfDay, isToday, isBefore } from "date-fns";
import { ChevronDown, StickyNote } from "lucide-react";
import {
  CalendarDay,
  GetPriceRulesPriceRule,
  SetPriceCalendarRequest,
} from "../../../../../../api-contracts/price-calendar";
import { PriceRuleSelect } from "./price-rule-select";
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { usePriceCalendarContext } from "../price-calendar-context";
import {
  formatOccupancyAsPercentage,
  getWeatherIcon,
} from "../price-calendar.utils";
import { useTranslation } from "react-i18next";

export const YearCalendarDayCell = ({
  day,
  today,
  priceRules,
  isSelected,
}: {
  day: CalendarDay;
  today: Date;
  priceRules: GetPriceRulesPriceRule[];
  isSelected: boolean;
}) => {
  const {
    showOccupancy,
    showPrices,
    showWeather,
    selectedCategory,
    handlePriceRuleChange,
    selectedSubCategory,
  } = usePriceCalendarContext();
  const { t } = useTranslation();
  const dateToCheck = startOfDay(day.date);
  const isCurrentDate = isToday(dateToCheck);
  const isPastDate = isBefore(dateToCheck, today);

  const priceRuleOfDay = day.priceRule;
  const occupancyOfDay = formatOccupancyAsPercentage(day.occupancy);
  const priceOfDay = day.price;
  const weatherOfDay = day.weather;
  const isDivergentPriceRule: boolean =
    day.distinctPriceRules > 1 && selectedSubCategory === "all";

  const updatePriceRule = async (newPriceRuleId: string | null) => {
    const payload: SetPriceCalendarRequest = {
      months: [
        {
          month: dateToCheck.getMonth() + 1,
          year: dateToCheck.getFullYear(),
          days: [
            {
              day: day.day,
              priceRuleId: newPriceRuleId,
            },
          ],
        },
      ],
      categoryId: selectedSubCategory === "all" ? null : selectedSubCategory,
      categoryTypes: selectedCategory,
    };

    try {
      handlePriceRuleChange(payload);
    } catch (error) {}
  };

  const deletePriceRule = async () => {
    const payload: SetPriceCalendarRequest = {
      months: [
        {
          month: dateToCheck.getMonth() + 1,
          year: dateToCheck.getFullYear(),
          days: [
            {
              day: day.day,
              priceRuleId: null,
            },
          ],
        },
      ],
      categoryId: selectedSubCategory === "all" ? null : selectedSubCategory,
      categoryTypes: selectedCategory,
    };
    try {
      handlePriceRuleChange(payload);
    } catch (error) {}
  };
  return (
    <div
      className={` group z-20 mx-1 flex h-full cursor-pointer flex-col justify-center p-2 align-middle  ${
        isPastDate ? "opacity-40" : ""
      }`}
    >
      <div className="flex justify-between">
        <div
          className={`${
            isCurrentDate
              ? "h-5 w-5 items-center justify-center rounded-full bg-amber-500 font-sans"
              : ""
          } flex ${isSelected ? "text-quaternary-text" : "text-secondary-text"}`}
        >
          <p className="text-xs font-normal">{day.day}</p>
        </div>

        <div className="flex items-center">
          {day.notes.length > 0 && (
            <StickyNote
              size={12}
              className={` ${isSelected ? "text-quaternary-text" : "text-secondary-text"}`}
            />
          )}
          {showWeather && weatherOfDay && (
            <div
              className={`ml-1 ${isSelected ? "text-quaternary-text" : "text-secondary-text"}`}
            >
              {getWeatherIcon(weatherOfDay.weatherIcon)}
            </div>
          )}
        </div>
      </div>

      <div
        className={`mt-1 flex justify-between ${isSelected && "text-quaternary-text"}`}
      >
        <p
          className={`mr-2 truncate text-sm font-medium ${
            isDivergentPriceRule
              ? "text-accent-amber-700"
              : isSelected
                ? "text-quaternary-text"
                : "text-primary-text"
          }`}
        >
          {isDivergentPriceRule
            ? `${t("divergent")}`
            : priceRuleOfDay.name
              ? priceRuleOfDay.name
              : "--"}
        </p>
        {priceRules && !isPastDate && (
          <PriceRuleDropdown
            priceRules={priceRules}
            priceRuleOfDay={priceRuleOfDay}
            updatePriceRule={updatePriceRule}
            deletePriceRule={deletePriceRule}
            className={`${isSelected ? "border-quaternary-text text-quaternary-text" : "border-border-color text-primary-text"}`}
          />
        )}
      </div>
      <div
        className={`flex justify-between text-xs font-medium text-secondary-text ${isSelected && "text-quaternary-text"}`}
      >
        {occupancyOfDay && showOccupancy && <p>{occupancyOfDay}%</p>}
        {priceOfDay && showPrices && <p>{priceOfDay} SEK</p>}
      </div>
    </div>
  );
};

export const PriceRuleDropdown = ({
  priceRules,
  priceRuleOfDay,
  updatePriceRule,
  deletePriceRule,
  className,
}: {
  priceRules: GetPriceRulesPriceRule[];
  priceRuleOfDay: GetPriceRulesPriceRule;
  updatePriceRule: (id: string | null) => void;
  deletePriceRule: () => void;
  className: string;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  return (
    <DropdownMenuRoot open={isDropdownOpen} onOpenChange={handleToggleDropdown}>
      <DropdownMenuTrigger onClick={handleToggleDropdown}>
        <div
          className={`flex h-4 w-4 items-center justify-center rounded-sm border p-0 opacity-0 transition-opacity duration-200 group-hover:opacity-100 ${className}`}
        >
          <ChevronDown size={12} />
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <PriceRuleSelect
          toggleDropdown={handleToggleDropdown}
          priceRules={priceRules}
          priceRuleOfDay={priceRuleOfDay}
          setPriceRule={updatePriceRule}
          deletePriceRule={deletePriceRule}
        />
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};
