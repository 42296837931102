import React, { useState } from "react";
import { CheckedState } from "@radix-ui/react-checkbox";
import { Button } from "@primitives/button.tsx";
import {
  DropdownMenu,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@primitives/dropdown-menu.tsx";
import { useOutsideClick } from "@hooks/use-outside-click.tsx";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label.tsx";
import { useTranslation } from "react-i18next";
import { AssetOverview } from "../../../../../../api-contracts/assets";
import { FilterIcon } from "lucide-react";

type FilterProps = {
  filters: AssetOverview[];
  onClick: (filters: string[]) => void;
  menuLabel: string;
};
export const BookablesFilterDropdown = ({
  filters,
  onClick,
  menuLabel,
}: FilterProps) => {
  const [filter, setFilter] = useState<AssetOverview[]>([]);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<AssetOverview[]>([]);

  const { t } = useTranslation();

  const updateChecked = (checked: CheckedState, item: AssetOverview) => {
    if (checked)
      setCheckedItems((prev) => (prev.includes(item) ? prev : [...prev, item]));
    if (!checked)
      setCheckedItems((prev) =>
        prev === null ? filter : prev.filter((it) => it.id != item.id),
      );
  };

  const filterRef = useOutsideClick(() => {
    setOpenMenu(false);
  });

  return (
    <DropdownMenu.Root open={openMenu}>
      <DropdownMenu.Trigger asChild onClick={() => setOpenMenu(true)}>
        <Button size={"icon"} variant="secondary">
          <FilterIcon size={17} />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        ref={filterRef}
        className="absolute z-[300] flex h-[70vh] w-[361px] flex-col justify-between overflow-y-auto"
      >
        <div>
          <DropdownMenuLabel>{menuLabel}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <div className="flex h-[50px] flex-col">
            {filters.map((it) => (
              <DropdownMenu.Item
                key={it.id}
                onSelect={(event) => event.preventDefault()}
              >
                <CheckboxWithLabel
                  label={it.name}
                  value={it.id}
                  key={it.id}
                  name={it.name}
                  checked={
                    checkedItems &&
                    checkedItems.map((asset) => asset.id).includes(it.id)
                  }
                  onCheckedChange={(checked) => updateChecked(checked, it)}
                />
              </DropdownMenu.Item>
            ))}

            <DropdownMenuSeparator />
          </div>
        </div>
        <div className="flex justify-between">
          <Button
            variant="outline"
            onClick={() => {
              setOpenMenu(false);
              setCheckedItems([]);
              setFilter(filters);
              onClick([]);
            }}
          >
            {t("reset")}
          </Button>
          <Button
            onClick={() => {
              setOpenMenu(false);
              setFilter(checkedItems);
              onClick(checkedItems.map((it) => it.id));
            }}
          >
            {t("use")}
          </Button>
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
