import { clearAuth, isAuthenticated, user } from "@shared/utils/auth-manager";
import { createContext } from "react";
import { LoginResponse } from "../../../../api-contracts/auth";

export type AuthUser = Omit<LoginResponse, 0>;
interface AuthContextProps {
  user?: AuthUser;
  isAuthenticated: boolean;
  clearAuth: () => void;
}

export const AuthContext = createContext<AuthContextProps>(
  undefined as unknown as AuthContextProps,
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthContext.Provider
      value={{
        clearAuth,
        user: user.value,
        isAuthenticated: isAuthenticated.value,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
