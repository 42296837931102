import { useAuth } from "@hooks/use-auth";
import { Navigate, Outlet } from "react-router-dom";

function Root() {
  const { user } = useAuth();

  const isAtRoot = window.location.pathname === "/";

  return user ? (
    <div className="min-h-screen overscroll-none bg-screen-background">
      <Outlet />
    </div>
  ) : (
    <>
      <Outlet />
      {isAtRoot && <Navigate to={"/login"} replace />}
    </>
  );
}

export default Root;
