import { Card, CardContent, CardTitle } from "@primitives/card.tsx";
import { Button } from "@primitives/button.tsx";
import { Plus } from "lucide-react";
import { TargetGroupsCardItem } from "./target-groups-card-item.tsx";
import {
  TargetGroupsActionType,
  useTargetGroupsPageContext,
} from "../target-groups-context.tsx";
import { AddTargetGroup } from "../sections/add-target-group.tsx";
import { EditTargetGroup } from "../sections/edit-target-group.tsx";
import { useTranslation } from "react-i18next";
import { GetTargetGroupResponse } from "../../../../../../api-contracts/target-groups";

export const TargetGroupsCard = () => {
  const {
    sharedState: { action, targetGroups },
    sharedFunctions: { setAction, setCurrent },
  } = useTargetGroupsPageContext();
  const { t } = useTranslation();

  const sortArray = (a: GetTargetGroupResponse, b: GetTargetGroupResponse) => {
    const compareAlwaysActive =
      (a.alwaysActive ?? 0) === (b.alwaysActive ?? 0)
        ? 0
        : (a.alwaysActive ?? 0) > (b.alwaysActive ?? 0)
          ? -1
          : 1;
    const compareMutable =
      (a.mutable ?? 0) === (b.mutable ?? 0)
        ? 0
        : (a.mutable ?? 0) > (b.mutable ?? 0)
          ? 1
          : -1;
    const compareName = t(a.name) > t(b.name) ? 1 : -1;

    return compareAlwaysActive || compareMutable || compareName;
  };

  return (
    <>
      {(action === TargetGroupsActionType.EDIT_CURRENT ||
        action === TargetGroupsActionType.EDIT_SELECTED) && <EditTargetGroup />}
      {action === TargetGroupsActionType.ADD && <AddTargetGroup />}
      <Card className="max-w-[500px] flex-grow">
        <CardTitle>{t("target-groups")}</CardTitle>
        <div className="py-4">
          <Button
            variant="primary"
            size="icon"
            onClick={() => {
              setCurrent(null);
              setAction(TargetGroupsActionType.ADD);
            }}
          >
            <Plus size={17} />
          </Button>
        </div>
        <CardContent className="mx-[-16px] h-[calc(100vh-250px)] flex-grow overflow-y-auto p-0">
          <div className="flex flex-col gap-0.5">
            {targetGroups
              .sort((a, b) => sortArray(a, b))
              .map((target, i) => (
                <TargetGroupsCardItem
                  key={`${i}-${target.id}`}
                  targetGroup={target}
                  editable={target.mutable ?? true}
                  disabled={target.alwaysActive}
                />
              ))}
          </div>
        </CardContent>
      </Card>
    </>
  );
};
