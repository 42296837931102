import { useTranslation } from "react-i18next";
import { SetStateAction, useEffect, useState } from "react";
import { Form } from "react-router-dom";
import { Input } from "@primitives/input";
import { Button } from "@primitives/button";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { ColorDialog } from "./color-picker";
import { ExtendedTag } from "../../../../../../api-contracts/tags";
import { getTextColor } from "../tags-utils";
import { isEqual } from "lodash";

export const TagSheet = ({
  openSheet,
  setOpenSheet,
  tagObject,
  onSave,
}: {
  openSheet: boolean;
  setOpenSheet: React.Dispatch<SetStateAction<boolean>>;
  tagObject: ExtendedTag;
  onSave: (tag: ExtendedTag) => void;
}) => {
  const { t } = useTranslation();
  const [openColor, setOpenColor] = useState<boolean>(false);
  const [tag, setTag] = useState<ExtendedTag>(tagObject);

  const handleInputChange = (value: string | boolean, key: string) => {
    setTag((prevTag) => ({
      ...prevTag,
      [key]: value,
    }));
  };

  const handleSetColor = (newColor: string) => {
    setTag((prev) => ({ ...prev, color: newColor }));
  };

  useEffect(() => {
    setTag(tagObject);
  }, [tagObject]);

  const hasChanges = !isEqual(tag, tagObject);
  const nameIsEmpty = tag.name.length > 0;
  const shortIsEmpty = tag.short.length > 0;
  const saveIsEnabled: boolean = hasChanges && nameIsEmpty && shortIsEmpty;

  return (
    <>
      <DefaultSideSheet
        open={openSheet}
        onOpenChange={setOpenSheet}
        title={tagObject.id ? t("edit-tag") : t("create-tag")}
      >
        <Form className="h-full rounded-md bg-secondary-card-backplate p-4 text-primary-text">
          <div>
            <p className="mb-1 text-sm font-medium ">
              {`${t("name")}`}
              {tag.name.length < 1 ? (
                <span className="text-status-error"> *</span>
              ) : null}
            </p>
            <Input
              required
              className="bg-primary-card-backplate"
              type="text"
              minLength={1}
              maxLength={20}
              placeholder={t("name")}
              value={tag.name}
              onChange={(e) => handleInputChange(e.target.value, "name")}
            />
          </div>
          <div className="py-4">
            <p className="mb-1 text-sm font-medium ">
              {`${t("abbreviation")}`}
              {tag.short.length < 1 ? (
                <span className="text-status-error"> *</span>
              ) : null}
            </p>
            <Input
              required
              className="bg-primary-card-backplate"
              type="text"
              minLength={1}
              maxLength={5}
              placeholder={t("abbreviation")}
              value={tag.short}
              onChange={(e) => handleInputChange(e.target.value, "short")}
            />
          </div>
          <div className="pb-4">
            <p className="mb-1 text-sm font-medium ">{`${t("color")}`}</p>
            <div
              className="flex h-10 w-full items-center justify-between rounded-md border
          border-highlighted-backplate bg-primary-card-backplate pe-3 ps-3 text-sm font-bold"
            >
              <div
                style={{
                  backgroundColor: tag.color,
                  color: getTextColor(tag.color),
                }}
                className="flex h-7 items-center justify-center rounded-md px-2 font-bold"
              >
                {tag.color.toUpperCase()}
              </div>

              <Button
                className="h-7 px-2"
                variant="outline"
                onClick={() => setOpenColor((prev) => !prev)}
              >
                {t("choose-color")}
              </Button>
            </div>
          </div>
          <div>
            <p className="mb-1 text-sm font-medium ">{`${t("active-wbe")}`}</p>
            <div
              className="flex h-10 w-full items-center justify-between rounded-md border
          border-highlighted-backplate bg-primary-card-backplate pe-3 ps-3 text-sm font-bold"
            >
              <SwitchWithLabel
                checked={tag.external}
                onCheckedChange={(val) =>
                  handleInputChange(val as boolean, "external")
                }
                label={t("active-wbe")}
              />
            </div>
          </div>
        </Form>

        <div className="flex justify-end">
          <Button disabled={!saveIsEnabled} onClick={() => onSave(tag)}>
            {t("save")}
          </Button>
        </div>
      </DefaultSideSheet>
      {openColor && (
        <ColorDialog
          isOpen={openColor}
          onOpenChange={setOpenColor}
          color={tag.color}
          setColor={handleSetColor}
        />
      )}
    </>
  );
};
