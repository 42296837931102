import { t } from "i18next";
import { Button } from "@primitives/button.tsx";
import {
  BridgeSheetState,
  useBridgesPageContext,
} from "../bridges-context.tsx";
import { FormField } from "@primitives/form-field.tsx";
import { Input } from "@primitives/input.tsx";
import { Form } from "react-router-dom";
import { useState } from "react";
import {
  CheckBridgeCodeResponse,
  CreateBridgeRequest,
  CreateBridgeResponse,
} from "../../../../../../api-contracts/bridges";
import { queryClient } from "../../../../query-client.ts";
import { useGetBridges } from "@api/bridges.ts";
import { CodeCheck } from "./code-check.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";

export const CODE_LENGTH = 6;

export const AddBridgeSheet = () => {
  const {
    setSheetState,
    sheetState,
    sendConnectBridgeCommand,
    setSelectedBridge,
  } = useBridgesPageContext();

  const onCheck = (code: string, codeRes: CheckBridgeCodeResponse) => {
    if (code.length === CODE_LENGTH) {
      setCheckCodeError(!codeRes.foundBridge);
    }
    setNewBridge({
      ...newBridge,
      code: code,
    });
  };

  const [newBridge, setNewBridge] = useState<CreateBridgeRequest>({
    name: "",
    description: "",
    code: "",
  });
  const [checkCodeError, setCheckCodeError] = useState<boolean>(false);
  const resetBridge = () => {
    setNewBridge({
      name: "",
      description: "",
      code: "",
    });
    setCheckCodeError(false);
  };

  const createdNewBridge = (bridge: CreateBridgeResponse) => {
    queryClient.invalidateQueries({
      queryKey: useGetBridges.getKey(),
    });
    setSelectedBridge(bridge);
    resetBridge();
    setSheetState(BridgeSheetState.CLOSED);
  };

  const hasValidBridgeInfo = () => {
    return newBridge.name !== "" && newBridge.code.length === CODE_LENGTH;
  };

  const submitForm = () => {
    if (hasValidBridgeInfo()) {
      sendConnectBridgeCommand(newBridge, createdNewBridge);
    }
    setCheckCodeError(!hasValidBridgeInfo());
  };

  return (
    <DefaultSideSheet
      title={t("add-bridge")}
      open={sheetState === BridgeSheetState.ADD_BRIDGE}
      onOpenChange={() => {
        resetBridge();
        setSheetState(BridgeSheetState.CLOSED);
      }}
    >
      <Form
        onSubmit={() => submitForm()}
        className="flex flex-grow flex-col justify-between"
      >
        <div>
          <div className={"flex flex-col"}>
            <FormField.Label htmlFor="BridgeName" className="pb-2">
              <span>{t("name")}</span>
              <span className={"pl-1 text-red-600"}>*</span>
            </FormField.Label>
            <Input
              className="w-full border"
              required
              name="BridgeName"
              type="text"
              value={newBridge.name}
              placeholder={t("name")}
              onChange={(e) =>
                setNewBridge({
                  ...newBridge,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className={"flex flex-col"}>
            <FormField.Label htmlFor="BridgeDescription" className="pb-2 pt-4">
              <span>{t("description")}</span>
            </FormField.Label>
            <Input
              className="w-full border"
              name="BridgeDescription"
              type="text"
              value={newBridge.description}
              placeholder={t("description-voluntary")}
              onChange={(e) =>
                setNewBridge({
                  ...newBridge,
                  description: e.target.value,
                })
              }
            />
          </div>
          <CodeCheck onCheck={onCheck} />
          {checkCodeError && (
            <FormField.Error>{t("bridge-code-invalid")}</FormField.Error>
          )}
        </div>
        <div className="flex w-full justify-end">
          <Button
            className="w-fit"
            type="submit"
            disabled={!hasValidBridgeInfo() || checkCodeError}
          >
            {t("add")}
          </Button>
        </div>
      </Form>
    </DefaultSideSheet>
  );
};
