import {
  useAdminCreateUser,
  useGetOrganizationUsers,
  useUpdateUser,
} from "@api/users";
import { useState } from "react";
import { CreateUserRequest, User } from "../../../../../api-contracts/admin";
import { useAdmin } from "../context";
import { useGetAllAvailableGrants } from "@api/roles";
import { CreateUserForm, EditUserForm } from "../components/forms/users";
import { OrgUserCard } from "../components/user-cards";
import { PatchOrgUserRequest } from "../../../../../api-contracts/organizations";

export const OrgUserSection: React.FC<unknown> = () => {
  const { state } = useAdmin();
  const { mutate: createUser } = useAdminCreateUser();
  const { mutate: updateUser } = useUpdateUser();

  const { data: grants, isLoading: loadingGrants } = useGetAllAvailableGrants({
    variables: {
      orgId: state.organization?.id ?? "",
    },
  });
  const { data: users, isLoading: loadingUsers } = useGetOrganizationUsers({
    variables: {
      orgId: state.organization?.id ?? "",
    },
  });
  const [editUser, setEditUser] = useState<User | null>(null);
  const [loadingUserUpdate, setLoadingUserUpdate] = useState(false);

  const toggleEditUser = (user: User) => {
    if (user.id === editUser?.id) {
      return setEditUser(null);
    }
    setEditUser(user);
  };

  const onCreateUserSubmit = (body: Omit<CreateUserRequest, "orgId">) => {
    setLoadingUserUpdate(true);
    createUser(
      {
        ...body,
        orgId: state.organization?.id ?? "",
      },
      { onSettled: () => setLoadingUserUpdate(false) },
    );
  };

  const onEditUserSubmit = (req: { user: PatchOrgUserRequest; id: string }) => {
    setLoadingUserUpdate(true);
    setEditUser(null);
    updateUser(
      {
        user: req.user,
        userId: req.id,
        orgId: state.organization?.id ?? "",
      },
      { onSettled: () => setLoadingUserUpdate(false) },
    );
  };
  const loading = loadingUsers && !loadingGrants;

  return (
    <>
      <div className="flex w-full space-x-6 divide-x">
        <div className="max-h-[50vh] w-1/2 space-y-4 overflow-scroll p-4">
          <>
            {users?.map((user) => (
              <OrgUserCard
                key={user.id}
                user={user}
                allGrants={grants?.organization ?? []}
                onEditClick={toggleEditUser}
                loading={loading}
              />
            ))}
          </>
        </div>
        <div className="w-1/2 pl-6">
          <h2 className="mb-4 text-xl font-semibold">
            {" "}
            {!editUser ? "Create User" : "Edit User"}{" "}
          </h2>
          {!editUser ? (
            <CreateUserForm
              grants={grants?.organization ?? []}
              loading={loadingUserUpdate}
              onSubmit={onCreateUserSubmit}
              loadingGrants={loadingGrants}
            />
          ) : (
            <EditUserForm
              user={editUser}
              grants={grants?.organization ?? []}
              loading={loadingUserUpdate}
              onSubmit={onEditUserSubmit}
              loadingGrants={loadingGrants}
            />
          )}
        </div>
      </div>
    </>
  );
};
