import { forwardRef, HTMLAttributes, useEffect, useRef, useState } from "react";
import { FilterIcon } from "lucide-react";
import { cn } from "@utils/css.ts";
import { LocationProps } from "../../../settings/locations/components/locations-hierarchy-tree.tsx";
import { Button } from "@primitives/button.tsx";
import { LocationsFilterDropdown } from "../../../settings/locations/components/locations-filter-dropdown.tsx";
import { SearchBarWithIcon } from "@primitives/search-bar-with-icon";
import { Form } from "react-router-dom";
import {
  LocationsAction,
  useLocationsPageContext,
} from "@pages/settings/locations/locations-context.tsx";
import { BookablesFilterDropdown } from "@pages/settings/locations/components/bookables-filter-dropdown.tsx";
import { AssetOverview } from "../../../../../../api-contracts/assets";

interface SettingsMenuProps extends HTMLAttributes<HTMLDivElement> {
  filters?: LocationProps[] | AssetOverview[];
  onSearchInput?: (value: string) => void;
  onAddFilter?: (filterIds: string[]) => void;
  filterMenuLabel?: string;
}

export const LocationsSettingsMenu = forwardRef<
  HTMLDivElement,
  SettingsMenuProps
>(
  (
    {
      filters,
      className,
      onSearchInput,
      onAddFilter,
      filterMenuLabel,
      ...props
    }: SettingsMenuProps,
    ref,
  ) => {
    const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");
    const {
      sharedState: { actionType },
    } = useLocationsPageContext();

    useEffect(() => {
      onSearchInput && onSearchInput(value);
    }, [value]);

    useEffect(() => {
      isSearchExpanded && input1.current && input1.current.focus();
    }, [isSearchExpanded]);

    const input1 = useRef<HTMLInputElement>(null);
    return (
      <div
        className={cn(`flex items-center justify-start space-x-2`, className)}
        ref={ref}
        {...props}
      >
        <Form>
          <SearchBarWithIcon
            searchTerm={value}
            setSearchTerm={setValue}
            isExpanded={isSearchExpanded}
            setIsExpanded={setIsSearchExpanded}
          />
        </Form>

        {filters &&
          onAddFilter &&
          (actionType === LocationsAction.ASSET_ADD ? (
            <BookablesFilterDropdown
              filters={filters as AssetOverview[]}
              onClick={onAddFilter}
              menuLabel={filterMenuLabel ?? ""}
            />
          ) : (
            <LocationsFilterDropdown
              filters={filters as LocationProps[]}
              onClick={onAddFilter}
              menuLabel={filterMenuLabel ?? ""}
            >
              <Button size={"icon"} variant="secondary">
                <FilterIcon size={17} />
              </Button>
            </LocationsFilterDropdown>
          ))}
      </div>
    );
  },
);
