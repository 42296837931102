import { Button } from "@primitives/button";
import { SearchBar } from "@primitives/search-bar";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetPriceRulesPriceRule } from "../../../../../../api-contracts/price-calendar";
import { Check } from "lucide-react";

export const PriceRuleSelect = ({
  priceRules,
  priceRuleOfDay,
  toggleDropdown,
  setPriceRule,
  deletePriceRule,
}: {
  priceRules: GetPriceRulesPriceRule[];
  priceRuleOfDay: GetPriceRulesPriceRule;
  toggleDropdown: () => void;
  setPriceRule: (id: string) => void;
  deletePriceRule: () => void;
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedPriceRule, setSelectedPriceRule] =
    useState<GetPriceRulesPriceRule>(priceRuleOfDay);

  const filteredOptions = useMemo(
    () =>
      priceRules.filter((priceRule) =>
        priceRule?.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [searchTerm, priceRules],
  );

  const handleOnSearch = (e: any) => {
    e.stopPropagation();
    setSearchTerm(e.target.value);
  };

  const handleSelectPriceRule = (priceRule: GetPriceRulesPriceRule) => {
    setSelectedPriceRule(priceRule);
  };

  const handleDeletePriceRule = () => {
    setSelectedPriceRule({ name: "", id: "" });
    deletePriceRule();
    toggleDropdown();
  };

  const handleSetPriceRule = (id: string) => {
    setPriceRule(id);
    toggleDropdown();
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <SearchBar
        value={searchTerm}
        onChange={handleOnSearch}
        placeholder={`${t("search")}...`}
        className="rounded-none border-l-0 border-r-0 border-t-0 bg-transparent text-sm font-normal  focus-visible:ring-0"
      />
      {filteredOptions.map((option) => {
        const isChecked: boolean = selectedPriceRule.id === option.id;
        return (
          <div
            key={option.id}
            className="flex cursor-pointer items-center py-1 hover:bg-gray-100"
            onClick={() => handleSelectPriceRule(option)}
          >
            {isChecked && <Check className="mx-1 h-4 w-4" />}
            <p className={`text-sm ${!isChecked && "ml-6"}`}>{option.name}</p>
          </div>
        );
      })}
      <button
        onClick={handleDeletePriceRule}
        style={{ backgroundColor: "rgba(220, 38, 38, 0.1)" }}
        className="flex w-full justify-center border-y  border-highlighted-backplate p-1 text-sm font-extrabold text-status-error hover:bg-hover "
      >
        {t("delete")}
      </button>
      <div className="flex overflow-hidden">
        <Button
          onClick={toggleDropdown}
          className="h-8 w-full rounded-r-none rounded-se-none rounded-ss-none"
          variant={"ghost"}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => handleSetPriceRule(selectedPriceRule.id)}
          className="h-8 w-full rounded-l-none rounded-es-none rounded-se-none"
        >
          {t("save")}
        </Button>
      </div>
    </div>
  );
};
