import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CreateChannelRequest,
  GetChannelResponse,
  GetChannelsResponse,
  PatchChannelRequest,
} from "../../../../api-contracts/channels";

export const CHANNELS_QUERY_KEY = "channels";

export const useGetChannel = createQuery<GetChannelResponse>({
  primaryKey: CHANNELS_QUERY_KEY,
  queryFn: async ({ queryKey: [channelId] }) =>
    api.get(`/api/${currentSite?.id}/${CHANNELS_QUERY_KEY}/${channelId}`),
});
export const useGetChannels = createQuery<GetChannelsResponse>({
  primaryKey: CHANNELS_QUERY_KEY,
  queryFn: async () => api.get(`/api/${currentSite?.id}/${CHANNELS_QUERY_KEY}`),
});

export const useCreateChannel = createMutation<
  GetChannelsResponse,
  CreateChannelRequest
>({
  mutationFn: async (data: CreateChannelRequest) =>
    api.post(`/api/${currentSite?.id}/${CHANNELS_QUERY_KEY}`, {
      name: data.name,
      url: data.url,
    }),
});

export const useUpdateChannel = createMutation<
  GetChannelsResponse,
  { body: PatchChannelRequest; id: string }
>({
  mutationFn: async (data) =>
    api.patch(
      `/api/${currentSite?.id}/${CHANNELS_QUERY_KEY}/${data.id}`,
      data.body,
    ),
});

export const useDeleteChannel = createMutation<null, { id: string }>({
  mutationFn: async (data) =>
    api.delete(`/api/${currentSite?.id}/${CHANNELS_QUERY_KEY}/${data.id}`),
});
