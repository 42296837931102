import { DropdownMenu } from "@shared/primitives/dropdown-menu.tsx";
import { Edit, Ellipsis, Trash2 } from "lucide-react";
import React, { ReactNode, useState } from "react";
import { AlertDialog } from "@shared/primitives/alert-dialog.tsx";
import { useTranslation } from "react-i18next";

type DropDownProps = {
  onEdit?: () => void;
  onMove?: () => void;
  deleteDisplayText?: string;
  onDelete?: () => void;
  alertTitle?: string;
  alertDescription?: string;
  alertSubmitButtonText?: string;
  children?: ReactNode | ReactNode[];
};

export const DefaultDropdownMenu = (props: DropDownProps) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <>
      {alertOpen && props.onDelete && (
        <AlertDialog.Root
          open={alertOpen}
          onOpenChange={() => setAlertOpen(false)}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>
              <AlertDialog.Title>{props.alertTitle}</AlertDialog.Title>
            </AlertDialog.Header>
            <AlertDialog.Description>
              {props.alertDescription}
            </AlertDialog.Description>
            <AlertDialog.Footer>
              <AlertDialog.Cancel
                onClick={(e) => {
                  setAlertOpen(false);
                  e.stopPropagation();
                }}
              >
                {t("cancel")}
              </AlertDialog.Cancel>
              <AlertDialog.Action
                onClick={(e) => {
                  props.onDelete && props.onDelete();
                  setAlertOpen(false);
                  e.stopPropagation();
                }}
              >
                {props.alertSubmitButtonText}
              </AlertDialog.Action>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog.Root>
      )}

      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <Ellipsis size={18} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className={"cursor-pointer"}>
          {props.onMove && (
            <DropdownMenu.Item
              onClick={() => {
                props.onMove && props.onMove();
              }}
            >
              <Edit className="mr-3 h-[12px] w-[12px] text-xs" />
              {t("move")}
            </DropdownMenu.Item>
          )}
          {props.onEdit && (
            <DropdownMenu.Item
              className="exclude-child"
              onClick={() => {
                props.onEdit && props.onEdit();
              }}
            >
              <Edit className="mr-3 h-[12px] w-[12px] text-xs" />
              {t("edit")}
            </DropdownMenu.Item>
          )}
          {props.children}
          {props.onDelete && (
            <DropdownMenu.Item
              onClick={(e) => {
                setAlertOpen(true);
                e.stopPropagation();
              }}
            >
              <Trash2 className="mr-2 h-[13px] w-[13px] p-0 text-xs text-status-error" />
              <span>{props.deleteDisplayText ?? t("delete")}</span>
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
};
