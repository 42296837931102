import { ChangeEvent, FormEvent, useState } from "react";
import { CreateRoleRequest } from "../../../../../../api-contracts/organizations";
import { ToggleGroup, ToggleGroupItem } from "@primitives/toggle-group";
import { Button } from "@primitives/button";
import { ExtendedRole } from "../../../admin/sections/org-roles";

export const RoleForm = ({
  copy,
  allGrants,
  initGrants,
  initName,
  loading,
  onSubmit: submitToParent,
  onCancel,
}: {
  copy: {
    placeholder: string;
    action: string;
    label: string;
    cancel?: string;
  };
  allGrants: string[];
  initGrants?: string[];
  initName?: string;
  loading: boolean;
  onSubmit: (role: CreateRoleRequest) => void;
  onCancel: () => void;
}) => {
  const [name, setName] = useState(initName ?? "");
  const [grants, setGrants] = useState(initGrants ?? []);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(() => e.target.value);
  };
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitToParent({ name, actions: grants });
    setName("");
    setGrants([]);
  };

  return (
    <form className="" onSubmit={onSubmit}>
      <div className="mb-4 flex">
        <label
          className="h-100  flex items-center rounded-l border border-r-0 bg-gray-50 p-1 pl-4 pr-4 text-sm font-bold text-gray-700"
          htmlFor="name"
        >
          {copy.label}
        </label>
        <input
          className="w-full appearance-none rounded-r border px-3 py-2 focus:outline-none"
          id="name"
          type="text"
          value={name}
          onChange={onNameChange}
          placeholder={copy.placeholder}
        />
      </div>
      <div className="mb-4 flex w-full flex-col rounded-md border p-2 pb-4 pt-4 shadow">
        <ToggleGroup
          type="multiple"
          variant="outline"
          className="flex flex-wrap"
          onValueChange={setGrants}
          value={grants}
        >
          {allGrants.map((grant) => (
            <ToggleGroupItem
              key={grant}
              value={grant}
              aria-label={`Toggle ${grant}`}
            >
              <span>{grant}</span>
            </ToggleGroupItem>
          ))}
        </ToggleGroup>
      </div>
      <div className="flex items-center space-x-4">
        {copy.cancel !== undefined && (
          <Button loading={loading} variant={"outline"} onClick={onCancel}>
            {copy.cancel}
          </Button>
        )}
        <Button
          loading={loading}
          variant={"purple"}
          disabled={name.length === 0 || grants.length === 0}
          type="submit"
        >
          {copy.action}
        </Button>
      </div>
    </form>
  );
};

export const UpdateRoleForm = ({
  allGrants,
  loading,
  onSubmit,
  role,
  onCancel,
}: {
  allGrants: string[];
  loading: boolean;
  role: ExtendedRole;
  onSubmit: (role: CreateRoleRequest) => void;
  onCancel: () => void;
}) => (
  <RoleForm
    copy={{
      action: "Submit",
      label: "Name",
      placeholder: "Name of role",
      cancel: "Cancel",
    }}
    allGrants={allGrants}
    loading={loading}
    onSubmit={onSubmit}
    initGrants={role.grants
      .filter((role) => role.enabled)
      .map((grant) => grant.grant)}
    initName={role.name}
    onCancel={onCancel}
  />
);
export const CreateRoleForm = ({
  allGrants,
  loading,
  onSubmit,
}: {
  allGrants: string[];
  loading: boolean;
  onSubmit: (role: CreateRoleRequest) => void;
}) => (
  <RoleForm
    copy={{ action: "Submit", label: "Name", placeholder: "Name of rule" }}
    allGrants={allGrants}
    loading={loading}
    onSubmit={onSubmit}
    onCancel={() => null}
  />
);
