import { TitlesActionType, useTitlesPageContext } from "../titles-context.tsx";
import { DefaultInput } from "@primitives/default-input.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { useTranslation } from "react-i18next";

export const EditTitle = () => {
  const {
    sharedState: { action, currentTitle },
    sharedFunctions: { setAction, updateTitle },
  } = useTitlesPageContext();
  const { t } = useTranslation();

  return (
    <DefaultSideSheet
      title={`${t("edit")} ${currentTitle?.name ?? ""}`}
      open={action === TitlesActionType.EDIT}
      onOpenChange={() => setAction(TitlesActionType.DEFAULT)}
    >
      <DefaultInput
        onSubmit={(value) =>
          currentTitle &&
          updateTitle(currentTitle.id, {
            active: currentTitle.active,
            name: value,
          })
        }
        buttonText={`${t("done")}`}
        inputName={"edit-title"}
        placeholder={`${t("name")}`}
        required={true}
        defaultValue={currentTitle?.name}
      />
    </DefaultSideSheet>
  );
};
