import { TabNavbar } from "@components/tab-navbar";
import { useProfileContext } from "@context/profile-context";
import { ROUTES } from "@shared/types/navigation";
import { useTranslation } from "react-i18next";

export const CategoryTabs = ({
  tab,
}: {
  tab: "all" | "hotel" | "area" | "hostel";
}) => {
  const { t } = useTranslation();
  const { module } = useProfileContext();

  return (
    <TabNavbar
      value={tab.toString()}
      items={[
        {
          value: "all",
          label: t("all"),
          to: `/${module}/${ROUTES.ARRIVALS}`,
        },
        {
          value: "hotel",
          label: t("hotel"),
          to: `/${module}/${ROUTES.ARRIVALS_HOTEL}`,
        },
        {
          value: "area",
          label: t("area"),
          to: `/${module}/${ROUTES.ARRIVALS_AREA}`,
        },
        {
          value: "hostel",
          label: t("hostel"),
          to: `/${module}/${ROUTES.ARRIVALS_HOSTEL}`,
        },
      ]}
    />
  );
};
