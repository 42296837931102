import { createMutation, createQuery } from "react-query-kit";
import { api } from "./api";
import { queryClient } from "../../query-client";
import {
  GetReservationStatusesResponse,
  PatchReservationStatusesRequest,
  PatchReservationStatusesResponse,
} from "../../../../api-contracts/reservations";

const RESERVATION_STATUS_KEY = "/api/<siteId>/reservation/status";

export const useGetBookableStatuses = createQuery<
  GetReservationStatusesResponse,
  { siteId: string }
>({
  primaryKey: RESERVATION_STATUS_KEY,
  queryFn: async ({ queryKey: [primaryKey, variables] }) => {
    const url = primaryKey.replace("<siteId>", variables.siteId);
    return api.get(url);
  },
});

export const usePatchBookableStatuses = createMutation<
  PatchReservationStatusesResponse,
  { body: PatchReservationStatusesRequest; siteId: string }
>({
  mutationFn: async (variables) => {
    const url = RESERVATION_STATUS_KEY.replace("<siteId>", variables.siteId);
    return api.patch(url, variables.body);
  },
  onSuccess: (_, variables) =>
    queryClient.invalidateQueries({
      queryKey: [RESERVATION_STATUS_KEY, { siteId: variables.siteId }],
    }),
});
