import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

import {
  type ToastActionElement,
  ToastProps,
} from "@shared/primitives/toast.tsx";
import { useToast } from "@shared/hooks/use-toast.ts";

type Toast = ToastProps & {
  id: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  action?: ToastActionElement;
  variant?: string;
  retry?: () => void;
};

interface SharedStateProps {
  isLoading: boolean;
}

interface SharedFunctionsProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
  toast: ({ ...props }: Omit<Toast, "id">) => {
    id: string;
    dismiss: () => void;
    update: (props: Toast) => void;
  };
}

interface SharedContextProps {
  sharedState: SharedStateProps;
  sharedFunctions: SharedFunctionsProps;
}

interface SharedProviderProps {
  children: ReactNode;
}

const SharedContext = createContext<SharedContextProps | undefined>(undefined);

export const useSharedContext = () => {
  const context = useContext(SharedContext);

  if (!context) {
    throw new Error("context must be used in correct provider");
  }

  return context;
};

export const SharedProvider: React.FC<SharedProviderProps> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const sharedState = {
    isLoading,
  };
  const sharedFunctions = {
    setLoading,
    toast,
  };
  return (
    <SharedContext.Provider value={{ sharedState, sharedFunctions }}>
      {children}
    </SharedContext.Provider>
  );
};
