import { useTranslation } from "react-i18next";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import {
  GridItem,
  GridRow,
} from "@pages/booking/price-rules/components/pricing-grid.tsx";
import { BaseProps } from "@shared/types/props.ts";
import { usePriceRulesPageContext } from "@pages/booking/price-rules/price-rules-context.tsx";
import { Badge } from "@primitives/badge.tsx";
import { cn } from "@utils/css.ts";
import React from "react";
import { PriceRulesDropDown } from "@pages/booking/price-rules/components/price-rules-drop-down.tsx";

type RateActionType = "enable" | "disable" | "duplicate" | "modify";

interface RateAction {
  (rateId: string, action: RateActionType): void;
}
interface RateHeaderProps extends BaseProps {
  hidden: boolean;
  onDropDownItemClick?: RateAction;
}

export const PriceRuleHeader = ({ hidden, ...rest }: RateHeaderProps) => {
  const {
    sharedState: { activeFilters, showStatus, rules },
    sharedFunctions: { onDropDownItemClick },
  } = usePriceRulesPageContext();

  const { t } = useTranslation();

  return hidden ? null : (
    <GridRow className={cn(showStatus && "h-28")}>
      {rules.map((rule, colIdx) => (
        <PriceRulesDropDown
          key={`${rule.id}-${colIdx}`}
          items={[
            {
              display: t("duplicate"),
              onClick: () => onDropDownItemClick(rule.id, "duplicate"),
            },
            {
              display: t("modify"),
              onClick: () => onDropDownItemClick(rule.id, "modify"),
            },
            {
              display: rule.active ? t("disable") : t("enable"),
              onClick: () =>
                onDropDownItemClick(
                  rule.id,
                  rule.active ? "disable" : "enable",
                ),
            },
          ]}
          className={""}
        >
          <GridItem
            as="button"
            className={cn(showStatus && "h-28 items-start pt-5")}
            {...rest}
          >
            <div
              className={cn(
                "flex w-full",
                showStatus &&
                  "h-[80%] flex-col items-center justify-between border-0",
              )}
            >
              <div className="flex w-full justify-center overflow-x-hidden text-ellipsis">
                <span className="truncate text-ellipsis border-0 text-[15px] text-secondary-text">
                  {rule.name}
                </span>
                <DotsVerticalIcon className="ml-3 h-4 w-4 shrink-0" />
              </div>
              {activeFilters.includes("status") && !rule.active && (
                <Badge className="bg-blush-50 font-bold text-blush-600">
                  {t("price-rules-inactive")}
                </Badge>
              )}
            </div>
          </GridItem>
        </PriceRulesDropDown>
      ))}
    </GridRow>
  );
};
