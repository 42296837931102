import { Card } from "@primitives/card.tsx";
import { useTranslation } from "react-i18next";
import { Infinity, Pen } from "lucide-react";
import { Button } from "@primitives/button.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table.tsx";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Space } from "@components/space.tsx";
import { Input } from "@primitives/input.tsx";
import { useGetBedAgeSpanSettings } from "@api/bed-types.ts";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";

interface AgePrio {
  ageFrom: number;
  ageTo: number;
  firstBedPrio?: string;
  secondBedPrio?: string;
}

export const AgeRangeTable = () => {
  const { t } = useTranslation();
  const { postSetting } = useBedData();
  const [edit, setEdit] = useState(false);
  const [agePrio, setAgePrio] = useState<AgePrio[]>([]);
  const agePrioColumnsHelper = createColumnHelper<AgePrio>();
  const { data: settings } = useGetBedAgeSpanSettings();
  const [fullBed, setFullBed] = useState<{ ageFrom: number; ageTo: number }>({
    ageFrom: 0,
    ageTo: 0,
  });
  const [halfBed, setHalfBed] = useState<{ ageFrom: number; ageTo: number }>({
    ageFrom: 0,
    ageTo: 0,
  });
  const [cot, setCot] = useState<{ ageFrom: number; ageTo: number }>({
    ageFrom: 0,
    ageTo: 0,
  });
  const full = t("full").charAt(0).toUpperCase() + t("full").slice(1);

  useEffect(() => {
    if (settings) {
      const prio: AgePrio[] = [
        {
          ageFrom: settings.max_age_half_size + 1,
          ageTo: 0,
          firstBedPrio: full,
          secondBedPrio: "",
        },
        {
          ageFrom: settings.max_age_cot + 1,
          ageTo: settings.max_age_half_size,
          firstBedPrio: full,
          secondBedPrio: t("shared-bed"),
        },
        {
          ageFrom: 0,
          ageTo: settings?.max_age_cot,
          firstBedPrio: t("shared-bed"),
          secondBedPrio: t("cot"),
        },
      ];
      setAgePrio(prio);
    }
  }, [settings, edit]);

  useEffect(() => {
    if (agePrio.length > 0) {
      setFullBed({ ageFrom: agePrio[0].ageFrom, ageTo: agePrio[0].ageTo });
      setHalfBed({ ageFrom: agePrio[1].ageFrom, ageTo: agePrio[1].ageTo });
      setCot({ ageFrom: agePrio[2].ageFrom, ageTo: agePrio[2].ageTo });
    }
  }, [agePrio, edit]);

  const setFullBedRules = (value: number) => {
    if (fullBed && halfBed) {
      setFullBed({
        ...fullBed,
        ageFrom: value,
      });
      setHalfBed({
        ...halfBed,
        ageTo: value - 1,
      });
    }
  };
  const setHalfBedFromRules = (value: number) => {
    if (cot && halfBed) {
      setHalfBed({
        ...halfBed,
        ageFrom: value,
      });
      setCot({
        ...cot,
        ageTo: value - 1,
      });
    }
  };
  const setHalfBedToRules = (value: number) => {
    if (fullBed && halfBed) {
      setHalfBed({
        ...halfBed,
        ageTo: value,
      });
      setFullBed({
        ...fullBed,
        ageFrom: value + 1,
      });
    }
  };
  const setCotRules = (value: number) => {
    if (cot && halfBed) {
      setCot({
        ...cot,
        ageTo: value,
      });
      setHalfBed({
        ...halfBed,
        ageFrom: value + 1,
      });
    }
  };

  const saveRule = () => {
    if (cot && halfBed)
      postSetting({
        max_age_cot: cot?.ageTo,
        max_age_half_size: halfBed?.ageTo,
      }).then(() => setEdit(!edit));
  };

  const standardBedsColumnDefinitions = [
    agePrioColumnsHelper.accessor("ageFrom", {
      header: t("age-from"),
      id: "ageFrom",
      cell: (data) => {
        if (!edit) return <div>{data.row.original.ageFrom}</div>;
        if (edit && data.cell.id === "0_ageFrom") {
          return (
            <Input
              onChange={(event) => setFullBedRules(Number(event.target.value))}
              className={
                "ml-0 font-neue text-[15px] font-normal text-secondary-text"
              }
              min={halfBed?.ageFrom + 2}
              type={"number"}
              value={fullBed?.ageFrom}
            />
          );
        } else if (edit && data.cell.id === "1_ageFrom") {
          return (
            <Input
              onChange={(event) =>
                setHalfBedFromRules(Number(event.target.value))
              }
              className={
                "ml-0 font-neue text-[15px] font-normal text-secondary-text"
              }
              type={"number"}
              value={halfBed?.ageFrom}
            />
          );
        } else return <div>{data.row.original.ageFrom}</div>;
      },
    }),
    agePrioColumnsHelper.accessor("ageTo", {
      header: t("age-to"),
      id: "ageTo",
      cell: (data) => {
        if (data.cell.id === "0_ageTo") {
          return <Infinity />;
        } else if (edit && data.cell.id === "1_ageTo") {
          return (
            <Input
              onChange={(value) => {
                setHalfBedToRules(Number(value.target.value));
              }}
              className={
                "ml-0 font-neue text-[15px] font-normal text-secondary-text"
              }
              min={halfBed?.ageFrom + 1}
              type={"number"}
              value={halfBed?.ageTo}
            />
          );
        } else if (edit && data.cell.id === "2_ageTo") {
          return (
            <Input
              onChange={(value) => {
                setCotRules(Number(value.target.value));
              }}
              className={
                "ml-0 font-neue text-[15px] font-normal text-secondary-text"
              }
              min={1}
              type={"number"}
              value={cot?.ageTo}
            />
          );
        } else return <div>{data.row.original.ageTo}</div>;
      },
    }),
    agePrioColumnsHelper.accessor("firstBedPrio", {
      header: t("first-bed-priority"),
      id: "firstBedPrio",
    }),
    agePrioColumnsHelper.accessor("secondBedPrio", {
      header: t("-bed-priority"),
      id: "secondBedPrio",
    }),
  ];
  const table = useReactTable({
    data: agePrio,
    columns: standardBedsColumnDefinitions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  return (
    <Card className="h-full w-full">
      <div className={"mb-3"}>
        <p className={"font-extrabold"}>{t("age-range")}</p>
      </div>
      {!edit ? (
        <>
          <Button
            onClick={() => setEdit(!edit)}
            className={"rounded-xl"}
            variant="primary"
            size="icon"
          >
            <Pen size={17} />
          </Button>
        </>
      ) : (
        <div>
          <Button
            onClick={() => setEdit((prevState) => !prevState)}
            className={"mr-2 w-[70px] rounded-xl"}
            variant="outline"
            size="icon"
          >
            Avbryt
          </Button>
          <Button
            onClick={saveRule}
            className={"w-[70px] rounded-xl"}
            variant="primary"
            size="icon"
          >
            Spara
          </Button>
        </div>
      )}
      <Space h={20} />
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead className={"select-none"} key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      className="flex cursor-pointer items-center"
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div>
                  )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                className={"hover:bg-secondary-card-backplate"}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}px`,
                        height: "74px",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={table.getAllLeafColumns().length}
                className="h-24 text-center"
              >
                {t("no-results")}.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
};
