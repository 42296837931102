import { useAuth } from "@hooks/use-auth";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

function Logout() {
  const { user, clearAuth } = useAuth();

  useEffect(() => {
    clearAuth();
  }, [clearAuth]);

  return user ? null : <Navigate to={"/login"} replace />;
}

export default Logout;
