import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  BedAccessory,
  BedTypeAccessory,
} from "../../../../../../api-contracts/bed-capacity";
import { CreateEdit } from "@pages/settings/bed-types/components/standard-bed-sheet.tsx";
import { useTranslation } from "react-i18next";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";
import { useGetBedLinen } from "@api/bed-types.ts";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { Card } from "@primitives/card.tsx";
import { Input } from "@primitives/input.tsx";
import { Space } from "@components/space.tsx";
import { PlaceholderInput } from "@pages/settings/categories/components/placeholder-input.tsx";
import { MultiSelect } from "@primitives/multi-select.tsx";
import { X } from "lucide-react";
import { Button } from "@primitives/button.tsx";
import { Textarea } from "@primitives/textarea.tsx";

interface Props {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  accessoryData: BedAccessory | undefined;
  type: CreateEdit;
  btnText: string;
}

export const BedAccessorySheet: FC<Props> = ({
  open,
  onOpenChange,
  accessoryData,
  type,
  btnText,
}): ReactElement => {
  const emptyAccessory: BedAccessory = {
    id: "",
    name: "",
    width: 0,
    length: 0,
    height: 0,
    description: "",
    bedlinen: [],
  };
  const { t } = useTranslation();
  const { createAccessory, updateAccessory } = useBedData();
  const { data: bedLinen } = useGetBedLinen();
  const [accessory, setAccessory] = useState<BedAccessory>(emptyAccessory);
  const [selectedLinens, setSelectedLinens] = useState<BedTypeAccessory[]>([]);
  const [availableLinens, setAvailableLinens] = useState<BedTypeAccessory[]>(
    [],
  );
  useEffect(() => {
    setAccessory(accessoryData ?? emptyAccessory);
    setSelectedLinens(accessoryData?.bedlinen ?? []);
  }, [accessoryData]);

  useEffect(() => {
    if (!bedLinen) return;
    const list = bedLinen.map((elem) => ({
      id: elem.id,
      name: elem.name,
      quantity: 1,
    }));
    setAvailableLinens(list);
  }, [bedLinen]);

  const updateQuantity = (id: string, quantity: number) => {
    const newList = selectedLinens?.map((item) => {
      if (item.id == id) {
        return { ...item, quantity: quantity };
      } else {
        return item;
      }
    });
    setSelectedLinens(newList);
  };

  return (
    <DefaultSideSheet
      title={type === "edit" ? t("edit") : t("create-accessory")}
      open={open}
      onOpenChange={onOpenChange}
    >
      <div className={"flex h-full flex-col justify-between overflow-auto"}>
        <div>
          <Card className={" mt-0 bg-secondary-card-backplate"}>
            <div>
              <p className={"font-extrabold"}>{t("accessory-information")}</p>
            </div>
            <div className={"mt-3"}>
              <div className={"flex"}>
                <p>{t("name")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <Input
                className={
                  "font-neue text-[15px] font-normal text-secondary-text"
                }
                type={"text"}
                value={accessory.name ?? t("name")}
                onChange={(event) =>
                  setAccessory({ ...accessory, name: event.target.value })
                }
                placeholder={t("name")}
              />
              <Space h={8} />
              <div className={"flex"}>
                <p>{t("width")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                defaultValue={t("width")}
                placeholder={"cm"}
                value={
                  accessory.width === 0
                    ? t("width")
                    : accessory.width?.toString()
                }
                onChange={(event) => {
                  setAccessory({
                    ...accessory,
                    width: Number(event.target.value),
                  });
                }}
              />
              <Space h={8} />
              <div className={" flex"}>
                <p>{t("length")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                defaultValue={t("length")}
                placeholder={"cm"}
                value={
                  accessory.length === 0
                    ? t("length")
                    : accessory.length?.toString()
                }
                onChange={(event) =>
                  setAccessory({
                    ...accessory,
                    length: Number(event.target.value),
                  })
                }
              />
              <Space h={8} />
              <div className={" flex"}>
                <p>Height</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                defaultValue={t("length")}
                placeholder={"cm"}
                value={
                  accessory.height === 0
                    ? t("length")
                    : accessory.height?.toString()
                }
                onChange={(event) =>
                  setAccessory({
                    ...accessory,
                    height: Number(event.target.value),
                  })
                }
              />
            </div>
            <Space h={20} />
            <Textarea
              placeholder={t("description")}
              disabled={false}
              value={accessory.description ?? ""}
              onChange={(e) =>
                setAccessory({ ...accessory, description: e.target.value })
              }
            />
          </Card>
          <Space h={18} />
          <Card className={" bg-secondary-card-backplate"}>
            <div>
              <p className={"font-extrabold"}>
                {t("accessory-dropdown-title")}
              </p>
            </div>
            <div className={"mt-3"}>
              {bedLinen && (
                <MultiSelect
                  placeholder={t("accessory-dropdown-title")}
                  options={availableLinens}
                  value={availableLinens.filter((group) =>
                    selectedLinens?.some(
                      (selected) => selected.name === group.name,
                    ),
                  )}
                  onChange={(value: BedTypeAccessory[]) =>
                    setSelectedLinens(value)
                  }
                  getName={(opt) => opt.name}
                />
              )}
            </div>
            <div>
              <Space h={12} />
              {selectedLinens?.map((accessory) => {
                return (
                  <div key={accessory.id}>
                    <Card
                      className={
                        "rounded-xl, flex h-[50px] items-center bg-primary-card-backplate"
                      }
                    >
                      <div className={"flex w-full justify-between"}>
                        <div className={"flex items-center"}>
                          <X
                            onClick={() => {
                              setSelectedLinens(
                                selectedLinens?.filter(
                                  (item) => item.name !== accessory.name,
                                ),
                              );
                            }}
                            size={14}
                            className={"cursor-pointer text-primary-icon-color"}
                          />
                          <Space w={12.5} />
                          <p
                            key={accessory.name}
                            className={
                              "h-[12px] font-neue text-xs font-extrabold"
                            }
                          >
                            {accessory.name}
                          </p>
                        </div>
                        <div>
                          <Input
                            min={1}
                            type={"number"}
                            onChange={(event) =>
                              updateQuantity(
                                accessory.id,
                                Number(event.target.value),
                              )
                            }
                            value={accessory.quantity}
                            className={
                              "h-[35px] w-[35px] rounded-lg bg-primary-button-backplate p-0 text-center"
                            }
                          />
                        </div>
                      </div>
                    </Card>
                    <Space h={8} />
                  </div>
                );
              })}
            </div>
          </Card>
        </div>
        <div className="flex justify-end">
          <Button
            className={"rounded-xl"}
            onClick={() => {
              const bedLinens = selectedLinens.map((acc) => ({
                id: acc.id,
                quantity: acc.quantity,
              }));

              const accessoryToAdd = {
                name: accessory.name,
                width: accessory.width,
                length: accessory.length,
                height: accessory.height,
                description: accessory.description,
                bedlinenIds: bedLinens,
              };

              if (type === "create") {
                createAccessory(accessoryToAdd).then(() =>
                  setAccessory(emptyAccessory),
                );
              } else {
                updateAccessory(accessoryToAdd, accessory.id).then(() =>
                  setAccessory(emptyAccessory),
                );
              }
              onOpenChange(!open);
            }}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </DefaultSideSheet>
  );
};
