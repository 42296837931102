import { useAuth } from "@hooks/use-auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import MainNav from "@components/nav/main-nav";
import { CalendarClock, ConciergeBell, Settings } from "lucide-react";
import { SiteProvider } from "@context/site-context";
import { CategoriesContextProvider } from "@pages/settings/categories/categories-context";
import {
  AMENITIES_PERMISSION,
  ASSET_GROUPS_PERMISSION,
  ASSETS_PERMISSION,
  BRIDGES_PERMISSION,
  CATEGORIES_PERMISSION,
  CHANNELS_PERMISSION,
  FIXTURES_PERMISSION,
  LOCATIONS_PERMISSION,
  NavItem,
  PRICE_CALENDAR_PERMISSION,
  PRICE_RULES_PERMISSION,
  RECEPTION_PERMISSION,
  RESERVATION_PERMISSION,
  ROUTES,
  TAGS_PERMISSION,
  TARGET_GROUPS_PERMISSION,
  TITLES_PERMISSION,
} from "@shared/types/navigation";

/** Filtering NavItems on routePermissions */
const createNavItems = (
  module: string,
  routePermissions: string[],
): NavItem[] =>
  [
    {
      icon: ConciergeBell,
      name: "reception",
      subItems: [
        {
          name: "arrivals",
          urls: [
            `/${module}/${ROUTES.ARRIVALS}`,
            `/${module}/${ROUTES.ARRIVALS_HOTEL}`,
            `/${module}/${ROUTES.ARRIVALS_AREA}`,
            `/${module}/${ROUTES.ARRIVALS_HOSTEL}`,
          ],
          permissions: [RECEPTION_PERMISSION],
        },
      ].filter((subItem) =>
        subItem.permissions.some((permission) =>
          routePermissions.includes(permission),
        ),
      ),
    },
    {
      icon: CalendarClock,
      name: "booking",
      subItems: [
        {
          name: "category-grid",
          urls: [
            `/${module}/${ROUTES.CATEGORY_GRID_HOTEL}`,
            `/${module}/${ROUTES.CATEGORY_GRID_AREA}`,
            `/${module}/${ROUTES.CATEGORY_GRID_HOTEL}`,
          ],
          permissions: [RESERVATION_PERMISSION],
        },
        {
          name: "asset-grid",
          urls: [
            `/${module}/${ROUTES.ASSET_GRID_HOTEL}`,
            `/${module}/${ROUTES.ASSET_GRID_AREA}`,
            `/${module}/${ROUTES.ASSET_GRID_HOSTEL}`,
          ],
          permissions: [RESERVATION_PERMISSION],
        },
        {
          name: "price-calendar",
          urls: [`/${module}/${ROUTES.PRICE_CALENDAR}`],
          permissions: [PRICE_CALENDAR_PERMISSION],
        },
        {
          name: "price-rules-title",
          urls: [`/${module}/${ROUTES.PRICE_RULES}`],
          permissions: [PRICE_RULES_PERMISSION],
        },
      ].filter((subItem) =>
        subItem.permissions.some((permission) =>
          routePermissions.includes(permission),
        ),
      ),
    },
    {
      name: "settings",
      icon: Settings,
      subItems: [
        {
          name: "bookables-and-products",
          urls: [
            `/${module}/${ROUTES.ROOMS}`,
            `/${module}/${ROUTES.AREAS}`,
            `/${module}/${ROUTES.BEDS}`,
            `/${module}/${ROUTES.DORMITORIES}`,
            `/${module}/${ROUTES.COMBINATIONS}`,
            `/${module}/${ROUTES.ASSETS}`,
          ],
          permissions: [ASSETS_PERMISSION],
        },
        {
          name: "locations",
          urls: [`/${module}/${ROUTES.LOCATIONS}`],
          permissions: [LOCATIONS_PERMISSION],
        },
        {
          name: "titles",
          urls: [`/${module}/${ROUTES.TITLES}`],
          permissions: [TITLES_PERMISSION],
        },
        {
          name: "channels",
          urls: [`/${module}/${ROUTES.CHANNELS}`],
          permissions: [CHANNELS_PERMISSION],
        },
        {
          name: "bridges",
          urls: [`/${module}/${ROUTES.BRIDGES}`],
          permissions: [BRIDGES_PERMISSION],
        },
        {
          name: "categories",
          urls: [`/${module}/${ROUTES.CATEGORIES}`],
          permissions: [CATEGORIES_PERMISSION],
        },
        {
          name: "target-groups",
          urls: [`/${module}/${ROUTES.TARGET_GROUPS}`],
          permissions: [TARGET_GROUPS_PERMISSION],
        },
        {
          name: "bookable-status-title",
          urls: [`/${module}/${ROUTES.STATUS}`],
          permissions: [RESERVATION_PERMISSION],
        },
        {
          name: "groups",
          urls: [`/${module}/${ROUTES.GROUPS}`],
          permissions: [ASSET_GROUPS_PERMISSION],
        },
        {
          name: "tags",
          urls: [`/${module}/${ROUTES.TAGS}`],
          permissions: [TAGS_PERMISSION],
        },
        {
          name: "fixtures-and-amenities",
          urls: [`/${module}/${ROUTES.AMENITIES}`],
          permissions: [AMENITIES_PERMISSION, FIXTURES_PERMISSION],
        },
        {
          name: "bed-types-and-inventories",
          urls: [`/${module}/${ROUTES.BED_TYPES}`],
          permissions: [TAGS_PERMISSION],
        },
      ].filter((subItem) =>
        subItem.permissions.some((permission) =>
          routePermissions.includes(permission),
        ),
      ),
    },
  ].filter((item) => item.subItems && item.subItems.length > 0);

/**
 * Ensure that the user is authenticated before rendering the child route.
 */
function Protected({
  routePermissions,
  module,
}: {
  routePermissions: ROUTES[];
  module: string;
}) {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  const returnUrl = location.pathname;

  return isAuthenticated ? (
    <SiteProvider>
      <MainNav user={user} navItems={createNavItems(module, routePermissions)}>
        <CategoriesContextProvider>
          <Outlet />
        </CategoriesContextProvider>
      </MainNav>
    </SiteProvider>
  ) : (
    <Navigate to={"/login"} replace={true} state={{ returnUrl }} />
  );
}

export default Protected;
